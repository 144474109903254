export const TIMELINE_OPTIONS = {
  none: 'None',
  acceptable: 'Acceptable',
  preferred: 'Preferred',
};

export const GUARANTEE_OPTIONS = {
  none: 'None',
  less_than_12_months: 'Less than 12 months',
  equals_12_months: '12 months',
  more_than_12_months: 'More than 12 months',
};

export const TIMELINE_DESCRIPTION_MAP = {
  none: 'No timeline provided.',
  acceptable: 'Timeline in contract includes overall reno timeline.',
  preferred:
    'Timelines are clear and include overall reno timeline, weekday breakdown, and working hours each day.',
};

export const MIN_NUMBER_OF_ROWS_FOR_DELETE = 3;

import {uniq, pathOr} from 'ramda';

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import isFunction from '@renofi/utilities/src/isFunction';

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

const RETURN_REASON_NEXT_STEPS = {
  expired: ({formattedTypes}) =>
    `Please renew your ${formattedTypes}, and upload a copy as this will help keep our records up to date.`,
  illegible:
    'The information provided is a good start but there is some information that we are unable to read',
  incomplete:
    'The information provided is a good start but we are still missing some requisite information to complete our review.',
  incorrect:
    'The information is a good start but there appears to be some information incorrect for our review',
  other: '',
};

export const getMessageForReferences = ({
  selectedReferences = [],
  totalReferences = [],
}) => {
  if (!totalReferences?.length || !selectedReferences?.length) {
    return '';
  }
  const selectedRefs = uniq(
    totalReferences.reduce((arr, ref) => {
      if (!selectedReferences.includes(ref.value)) {
        return arr;
      }
      return arr.concat(ref.label);
    }, []),
  );
  const formattedRefs = formatter.format(selectedRefs);
  return `Hi,

Please check that the details you provided for ${formattedRefs} are correct and update if necessary. These references haven’t responded to our request to complete a short questionnaire. 
  
Thanks,
The RenoFi team
`;
};

export const getMessageForReturnedDocument = ({
  selectedDocuments = [],
  totalDocuments = [],
  returnReason,
}) => {
  if (!selectedDocuments?.length || !returnReason) {
    return '';
  }

  const types = uniq(
    totalDocuments.reduce((arr, doc) => {
      if (!selectedDocuments.includes(doc.id)) {
        return arr;
      }
      return arr.concat(
        humanizeSnakeCase(doc.documentType.replace('contractor', '')),
      );
    }, []),
  );
  const formattedTypes = formatter.format(types);
  const verb = types.length > 1 ? 'are' : 'is';
  const nextStep = RETURN_REASON_NEXT_STEPS[returnReason] || '';
  const formattedNextStep = isFunction(nextStep)
    ? nextStep({formattedTypes})
    : nextStep;
  return `The ${formattedTypes} you provided ${verb} ${returnReason}. ${formattedNextStep}`.trim();
};

export const getGcddDocuments = (gcddReview) => {
  return [
    pathOr(null, ['questionnaire', 'contractorLicense'], gcddReview),
    pathOr(
      null,
      ['questionnaire', 'contractorGeneralLiabilityInsurance'],
      gcddReview,
    ),
    pathOr(
      null,
      ['questionnaire', 'contractorWorkersCompInsurance'],
      gcddReview,
    ),
  ].filter((doc) => Boolean(doc) && !doc.rejectedAt && !doc.reviewResult);
};

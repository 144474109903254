import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {isSplitWideLayout, isSplitNarrowLayout} from 'lib/layoutHelper';
import {isInternalSource} from 'lib/sourceHelper';

import {
  Box,
  Flex,
  LazyImage,
  Label,
  Switch,
  Text,
} from '@renofi/components-internal';

import {Container, Controls, Photo} from './styled';

const getInitialScale = (layout) => {
  if (isSplitNarrowLayout(layout)) {
    return 0.8;
  }
  if (isSplitWideLayout(layout)) {
    return 0.6;
  }
  return 1;
};

const PropertyPhotos = ({currentSource, layout, photos = [], onSharePhoto}) => {
  const internal = isInternalSource(currentSource);

  const [scale, setScale] = useState(getInitialScale(layout));

  const scaleHandler = (level) => {
    if (!(level > 0.3 && level < 4)) return;
    setScale(level);
  };

  const wheelHandler = useCallback(
    (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        const level = scale + (event.deltaY < 0 ? 0.05 : -0.05);
        scaleHandler(level);
      }
    },
    [scale],
  );

  useEffect(() => {
    window.addEventListener('wheel', wheelHandler, {
      passive: false,
    });
    return () =>
      window.removeEventListener('wheel', wheelHandler, {
        passive: false,
      });
  }, [wheelHandler]);

  const width = scale * 1600;

  return (
    <Container>
      {photos.map(
        ({id, homeownerDescription, objectName, shareableWithAmc}) => (
          <Photo width={width} key={id}>
            {internal && (
              <Controls>
                <Box>
                  <Label>Source</Label>
                  <Text mt="6px" fontSize={14} lineHeight="17px" color="white">
                    Homeowner
                  </Text>
                </Box>
                <Box>
                  <Label>Homeowner</Label>
                  <Text mt="6px" fontSize={14} lineHeight="17px" color="white">
                    {homeownerDescription}
                  </Text>
                </Box>
                <Flex alignItems="center">
                  <Box mr={20}>
                    <Label>Share with Appraiser</Label>
                  </Box>
                  <Switch
                    checked={shareableWithAmc}
                    onClick={() => onSharePhoto(id, !shareableWithAmc)}
                  />
                </Flex>
              </Controls>
            )}
            <LazyImage objectName={objectName} alt={homeownerDescription} />
          </Photo>
        ),
      )}
    </Container>
  );
};

PropertyPhotos.propTypes = {
  currentSource: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      objectName: PropTypes.string.isRequired,
    }),
  ),
  onSharePhoto: PropTypes.func.isRequired,
};

export default React.memo(PropertyPhotos);

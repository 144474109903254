import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {UPDATE_REFERENCE} from '../../../mutations';

export default function useUpdateReference({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateReference, {data, loading, error}] = useMutation(
    UPDATE_REFERENCE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const reference = pathOr(null, ['updateReference', 'reference'], data);

  return {updateReference, reference, loading, error};
}

import {last, pathOr} from 'ramda';

import TabContent from './components/TabContent';

export const QUESTIONNAIRE_TAB = 'Contractor questionnaire';

export const getQuestionnaireTabs = ({gcddReview = {}, search}) => {
  const questionnaireDocs = gcddReview?.returnedQuestionnaireDocuments || [];
  const {documentId} = search || {};

  const currentValue = !!documentId ? documentId : QUESTIONNAIRE_TAB;
  const tabs = questionnaireDocs.reduce(
    (arr, {id, objectName}) => {
      const label = objectName ? last(objectName.split('/')) : 'PDF returned';

      return arr.concat({
        value: id,
        label,
      });
    },
    [{value: QUESTIONNAIRE_TAB, label: QUESTIONNAIRE_TAB}],
  );

  return {currentValue, tabs};
};

export const getReviewDocuments = ({gcddReview = {}, propKey, search}) => {
  const documents = pathOr([], ['contractor', propKey], gcddReview);
  const {documentId} = search || {};
  const currentValue = !!documentId
    ? documentId
    : pathOr(null, [0, 'id'], documents);

  const tabs = documents.map((doc) => ({
    value: doc.id,
    label: <TabContent doc={doc} />,
  }));

  return {currentValue, tabs};
};

export const getReferencesTabs = ({gcddReview = {}, search}) => {
  const references = pathOr([], ['questionnaire', 'references'], gcddReview);
  const {referenceId} = search || {};
  const currentValue = !!referenceId
    ? referenceId
    : pathOr(null, [0, 'id'], references);

  const tabs = references.map((ref) => ({
    label: `${ref.name} (${ref.type})`,
    value: ref.id,
  }));

  return {currentValue, tabs};
};

import React, {Fragment, useEffect, useMemo, useState} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isNil, omit} from 'ramda';
import {isContractorMessageDisabled} from 'lib/gcdd/validators';
import {
  MESSAGE_RETURN_DOCUMENT_REASON,
  MESSAGE_DELETE_DOCUMENT,
  MESSAGE_REASONS,
} from 'lib/gcdd';
import {MessageContractorModal, ModalsContext} from 'modules/modals';
import {Approve, Discard} from 'modules/viewer-v2/icons';

import {Box, Tooltip} from '@renofi/components-internal';
import {Comment as CommentIcon} from '@renofi/icons';
import {basic55} from '@renofi/theme';
import {
  useGcddReviewById,
  useRejectGcddDocuments,
  useReviewDocument,
  useSendCorrespondenceMessage,
  useRemoveGcddDocument,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {getGcddDocumentIds} from './utils';

const LAZY = {lazy: true};
const TOOLTIP_CSS = {
  padding: 12,
  maxWidth: 300,
  textAlign: 'left',
  zIndex: '1011 !important',
};

function GcddReviewDocumentActions({onGcddReviewUpdate, ...props}) {
  const {fetch, gcddReview} = useGcddReviewById(LAZY);
  const {loading: isReviewing, reviewDocument} = useReviewDocument(LAZY);
  const {loading: isRejecting, rejectGcddDocuments} =
    useRejectGcddDocuments(LAZY);
  const {loading: isSending, sendCorrespondenceMessage} =
    useSendCorrespondenceMessage(LAZY);
  const {loading: isRemoving, removeGcddDocument} = useRemoveGcddDocument(LAZY);
  const isLoading = isRejecting || isReviewing || isSending || isRemoving;

  const {itemId} = useParams();
  const [gcddReviewId] = (itemId || '').split(':');

  const {addNotification} = useNotifications();

  const [item, setItem] = useState(props?.item || {});
  const {documentType, id, rejectedAt, rejectionDetails, reviewResult} = item;

  const isRejected = !isNil(rejectedAt);
  const isReviewed = !isNil(reviewResult);
  const isFailed = isRejected || reviewResult === 'fail';
  const isPassed = reviewResult === 'pass';
  const isQuestionnaireDoc = useMemo(() => {
    const gcddDocumentIds = getGcddDocumentIds(gcddReview);
    return gcddDocumentIds?.includes(id);
  }, [gcddReview, id]);
  const disabled =
    // isReviewFinished || //commenting this to allow file review even after file is sent to RU
    isRejected || isReviewed || isLoading || !isQuestionnaireDoc;

  useEffect(() => {
    if (gcddReviewId) {
      fetch({variables: {id: gcddReviewId}});
    }
  }, [gcddReviewId]);

  const onClickIcon = async (e, reviewResult) => {
    e.preventDefault();
    if (disabled) {
      return false;
    }

    const rsp = await reviewDocument({
      variables: {documentType, gcddReviewId, id, reviewResult},
    });
    const data = rsp?.data?.reviewDocument?.document || {};

    setItem((s) => ({...s, ...omit(['__typename'], data)}));
    onGcddReviewUpdate();
  };

  const onAcceptMessage = async ({
    message,
    otherReason,
    reason,
    returnReason,
    selectedDocuments,
  }) => {
    const projectId = gcddReview?.project?.id;
    const recipientEmailAddress = gcddReview?.questionnaire?.email || 'test';
    const subject =
      reason === 'other'
        ? otherReason
        : MESSAGE_REASONS[reason] || 'Message from RenoFi';

    if (reason === MESSAGE_RETURN_DOCUMENT_REASON) {
      await rejectGcddDocuments({
        variables: {
          documentIds: selectedDocuments || [],
          gcddReviewId,
          rejectionDetails: message,
          rejectionReason: returnReason,
        },
      });
    } else if (reason === MESSAGE_DELETE_DOCUMENT) {
      const reviewId = gcddReviewId;
      try {
        await removeGcddDocument({
          variables: {
            id: item.id,
            gcddReviewId: reviewId,
          },
        });
        addNotification({
          variant: 'success',
          content: 'File successfully deleted',
        });
      } catch (error) {
        addNotification({
          variant: 'danger',
          content: 'File deletion failed',
        });
      }
    } else {
      await sendCorrespondenceMessage({
        variables: {
          bodyText: message,
          entityId: gcddReviewId,
          entityType: 'gcdd_review',
          projectId,
          subject,
          source: 'contractor',
          recipientEmailAddress,
        },
      });
    }

    setItem((s) => ({
      ...s,
      rejectedAt: new Date().toISOString(),
      rejectionReason: returnReason,
    }));
    onGcddReviewUpdate();
  };

  const acceptLabel = ({reason}) => {
    return reason === 'delete_document' ? 'delete' : 'send message';
  };

  return (
    <ModalsContext.Consumer>
      {({onClose, setLoading, setModalProps}) => {
        const modalProps = {
          acceptLabel,
          component: MessageContractorModal,
          componentProps: {
            gcddReviewId,
            selectedDocuments: [item.id],
            reason: 'return_document',
          },
          hasButtons: true,
          header: 'Reject document',
          onAccept: async (props) => {
            setLoading(true);
            onAcceptMessage(props);
            onClose();
          },
          onReject: () => onClose(),
          submitDisabled: isContractorMessageDisabled,
          clickBackdropToClose: false,
        };

        return (
          <Fragment>
            {isRejected && (
              <Box height={24}>
                <Tooltip
                  delayHide={200}
                  css={TOOLTIP_CSS}
                  content={rejectionDetails}>
                  <div css={{cursor: 'pointer'}}>
                    <CommentIcon color={basic55} />
                  </div>
                </Tooltip>
              </Box>
            )}
            <Box height={24} ml={16}>
              <Discard
                active={isFailed}
                disabled={disabled}
                loading={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  if (!disabled) {
                    setModalProps(modalProps);
                  }
                }}
              />
            </Box>
            <Box height={24} ml={16}>
              <Approve
                active={isPassed}
                disabled={disabled}
                loading={isLoading}
                onClick={(e) => onClickIcon(e, 'pass')}
              />
            </Box>
          </Fragment>
        );
      }}
    </ModalsContext.Consumer>
  );
}

GcddReviewDocumentActions.propTypes = {
  item: PropTypes.shape({
    documentType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    rejectedAt: PropTypes.string,
    rejectionDetails: PropTypes.string,
    reviewResult: PropTypes.string,
  }).isRequired,
  onGcddReviewUpdate: PropTypes.func.isRequired,
};

export default GcddReviewDocumentActions;

import {range} from 'ramda';

export const RATING_OPTIONS = {
  reasonable: 'Reasonable',
  overstated: 'Overstated',
  understated: 'Understated',
};

export const PENDING = 'pending';

export const NUMBER_OF_ROWS_OPTIONS = range(1, 10);

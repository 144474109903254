/* global Canny */
/* eslint-disable no-unused-expressions, no-sequences */
import React, {Fragment, useContext, useEffect, useState} from 'react';

import {Icon, Loader, Modal} from '@renofi/components-internal';
import {white} from '@renofi/theme';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import {Trigger} from './styled';

const CANNY_TOKEN = '8a4e2d56-a282-6ad7-0dca-f7e0206fbd44';

const CannyFeedback = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const config = useContext(ConfigContext);
  const environment = config['ENVIRONMENT'];
  const isProduction = environment === 'production';

  useEffect(() => {
    if (!isProduction) {
      return;
    }

    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = 'text/javascript'),
            (e.async = !0),
            (e.src = 'https://canny.io/sdk.js'),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ('function' != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          'complete' === d.readyState
            ? l()
            : w.attachEvent
              ? w.attachEvent('onload', l)
              : w.addEventListener('load', l, !1);
      }
    })(window, document, 'canny-jssdk', 'script');
  }, []);

  useEffect(() => {
    if (showFeedback) {
      Canny('render', {
        boardToken: CANNY_TOKEN,
        basePath: null, // See step 2
        ssoToken: null, // See step 3,
        theme: 'light', // options: light [default], dark, auto
        onLoadCallback: () => setIsReady(true),
      });
    } else {
      setIsReady(false);
    }
  }, [showFeedback]);

  if (!isProduction) {
    return null;
  }

  return (
    <Fragment>
      {!showFeedback ? (
        <Trigger
          variant="renofi"
          title="Suggest an idea on Canny"
          onClick={() => setShowFeedback(!showFeedback)}>
          <Icon name="bullhorn" color={white} width={32} />
        </Trigger>
      ) : null}
      <Modal
        header="Suggest an idea on Canny"
        onClose={() => setShowFeedback(false)}
        show={showFeedback}
        full={true}>
        <div data-canny>
          {!isReady ? <Loader labelText="Loading Canny dashboard..." /> : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default CannyFeedback;

import styled from '@emotion/styled';

import {Badge, Flex} from '@renofi/components-internal';
import {actionDark20, actionLight} from '@renofi/theme';

export const GroupContainer = styled(Flex)({
  width: 'auto',
  height: 85,
  color: actionLight,
  background: actionDark20,
  marginRight: 4,
  padding: '4px 2px 0 2px',
  borderRadius: '4px 4px 0px 0px',
  fontSize: 14,
  lineHeight: '17px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  cursor: 'move',
  userSelect: 'none',
  // flexWrap: 'nowrap',
});
export const GroupStatus = styled(Badge)({
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

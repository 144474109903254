import {matchPath, useHistory, useLocation} from 'react-router-dom';

import {useQueryString} from '@renofi/utilities/src/hooks';

export default function useViewerNavigation() {
  const history = useHistory();
  const {pathname} = useLocation();
  const search = useQueryString();

  const {params = {}} =
    matchPath(pathname.split('?')[0], {
      path: '/viewer-v2/:projectId/:dataType/:itemId',
    }) || {};

  return {
    history,
    pathname,
    params,
    search,
  };
}

import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {prop, propOr} from 'ramda';
import {useCanEditGcddReview} from 'lib/gcdd';
import {Container, StatusBadge} from 'modules/viewer-v2/components/Layout';
import Row from 'modules/viewer-v2/components/Row';
import Col from 'modules/viewer-v2/components/Col';

import useDebounce from '@renofi/utilities/src/useDebounce';
import {useSubmitOverallScore} from '@renofi/graphql/src/hooks';
import {Text, Textarea} from '@renofi/components-internal';

function GcddOverallScore({gcddReview}) {
  const {submitOverallScore} = useSubmitOverallScore();
  const [overallScore, setOverallScore] = useState(
    propOr(null, 'overallScore', gcddReview),
  );
  const [overallScoreNotes, setOverallScoreNotes] = useState(
    propOr('', 'overallScoreNotes', gcddReview),
  );
  const isCompleted = Boolean(propOr(false, 'completedAt', gcddReview));
  const {canEdit} = useCanEditGcddReview(gcddReview);
  const gcddReviewId = prop('id', gcddReview);

  useEffect(() => {
    setOverallScore(propOr(null, 'overallScore', gcddReview));
    setOverallScoreNotes(propOr('', 'overallScoreNotes', gcddReview));
  }, [gcddReview]);

  const debouncedSubmitScore = useDebounce((variables) => {
    submitOverallScore({variables});
  }, 300);

  const onChangeNotes = (notes) => {
    setOverallScoreNotes(notes);
    debouncedSubmitScore({
      gcddReviewId,
      overallScore,
      overallScoreNotes: notes,
    });
  };

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Overall score</Text>
          <StatusBadge variant={isCompleted ? 'success' : 'warning'}>
            {isCompleted ? 'Reviewed' : 'Pending review'}
          </StatusBadge>
        </Row>
      }>
      <Row>
        <Col width={1}>
          <Textarea
            disabled={!canEdit}
            mb={0}
            label="Notes"
            onChange={onChangeNotes}
            rows={4}
            value={overallScoreNotes}
          />
        </Col>
      </Row>
    </Container>
  );
}

GcddOverallScore.propTypes = {
  gcddReview: PropTypes.shape({
    completedAt: PropTypes.string,
    overallScore: PropTypes.string,
    overallScoreNotes: PropTypes.string,
  }).isRequired,
};

export default GcddOverallScore;

import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {keys} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {useTheme} from '@renofi/theme';

import {Wrapper} from './styled';

const Tab = ({children, id: tabId, ...props}) => {
  const {styles} = useTheme('tab', props);
  const {search} = useViewerNavigation();

  useEffect(() => {
    const isCurrentTab = keys(search).some((key) => search[key] === tabId);
    const el = document.getElementById(tabId);

    if (isCurrentTab && Boolean(el)) {
      el.scrollIntoView({inline: 'center', behavior: 'smooth'});
    }
  }, [search, tabId]);

  return (
    <Wrapper css={styles} id={tabId} {...props}>
      {children}
    </Wrapper>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default Tab;

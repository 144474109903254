import React, {useContext, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {Tabs} from '@renofi/components-internal/src/next';
import {basic88} from '@renofi/theme';
import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../../context';

import Tab from './components/Tab';
import TabsContainer from './TabsContainer';

const ItemTabsBar = ({itemKey = 'documentId', onChange, tabs = [], value}) => {
  const {setIsLeftToRight, setItemsBarCache} = useContext(ViewerV2Context);
  const {history, search} = useViewerNavigation();
  const values = tabs.map((t) => t.value);
  const currentIndex = values.indexOf(value);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(value);
      if (el) {
        el.scrollIntoView({block: 'center', behavior: 'auto'});
      }
    }, 500);
  }, []);

  useEffect(() => {
    setItemsBarCache(values);
  }, [values.length]);

  const onClickArrow = (forward) => {
    setIsLeftToRight(forward);
    const newIndex = forward
      ? Math.max(currentIndex + 1, 0)
      : Math.max(currentIndex - 1, 0);
    const {value} = tabs[newIndex] || {};

    if (!value) {
      return;
    }

    const to = queryString.stringify({
      ...search,
      [itemKey]: value,
    });
    history.push(`?${to}`);
  };

  const onTabChange = (...args) => {
    setIsLeftToRight(true);
    onChange(...args);
  };

  return (
    <TabsContainer
      isNextDisabled={currentIndex === tabs.length - 1}
      isPrevDisabled={currentIndex === 0}
      onClickArrow={onClickArrow}>
      <Tabs
        activeColor={basic88}
        tabComponent={Tab}
        tabs={tabs}
        value={value}
        onChange={onTabChange}
      />
    </TabsContainer>
  );
};

ItemTabsBar.propTypes = {
  itemKey: PropTypes.string,
  onChange: PropTypes.func,
  tabs: Tabs.propTypes.tabs,
  value: Tabs.propTypes.value,
};

export default ItemTabsBar;

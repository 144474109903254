import {propOr} from 'ramda';

const QUESITONNAIRE_STATUS = {
  REVIEWED: 'reviewed',
  RETURNED: 'returned',
  SENT: 'sent',
};

const getQuestionnaireStatus = ({reviewedAt, returnedAt}) => {
  if (reviewedAt) {
    return QUESITONNAIRE_STATUS.REVIEWED;
  }

  return returnedAt ? QUESITONNAIRE_STATUS.RETURNED : QUESITONNAIRE_STATUS.SENT;
};

export const isReturnedAsPdf = ({questionnaire = {}}) => {
  const {reviewedAt, returnedAt, submissionType} = questionnaire || {};

  const questionnaireStatus = getQuestionnaireStatus({reviewedAt, returnedAt});
  const isReturned = questionnaireStatus === QUESITONNAIRE_STATUS.RETURNED;

  return submissionType === 'pdf' && isReturned;
};

export const getTitle = (questionnaire = {}) => {
  const {reviewedAt, returnedAt} = questionnaire || {};
  const questionnaireStatus = getQuestionnaireStatus({reviewedAt, returnedAt});

  return `Contractor questionnaire ${questionnaireStatus}`;
};

export const extractAssignedToInfo = (assignedTo) => {
  const assignedToId = propOr(null, 'id', assignedTo);
  const assignedAgent = `${assignedTo?.firstName} ${assignedTo?.lastName}`;
  const assignedToText = assignedToId ? assignedAgent : 'Unassigned';
  const assignedToButtonText = assignedToId
    ? 'Reassign Analyst'
    : 'Assign Analyst';

  return {assignedToId, assignedToText, assignedToButtonText};
};

import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_APPRAISAL_COMPANIES} from '../../queries';

export default function useAppraisalManagementCompanies({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_APPRAISAL_COMPANIES,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const appraisalManagementCompanies = propOr(
    [],
    'appraisalManagementCompanies',
    data,
  );

  return {error, fetch, appraisalManagementCompanies, loading};
}

import React from 'react';

import PropTypes from 'prop-types';

const CertificationAndAuthorizationIcon = ({color = '#CCCCCC', ...props}) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.175 11.7L7.05 8.85L4.75 7H7.6L8.5 4.2L9.4 7H12.25L9.925 8.85L10.8 11.7L8.5 9.925L6.175 11.7ZM2.5 21V13.275C1.86667 12.575 1.375 11.775 1.025 10.875C0.675 9.975 0.5 9.01667 0.5 8C0.5 5.76667 1.275 3.875 2.825 2.325C4.375 0.775 6.26667 0 8.5 0C10.7333 0 12.625 0.775 14.175 2.325C15.725 3.875 16.5 5.76667 16.5 8C16.5 9.01667 16.325 9.975 15.975 10.875C15.625 11.775 15.1333 12.575 14.5 13.275V21L8.5 19L2.5 21ZM8.5 14C10.1667 14 11.5833 13.4167 12.75 12.25C13.9167 11.0833 14.5 9.66667 14.5 8C14.5 6.33333 13.9167 4.91667 12.75 3.75C11.5833 2.58333 10.1667 2 8.5 2C6.83333 2 5.41667 2.58333 4.25 3.75C3.08333 4.91667 2.5 6.33333 2.5 8C2.5 9.66667 3.08333 11.0833 4.25 12.25C5.41667 13.4167 6.83333 14 8.5 14ZM4.5 18.025L8.5 17L12.5 18.025V14.925C11.9167 15.2583 11.2877 15.5207 10.613 15.712C9.93767 15.904 9.23333 16 8.5 16C7.76667 16 7.06233 15.904 6.387 15.712C5.71233 15.5207 5.08333 15.2583 4.5 14.925V18.025Z"
        fill={color}
      />
    </svg>
  );
};

CertificationAndAuthorizationIcon.propTypes = {
  color: PropTypes.string,
};

export default CertificationAndAuthorizationIcon;

import styled from '@emotion/styled';

import CommonLink from '@renofi/components-internal/src/Link';

export const Photo = styled.img({
  maxWidth: 80,
  maxHeight: 60,
  borderRadius: 6,
  margin: 0,
  padding: 0,
});

export const Link = styled(CommonLink)({
  height: 60,
  display: 'flex',
  alignItems: 'center',
  marginRight: 8,
  padding: 0,
});

import {gql} from '@apollo/client';

export default gql`
  query projectFullTimeline($id: ID!) {
    projectById(id: $id) {
      id
      actions {
        id
        createdAt
        type
        task {
          id
        }
        document {
          id
        }
        note {
          id
        }
      }
      timeline {
        id
        advancedToRuAt
        appraisalCompletedAt
        appraisalCorrectedReportReceivedAt
        appraisalFeeConsentOn
        appraisalInspectionAppointmentAt
        appraisalInspectionCompletedAt
        appraisalPaymentProcessedAt
        appraisalReportReceivedAt
        appraisalReportReviewFinishedAt
        appraisalRequestedAt
        appraisalSubmittedAt
        appraisalWithAppraiserSince
        completionCertificateCompletedAt
        completionCertificateRequestedAt
        contractorDueDiligenceCancelledAt
        contractorDueDiligenceCompletedAt
        contractorDueDiligenceRequestedAt
        contractorDueDiligenceReturnedAt
        contractorInfoFirstSubmittedAt
        contractorsReadyForContactAt
        contractReviewCompletedAt
        contractReviewRequestedAt
        estimatedCloseDate
        exceptionApprovedAt
        exceptionRejectedAt
        exceptionSentAt
        expectedRenovationCompletionOn
        expectedRenovationStart
        feasibilityCompletedAt
        feasibilityRequestedAt
        gcddReviewCancelledAt
        gcddReviewCompletedAt
        gcddReviewRequestedAt
        gcddReviewReturnedAt
        invitationAcceptedAt
        invitationSentAt
        jpNextContactDate
        loanApprovedOn
        loanClearToCloseDate
        loanClosedOn
        loanInProgressAt
        readyFor1003PrepOn
        renovationCompletedOn
        renovationStartedOn
        renovationUnderwritingRequestCanceledAt
        renovationUnderwritingRequestCompletedAt
        respaCapturedOn
        stageLastChangedAt
        tpoRegistrationCompletedAt
        x1003CompleteOn
        x1003PrepCompleteOn
      }
      tasks {
        id
        taskType
        borrowerFinishedAt
        completedAt
        createdAt
        dataSubmittedAt
        reviewCompletedAt
        reviewStartedAt
        submittedAt
      }
      underwritingConditions {
        id
        condition
        createdAt
        completedAt
        history {
          id
          createdAt
          note {
            id
            body
          }
          performerNotes
          updatedAssignedTo {
            id
            firstName
            lastName
          }
          updatedAssignedToId
          updatedBy {
            fullName
          }
          updatedResponsibleAgent
          updatedStatus
        }
      }
    }
  }
`;

import {gql} from '@apollo/client';

export default gql`
  query salesforceEmptyErrors($id: ID!) {
    projectById(id: $id) {
      id
      salesforceImportErrors
    }
  }
`;

import {
  getOrphanDocumentsByTaskDataSetType,
  getTaskDataSetType,
} from 'modules/project/components/UnassignedDocuments';
import {sortBy} from 'lodash';
import {COBORROWER_TASK_TYPES} from 'modules/viewer-v2/constants';

import {taskTypes} from '@renofi/utilities/src/enums';
import {
  filterItemsByBorrowerRole,
  hasCoBorrowerInProject,
} from '@renofi/utilities/src/coborrower';

import {useTaskByCurrentItemId, useViewerNavigation} from '../../hooks';

const COBORROWER = {borrowerRole: 'coborrower'};

const mapDocuments = (docs = []) =>
  docs.map((d) => ({
    ...d,
    label: d.fileName,
    tabType: 'documentId',
    tabValue: d.id,
  }));

const buildTabsByDataSet = ({data = [], project} = {}) => {
  const hasCoborrower = hasCoBorrowerInProject(project);
  const coborrowerName = project?.coborrower?.fullName;
  const borrowerName = project?.primaryApplicant?.fullName;

  const borrowerItems = filterItemsByBorrowerRole(data);
  const coborrowerItems = filterItemsByBorrowerRole(data, COBORROWER);
  const borrowerDocuments = borrowerItems.reduce(
    (arr, item) => arr.concat(item.documents || []),
    [],
  );
  const borrowerTab = {
    label: borrowerName,
    tabValue: 'borrower',
    tabType: 'borrowerRole',
    children: mapDocuments(borrowerDocuments),
  };

  if (!hasCoborrower) {
    return [borrowerTab];
  }

  return [
    borrowerTab,
    {
      label: coborrowerName,
      tabValue: 'coborrower',
      tabType: 'borrowerRole',
      children: mapDocuments(
        coborrowerItems.reduce(
          (arr, dataSet) => arr.concat(dataSet.documents || []),
          [],
        ),
      ),
    },
  ];
};

export default function useTabsByTaskType() {
  const {project, task} = useTaskByCurrentItemId();
  const {search} = useViewerNavigation();
  const {taskType} = task || {};
  const coborrowerName = project?.coborrower?.fullName;
  const borrowerName = project?.primaryApplicant?.fullName;

  const taskDataSetType = getTaskDataSetType({taskType});
  const unassignedDocuments = getOrphanDocumentsByTaskDataSetType({
    taskDataSetType,
    task,
  });
  const hasUnassignedDocs = Boolean(unassignedDocuments?.length);

  const getFilterTabs = () => {
    const {
      assetDetails = [],
      canHaveDocument,
      documents = [],
      employmentHistories = [],
      incomeSources = [],
      realEstateOwned,
      taskType,
    } = task || {};
    const {streetAddressOne} = project || {};
    const {
      additionalProperties = [],
      soldProperties = [],
      subjectProperty,
    } = realEstateOwned || {};
    const hasCoborrower = hasCoBorrowerInProject(project);
    const borrowerDocuments = filterItemsByBorrowerRole(documents);
    const coborrowerDocuments = filterItemsByBorrowerRole(
      documents,
      COBORROWER,
    );
    let data;

    /**
     * @TODO Bit of a mess, I'm sure it could be much tidier
     * */
    switch (true) {
      case taskType === taskTypes.employment_history:
        data = sortBy(employmentHistories, ['coborrowerEmploymentHistory']);
        return data.map((employment) => {
          const {
            coborrowerEmploymentHistory,
            currentEmployment,
            documents = [],
            employerName,
            id,
            noEmploymentDetails,
          } = employment;
          const current = currentEmployment ? '(current)' : '';
          const borrower = coborrowerEmploymentHistory ? '(co-borrower)' : '';
          const label = coborrowerEmploymentHistory
            ? [employerName, current, borrower].join(' ')
            : [employerName, current].join(' ');

          return {
            label: noEmploymentDetails ? 'No employment given' : label,
            tabValue: id,
            tabType: 'employmentId',
            children: mapDocuments(documents),
          };
        });
      case taskType === taskTypes.assets:
        return buildTabsByDataSet({data: assetDetails, project});

      case taskType === taskTypes.income:
        return buildTabsByDataSet({data: incomeSources, project});

      case taskType === taskTypes.real_estate_owned:
        const allProperties = [
          {...subjectProperty, streetAddressOne},
          ...additionalProperties,
          ...soldProperties,
        ];
        if (!allProperties.length) {
          return [];
        }
        return allProperties.map(({streetAddressOne, id, ...reo}) => {
          return {
            label: streetAddressOne,
            tabType: 'propertyId',
            tabValue: id,
            children: mapDocuments(reo?.documents || []),
          };
        });
      case COBORROWER_TASK_TYPES.includes(taskType):
      case canHaveDocument:
        return hasCoborrower
          ? [
              {
                label: borrowerName,
                tabValue: 'borrower',
                tabType: 'borrowerRole',
                children: mapDocuments(borrowerDocuments),
              },
              {
                label: coborrowerName,
                tabValue: 'coborrower',
                tabType: 'borrowerRole',
                children: mapDocuments(coborrowerDocuments),
              },
            ]
          : [
              {
                label: borrowerName,
                tabValue: 'borrower',
                tabType: 'borrowerRole',
                children: mapDocuments(borrowerDocuments),
              },
            ];
      default:
        return [];
    }
  };

  const baseTabs = getFilterTabs();

  if (hasUnassignedDocs) {
    baseTabs.push({
      label: 'Unassigned documents',
      tabValue: true,
      tabType: 'unassigned',
      children: mapDocuments(unassignedDocuments),
    });
  }

  const currentTab = baseTabs.find((tab) => {
    return search[tab.tabType] === tab.tabValue;
  });

  const flattened = baseTabs.reduce((filterArray, filter) => {
    const {children = [], ...tab} = filter;
    const hasChildren = Boolean(children.length);
    const baseQuery = {[tab.tabType]: tab.tabValue};
    if (!hasChildren) {
      return filterArray.concat({
        query: baseQuery,
        ...tab,
      });
    }

    return children.reduce((itemsArray, child) => {
      const query = {...baseQuery, [child.tabType]: child.tabValue};
      return itemsArray.concat({query, ...child});
    }, filterArray);
  }, []);

  return {
    currentTab,
    flattened,
    hasUnassigned: Boolean(unassignedDocuments?.length),
    tabs: baseTabs,
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import {ItemsBarTabFileName} from 'modules/viewer-v2/components/Layout';

import {Flex} from '@renofi/components-internal';
import truncateString from '@renofi/utilities/src/truncateMiddle';

import Actions from '../../AppraisalReportDocumentActions';

const TabContent = ({document, projectId}) => {
  return (
    <Flex justifyContent="space-between" width={1}>
      <Flex flexDirection="column" maxWidth={200}>
        <ItemsBarTabFileName title={document.fileName}>
          {truncateString(document.fileName, 16, 14)}
        </ItemsBarTabFileName>
      </Flex>
      <Flex alignItems="center" flexShrink={0}>
        <Actions document={document} projectId={projectId} />
      </Flex>
    </Flex>
  );
};

TabContent.propTypes = {
  appraisalOrders: PropTypes.array,
  projectId: PropTypes.string,
  appraisalReport: PropTypes.shape({
    fileName: PropTypes.string,
  }),
};

export default TabContent;

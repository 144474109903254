import React, {memo, useEffect} from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {Flex, Text} from '@renofi/components-internal';

import {GroupContainer, GroupStatus} from './styled';

const Group = ({children, onUpdate, status, statusVariant, title}) => {
  const {pathname} = useViewerNavigation();
  const isArray = Array.isArray(children);

  useEffect(() => {
    if (!isArray) {
      onUpdate([pathOr(false, ['props', 'path'], children)]);
      return;
    }

    const groupData = children.reduce((arr, child) => {
      return arr.concat(pathOr(false, ['props', 'path'], child));
    }, []);
    onUpdate(groupData);
  }, [pathname]);

  return (
    <GroupContainer>
      <Flex>
        <Text mx={2} css={{whiteSpace: 'nowrap'}}>
          {title}
        </Text>
        <GroupStatus small variant={statusVariant}>
          {status}
        </GroupStatus>
      </Flex>

      <Flex>{children}</Flex>
    </GroupContainer>
  );
};

Group.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  ]),
  onUpdate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  statusVariant: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default memo(Group);

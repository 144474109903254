import {useMemo} from 'react';

import {find, propEq} from 'ramda';

import {useProject} from '@renofi/graphql';

import useViewerNavigation from './useViewerNavigation';

export default function useTaskByCurrentItemId(options = {}) {
  const {params} = useViewerNavigation();
  const {projectId} = params;
  const {loading, project} = useProject({variables: {id: projectId}});
  const taskId = options?.taskId || params?.itemId;

  const tasks = project?.tasks || [];
  const task = useMemo(
    () => find(propEq('id', taskId), tasks),
    [taskId, tasks.length],
  );

  return {loading, projectId, project, task};
}

import styled from '@emotion/styled';

import {Box, Flex} from '@renofi/components-internal';
import {basic55, basic80} from '@renofi/theme';

export const Table = styled(Box)({
  color: basic55,
  fontSize: 14,
  lineHeight: '17px',
  padding: '0 16px',
});

const Cell = styled(Flex)({
  margin: '0 4px',
  alignItems: 'center',
});

export const Section = styled(Flex)({
  width: '100%',
  padding: '16px 20px',
  borderRadius: 4,
  backgroundColor: basic80,
});

export const ContractorCostCell = styled(Cell)(({wide}) => ({
  paddingLeft: wide ? 20 : 0,
  width: wide ? 142 : 1 / 3,
  justifyContent: wide ? 'flex-start' : 'flex-end',
}));

export const DescriptionOfWorkCell = styled(Cell)(({wide}) => ({
  width: wide ? 348 : 2 / 3,
}));

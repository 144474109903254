import {useMemo} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

import {Card} from '@renofi/components-internal';
import {GET_PROJECT} from '@renofi/graphql';
import {border} from '@renofi/theme';

import SupportingDocuments from '../SupportingDocuments';

import MoveDocumentsStickyFooter from './MoveDocumentsStickyFooter';
import UnassignedDocumentsContext from './context';
import {getOrphanDocumentsByTaskDataSetType, getTaskDataSetType} from './utils';

const UnassignedDocuments = ({
  inline = false,
  onClickDocument,
  task,
  ...props
}) => {
  const taskDataSetType = getTaskDataSetType(task);
  const {projectId} = useParams();
  const refetchQueries = props.refetchQueries || [
    {
      query: GET_PROJECT,
      variables: {id: projectId},
    },
  ];

  const orphanDocuments = useMemo(() => {
    return getOrphanDocumentsByTaskDataSetType({taskDataSetType, task});
  }, [JSON.stringify(task), taskDataSetType]);

  if (isEmpty(task) || !orphanDocuments?.length) {
    return null;
  }

  return (
    <Card
      defaultBorderColor={inline ? 'transparent' : border}
      mt={inline ? 4 : 3}
      p={inline ? 2 : 3}
      styledTitle={!inline}
      title="Unassigned documents">
      <UnassignedDocumentsContext.Provider
        value={{refetchQueries, taskDataSetType, task}}>
        <SupportingDocuments
          canSelect
          documents={orphanDocuments}
          onClickDocument={onClickDocument}
          selectedDocumentsFooter={MoveDocumentsStickyFooter}
          inline={inline}
          showLabel
        />
      </UnassignedDocumentsContext.Provider>
    </Card>
  );
};

UnassignedDocuments.propTypes = {
  inline: PropTypes.bool,
  onClickDocument: PropTypes.func,
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.any.isRequired,
      variables: PropTypes.object,
    }),
  ),
  task: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default UnassignedDocuments;

import React, {useContext, useMemo} from 'react';

import {propOr} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import {FEASIBILITY_TASK_TYPES} from 'modules/viewer-v2/constants';

import queryString from '@renofi/utilities/src/queryString';
import {useFeasibilityReviews, useProject} from '@renofi/graphql';

import ViewerV2Context from '../../../../context';
import {getTabTitleDetails, isTaskReviewed} from '../../../../utils';
import Tab from '../Tab';
import Group from '../Group/Group';
import {getFeasibilityStatus} from '../../utils';

const getValidTasks = ({taskType}) => FEASIBILITY_TASK_TYPES.includes(taskType);

const FeasibilityReviews = () => {
  const {onGroupPathsUpdate} = useContext(ViewerV2Context);

  const {params, search} = useViewerNavigation();
  const {projectId, itemId} = params;

  const {project, ...rest} = useProject({variables: {id: projectId}});
  const {feasibilityReviews, loading: loadingReviews} = useFeasibilityReviews({
    variables: {projectId},
  });
  const projectTasks = propOr([], 'tasks', project);
  const loading = rest?.loading || loadingReviews.loading;

  const data = useMemo(() => {
    return feasibilityReviews.map((feasibilityReview) => ({
      feasibilityReview,
      tasks: projectTasks.filter(getValidTasks),
    }));
  }, [projectTasks, feasibilityReviews]);

  if (loading || !data.length) {
    return null;
  }

  return data.map(({feasibilityReview, tasks}) => {
    const facetStatus = getFeasibilityStatus({
      feasibilityReview,
      feasibilityReviews,
      tasks,
    });
    const [newVariant, newStatus] = facetStatus;
    const groupKey = `feasibility:${feasibilityReview.id}`;

    return (
      <Group
        onUpdate={(ids) =>
          onGroupPathsUpdate(groupKey, 'feasibility_review', ids)
        }
        status={newStatus}
        statusVariant={newVariant}
        title="Feasibility"
        key={feasibilityReview.id}>
        {tasks.map((task) => {
          const taskStatus = isTaskReviewed(task, project)
            ? TASK_STATUSES.COMPLETED
            : task.status;
          const item = {...task, type: 'task'};
          const [title, details] = getTabTitleDetails(item);
          const active =
            itemId === feasibilityReview.id && task.id === search?.taskId;
          const qString = queryString.stringify(
            {
              taskId: task.id,
            },
            {skipEmptyString: true},
          );

          return (
            <Tab
              active={active}
              details={details}
              title={title}
              status={taskStatus}
              key={task.id}
              path={`/viewer-v2/${projectId}/feasibility_review/${feasibilityReview.id}?${qString}`}
            />
          );
        })}
      </Group>
    );
  });
};

export default FeasibilityReviews;

import {isNil, reject} from 'ramda';

export default (task = {}) => {
  const {realEstateOwned} = task;
  const {
    additionalProperties = [],
    soldProperties = [],
    subjectProperty,
  } = realEstateOwned || {};

  return reject(isNil, [
    subjectProperty,
    ...additionalProperties,
    ...soldProperties,
  ]);
};

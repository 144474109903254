import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REMOVE_GCDD_DOCUMENT} from '../../../mutations';

export default function useRemoveGcddDocument({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [removeGcddDocument, {data, loading, error}] = useMutation(
    REMOVE_GCDD_DOCUMENT,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['removeGcddDocument'], data);
  return {removeGcddDocument, response, loading, error};
}

import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import {Wrapper} from './styled';

const Tab = ({children, ...props}) => {
  const {styles} = useTheme('tab', props);
  return (
    <Wrapper css={styles} {...props}>
      {children}
    </Wrapper>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tab;

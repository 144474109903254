import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {BORROWER_INVITED_TO_PROJECT} from '../../queries';

export default function useBorrowerInvitedToProject({
  fetchPolicy = 'no-cache',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: BORROWER_INVITED_TO_PROJECT,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const borrowerInvitedToProject = propOr(
    null,
    'borrowerInvitedToProject',
    data,
  );

  return {error, fetch, borrowerInvitedToProject, loading};
}

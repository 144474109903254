import {gql} from '@apollo/client';

import {user} from '@renofi/graphql/src/partials';

export default gql`
  query userById($id: ID!) {
    userById(id: $id) {
      ${user}
      slackId
    }
  }
`;

import {useContext} from 'react';

import {Context as StorageContext} from '@renofi/utilities/src/storage';

import {isFullWidthLayout} from '../../../lib/layoutHelper';
import layoutWidths from '../../../lib/layoutWidths';

import useSettings from './useSettings';

const useViewerLayout = () => {
  const {settings, updateSettings} = useSettings();
  const storage = useContext(StorageContext);
  const layout = settings?.viewerLayout;
  const widths = layoutWidths(layout);

  const onChangeLayout = (value) => {
    const viewerLayout = isFullWidthLayout(layout) ? 'split_wide' : value;
    updateSettings({viewerLayout});
    storage.setItem('viewer:layout', viewerLayout);
  };

  return {layout, onChangeLayout, widths};
};

export default useViewerLayout;

import React from 'react';

import PropTypes from 'prop-types';

import {Box, Button, Flex} from '@renofi/components-internal';

const ModalFooter = ({
  disabled = false,
  isRemoving = false,
  isUpdating = false,
  remove = false,
  isSaveButtonVisible = false,
  isSaveButtonDisabled = false,
  onClose,
  onRemove,
  onRemoveConfirm,
  onUpdate,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Flex width={0.5} alignItems="center">
        <Box>
          {!remove && (
            <Button variant="danger" onClick={onRemove} disabled={disabled}>
              Delete this reference
            </Button>
          )}
          {remove && (
            <Button
              variant="danger"
              loading={isRemoving}
              disabled={disabled}
              onClick={onRemoveConfirm}>
              Confirm Deletion
            </Button>
          )}
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Flex justifyContent="flex-end" alignItems="center">
          <Box ml="4px" pr="10px">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Box>
          {isSaveButtonVisible && (
            <Box ml="4px">
              <Button
                onClick={onUpdate}
                disabled={isSaveButtonDisabled}
                loading={isUpdating}>
                Save reference
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

ModalFooter.propTypes = {
  disabled: PropTypes.bool,
  isSaveButtonVisible: PropTypes.bool,
  isRemoving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  remove: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveConfirm: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ModalFooter;

import React, {useEffect, useRef, useState} from 'react';

import {last} from 'ramda';
import {useParams} from 'react-router-dom';
import {withViewerLayout} from 'api/hoc';
import {Container, getPercentageChange} from 'modules/viewer-v2';
import Col from 'modules/viewer-v2/components/Col';
import Row from 'modules/viewer-v2/components/Row';

import {
  useAppraisalOrders,
  useProject,
  useProjectTimeline,
  useUpdateProject,
} from '@renofi/graphql';
import DollarIcon from '@renofi/icons/src/DollarCircleInvert';
import {
  Box,
  DateField,
  TextField,
  Flex,
  Text,
} from '@renofi/components-internal';
import {formatDateTime} from '@renofi/utilities/src/dates';

import {NO_VALUE} from './constants';
import BorrowerAppraisalValues from './components/BorrowerAppraisalValues';
import {InfoText, LabelText} from './components/BorrowerAppraisalValues/styled';

const getRoi = (afterRenoValue, homeValue, renoCost) => {
  if (!afterRenoValue || !homeValue || !renoCost) {
    return NO_VALUE;
  }

  return getPercentageChange(afterRenoValue - homeValue, renoCost);
};

const haveValuesChanged = (original, toCompare) =>
  Object.keys(toCompare).some((key) => toCompare[key] !== original[key]);

function Appraisal() {
  const ref = useRef(null);
  const {projectId} = useParams();
  const {project} = useProject({variables: {id: projectId}});
  const {timeline} = useProjectTimeline({variables: {id: project?.id}});

  const {appraisalOrders} = useAppraisalOrders({variables: {projectId}});
  const {updateProject, loading: isUpdating} = useUpdateProject();

  const [currentAppraisalOrder, setCurrentAppraisalOrder] = useState(null);

  const [actualCurrentHomeValue, setActualCurrentHomeValue] = useState(
    project.actualCurrentHomeValue,
  );
  const [actualRenovationHomeValue, setActualRenovationHomeValue] = useState(
    project.actualRenovationHomeValue,
  );
  const [appraisalCostPaidByBorrower, setAppraisalCostPaidByBorrower] =
    useState(project?.opportunity?.appraisalCostPaidByBorrower);

  const [appraiserName, setAppraiserName] = useState(project.appraiserName);
  const [completionCertificateOrderedOn, setCompletionCertificateOrderedOn] =
    useState(project.completionCertificateOrderedOn);
  const [completionCertificateReceivedOn, setCompletionCertificateReceivedOn] =
    useState(project.completionCertificateReceivedOn);

  const {avmValue, status, formType} = currentAppraisalOrder || {};
  const isAppraisalCompleted = status === 'completed';
  const {estimatedRenovationHomeValue, renovationCost, statedCurrentHomeValue} =
    project;
  const stateValues = {
    actualCurrentHomeValue,
    actualRenovationHomeValue,
    completionCertificateOrderedOn,
    completionCertificateReceivedOn,
    appraiserName,
    appraisalCostPaidByBorrower,
  };
  const [cache, setCache] = useState(stateValues);

  useEffect(() => {
    setCurrentAppraisalOrder(last(appraisalOrders));
  }, [JSON.stringify(appraisalOrders)]);

  useEffect(() => {
    onUpdate();
  }, [completionCertificateOrderedOn, completionCertificateReceivedOn]);

  const onUpdate = () => {
    if (haveValuesChanged(cache, stateValues)) {
      updateProject({
        variables: {
          attributes: {
            actualCurrentHomeValue,
            actualRenovationHomeValue,
            appraiserName,
            completionCertificateOrderedOn,
            completionCertificateReceivedOn,
          },
          opportunityAttributes: {
            appraisalCostPaidByBorrower,
          },
          id: project.id,
        },
      });
    }

    setCache(stateValues);
  };

  return (
    <div ref={ref}>
      <Container
        header={
          <Flex alignItems="center">
            <Box height={24}>
              <DollarIcon />
            </Box>
            <Text ml={12}>Appraisal</Text>
          </Flex>
        }>
        <Row>
          <Col>
            <TextField
              disabled={isUpdating || !isAppraisalCompleted}
              onBlur={onUpdate}
              label="Appraiser"
              onChange={setAppraiserName}
              value={appraiserName}
            />
          </Col>

          <Col>
            <TextField
              money
              value={appraisalCostPaidByBorrower || NO_VALUE}
              leftIcon="dollar"
              label="Cost paid by borrower"
              disabled={isUpdating}
              onBlur={onUpdate}
              onChange={setAppraisalCostPaidByBorrower}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <DateField
              label="Completion certificate ordered"
              value={
                completionCertificateOrderedOn &&
                new Date(completionCertificateOrderedOn)
              }
              disabled={isUpdating}
              onChange={setCompletionCertificateOrderedOn}
            />
          </Col>
          <Col>
            <DateField
              label="Completion certificate received"
              value={
                completionCertificateReceivedOn &&
                new Date(completionCertificateReceivedOn)
              }
              disabled={isUpdating}
              onChange={setCompletionCertificateReceivedOn}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <LabelText>With appraiser since</LabelText>
            <InfoText>{timeline?.appraisalWithAppraiserSince}</InfoText>
          </Col>
          <Col>
            <LabelText>Completed at</LabelText>
            <InfoText>
              {formatDateTime(timeline?.appraisalCompletedAt)}
            </InfoText>
          </Col>
        </Row>

        <Row>
          <Col>
            <LabelText>Form type</LabelText>
            <InfoText>{formType}</InfoText>
          </Col>
        </Row>

        <BorrowerAppraisalValues
          appraisalValue={actualCurrentHomeValue}
          borrowerValue={statedCurrentHomeValue}
          disabled={isUpdating}
          isAppraisalCompleted={isAppraisalCompleted}
          onChange={setActualCurrentHomeValue}
          onBlur={onUpdate}
          roi={getRoi(
            actualCurrentHomeValue,
            statedCurrentHomeValue,
            renovationCost,
          )}
          title="As-is value"
          esimated
        />

        <Row>
          <Col>
            <TextField
              disabled
              money
              value={avmValue || NO_VALUE}
              leftIcon="dollar"
              label="AVM value"
            />
          </Col>
        </Row>

        <BorrowerAppraisalValues
          appraisalValue={actualRenovationHomeValue}
          borrowerValue={estimatedRenovationHomeValue}
          disabled={isUpdating}
          isAppraisalCompleted={isAppraisalCompleted}
          onChange={setActualRenovationHomeValue}
          onBlur={onUpdate}
          roi={getRoi(
            actualRenovationHomeValue,
            estimatedRenovationHomeValue,
            renovationCost,
          )}
          title="After renovation value"
        />
      </Container>
    </div>
  );
}

export default withViewerLayout()(Appraisal);

import withCurrentProject from 'lib/withCurrentProject';

import {compose} from '@renofi/recompose/src';
import {withTaskAdditionalRequestPreview} from '@renofi/graphql/src/hoc';

import Component from './Component';

export default compose(
  withCurrentProject(),
  withTaskAdditionalRequestPreview(),
)(Component);

import {pathOr} from 'ramda';

export default function getContractorName(gcddReview) {
  const contractorFirstName = pathOr(
    '',
    ['questionnaire', 'contactFirstName'],
    gcddReview,
  );
  const contractorLastName = pathOr(
    '',
    ['questionnaire', 'contactLastName'],
    gcddReview,
  );

  return `${contractorFirstName} ${contractorLastName}`;
}

const pluralRules = new Intl.PluralRules('en', {type: 'ordinal'});

export default function numberWithOrdinal(num) {
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };
  const key = pluralRules.select(num);
  const suffix = suffixes[key];
  return `${num}${suffix}`;
}

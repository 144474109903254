import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {MARK_GCDD_REVIEW_AS_COMPLETED} from '../../../mutations';

export default function useMarkGcddReviewAsCompleted({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [markGcddReviewAsCompleted, {data, loading, error}] = useMutation(
    MARK_GCDD_REVIEW_AS_COMPLETED,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(
    null,
    ['markGcddReviewAsCompleted', 'gcddReview'],
    data,
  );

  return {markGcddReviewAsCompleted, response, loading, error};
}

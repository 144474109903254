import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {SUBMIT_RENOFI_PROVIDED_DOCUMENT} from '../../mutations';

export default function useSubmitRenofiProvidedDocument({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitRenofiProvidedDocument, {data, loading, error}] = useMutation(
    SUBMIT_RENOFI_PROVIDED_DOCUMENT,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['submitRenofiProvidedDocument'], data);
  return {submitRenofiProvidedDocument, response, loading, error};
}

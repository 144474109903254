import {gql} from '@apollo/client';

export default gql`
  mutation setAppraisalPropertyPhotoShareable($id: ID!, $shareable: Boolean!) {
    setAppraisalPropertyPhotoShareable(id: $id, shareable: $shareable) {
      appraisalPropertyPhoto {
        id
        createdAt
        homeownerDescription
        objectName
        shareableWithAmc
        updatedAt
      }
    }
  }
`;

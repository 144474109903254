import styled from '@emotion/styled';

import {basic55, basic80} from '@renofi/theme';

export const TableRow = styled.tr({
  '& td:nth-of-type(-n + 2)': {
    color: basic80,
  },
  '& td:nth-last-of-type(-n + 2)': {
    color: basic55,
  },
});

import styled from '@emotion/styled';

import {Box, Link as CommonLink} from '@renofi/components-internal';

export const Th = styled.th({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',

  '&:last-of-type': {
    textAlign: 'right',
  },
});

export const Td = styled.td({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '&:last-of-type': {
    width: 200,
    textAlign: 'right',
  },
});

export const Link = styled(CommonLink)({
  display: 'flex',
  alignItems: 'center',
});

export const Url = styled(Box)({
  maxWidth: 500,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

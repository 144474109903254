import note from './note';

export default `
address
applicants
appraisalReportDocumentId
appraisedValue
createdAt
creditScore
currentHomeValue
declineCommentsPersonalDetails
declineCommentsPropertyDetails
declineCommentsLoanDetails
dtiRatio
email
estimatedClosingCosts
estimatedMonthlyPayment
estimatedRenovationHomeValue
id
interestRate
latestNote {
  ${note}
}
latestDeclineNote {
	${note}
}
lenderId
lenderName
lienPosition
loanProductType
loanAmount
loanType
name
phone
renovationAmount
renovationServicingFeeAmount
renovationServicingFeeInterval
taskId
titleHolders
interestRateType
applicationProcess
autoTransmitToLender
updatedAt
`;

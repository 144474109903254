import {actionRegular, dangerRegular, basic55} from '@renofi/theme/src/colors';

export const getTextColor = ({isSaving, required, value}) => {
  if (isSaving) {
    return actionRegular;
  }

  if (required && !value) {
    return dangerRegular;
  }

  return basic55;
};

export const getHelpText = ({isSaving, required}) => {
  if (isSaving) {
    return 'Saving...';
  }

  if (required) {
    return 'Required';
  }

  return null;
};

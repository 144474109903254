import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {CANCEL_GCDD_REVIEW} from '../../../mutations';

export default function useCancelGcddReview({
  awaitRefetchQueries = true,
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [cancelGcddReview, {data, loading, error}] = useMutation(
    CANCEL_GCDD_REVIEW,
    {
      onCompleted,
      refetchQueries,
      awaitRefetchQueries,
    },
  );
  const response = pathOr(null, ['rejectGcddDocuments', 'gcddReview'], data);

  return {cancelGcddReview, response, loading, error};
}

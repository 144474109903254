import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_GCDD_REVIEWS_COUNTS} from '../../queries';

export default function useGcddReviewsCounts({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_GCDD_REVIEWS_COUNTS,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const gcddReviewsCounts = propOr(null, 'gcddReviewsCounts', data);

  return {error, fetch, gcddReviewsCounts, loading};
}

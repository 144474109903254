import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {PREVIEW_APPRAISAL_PACKAGE} from '../../queries';

export default function usePreviewAppraisalPackage({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: PREVIEW_APPRAISAL_PACKAGE,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const previewAppraisalPackage = propOr(null, 'previewAppraisalPackage', data);

  return {error, fetch, previewAppraisalPackage, loading};
}

import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Card, Link, Text} from '@renofi/components-internal';
import {ELLIPSIS_OVERFLOW} from '@renofi/theme';

import {
  ItemContainer,
  Header,
  ItemDetail,
  ItemName,
  ItemRow,
} from '../../../../../styled';

function ContractorDetails({gcddReview}) {
  const contractor = propOr(null, 'contractor', gcddReview);
  const questionnaire = propOr(null, 'questionnaire', gcddReview);
  const address = ['streetAddress', 'city', 'state', 'zipCode'].map((k) =>
    propOr('', k, contractor),
  );

  const contactTitle = propOr(null, 'contactTitle', questionnaire);
  const email = propOr(null, 'email', questionnaire);
  const phoneNumber = propOr(null, 'phoneNumber', questionnaire);

  return (
    <ItemContainer flexDirection="column">
      <Header>
        <Text fontSize={18}>Contractor details</Text>
      </Header>

      <Card p={4}>
        <ItemRow>
          <ItemName>Company name</ItemName>
          <ItemDetail>{contractor?.businessName}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Contact name</ItemName>
          <ItemDetail>
            {questionnaire?.contactFirstName}
            {questionnaire?.contactLastName}
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Title</ItemName>
          <ItemDetail>{contactTitle}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Phone number</ItemName>
          <ItemDetail>{phoneNumber}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Email address</ItemName>
          <ItemDetail>{email}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Website</ItemName>
          <ItemDetail>
            <Link
              css={{
                display: 'inline-block',
                maxWidth: 380,
                ...ELLIPSIS_OVERFLOW,
              }}
              title={contractor?.website}
              target="_blank"
              href={contractor?.website}>
              {contractor?.website}
            </Link>
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Address</ItemName>
          <ItemDetail>{address.join(', ')}</ItemDetail>
        </ItemRow>
      </Card>
    </ItemContainer>
  );
}

ContractorDetails.propTypes = {
  gcddReview: PropTypes.shape({
    contractor: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      streetAddress: PropTypes.string,
      zipCode: PropTypes.string,
    }),
  }),
};

export default ContractorDetails;

import {pathOr} from 'ramda';

export const getGcddDocumentIds = (gcddReview) => {
  return [
    pathOr(null, ['questionnaire', 'contractorLicense', 'id'], gcddReview),
    pathOr(
      null,
      ['questionnaire', 'contractorGeneralLiabilityInsurance', 'id'],
      gcddReview,
    ),
    pathOr(
      null,
      ['questionnaire', 'contractorWorkersCompInsurance', 'id'],
      gcddReview,
    ),
  ].filter((docId) => Boolean(docId));
};

import React, {useCallback, useState, useMemo} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {propEq, isEmpty} from 'ramda';
import {useProjectDetails, useViewerLayout} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {OrderAnalysisModal} from 'modules/modals';
import {
  Complete as Wrapper,
  Tooltip,
  ValidationList,
  FEASIBILITY_TASK_TYPES,
} from 'modules/viewer-v2';

import {useAcceptDocument} from '@renofi/graphql/src/hooks';
import {basic20, basic80, white} from '@renofi/theme/src/colors';
import {
  Box,
  Button,
  Checkbox,
  MouseHover,
  Text,
} from '@renofi/components-internal';

import {extendValidation} from './utils';

const Request = ({feasibilityReview, validation}) => {
  const {projectId} = useParams();
  const {project} = useProjectDetails({variables: {id: projectId}});
  const {layout} = useViewerLayout();
  const {acceptDocument, abort} = useAcceptDocument();

  const [force, setForce] = useState(false);
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const wide = isSplitWideLayout(layout);
  const projectTasks = project?.tasks || [];

  const tasks = useMemo(
    () =>
      projectTasks.filter(({taskType}) =>
        FEASIBILITY_TASK_TYPES.includes(taskType),
      ),
    [projectTasks],
  );

  const extendedValidation = useMemo(() => {
    return extendValidation(tasks, validation);
  }, [tasks, validation]);

  const isReviewRequested = Boolean(feasibilityReview?.requestedAt);

  const disabled = useMemo(() => {
    const isButtonEnabled = tasks.every(({canHaveDocument, documents}) => {
      const isAllDocumentsAccepted = documents.every(
        propEq('status', 'accepted'),
      );

      return (
        !canHaveDocument ||
        (canHaveDocument && !isEmpty(documents) && isAllDocumentsAccepted)
      );
    });

    return !isButtonEnabled;
  }, [tasks, isReviewRequested]);

  const onAcceptDocuments = useCallback(async () => {
    setLoading(true);

    const requestParams = tasks.reduce((accumulator, task) => {
      task.documents.forEach((document) => {
        accumulator.push(document.id);
      });

      return accumulator;
    }, []);

    await Promise.all(
      requestParams.map((documentId) =>
        acceptDocument({variables: {id: documentId}}),
      ),
    );

    abort();

    setLoading(false);
  }, [tasks, projectId]);

  const requestReviewHandler = useCallback(async () => {
    if (disabled && force) {
      await onAcceptDocuments();
    }

    setModal(true);
  }, [disabled, force, modal]);

  return (
    <>
      <Wrapper>
        <Text
          fontSize={12}
          lineHeight="14px"
          mb={wide ? 0 : 16}
          textAlign={wide ? 'left' : 'center'}>
          Review all documents/info before ordering an analysis
        </Text>
        <MouseHover
          type="div"
          hideDelay={500}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <>
            {disabled && hover && (
              <Tooltip
                width={wide ? 330 : 280}
                css={{right: wide ? 100 : 10}}
                tailPosition="right"
                onClose={() => setHover(false)}>
                <Text fontSize={20} mb={12}>
                  Checklist
                </Text>

                <Text mb={16} color={basic20} fontSize={14} lineHeight="17px">
                  The items listed below should be marked as reviewed and the
                  documents approved before ordering a Feasibility Analysis.
                </Text>

                <ValidationList
                  feasibilityReview={feasibilityReview}
                  validation={extendedValidation}
                />

                <Box>
                  <Checkbox
                    checked={force}
                    css={{
                      borderRadius: 4,
                      padding: '12px 16px',
                      backgroundColor: basic80,
                    }}
                    color={white}
                    fontSize={14}
                    lineHeight="17px"
                    label={
                      <Text ml="8px">
                        I confirm the documentation provided by the homeowner is
                        in order and ready for review by the Feasibility
                        Analysis.
                      </Text>
                    }
                    onChange={() => setForce(!force)}
                  />
                </Box>
              </Tooltip>
            )}
            <Button
              disabled={(disabled && !force) || loading}
              loading={loading}
              variant="danger"
              onClick={requestReviewHandler}>
              Order feasibility analysis
            </Button>
          </>
        </MouseHover>
      </Wrapper>

      {modal && !loading && (
        <OrderAnalysisModal onClose={() => setModal(false)} />
      )}
    </>
  );
};

Request.propTypes = {
  feasibilityReview: PropTypes.shape({
    requestedAt: PropTypes.string,
    reviewer: PropTypes.object,
  }),
  validation: PropTypes.object.isRequired,
};

export default Request;

import React from 'react';

const Arrow = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 7.5L6 3.17341L1.41 7.5L0 6.16802L6 0.5L12 6.16802L10.59 7.5Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Arrow;

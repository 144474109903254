import {keys, map, pipe, prop, propOr, uniq} from 'ramda';
import {facetTypes} from 'lib/groupTasksIntoFacets';

import PERMISSIONS from '@renofi/utilities/src/permissions';

const FACET_PERMISSIONS = [
  [PERMISSIONS.BLUEPRINT_VIEW, keys(facetTypes)],
  [PERMISSIONS.BLUEPRINT_EDIT, keys(facetTypes)],
  [PERMISSIONS.GCDD_VIEW, [facetTypes.gcdd]],
  [PERMISSIONS.GCDD_EDIT, [facetTypes.gcdd]],
];

const getPermissionKeys = pipe(propOr([], 'permissions'), map(prop('key')));

const getAllowedFacetTypesByUser = (user) => {
  const permissions = getPermissionKeys(user);
  return uniq(
    FACET_PERMISSIONS.reduce(
      (arr, [k, f]) => (permissions.includes(k) ? arr.concat(f) : arr),
      [],
    ),
  );
};

export default getAllowedFacetTypesByUser;

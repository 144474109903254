import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {CREATE_TASK} from '../../mutations';

export default function useCreateTask({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [createTask, {data, loading, error}] = useMutation(CREATE_TASK, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['createTask'], data);
  return {createTask, response, loading, error};
}

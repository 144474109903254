import React, {Fragment, useEffect} from 'react';

import {pathOr} from 'ramda';

import {Loader} from '@renofi/components-internal';
import {useGcddReviewsByProjectId} from '@renofi/graphql';

import {useTaskByCurrentItemId, useViewerNavigation} from '../../hooks';
import GcddReviewStatusBar from '../GcddReviewStatusBar';
import {DataTypeViewport, ItemView} from '../Layout';

import GcddReviewItemView from './components/GcddReviewItemView';
import Navbar from './components/GcddReviewItemsBar';
import GcddReview from './components/GcddReview';
import GcddReviewFooter from './components/GcddReviewFooter';

const getDocuments = (gcddReview, tab) => {
  return pathOr(null, ['contractor', tab], gcddReview);
};

const GcddReviewType = () => {
  const {history, params, search} = useViewerNavigation();
  const {projectId, itemId} = params;
  const {project} = useTaskByCurrentItemId();
  const {gcddReviews, loading, refetch} = useGcddReviewsByProjectId({
    fetchPolicy: 'no-cache',
    variables: {projectId},
  });
  const gcddReview = gcddReviews.find(({id}) => id === itemId);
  const documents = getDocuments(gcddReview, search?.tab);

  useEffect(() => {
    if (!search.tab) {
      history.push('?tab=contractor');
    }
  }, []);

  const onGcddReviewUpdate = async () => {
    await refetch({projectId});
  };

  if (!gcddReview || !project) {
    return null;
  } else if (loading) {
    return <Loader />;
  }

  return (
    <DataTypeViewport
      sidebar={
        <Fragment>
          <GcddReview
            documents={documents}
            itemId={`${itemId}:${search?.tab}`}
            gcddReview={gcddReview}
            onGcddReviewUpdate={onGcddReviewUpdate}
          />
          <GcddReviewFooter
            gcddReview={gcddReview}
            onGcddReviewUpdate={onGcddReviewUpdate}
          />
        </Fragment>
      }
      showProjectSummary={search?.tab === 'contractor'}
      statusBar={<GcddReviewStatusBar />}>
      <Navbar gcddReview={gcddReview} />
      <ItemView>
        <GcddReviewItemView gcddReview={gcddReview} />
      </ItemView>
    </DataTypeViewport>
  );
};

export default GcddReviewType;

import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Badge, Flex} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';
import {formatDateTime, isTimeBeforeNow} from '@renofi/utilities/src/dates';
import {getFullName} from '@renofi/utilities/src/data';

import {SmallText} from './styled';

function RejectedDocument({document}) {
  const {
    rejectedAt,
    rejectedBy,
    rejectedBySystem,
    rejectionDetails,
    rejectionMessageScheduledAt,
  } = document;
  const fullName = rejectedBySystem ? 'System' : getFullName(rejectedBy || {});
  const rejectedDate = rejectionMessageScheduledAt || rejectedAt;
  const time = formatDateTime(rejectedDate);
  const isRejectedInPast = isTimeBeforeNow(rejectedDate);

  return (
    <Fragment>
      <Flex alignItems="center">
        <Badge css={{fontSize: 10, marginRight: 8}}>FROM</Badge>
        <SmallText>{fullName}</SmallText>
      </Flex>
      <Flex pt="8px" color={basic55} fontSize={14}>
        {isRejectedInPast ? `Sent at ${time}` : `Will be sent at ${time}`}
      </Flex>
      <SmallText mt={3}>{rejectionDetails}</SmallText>
    </Fragment>
  );
}

RejectedDocument.propTypes = {
  document: PropTypes.shape({
    rejectedAt: PropTypes.string,
    rejectedBy: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    rejectedBySystem: PropTypes.bool,
    rejectionDetails: PropTypes.string,
    rejectionMessageScheduledAt: PropTypes.string,
  }),
};

export default RejectedDocument;

import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {withViewerLayout} from 'api/hoc';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {Flex, Text} from '@renofi/components-internal';

import Col from './Col';

const Row = ({children, layout, title, ...props}) => {
  const wide = isSplitWideLayout(layout);
  const flexDirection = wide ? 'row' : 'column';
  return (
    <Fragment>
      {title && (
        <Col mt={2} mb={2} width={1}>
          <Text p={1}>{title}</Text>
        </Col>
      )}
      <Flex width={1} px="4px" flexDirection={flexDirection} {...props}>
        {children}
      </Flex>
    </Fragment>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
  title: PropTypes.node,
};

export default withViewerLayout()(Row);

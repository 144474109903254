import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_GCDD_REVIEW_SEARCH} from '../../queries';

export default function useGcddReviewSearch({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_GCDD_REVIEW_SEARCH,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const gcddReviewSearch = propOr(null, 'gcddReviewSearch', data);

  return {error, fetch, gcddReviewSearch, loading};
}

import styled from '@emotion/styled';

import {Box, Flex, Text, SalesforceLink} from '@renofi/components-internal';
import {basic88, basic55} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  background: basic88,
});

export const Content = styled(Box)({
  width: 'calc(100% - 24px)',
});

export const Details = styled(Text)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const Address = styled(Text)({
  fontSize: 14,
  color: basic55,
  paddingTop: '5px',
});

export const External = styled(SalesforceLink)({
  fontSize: 14,
  marginLeft: 10,
  marginTop: 2,
  ':hover': {
    textDecoration: 'underline',
  },
});

import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';
import {Container, StatusBadge, Row} from 'modules/viewer-v2';

import {Text, ButtonGroup, Box} from '@renofi/components-internal';
import {useSubmitQuestionnaireScore} from '@renofi/graphql/src/hooks';
import {useNotifications} from '@renofi/utilities/src/hooks';

const SCORE_BUTTON_OPTIONS = [
  {label: 'Pass', value: 'pass'},
  {label: 'Fail', value: 'fail'},
];

function QuestionnaireScore({gcddReview}) {
  const {submitQuestionnaireScore} = useSubmitQuestionnaireScore({lazy: true});
  const {addNotification} = useNotifications();

  const initialScore = pathOr(null, ['questionnaire', 'score'], gcddReview);
  const reviewedAt = pathOr(null, ['questionnaire', 'reviewedAt'], gcddReview);
  const questionnaireId = pathOr(null, ['questionnaire', 'id'], gcddReview);
  const isReviewed = Boolean(reviewedAt);

  const [score, setScore] = useState(initialScore);

  const onChangeValue = useCallback(
    async (value) => {
      const response = await submitQuestionnaireScore({
        variables: {
          questionnaireId,
          score: value,
        },
      });
      const newScore = pathOr(
        null,
        ['data', 'submitQuestionnaireScore', 'questionnaire', 'score'],
        response,
      );

      if (!newScore) {
        addNotification({
          variant: 'danger',
          content: 'Failed to submit Questionnaire Score',
        });
        return;
      }

      setScore(value);
    },
    [questionnaireId],
  );

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Contractor Questionnaire</Text>
          <StatusBadge variant={isReviewed ? 'success' : 'warning'}>
            {isReviewed ? 'Reviewed' : 'Pending review'}
          </StatusBadge>
        </Row>
      }>
      <Box px={3}>
        <ButtonGroup
          active
          label="Score"
          onChange={onChangeValue}
          options={SCORE_BUTTON_OPTIONS}
          value={score}
        />
      </Box>
    </Container>
  );
}

QuestionnaireScore.propTypes = {
  gcddReview: PropTypes.shape({
    questionnaire: PropTypes.shape({
      reviewedAt: PropTypes.string,
    }),
  }),
};

export default QuestionnaireScore;

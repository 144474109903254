import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REFRESH_SOFT_CREDIT_CHECK} from '../../../mutations';

export default function useRefreshSoftCreditCheck({onCompleted = noop} = {}) {
  const [refreshSoftCreditCheck, {data, loading, error}] = useMutation(
    REFRESH_SOFT_CREDIT_CHECK,
    {
      onCompleted,
    },
  );

  const response = propOr(null, 'refreshSoftCreditCheck', data);

  return {refreshSoftCreditCheck, response, loading, error};
}

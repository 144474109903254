import {assoc, map, pipe} from 'ramda';
import {GET_APPLICATION_STATE, GET_FEASIBILITY_REVIEW_STATE} from 'api/queries';

import {
  GET_EXCEPTION_REQUEST_STATE,
  GET_RENOVATION_UPDATE,
} from '@renofi/graphql/src/queries';

export default ({client, isReviewers, storage}) => {
  client.writeQuery({
    query: GET_APPLICATION_STATE,
    data: {
      application: {
        message: {
          type: 'documents',
          active: false,
          reason: null,
          rejectionReason: null,
          showReasonDropdown: true,
          messageContent: '',
          documents: [],
          tasks: [],
          __typename: 'Message',
        },
        settings: {
          viewerLayout: storage.getItem('viewer:layout') || 'split_wide',
          viewerFeasibilityTab:
            storage.getItem('viewer:feasibilityTab') || 'contractReview',
          __typename: 'Settings',
        },
        currentSource: isReviewers ? 'feasibility_reviewer' : 'internal',
        __typename: 'Application',
      },
    },
  });

  client.writeQuery({
    query: GET_EXCEPTION_REQUEST_STATE,
    data: {
      exceptionRequest: {
        __typename: 'ExceptionRequestState',
        projectId: null,
        lenderId: null,
        exceptionTitle: '',
        compensatingFactors: '',
        renofiNotes: '',
        renovationScope: '',
        comments: [],
        metrics: map(
          pipe(
            assoc('__typename', 'ExceptionRequestStateMetric'),
            assoc('exception', false),
            assoc('notes', ''),
            assoc('value', ''),
          ),
          [
            {
              name: 'self_reported_credit_score_range',
              label: 'FICO Range',
            },
            {
              name: 'self_reported_dti_ratio',
              label: 'Stated DTI',
            },
            {
              name: 'self_reported_reserves',
              label: 'Reserves/Assets',
            },
            {
              name: 'peak_cltv_ratio',
              label: 'Peak CLTV (PCLTV)',
            },
            {
              name: 'arv_cltv_ratio',
              label: 'ARV CLTV',
            },
            {
              name: 'loan_value',
              label: 'Loan amount requested',
            },
            {
              name: 'positioned_interest_rate',
              label: 'Interest Rate Positioned',
            },
            {
              name: 'current_mortgage_balance',
              label: 'First Mortgage Balance',
            },
            {
              name: 'years_in_home',
              label: 'Time in home',
            },
            {
              name: 'renovation_cost_to_current_property_value_ratio',
              label: 'Reno Cost to Current Property Value Ratio',
            },
          ],
        ),
      },
    },
  });

  client.writeQuery({
    query: GET_RENOVATION_UPDATE,
    data: {
      renovationUpdate: {
        action: null,
        kind: null,
        data: {
          id: null,
          date: null,
          details: '',
          files: [],
          __typename: 'RenovationUpdateData',
        },
        __typename: 'RenovationUpdateModal',
      },
    },
  });

  client.writeQuery({
    query: GET_FEASIBILITY_REVIEW_STATE,
    data: {
      feasibilityReviewState: {
        id: null,
        changeOrderProcedureApproved: null,
        changeOrderProcedureNotes: null,
        clearDescriptionOfWorkApproved: null,
        clearDescriptionOfWorkNotes: null,
        clearGuaranteeApproved: null,
        clearGuaranteeNotes: null,
        clearGuaranteeRating: null,
        complexityGrading: null,
        contactInfoApproved: null,
        contactInfoNotes: null,
        contractTimelineApproved: null,
        contractTimelineNotes: null,
        contractTimelineRating: null,
        costEstimateSuppliedApproved: null,
        costEstimateSuppliedNotes: null,
        estimatedDuration: null,
        feasibilityStudyItems: [],
        generalCommentaryAndNotes: null,
        homeownerResponsibilityCompleted: null,
        inScopeAdjustedTotalRating: null,
        inScopeTotalRating: null,
        outOfScopeCompleted: null,
        termsOfPaymentApproved: null,
        termsOfPaymentNotes: null,
        termsOfPayments: [],
        __typename: 'FeasibilityReviewState',
      },
    },
  });
};

export default function getGcddDocumentStatus(document, questionnaire = {}) {
  const isQuestionnaireReturned = Boolean(questionnaire?.returnedAt);

  if (!document && !isQuestionnaireReturned) {
    return {
      status: 'Outstanding',
      variant: 'danger',
    };
  }

  const isReviewed = Boolean(document?.reviewedAt);
  const isRejected = Boolean(document?.rejectedAt);

  if (isRejected) {
    return {
      status: 'Rejected',
      variant: 'danger',
    };
  }

  if (isReviewed) {
    return {
      status: 'Reviewed',
      variant: 'success',
    };
  }

  return {status: 'Pending review', variant: 'warning'};
}

import React from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';

import {Wrapper, Page} from './styled';

const Pagination = ({current, full, max = 5, total, onChange, ...props}) => {
  const maximum = max * 2;
  const start =
    current - max < 1
      ? 1
      : current + max > total
        ? total - maximum
        : current - max;
  return (
    <Wrapper {...props}>
      {full && (
        <Page disabled={current < 2} onClick={() => onChange(1)}>
          First
        </Page>
      )}
      <Page disabled={current < 2} onClick={() => onChange(current - 1)}>
        Previous
      </Page>
      {range(
        start > 0 ? start : 1,
        (total > maximum ? start + maximum : total) + 1,
      ).map((page) => (
        <Page
          active={current === page}
          key={page}
          onClick={() => onChange(page)}>
          {page}
        </Page>
      ))}
      <Page disabled={current >= total} onClick={() => onChange(current + 1)}>
        Next
      </Page>
      {full && (
        <Page disabled={current >= total} onClick={() => onChange(total)}>
          Last
        </Page>
      )}
    </Wrapper>
  );
};

Pagination.propTypes = {
  full: PropTypes.bool,
  current: PropTypes.number.isRequired,
  max: PropTypes.number,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;

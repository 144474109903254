import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {lensProp, set} from 'ramda';
import {SCORE_BUTTON_OPTIONS} from 'lib/gcdd';

import {Box, ButtonGroup, Flex, Textarea} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';

function GcddSetOverallScore({onChange, ...props}) {
  const [gcddReview, setGcddReview] = useState(props?.gcddReview);

  useEffect(() => {
    onChange({gcddReview});
  }, []);

  const onChangeValue = useCallback(
    (key, value) => {
      const updated = set(lensProp(key), value, gcddReview);
      setGcddReview(updated);
      onChange({gcddReview: updated});
    },
    [gcddReview],
  );

  return (
    <ThemeProvider theme="light">
      <Flex width={1}>
        <ButtonGroup
          active
          label="Score"
          onChange={(v) => onChangeValue('overallScore', v)}
          options={SCORE_BUTTON_OPTIONS}
          value={gcddReview?.overallScore}
        />
      </Flex>
      <Flex>
        <Box width={1}>
          <Textarea
            mb={0}
            label="Notes"
            onChange={(v) => onChangeValue('overallScoreNotes', v)}
            rows={4}
            value={gcddReview?.overallScoreNotes}
          />
        </Box>
      </Flex>
    </ThemeProvider>
  );
}

GcddSetOverallScore.propTypes = {
  gcddReview: PropTypes.shape({
    overallScore: PropTypes.string,
    overallScoreNotes: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GcddSetOverallScore;

import * as React from 'react';

import PropTypes from 'prop-types';

const SvgAssignment = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-3-1h24v24H-3z" />
        <path
          d="M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H4v-2h7v2zm3-4H4v-2h10v2zm0-4H4V6h10v2z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgAssignment.propTypes = {
  color: PropTypes.string,
};
export default SvgAssignment;

import {gql} from '@apollo/client';

export default gql`
  query application {
    application @client {
      message {
        type
        active
        reason
        rejectionReason
        messageContent
        showReasonDropdown
        documents
        tasks
      }
      settings {
        viewerLayout
        viewerFeasibilityTab
      }
      currentSource
    }
  }
`;

import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {Switch} from '@renofi/components-internal';

function ShareableCheckbox({onChange, shareableWithAmc}) {
  const [checked, setChecked] = useState(shareableWithAmc);

  const onClick = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return <Switch checked={checked} onClick={onClick} />;
}

ShareableCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  shareableWithAmc: PropTypes.bool,
};

export default ShareableCheckbox;

import React from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {useViewerLayout} from 'api';
import {useTaskByCurrentItemId} from 'modules/viewer-v2/hooks';

import SidebarContainer from '../SidebarContainer';

import BorrowerDetails from './components/BorrowerDetails';
import ProjectSummary from './components/Summary';
import LayoutControls from './components/Layout';
import {InnerWrapper, MainContentWrapper, OuterWrapper} from './styled';

const DataTypeViewport = ({
  children,
  sidebar = null,
  showProjectSummary = true,
  statusBar = null,
}) => {
  const {widths} = useViewerLayout();
  const {project} = useTaskByCurrentItemId();
  if (!project || isEmpty(project)) {
    return null;
  }

  return (
    <OuterWrapper>
      {statusBar}

      <InnerWrapper>
        <MainContentWrapper offset={widths[0]}>{children}</MainContentWrapper>

        <SidebarContainer>
          <LayoutControls sticky />
          <BorrowerDetails project={project} />
          {showProjectSummary && <ProjectSummary />}

          {sidebar}
        </SidebarContainer>
      </InnerWrapper>
    </OuterWrapper>
  );
};

DataTypeViewport.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  showProjectSummary: PropTypes.bool,
  statusBar: PropTypes.node,
};

export default DataTypeViewport;

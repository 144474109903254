import styled from '@emotion/styled';

import {dangerLight, white, basic55, basic20} from '@renofi/theme/src/colors';
import {Text} from '@renofi/components-internal';

export const List = styled.ul({
  color: basic55,
  margin: 0,
  paddingLeft: 16,
});

export const Item = styled.li({
  color: dangerLight,
  fontSize: 14,
  lineHeight: '24px',
});

export const ItemTitle = styled(Text)({
  marginBottom: 8,
  color: white,
  fontSize: 16,
  lineHeight: '19px',
  fontWeight: 'bold',
});

export const SubTitle = styled(Text)({
  marginBottom: 8,
  color: basic20,
  fontSize: 12,
  marginTop: 14,
  lineHeight: '14px',
  fontWeight: 'bold',
});

import {filter, flatten, map, pipe, prop, propOr} from 'ramda';

const FACETS_TO_INCLUDE = ['appraisal', 'contractor', 'renovation'];

export const getAllDocs = pipe(
  filter(({facet}) => FACETS_TO_INCLUDE.includes(facet)),
  map(propOr([], 'documents')),
  flatten,
);
export const getAllIds = map(prop('id'));

/* eslint-disable max-lines */
import React from 'react';

import PropTypes from 'prop-types';
import {propOr, isNil} from 'ramda';
import {NO_LICENSE_REASONS, NO_INSURANCE_REASONS} from 'lib/gcdd';

import {Box, Flex, Table, Text} from '@renofi/components-internal';
import states from '@renofi/utilities/src/states';
import formatMoney from '@renofi/utilities/src/formatMoney';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {
  ItemContainer,
  Header,
  ItemCard,
  ItemDetail,
  ItemTitle,
  ItemName,
  ItemRow,
} from '../../../../../styled';

import {
  stringifyWithNullCheck,
  roundOffEstCost,
  mapYearsAsContractor,
  mapNumberOfCompletedRenovations,
  mapPercentageInLastTwoYears,
} from './utils';
import {TableRow} from './styled';
import {
  EXPERT_DESIGN_OPTIONS,
  MARKETING_SPEND_OPTIONS,
  RENOVATION_AMOUNT_RANGES,
} from './constants';

function QuestionnaireDetails({gcddReview}) {
  const questionnaire = propOr({}, 'questionnaire', gcddReview);
  const estimatedCostOfWork = gcddReview?.estimatedCostOfWork || null;
  const {
    propertyState,
    contactFirstName,
    contactLastName,
    contactTitle,
    phoneNumber,
    email,
    website,

    businessName,
    businessType,
    subsidaries,
    officePhoneNumber,
    licenseNumber,

    hasLicense,
    noLicenseReasons,
    hasW2Employees,
    hasWorkersCompInsurance,
    noInsuranceReasons,
    contractorGeneralLiabilityInsurance,

    yearsAsAContractor,
    teamSize,
    percentageBusinessWithCommercialInLast2y,
    percentageBusinessWithResidentialInLast2y,
    numberOfCompletedRenovations,
    percentageLeadsNeedingExpertDesign,
    percentageSalesSpentOnMarketing,
    residentialHomeRemodelBlueprintingOption,
    residentialHomeRemodelBlueprintingOptionOther,
    residentialHomeRemodelRole,
    residentialHomeRemodelRoleOther,
    percentageCompletedRenovationsAmountRanges,
    percentageOfCompletedRenovationsIn30PercentAmountRange,
    avgNumberOfRenovationsPerYear,
    avgPriceOfProjects,
    typicalRenovationProjects,
    hasBeenInvolvedInLawsuit,
    involvedInLawsuitDetails,
    hasTaxLiens,
    taxLiensDetails,
    hasEverFiledForBankruptcy,
    bankruptcyDetails,
  } = questionnaire;

  const workersComp =
    contractorGeneralLiabilityInsurance?.workersCompDetailsAvailableInGeneralLiability;

  const address = ['streetAddress', 'city', 'state', 'zipCode']
    .map((addressKey) => propOr('', addressKey, questionnaire))
    .join(', ');
  const references = propOr([], 'references', questionnaire);
  const state = states[propertyState] || '';

  const lowerLimitEstimatedCost = roundOffEstCost(estimatedCostOfWork, 0.85);
  const upperLimitEstimatedCost = roundOffEstCost(estimatedCostOfWork, 1.15);
  const hasDeprecatedRange = Boolean(
    percentageOfCompletedRenovationsIn30PercentAmountRange,
  );

  return (
    <ItemContainer flexDirection="column">
      <Header>
        <ItemTitle>Contractor questionnaire</ItemTitle>
      </Header>

      <ItemCard>
        <ItemTitle>Contact information</ItemTitle>
        <ItemRow>
          <ItemName>First name</ItemName>
          <ItemDetail>{contactFirstName}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Last name</ItemName>
          <ItemDetail>{contactLastName}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Title</ItemName>
          <ItemDetail>{contactTitle}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Phone number</ItemName>
          <ItemDetail>{phoneNumber}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Email</ItemName>
          <ItemDetail>{email}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Website</ItemName>
          <ItemDetail>{website}</ItemDetail>
        </ItemRow>
      </ItemCard>

      <ItemCard>
        <ItemTitle>Company information</ItemTitle>

        <ItemRow>
          <ItemName>Company name</ItemName>
          <ItemDetail>{businessName}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Company type</ItemName>
          <ItemDetail>{businessType}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Subsidiaries / affiliates</ItemName>
          <ItemDetail>{subsidaries}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Office phone</ItemName>
          <ItemDetail>{officePhoneNumber}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Office street address</ItemName>
          <ItemDetail css={{wordBreak: 'break-all'}}>{address}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>HIC license #</ItemName>
          <ItemDetail>{licenseNumber}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>
            Typical role in a residential home remodel project
          </ItemName>
          <ItemDetail>
            {humanizeSnakeCase(residentialHomeRemodelRole)}
            {residentialHomeRemodelRole === 'other' ? (
              <div>{residentialHomeRemodelRoleOther}</div>
            ) : null}
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>
            When necessary, your typical drafting/blueprinting for residential
            home remodel
          </ItemName>
          <ItemDetail>
            {humanizeSnakeCase(residentialHomeRemodelBlueprintingOption)}
            {residentialHomeRemodelBlueprintingOption === 'other' ? (
              <div>{residentialHomeRemodelBlueprintingOptionOther}</div>
            ) : null}
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>
            What % of gross sales do you typically spend on marketing
          </ItemName>
          <ItemDetail>
            {MARKETING_SPEND_OPTIONS[percentageSalesSpentOnMarketing]}
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>
            Approximately what % of your leads could use fast expert design
            assistance to help you close more deals
          </ItemName>
          <ItemDetail>
            {EXPERT_DESIGN_OPTIONS[percentageLeadsNeedingExpertDesign]}
          </ItemDetail>
        </ItemRow>
      </ItemCard>

      <ItemCard>
        <ItemTitle>Eligibility</ItemTitle>

        <ItemRow>
          <ItemName>Licensed in the state of {state}</ItemName>
          <ItemDetail>{stringifyWithNullCheck(hasLicense)}</ItemDetail>
        </ItemRow>
        {!hasLicense && noLicenseReasons?.length > 0 && (
          <ItemRow>
            <ItemName>Reasons for no state license</ItemName>
            <ItemDetail>
              {noLicenseReasons
                .map((reasonKey) => NO_LICENSE_REASONS[reasonKey]?.label)
                .join(',')}
            </ItemDetail>
          </ItemRow>
        )}
        <ItemRow>
          <ItemName>Has workers comp insurance</ItemName>
          <ItemDetail>
            {hasW2Employees
              ? stringifyWithNullCheck(hasWorkersCompInsurance)
              : 'No'}
            {hasWorkersCompInsurance &&
              workersComp &&
              ', included in General liability doc'}
          </ItemDetail>
        </ItemRow>
        {hasW2Employees &&
          !hasWorkersCompInsurance &&
          noInsuranceReasons?.length > 0 && (
            <ItemRow>
              <ItemName>Reasons for no workers comp insurance</ItemName>
              <ItemDetail>
                {noInsuranceReasons
                  .map((reasonKey) => NO_INSURANCE_REASONS[reasonKey]?.label)
                  .join(',')}
              </ItemDetail>
            </ItemRow>
          )}
        {!hasW2Employees && !hasWorkersCompInsurance && (
          <ItemRow>
            <ItemName>Reasons for no workers comp insurance</ItemName>
            <ItemDetail>Has no W2 Employees</ItemDetail>
          </ItemRow>
        )}
      </ItemCard>

      <ItemCard>
        <ItemTitle>Experience</ItemTitle>

        <ItemRow>
          <ItemName>Experience as a licensed contractor</ItemName>
          {!isNil(yearsAsAContractor) && (
            <ItemDetail>{mapYearsAsContractor(yearsAsAContractor)}</ItemDetail>
          )}
        </ItemRow>
        <ItemRow>
          <ItemName>Number of owners</ItemName>
          <ItemDetail>{teamSize}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Has W2 employees</ItemName>
          <ItemDetail>{stringifyWithNullCheck(hasW2Employees)}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Combined years of construction experience</ItemName>
          {!isNil(yearsAsAContractor) && (
            <ItemDetail>{mapYearsAsContractor(yearsAsAContractor)}</ItemDetail>
          )}
        </ItemRow>
        <ItemRow>
          <ItemName>Percentage of business commercial properties?</ItemName>
          {!isNil(percentageBusinessWithCommercialInLast2y) && (
            <ItemDetail css={{textTransform: 'none'}}>
              {mapPercentageInLastTwoYears(
                percentageBusinessWithCommercialInLast2y,
              )}
              %
            </ItemDetail>
          )}
        </ItemRow>
        <ItemRow>
          <ItemName>Percentage of business residential properties?</ItemName>
          {!isNil(percentageBusinessWithResidentialInLast2y) && (
            <ItemDetail css={{textTransform: 'none'}}>
              {mapPercentageInLastTwoYears(
                percentageBusinessWithResidentialInLast2y,
              )}
              %
            </ItemDetail>
          )}
        </ItemRow>
        <ItemRow>
          <ItemName>No. of renovations completed in career?</ItemName>
          <ItemDetail css={{textTransform: 'none'}}>
            {mapNumberOfCompletedRenovations(numberOfCompletedRenovations)}
          </ItemDetail>
        </ItemRow>

        {hasDeprecatedRange ? (
          <ItemRow>
            <ItemName>
              What percentage of the number above fell between a total cost of{' '}
              {`${lowerLimitEstimatedCost} - ${upperLimitEstimatedCost}`}
            </ItemName>
            {!isNil(percentageOfCompletedRenovationsIn30PercentAmountRange) && (
              <ItemDetail>
                {percentageOfCompletedRenovationsIn30PercentAmountRange}%
              </ItemDetail>
            )}
          </ItemRow>
        ) : (
          <ItemRow flexWrap="wrap">
            <ItemName>
              Of those total renovations completed, percentages were:
            </ItemName>
            <ItemDetail css={{width: '100%'}}>
              {RENOVATION_AMOUNT_RANGES.map((range, index) => {
                return (
                  <Flex mb={2} css={{gap: 32}} key={range}>
                    <Box flex={0.5} as="strong">
                      {range}:
                    </Box>
                    <Box>
                      {percentageCompletedRenovationsAmountRanges[index]}
                    </Box>
                  </Flex>
                );
              })}
            </ItemDetail>
          </ItemRow>
        )}

        <ItemRow>
          <ItemName>Average number of completed projects per year</ItemName>
          <ItemDetail>{avgNumberOfRenovationsPerYear}</ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Average price range of project</ItemName>
          {!isNil(avgPriceOfProjects) && (
            <ItemDetail>{formatMoney(avgPriceOfProjects)}</ItemDetail>
          )}
        </ItemRow>
        <ItemRow flexWrap="wrap">
          <ItemName>Typical renovation project:</ItemName>
          <ItemDetail
            css={{
              width: '100%',
            }}>
            {Boolean(typicalRenovationProjects?.length) &&
              typicalRenovationProjects.map((item) => (
                <Text key={item}>{humanizeSnakeCase(item)}</Text>
              ))}
          </ItemDetail>
        </ItemRow>
        <ItemRow>
          <ItemName>Involved in any lawsuit or litigation?</ItemName>
          <ItemDetail>
            {stringifyWithNullCheck(hasBeenInvolvedInLawsuit)}
          </ItemDetail>
        </ItemRow>
        {hasBeenInvolvedInLawsuit && involvedInLawsuitDetails && (
          <ItemRow>
            <ItemName>Involed in lawsuit details:</ItemName>
            <ItemDetail>{involvedInLawsuitDetails}</ItemDetail>
          </ItemRow>
        )}
        <ItemRow>
          <ItemName>
            Do you (or your company) have any Tax Liens or Judgments filed
            against you?
          </ItemName>
          <ItemDetail>{stringifyWithNullCheck(hasTaxLiens)}</ItemDetail>
        </ItemRow>
        {hasTaxLiens && taxLiensDetails && (
          <ItemRow>
            <ItemName>Tax liens details:</ItemName>
            <ItemDetail>{taxLiensDetails}</ItemDetail>
          </ItemRow>
        )}
        <ItemRow>
          <ItemName>
            Have you (or your company) ever filed for Bankruptcy?
          </ItemName>
          <ItemDetail>
            {stringifyWithNullCheck(hasEverFiledForBankruptcy)}
          </ItemDetail>
        </ItemRow>
        {hasEverFiledForBankruptcy && bankruptcyDetails && (
          <ItemRow>
            <ItemName>Bankruptcy details:</ItemName>
            <ItemDetail>{bankruptcyDetails}</ItemDetail>
          </ItemRow>
        )}
      </ItemCard>

      {Boolean(references.length) && (
        <ItemCard>
          <ItemTitle>References</ItemTitle>

          {references.map((reference) => (
            <ItemRow style={{padding: 0}} key={reference.id}>
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Contact</th>
                    <th>Phone</th>
                    <th width="40%">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <TableRow>
                    <td>{humanizeSnakeCase(reference.type)}</td>
                    <td style={{wordBreak: 'break-all'}}>{reference.name}</td>
                    <td>{reference.phoneNumber}</td>
                    <td style={{wordBreak: 'break-all'}}>{reference.email}</td>
                  </TableRow>
                </tbody>
              </Table>
            </ItemRow>
          ))}
        </ItemCard>
      )}
    </ItemContainer>
  );
}

QuestionnaireDetails.propTypes = {
  gcddReview: PropTypes.shape({
    estimatedCostOfWork: PropTypes.number,
    questionnaire: PropTypes.shape({
      id: PropTypes.string.isRequired,
      propertyState: PropTypes.string,
      contactFirstName: PropTypes.string,
      contactLastName: PropTypes.string,
      contactTitle: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
      businessName: PropTypes.string,
      businessType: PropTypes.string,
      subsidaries: PropTypes.arrayOf(PropTypes.string),
      officePhoneNumber: PropTypes.string,
      licenseNumber: PropTypes.string,
      hasLicense: PropTypes.bool,
      noLicenseReasons: PropTypes.arrayOf(PropTypes.string),
      hasW2Employees: PropTypes.bool,
      hasWorkersCompInsurance: PropTypes.bool,
      noInsuranceReasons: PropTypes.arrayOf(PropTypes.string),
      contractorGeneralLiabilityInsurance: PropTypes.shape({
        workersCompDetailsAvailableInGeneralLiability: PropTypes.bool,
      }),
      yearsAsAContractor: PropTypes.number,
      teamSize: PropTypes.number,
      percentageBusinessWithCommercialInLast2y: PropTypes.number,
      percentageBusinessWithResidentialInLast2y: PropTypes.number,
      percentageCompletedRenovationsAmountRanges: PropTypes.arrayOf(
        PropTypes.number,
      ),
      numberOfCompletedRenovations: PropTypes.number,
      percentageOfCompletedRenovationsIn30PercentAmountRange: PropTypes.number,
      avgNumberOfRenovationsPerYear: PropTypes.number,
      avgPriceOfProjects: PropTypes.number,
      typicalRenovationProjects: PropTypes.arrayOf(PropTypes.string),
      hasBeenInvolvedInLawsuit: PropTypes.bool,
      involvedInLawsuitDetails: PropTypes.string,
      hasTaxLiens: PropTypes.bool,
      hasEverFiledForBankruptcy: PropTypes.bool,
      references: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
          name: PropTypes.string,
          phoneNumber: PropTypes.string,
          email: PropTypes.string,
        }),
      ),
    }),
  }),
};

export default QuestionnaireDetails;

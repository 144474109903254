import {pathOr} from 'ramda';
import {isInternalSource, isFeasibilityReviewerSource} from 'lib/sourceHelper';

import {useQuery} from '@renofi/graphql/src/hooks';

import {GET_APPLICATION_STATE} from '../../queries';

const useCurrentSource = () => {
  const {data} = useQuery(GET_APPLICATION_STATE);
  const currentSource = pathOr('', ['application', 'currentSource'], data);

  const isInternal = isInternalSource(currentSource);
  const isReviewer = isFeasibilityReviewerSource(currentSource);

  return {currentSource, isInternal, isReviewer};
};

export default useCurrentSource;

import {useApolloClient} from '@apollo/client';
import {mergeDeepRight, propOr} from 'ramda';

import {useQuery} from '@renofi/graphql/src/hooks';

import {GET_APPLICATION_STATE} from '../../queries';

const useUpdateMessage = () => {
  const client = useApolloClient();
  const {data} = useQuery(GET_APPLICATION_STATE);
  const application = propOr({}, 'application', data);

  const updateMessage = (partial) => {
    const updated = mergeDeepRight(application.message, partial);

    client.writeQuery({
      query: GET_APPLICATION_STATE,
      data: {
        application: {
          ...application,
          message: updated,
        },
      },
    });
  };

  return updateMessage;
};

export default useUpdateMessage;

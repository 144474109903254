import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';

import {useSignedUrls} from '@renofi/graphql';

import {Link, Photo} from './styled';

function AppraisalPhotoThumbnail({objectName}) {
  const thumbObjectName = `thumbnails/xsmall/${objectName}`;
  const {fetch, loading, signedUrls} = useSignedUrls({lazy: true});
  const url = pathOr(null, [0, 'url'], signedUrls);
  const src = pathOr(null, [1, 'url'], signedUrls);

  useEffect(() => {
    if (objectName) {
      const variables = {objectNames: [objectName, thumbObjectName]};
      fetch({variables});
    }
  }, [objectName]);

  return (
    !loading && (
      <Link blank href={url}>
        <Photo src={src} />
      </Link>
    )
  );
}

AppraisalPhotoThumbnail.propTypes = {
  objectName: PropTypes.string.isRequired,
};

export default AppraisalPhotoThumbnail;

import React, {useEffect, useState} from 'react';

import {isEmpty, propOr} from 'ramda';

import {Box, Button, Flex, Icon, Text} from '@renofi/components-internal';
import {white} from '@renofi/theme';
import {RENOFI_GRAPHQL_ERROR} from '@renofi/utilities/src/analytics';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Alert, Code, Link} from './styled';

const UNKNOWN = 'Unknown mutation';

const GraphQlAlert = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState(false);

  const verb = showDetails ? 'Hide' : 'Show';
  const operationName = propOr(UNKNOWN, 'operationName', error);
  const humanizedOpName = humanizeSnakeCase(operationName);
  const data = propOr([], 'data', error);
  const variables = propOr({}, 'variables', error);
  const hasVariables = !isEmpty(variables);

  const onClickDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  const onGraphQlError = (evt) => {
    const detail = propOr(null, 'detail', evt);
    const error = propOr(null, 'error', detail);
    if (!error) {
      return;
    }
    setShowDetails(false);
    setError(detail);
  };

  useEffect(() => {
    global.addEventListener(RENOFI_GRAPHQL_ERROR, onGraphQlError);

    return () => {
      global.removeEventListener(RENOFI_GRAPHQL_ERROR, onGraphQlError);
    };
  }, []);

  if (!error) {
    return null;
  }

  return (
    <Alert variant="dangerVibrant">
      <Flex flexDirection="column" width={1}>
        <Flex alignItems="center" width={1}>
          <Box>
            Error: the request "{humanizedOpName}" failed
            <Link onClick={onClickDetails}>{verb} details</Link>
          </Box>
          <Box ml="auto">
            <Button variant="link" onClick={() => setError(false)}>
              <Icon name="cross" color={white} />
            </Button>
          </Box>
        </Flex>
        {showDetails && (
          <Flex mt={3} flexDirection="column">
            <Flex>
              <Text fontWeight="bold">{operationName}</Text>
            </Flex>

            <Text fontWeight="bold">at {new Date().toISOString()}</Text>

            {hasVariables && <Code>{JSON.stringify(variables)}</Code>}
            {data.map((item) => {
              const message = propOr('Unknown error', 'message', item);
              const extensions = propOr({}, 'extensions', item);
              const hasExtra = !isEmpty(extensions);
              return (
                <Box key={message} mt={16}>
                  {message}
                  {hasExtra && (
                    <Code>{JSON.stringify(extensions, null, 2)}</Code>
                  )}
                </Box>
              );
            })}
          </Flex>
        )}
      </Flex>
    </Alert>
  );
};

export default GraphQlAlert;

import React from 'react';

const Pipeline = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4185_59209"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect
          width="24"
          height="24"
          transform="matrix(-1 0 0 1 24 0)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4185_59209)">
        <path
          d="M21 15V13H7V15H21ZM21 11V9H7V11H21ZM21 7V5H7V7H21ZM4 15C4.28333 15 4.52067 14.904 4.712 14.712C4.904 14.5207 5 14.2833 5 14C5 13.7167 4.904 13.479 4.712 13.287C4.52067 13.0957 4.28333 13 4 13C3.71667 13 3.47933 13.0957 3.288 13.287C3.096 13.479 3 13.7167 3 14C3 14.2833 3.096 14.5207 3.288 14.712C3.47933 14.904 3.71667 15 4 15ZM4 11C4.28333 11 4.52067 10.904 4.712 10.712C4.904 10.5207 5 10.2833 5 10C5 9.71667 4.904 9.479 4.712 9.287C4.52067 9.09567 4.28333 9 4 9C3.71667 9 3.47933 9.09567 3.288 9.287C3.096 9.479 3 9.71667 3 10C3 10.2833 3.096 10.5207 3.288 10.712C3.47933 10.904 3.71667 11 4 11ZM4 7C4.28333 7 4.52067 6.904 4.712 6.712C4.904 6.52067 5 6.28333 5 6C5 5.71667 4.904 5.479 4.712 5.287C4.52067 5.09567 4.28333 5 4 5C3.71667 5 3.47933 5.09567 3.288 5.287C3.096 5.479 3 5.71667 3 6C3 6.28333 3.096 6.52067 3.288 6.712C3.47933 6.904 3.71667 7 4 7Z"
          fill="#212F49"
        />
        <path
          d="M21 19V17H7V19H21ZM4 19C4.28333 19 4.52067 18.904 4.712 18.712C4.904 18.5207 5 18.2833 5 18C5 17.7167 4.904 17.479 4.712 17.287C4.52067 17.0957 4.28333 17 4 17C3.71667 17 3.47933 17.0957 3.288 17.287C3.096 17.479 3 17.7167 3 18C3 18.2833 3.096 18.5207 3.288 18.712C3.47933 18.904 3.71667 19 4 19Z"
          fill="#212F49"
        />
        <path
          d="M21 11V9H7V11H21ZM4 11C4.28333 11 4.52067 10.904 4.712 10.712C4.904 10.5207 5 10.2833 5 10C5 9.71667 4.904 9.479 4.712 9.287C4.52067 9.09567 4.28333 9 4 9C3.71667 9 3.47933 9.09567 3.288 9.287C3.096 9.479 3 9.71667 3 10C3 10.2833 3.096 10.5207 3.288 10.712C3.47933 10.904 3.71667 11 4 11Z"
          fill="#FF5253"
        />
      </g>
    </svg>
  );
};

export default Pipeline;

import React from 'react';

import {useParams} from 'react-router-dom';
import {useViewerLayout} from 'api/hooks';
import asyncComponent from 'lib/asyncComponent';
import {isSplitWideLayout} from 'lib/layoutHelper';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {Box} from '@renofi/components-internal';
import {useProject} from '@renofi/graphql';

import Complete from './components/Complete';

const BorrowerIncomeSources = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-income-sources" */ './components/BorrowerIncomeSources'
    ),
);

const AssetsStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-assets" */ './components/AssetsStatement'
    ),
);

const EmploymentDetails = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-assets" */ './components/EmploymentDetails'
    ),
);

const Mortgage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-mortgage" */ './components/Mortgage'
    ),
);

const GovernmentIdStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-tax" */ './components/GovernmentIdStatement/Component'
    ),
);
const RealEstateOwnedStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-tax" */ './components/RealEstateOwnedStatement/Component'
    ),
);

const Eligibility = () => {
  const {projectId, itemId: taskId} = useParams();
  const {project} = useProject({variables: {id: projectId}});
  const {tasks: projectTasks = []} = project || {};
  const tasks = projectTasks.filter((t) => t.facet === 'eligibility');
  const complete =
    tasks.length && tasks.every((t) => t.status === TASK_STATUSES.COMPLETED);
  const task = projectTasks.find((t) => t.id === taskId);
  const {layout} = useViewerLayout();

  const wide = isSplitWideLayout(layout);
  const padding = complete ? 0 : wide ? 72 : 116;
  if (!task) {
    return null;
  }

  return (
    <Box pb={padding}>
      {task.taskType === 'mortgage_statement' && <Mortgage />}
      {task.taskType === 'government_issued_id' && (
        <GovernmentIdStatement taskDetails={task} />
      )}
      {task.taskType === 'real_estate_owned' && (
        <RealEstateOwnedStatement taskDetails={task} project={project} />
      )}
      {task.taskType === 'employment_history' && (
        <EmploymentDetails task={task} />
      )}
      {task.taskType === 'income' && (
        <BorrowerIncomeSources task={task} project={project} />
      )}
      {task.taskType === 'assets' && (
        <AssetsStatement task={task} project={project} />
      )}
      {!complete && <Complete facet="eligibility" />}
    </Box>
  );
};

export default Eligibility;

import styled from '@emotion/styled';

import {basic88, white} from '@renofi/theme/src';

export const Wrapper = styled.div({
  // position: 'fixed',
  background: basic88,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  // top: 0,
  // right: 0,
  // bottom: 0,
  // left: 0,
  zIndex: 1010,
  overflow: 'hidden',
  color: white,
});

import React from 'react';

import PropTypes from 'prop-types';

import {Flex, Text} from '@renofi/components-internal';

function EmailNotificationsConfirmationModal({action}) {
  return (
    <Flex px={5} height={190} alignItems="center" justifyContent="center">
      <Text fontSize={18} linehHeight={21} textAlign="center">
        Are you sure you want {action} all system generated email notifications
        to the Contractor?
      </Text>
    </Flex>
  );
}

EmailNotificationsConfirmationModal.propTypes = {
  action: PropTypes.string,
};

export default EmailNotificationsConfirmationModal;

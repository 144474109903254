import {useParams} from 'react-router-dom';
import {find, isNil, last, once, pathOr, pipe, propEq} from 'ramda';
import {withFeasibilityReviewState} from 'api/hoc';
import {
  removeEmptyPayments,
  prepareFeasibilityReviewForSubmit,
} from 'lib/feasibilityReview/utils';

import {
  branch,
  compose,
  renderNothing,
  withHandlers,
  withLifecycle,
  withProps,
} from '@renofi/recompose/src';
import {
  withApollo,
  withFeasibilityReviews,
  withSubmitFeasibilityReview,
} from '@renofi/graphql/src/hoc';
import debounce from '@renofi/utilities/src/debounce';

import withProjectId from './withProjectId';
import {convertReviewToCacheState} from './feasibilityReview';

const debounceMutation = once((func, wait = 500) => {
  return debounce(func, wait);
});

const storeFeasibilityReview = (
  feasibilityReview,
  updateFeasibilityReviewState,
) => {
  const state = convertReviewToCacheState(feasibilityReview);
  updateFeasibilityReviewState(state);
};

export default () =>
  compose(
    withApollo,
    withProjectId(),
    withFeasibilityReviews(),
    withFeasibilityReviewState(),
    withSubmitFeasibilityReview(),
    withProps(({feasibilityReviews = []}) => {
      const params = useParams();
      const id = params?.itemId || params?.feasibilityReviewId;
      const review = find(propEq('id', id), feasibilityReviews);

      return {
        feasibilityReview: review || last(feasibilityReviews),
        feasibilityReviewId: id,
        returned: Boolean(review?.returnedAt),
        completed: Boolean(review?.completedBy),
      };
    }),
    withLifecycle({
      componentDidMount() {
        const {
          feasibilityReview,
          feasibilityReviewId,
          feasibilityReviewState,
          updateFeasibilityReviewState,
        } = this.props;
        if (
          !(
            (feasibilityReview && !feasibilityReviewState?.id) ||
            feasibilityReviewId !== feasibilityReviewState?.id
          )
        )
          return;
        storeFeasibilityReview(feasibilityReview, updateFeasibilityReviewState);
      },
      componentDidUpdate(prevProps) {
        const {
          feasibilityReview,
          feasibilityReviewId,
          feasibilityReviewState,
          updateFeasibilityReviewState,
        } = this.props;

        if (
          !feasibilityReview ||
          !feasibilityReviewId ||
          !feasibilityReviewState?.id ||
          feasibilityReviewId === feasibilityReviewState.id ||
          prevProps?.feasibilityReview?.id === feasibilityReview?.id
        ) {
          return;
        }

        storeFeasibilityReview(feasibilityReview, updateFeasibilityReviewState);
      },
    }),
    branch(
      pipe(pathOr(null, ['feasibilityReviewState', 'id']), isNil),
      renderNothing,
    ),
    withHandlers({
      updateFeasibilityReview:
        ({
          readFeasibilityReviewState,
          submitFeasibilityReview,
          updateFeasibilityReviewState,
        }) =>
        (partial) => {
          const feasibilityReviewState = removeEmptyPayments(
            readFeasibilityReviewState(),
          );
          updateFeasibilityReviewState(partial);
          setTimeout(async () => {
            const {id} = feasibilityReviewState;
            const submit = debounceMutation(submitFeasibilityReview);
            const feasibilityReview = prepareFeasibilityReviewForSubmit(
              feasibilityReviewState,
              partial,
            );

            submit({
              id,
              feasibilityReview,
            });
          }, 0);
        },
    }),
    withHandlers({
      updateFeasibilityReviewField:
        ({updateFeasibilityReview}) =>
        async (key, value) => {
          await updateFeasibilityReview({[key]: value});
        },
    }),
    withProps(({feasibilityReview, feasibilityReviewState}) => ({
      feasibilityReview: {
        ...feasibilityReview,
        ...(feasibilityReviewState?.id ? feasibilityReviewState : {}),
      },
    })),
  );

import React from 'react';

import PropTypes from 'prop-types';
import {withViewerLayout} from 'api/hoc';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {Box} from '@renofi/components-internal';

const Col = ({children, layout, ...props}) => {
  const wide = isSplitWideLayout(layout);
  const width = wide ? 1 / 2 : 1;
  return (
    <Box px={12} width={width} {...props}>
      {children}
    </Box>
  );
};

Col.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.string,
};

export default withViewerLayout()(Col);

import {pathOr, pick} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {GET_TASK_ADDITIONAL_REQUEST_BORROWER_NOTIFICATION} from '../queries';

import withApollo from './withApollo';

export default () =>
  compose(
    withApollo,
    withHandlers({
      getTaskAdditionalRequestPreview:
        ({client}) =>
        async ({borrowerId, task, taskAdditionalRequest} = {}) => {
          const result = await client.query({
            query: GET_TASK_ADDITIONAL_REQUEST_BORROWER_NOTIFICATION,
            variables: {
              task: {
                borrowerId,
                ...pick(['title', 'details', 'taskType', 'informationKind'])(
                  task,
                ),
              },
              taskAdditionalRequest,
            },
          });
          return pathOr(
            null,
            ['data', 'taskAdditionalRequestBorrowerNotification'],
            result,
          );
        },
    }),
  );

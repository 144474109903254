import {identity} from 'ramda';

import {compose, mapProps} from '@renofi/recompose/src';

import {GET_FEASIBILITY_REVIEW} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options, ...config} = {}) =>
  compose(
    withQuery({
      query: GET_FEASIBILITY_REVIEW,
      options:
        options ||
        (({feasibilityReviewId, id}) => ({
          variables: {id: feasibilityReviewId || id},
        })),
      props: ({
        data: {feasibilityReviewById, loading, previousData},
        ...props
      }) => ({
        loading,
        feasibilityReview:
          feasibilityReviewById || previousData?.feasibilityReviewById || null,
        ...props,
      }),
      ...config,
    }),
    mapProps(mapper),
  );

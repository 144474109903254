import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {MARK_INSURANCE_REVIEW_AS_COMPLETED} from '../mutations';

import useMutation from './useMutation';

export default function useMarkInsuranceReviewAsCompleted({
  onCompleted = noop,
} = {}) {
  const [markInsuranceReviewAsCompleted, {data, loading, error}] = useMutation(
    MARK_INSURANCE_REVIEW_AS_COMPLETED,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['insuranceReview'], data);

  return {markInsuranceReviewAsCompleted, response, loading, error};
}

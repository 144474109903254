import * as React from 'react';

import PropTypes from 'prop-types';

const SvgUploadNoCircle = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z"
        fill={color}
      />
    </svg>
  );
};

SvgUploadNoCircle.propTypes = {
  color: PropTypes.string,
};
export default SvgUploadNoCircle;

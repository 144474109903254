import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Box,
  Modal,
  SelectField,
} from '@renofi/components-internal';
import {GET_GCDD_REVIEW_BY_ID} from '@renofi/graphql/src';
import {
  useAssignGcddReview,
  useUnassignGcddReview,
} from '@renofi/graphql/src/hooks';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {Provider as ThemeProvider} from '@renofi/theme/src';

import {SelectFieldBox} from './styled';

const AssignGcddAnalystModal = ({
  onClose,
  users = [],
  selectedUserId,
  gcddReviewId,
}) => {
  const {addNotification} = useNotifications();
  const [value, setValue] = useState(selectedUserId);
  const header = 'Assign CDD Analyst';
  const refetchQuery = {
    query: GET_GCDD_REVIEW_BY_ID,
    variables: {
      id: gcddReviewId,
    },
  };
  const {assignGcddReview, loading: assignLoading} = useAssignGcddReview({
    refetchQueries: [refetchQuery],
  });
  const {unassignGcddReview, loading: unassignLoading} = useUnassignGcddReview({
    refetchQueries: [refetchQuery],
  });
  const isAssignDisabled = selectedUserId === value;
  const isAssignAnalystLoading = assignLoading || unassignLoading;

  const options = useMemo(() => {
    const userOptions = users?.map((user) => ({
      label: `${user?.firstName} ${user?.lastName}`,
      value: user?.id,
    }));
    return [...userOptions, {label: 'Unassigned', value: null}] || [];
  }, [users]);

  const handleUnassignGcddReview = async () => {
    try {
      await unassignGcddReview({
        variables: {
          id: gcddReviewId,
        },
      });
      onClose();
      addNotification({
        variant: 'success',
        content: 'CDD Analyst successfully unassigned',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to unassign CDD Analyst',
      });
    }
  };

  const handleAssignGcddReview = async () => {
    try {
      await assignGcddReview({
        variables: {
          id: gcddReviewId,
          assignedToId: value,
        },
      });
      onClose();
      addNotification({
        variant: 'success',
        content: 'CDD Analyst successfully assigned',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to assign CDD Analyst',
      });
    }
  };

  const handleAssign = () => {
    if (!value) {
      handleUnassignGcddReview();
    } else {
      handleAssignGcddReview();
    }
  };

  const handleAgentOnaChange = (value) => {
    setValue(value);
  };

  return (
    <Modal
      disableEscape
      clickBackdropToClose={false}
      lock
      fixed
      p={24}
      width={600}
      show
      footer={
        <Flex justifyContent="flex-end" alignItems="center">
          <Box mr="4px">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box ml="4px">
            <Button
              disabled={isAssignAnalystLoading || isAssignDisabled}
              loading={isAssignAnalystLoading}
              onClick={handleAssign}>
              Assign
            </Button>
          </Box>
        </Flex>
      }
      header={header}
      onClose={onClose}>
      <ThemeProvider theme="light">
        <SelectFieldBox flex={1} mx={2} mb={95}>
          <SelectField
            label="Assign to"
            onChange={handleAgentOnaChange}
            options={options}
            placeholder="Select Analyst"
            value={value}
          />
        </SelectFieldBox>
      </ThemeProvider>
    </Modal>
  );
};

AssignGcddAnalystModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array,
  selectedUserId: PropTypes.string,
  gcddReviewId: PropTypes.string,
};

export default AssignGcddAnalystModal;

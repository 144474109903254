import {isEmpty, isNil, pick} from 'ramda';

import {ADDITIONAL_PROPERTIES, SUBJECT_PROPERTIES, OTHER} from './constants';

const FALSY_VALUES = [undefined, '', null];

const isListItemsValid = (items = []) => {
  return items.every((item) => !FALSY_VALUES.includes(item));
};

const isMortgageCompanyOther = (mortgageCompany = '') => {
  return mortgageCompany === OTHER;
};

const isSubjectPropertyValid = ({
  primaryBorrowerOwned,
  coborrowerOwned,
  mortgageCompanyOther,
  ...subjectProperty
}) => {
  const isValidBorrower =
    Boolean(primaryBorrowerOwned) || Boolean(coborrowerOwned);

  const subjectPropertyValues = Object.values(
    pick(SUBJECT_PROPERTIES, subjectProperty),
  );

  if (isMortgageCompanyOther(subjectProperty.mortgageCompany)) {
    subjectPropertyValues.push(mortgageCompanyOther);
  }

  return isListItemsValid(subjectPropertyValues) && isValidBorrower;
};

const areAdditionalPropertiesValid = (additionalProperties = []) => {
  return additionalProperties.every(
    ({
      primaryBorrowerOwned,
      coborrowerOwned,
      mortgageCompanyOther,
      ...property
    }) => {
      const isValidBorrower =
        Boolean(primaryBorrowerOwned) || Boolean(coborrowerOwned);

      const propertyData = Object.values(pick(ADDITIONAL_PROPERTIES, property));

      if (isMortgageCompanyOther(property.mortgageCompany)) {
        propertyData.push(mortgageCompanyOther);
      }

      return isListItemsValid(propertyData) && isValidBorrower;
    },
  );
};

const areSoldPropertiesValid = (soldProperties = []) => {
  return soldProperties?.every((property) => {
    const {
      primaryBorrowerOwned,
      coborrowerOwned,
      streetAddressOne,
      streetAddressTwo,
      city,
      propertyType,
    } = property || {};

    const propertyData = [
      streetAddressOne,
      streetAddressTwo,
      city,
      propertyType,
    ];

    const isValidBorrower =
      Boolean(primaryBorrowerOwned) || Boolean(coborrowerOwned);

    return isListItemsValid(propertyData) && isValidBorrower;
  });
};

export default function validateRealEstateOwnedData(item) {
  const {additionalProperties, soldProperties, subjectProperty} = item || {};
  const hasAdditionalProperties =
    !isEmpty(additionalProperties) && !isNil(additionalProperties);
  const hasSoldProperties = !isEmpty(soldProperties) && !isNil(soldProperties);

  const isSubjectPropertyTypeValid = subjectProperty
    ? isSubjectPropertyValid(subjectProperty)
    : true;
  const isAdditionalPropertyTypeValid = hasAdditionalProperties
    ? areAdditionalPropertiesValid(additionalProperties)
    : true;
  const isSoldPropertyTypeValid = hasSoldProperties
    ? areSoldPropertiesValid(soldProperties)
    : true;

  return (
    isSubjectPropertyTypeValid &&
    isAdditionalPropertyTypeValid &&
    isSoldPropertyTypeValid
  );
}

import noop from '../../noop';

import useChannel from './useChannel';

export default function useContractorDueDilligenceRequested({
  projectId,
  onUpdate = noop,
  refetchQuery,
} = {}) {
  useChannel({
    channelName:
      projectId && `contractor_due_dilligence_requested--project=${projectId}`,
    onUpdate,
  });

  return {};
}

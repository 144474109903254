import React from 'react';

import PropTypes from 'prop-types';

import {Box} from '@renofi/components-internal';

import {Remove, Wrapper} from './styled';

const Element = ({children, onRemove}) => (
  <Wrapper>
    <Box>{children}</Box>
    <Remove ml={16} onClick={() => onRemove()} />
  </Wrapper>
);

Element.propTypes = {
  children: PropTypes.node,
  onRemove: PropTypes.func.isRequired,
};

export default Element;

import React from 'react';

import PropTypes from 'prop-types';
import {all, map, pipe, prop, propEq, toUpper, uniq} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {Text} from '@renofi/components-internal';

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

function ConfirmBulkTaskStatusChange({tasks}) {
  const areAllCompleted = all(propEq('status', TASK_STATUSES.COMPLETED), tasks);
  const facets = pipe(map(prop('facet')), uniq)(tasks);
  const facetsUpper = map(toUpper, facets);
  const updatedFacet = facetsUpper?.map((facet) => facet.replaceAll('_', ' '));
  const newStatus = areAllCompleted
    ? TASK_STATUSES.OUTSTANDING
    : TASK_STATUSES.COMPLETED;

  return (
    <Text>
      Are you sure you wish to mark all these tasks in{' '}
      <strong>{formatter.format(updatedFacet)}</strong> as {newStatus}?
    </Text>
  );
}

ConfirmBulkTaskStatusChange.propTypes = {
  facet: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  ),
};

ConfirmBulkTaskStatusChange.defaultProps = {
  tasks: [],
};

export default ConfirmBulkTaskStatusChange;

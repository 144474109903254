import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {REQUEST_PROJECT_IMPORT} from '../../mutations';

export default function useRequestProjectImport({onCompleted = noop} = {}) {
  const [requestProjectImport, {data, loading, error}] = useMutation(
    REQUEST_PROJECT_IMPORT,
    {
      onCompleted,
    },
  );

  const response = pathOr(null, ['requestProjectImport', 'project'], data);
  return {requestProjectImport, response, loading, error};
}

import React from 'react';

import PropTypes from 'prop-types';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import {ItemTabsBar} from 'modules/viewer-v2/components/Layout';
import {useHistory} from 'react-router-dom';

import queryString from '@renofi/utilities/src/queryString';

import TabContent from './TabContent';

const AppraisalReportItemsBar = ({projectId, appraisalDocs}) => {
  const {search} = useViewerNavigation();
  const history = useHistory();
  const currentValue = search?.documentId;

  const tabs = appraisalDocs?.map((doc) => ({
    label: <TabContent document={doc} projectId={projectId} />,
    value: doc.id,
  }));

  function onChangeTab(id) {
    const to = queryString.stringify({
      ...search,
      documentId: id,
    });
    return history.push(`?${to}`);
  }

  return (
    <ItemTabsBar onChange={onChangeTab} tabs={tabs} value={currentValue} />
  );
};

AppraisalReportItemsBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  appraisalDocs: PropTypes.array,
};

export default AppraisalReportItemsBar;

import styled from '@emotion/styled';

import {Checkbox as CommonCheckbox} from '@renofi/components-internal';
import {basic55, basic75} from '@renofi/theme/src/colors';

export const Checkbox = styled(CommonCheckbox)({
  margin: 16,
  padding: 16,
  background: basic75,
  color: basic55,
});

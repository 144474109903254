import styled from '@emotion/styled';
import {Row} from 'modules/viewer-v2';

import {Switch, Text} from '@renofi/components-internal';
import {basic20, basic55, basic75, basic88} from '@renofi/theme';

export const AppraisalValueRow = styled(Row)({
  borderBottom: `1px solid ${basic75}`,
});

export const LabelNote = styled(Text)({
  marginBottom: 8,
  color: basic55,
  fontSize: 14,
});

export const StickyFooter = styled(Row)({
  background: basic88,
  borderTop: `1px solid ${basic75}`,
  boxShadow: '0 -2px 7px rgba(0, 0, 0, 0.2)',
  position: 'sticky',
  bottom: 0,
});

export const WrittenProofSwitch = styled(Switch)(({checked}) => ({
  ...(!checked ? {backgroundColor: basic20} : {}),
}));

import React from 'react';

import PropTypes from 'prop-types';

import {actionRegular, basic55} from '@renofi/theme';
import {Arrow} from '@renofi/icons';

import {ArrowWrapper} from './styled';

const NextPrevButton = ({back, disabled, onClick}) => {
  const arrowColor = disabled ? basic55 : actionRegular;
  return (
    <ArrowWrapper
      alignItems="center"
      disabled={disabled}
      mx={1}
      px={2}
      justifyContent={'center'}
      onClick={onClick}>
      <Arrow direction={back ? 'left' : 'right'} color={arrowColor} />
    </ArrowWrapper>
  );
};

NextPrevButton.propTypes = {
  back: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NextPrevButton;

import {isNil} from 'ramda';

export const calculatePercentage = ({amount, renovationBudget}) => {
  if (isNil(amount) || isNil(renovationBudget)) {
    return null;
  }

  return Math.round((amount / renovationBudget) * 100);
};

export const calculateAdjusted = ({
  amount,
  renovationBudget,
  specialMaterialCosts,
}) => {
  if (isNil(amount) || isNil(renovationBudget) || isNil(specialMaterialCosts)) {
    return null;
  }

  return Math.round(((amount - specialMaterialCosts) / renovationBudget) * 100);
};

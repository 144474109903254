import React, {useContext, useState} from 'react';

import {isNil, is} from 'ramda';
import {useParams} from 'react-router-dom';
import {useViewerLayout} from 'api';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {useCurrentFeasibilityReview} from 'lib/feasibilityReview';
import {Container, GradingButton} from 'modules/viewer-v2/components/Layout';
import {useViewerNavigation} from 'modules/viewer-v2';

import {
  GET_PROJECT,
  useGetUser,
  useProject,
  useSubmitFeasibilityReview,
  useUpdateProject,
} from '@renofi/graphql';
import {
  canEditStandard,
  canViewStandard,
} from '@renofi/utilities/src/permissions';
import {Box, Text, Textarea, MoneyField} from '@renofi/components-internal';
import {basic55} from '@renofi/theme';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {Tabs} from '@renofi/components-internal/src/next';

import {
  TABS,
  budgetLabels,
  complexityDetails,
  complexityTitles,
} from './constants';

const Summary = () => {
  const {layout} = useViewerLayout();
  const wide = isSplitWideLayout(layout);

  const storage = useContext(StorageContext);
  const [tab, setTab] = useState(storage.getItem('viewer:summaryTab'));
  const {projectId, ...paramsV1} = useParams();
  const {params: paramsV2} = useViewerNavigation();

  const isV2Feasibility = paramsV2?.dataType === 'feasibility_review';
  const feasibilityReviewId = isV2Feasibility
    ? paramsV2?.itemId
    : paramsV1?.feasibilityReviewId;

  //  If feasibilityReviewId is null, just gets last (most-recent) review
  const {feasibilityReview} = useCurrentFeasibilityReview({
    feasibilityReviewId,
  });
  const {loading: isSubmittingReview, submitFeasibilityReview} =
    useSubmitFeasibilityReview();
  const {project} = useProject({variables: {id: projectId}});
  const {updateProject} = useUpdateProject({
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: {id: projectId},
      },
    ],
  });
  const {user} = useGetUser();
  const isOpsUser = canEditStandard(user) || canViewStandard(user);
  const {renovationCost, renovationScope, ruSummary} = project || {};
  const {complexityGrading} = feasibilityReview || {};

  const [budgetGrade, setBudgetGrade] = useState(
    is(Number, project?.renovationCostToCurrentPropertyValueRatio)
      ? Math.trunc(project.renovationCostToCurrentPropertyValueRatio)
      : null,
  );

  const onChangeComplexity = async (value) => {
    await submitFeasibilityReview({
      variables: {
        feasibilityReview: {complexityGrading: value},
        id: feasibilityReviewId,
      },
    });
  };

  const onChangeTab = (newTab) => {
    setTab(newTab);
    storage.setItem('viewer:summaryTab', newTab);
  };

  const onChangeValue = (key, value) => {
    debouncedUpdateProject({
      variables: {
        id: projectId,
        attributes: {[key]: value},
      },
    });
  };

  const debouncedUpdateProject = useDebounce(updateProject, 500);

  if (!isOpsUser) {
    return null;
  }

  return (
    <Container
      p={16}
      header={
        <Tabs tabs={TABS} value={tab} onChange={onChangeTab} wide={wide} />
      }>
      {tab === 'summary' && (
        <Textarea
          mb={0}
          rows={6}
          resizable
          value={ruSummary}
          placeholder="Summary will be displayed in the RU certificate."
          onChange={(v) => onChangeValue('ruSummary', v)}
        />
      )}
      {tab === 'scope' && (
        <Textarea
          mb={0}
          rows={4}
          value={renovationScope}
          onChange={(v) => onChangeValue('renovationScope', v)}
        />
      )}
      {tab === 'complexity' && (
        <Box width={1}>
          <GradingButton
            disabled={isSubmittingReview}
            mb={16}
            value={complexityGrading}
            placeholder="Select grading"
            label="Renovation complexity grading"
            onChange={onChangeComplexity}
          />
          {!isNil(complexityGrading) && (
            <>
              <Text lineHeight="22px">
                {complexityTitles[complexityGrading - 1]}
              </Text>
              <Text mt="4px" fontSize={12} lineHeight="20px" color={basic55}>
                {complexityDetails[complexityGrading - 1]}
              </Text>
            </>
          )}
        </Box>
      )}
      {tab === 'budget' && (
        <Box width={1}>
          <MoneyField
            value={renovationCost}
            label="Renovation budget"
            onChange={(v) => onChangeValue('renovationCost', v)}
          />
          <GradingButton
            mb={16}
            value={budgetGrade}
            placeholder="Select grading"
            label="Renovation budget grading"
            onChange={setBudgetGrade}
          />
          {!isNil(budgetGrade) && (
            <Text lineHeight="22px">{budgetLabels[budgetGrade - 1]}</Text>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Summary;

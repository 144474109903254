import noop from '@renofi/utilities/src/noop';

import {GET_INTERNAL_USERS_LIST} from '../../queries';
import useLazyQuery from '../useLazyQuery';

export default function useInternalUsers({onCompleted = noop} = {}) {
  const [getInternalUsers, {data, loading, error}] = useLazyQuery(
    GET_INTERNAL_USERS_LIST,
    {
      onCompleted,
    },
  );
  return {
    getInternalUsers,
    internalUsers: data?.internalUsers,
    data,
    loading,
    error,
  };
}

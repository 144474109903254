import React, {useContext, useEffect, useMemo} from 'react';

import {
  useTaskByCurrentItemId,
  useViewerNavigation,
} from 'modules/viewer-v2/hooks';
import {getTabsByTaskDocuments} from 'modules/viewer-v2/utils';
import {ItemTabsBar} from 'modules/viewer-v2/components/Layout';

import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../context';

import TabContent from './TabContent';

const FeasibilityReviewItemsBar = () => {
  const {getItemIdByDirection} = useContext(ViewerV2Context);
  const {history, search} = useViewerNavigation();
  const {documentId, taskId} = search || {};
  const {task} = useTaskByCurrentItemId({taskId});
  const {taskType} = task || {};

  const currentValue = useMemo(() => {
    if (taskType === 'appraisal_property_photos') {
      return taskType;
    }
    return documentId;
  }, [taskType, documentId, taskId]);

  const tabs = useMemo(() => {
    switch (taskType) {
      case 'renovation_contract':
      case 'renovation_plan':
        return getTabsByTaskDocuments({task, component: TabContent});
      case 'appraisal_property_photos':
        return [
          {
            label: task.title,
            value: taskType,
          },
        ];
      default:
        return [];
    }
  }, [taskType, documentId, taskId]);

  useEffect(() => {
    const documents = task?.documents || [];

    if (!!taskId && !documentId) {
      const to = queryString.stringify({
        ...search,
        documentId: getItemIdByDirection(documents),
      });
      history.replace(`?${to}`);
    }
  }, [tabs]);

  const onChangeTab = (id) => {
    switch (taskType) {
      case 'renovation_contract':
      case 'renovation_plan':
        const to = queryString.stringify({
          ...search,
          documentId: id,
        });
        return history.push(`?${to}`);
      default:
        return;
    }
  };

  if (!taskId || !task) {
    return null;
  }

  return (
    <ItemTabsBar onChange={onChangeTab} tabs={tabs} value={currentValue} />
  );
};

export default FeasibilityReviewItemsBar;

import styled from '@emotion/styled';

export const Button = styled.a(({active, hover}) => {
  return {
    display: 'flex',
    marginTop: 8,
    padding: 16,
    backgroundColor: active || hover ? 'rgba(229, 245, 255, 0.6)' : '#F5F6F7',
    borderRadius: 8,
    textDecoration: 'none',
    cursor: 'pointer',
    ':first-of-type': {
      marginTop: 0,
    },
    svg: {
      transition: 'all 200ms ease-out',
      opacity: active ? 1 : 0.25,
      filter: `grayscale(${active ? 0 : 1})`,
    },
  };
});

import React, {useRef} from 'react';

import {Global, css} from '@emotion/react';
import {Freeze} from 'react-freeze';
import {useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {BORROWER_PATH} from 'lib/routes';

const FreezingWrapper = ({children}) => {
  const ref = useRef(null);
  const viewerRouteMatch = useRouteMatch(
    `${BORROWER_PATH}/:tab(tasks|rrf)/:facet(eligibility|feasibility|appraisal|insurance|gcdd)`,
  );
  const isViewer = Boolean(viewerRouteMatch);

  return (
    <Freeze
      freeze={isViewer}
      placeholder={
        <div style={{height: ref?.current?.clientHeight}}>
          <Global
            styles={css`
              html {
                overflow: hidden;
              }
            `}></Global>
        </div>
      }>
      <div ref={ref}>{children}</div>
    </Freeze>
  );
};

FreezingWrapper.propTypes = {
  children: PropTypes.object.isRequired,
};

export default FreezingWrapper;

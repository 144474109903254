import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useViewerLayout, useCurrentSource} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {
  FEASIBILITY_STUDY_ITEM_RATINGS,
  FEASIBILITY_STUDY_ITEM_RATINGS_OPTIONS,
} from 'lib/feasibilityReview/constants';
import {
  useHasFeasibilityEditPermission,
  FEASIBILITY_STUDY_ITEM_TYPES,
} from 'modules/viewer-v2';

import {
  Box,
  Flex,
  Label,
  Money,
  Text,
  Textarea,
  TextField,
} from '@renofi/components-internal';
import {Error} from '@renofi/components-internal/src/FieldFrame';
import {basic55, dangerLight, white} from '@renofi/theme';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {Trash} from '@renofi/icons';

import SelectButton from '../../../SelectButton';
import Rating from '../StudyRating';

import {Cell, Row, Wrapper} from './styled';

const StudyItem = ({
  adjustedCost,
  cost,
  descriptionOfWork,
  returned,
  reviewerNotes,
  reviewerRating,
  type,
  editable,
  onChangeAdjustedCost,
  onChangeCost,
  onChangeDescription,
  onChangeNotes,
  onChangeRating,
  onRemove,
  isRequested,
}) => {
  const {layout} = useViewerLayout();
  const {isInternal, isReviewer} = useCurrentSource();

  const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();
  const debouncedChangeDescription = useDebounce(onChangeDescription, 500);
  const debouncedChangeCost = useDebounce(onChangeCost, 500);
  const debouncedChangeNotes = useDebounce(onChangeNotes, 500);
  const debouncedChangeAdjustedCost = useDebounce(onChangeAdjustedCost, 500);

  const [description, setDescription] = useState(descriptionOfWork);
  const [notes, setNotes] = useState(reviewerNotes);

  const wide = isSplitWideLayout(layout);
  const flexDirection = wide ? 'row' : 'column';
  const inScope = type === FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE;

  const showViewableItems = inScope && !editable;
  const showEditableItems = inScope && editable;

  const showLabel = isReviewer || (isInternal && hasFeasibilityEditPermission);
  const descriptionOfWorkLabel = showLabel ? 'Description of work' : '';
  const contractorCostLabel = showLabel ? 'Contractor cost' : '';

  const showCalculatedCost =
    reviewerRating &&
    reviewerRating !== FEASIBILITY_STUDY_ITEM_RATINGS.REASONABLE;

  const descriptionChangeHandler = (value) => {
    setDescription(value);
    debouncedChangeDescription(value);
  };

  const notesChangeHandler = (value) => {
    setNotes(value);
    debouncedChangeNotes(value);
  };

  return (
    <Wrapper
      px={16}
      py={returned ? 16 : 12}
      mb={isInternal ? '2px' : '4px'}
      justifyContent={wide && isInternal ? 'center' : 'flex-start'}>
      {editable && (
        <Row flexDirection={flexDirection}>
          <Cell mr={wide ? '4px' : 0} width={wide ? 348 : 1} flexGrow={0}>
            <Textarea
              required={isRequested}
              mb={0}
              rows={1}
              resizable
              value={description ?? ''}
              label={descriptionOfWorkLabel}
              onChange={descriptionChangeHandler}
            />
          </Cell>
          <Flex flexGrow={1} alignItems="flex-end" mt={wide ? 0 : '8px'}>
            <Cell ml={wide ? '4px' : 0} mr="4px" width={wide ? 142 : 'auto'}>
              <TextField
                required={isRequested}
                money
                mb={0}
                value={cost}
                leftIcon="dollar"
                label={contractorCostLabel}
                onChange={(value) => debouncedChangeCost(value)}
              />
            </Cell>
            <Cell
              ml="4px"
              height={44}
              alignItems="center"
              flexGrow={1}
              justifyContent="center">
              <Trash
                color={basic55}
                css={{cursor: 'pointer'}}
                onClick={onRemove}
              />
            </Cell>
          </Flex>
        </Row>
      )}
      {!editable && (
        <Row>
          <Box>
            <Label>Description of work</Label>
            <Text mt="4px" color={white} fontSize={16} lineHeight="19px">
              {descriptionOfWork}
            </Text>
          </Box>
          {isInternal && returned && Boolean(adjustedCost) && (
            <Box css={{textAlign: 'right'}}>
              <Label>Calculated cost</Label>
              <Text mt="4px" color={white} fontSize={16} lineHeight="19px">
                {adjustedCost && <Money value={adjustedCost} />}
              </Text>
            </Box>
          )}
          <Box css={{textAlign: 'right'}}>
            <Label>Contractor cost</Label>
            <Text mt="4px" color={white} fontSize={16} lineHeight="19px">
              {cost && <Money value={cost} />}
            </Text>
          </Box>
        </Row>
      )}
      {showViewableItems && (
        <Row mt={24}>
          <Box>
            <Label>Rating</Label>
            {reviewerRating && (
              <Rating small mt="6px" rating={reviewerRating}>
                {reviewerRating}
              </Rating>
            )}
          </Box>
          <Box css={{textAlign: 'right'}}>
            <Label>Notes</Label>
            <Text mt="4px" color={white} fontSize={16} lineHeight="19px">
              {reviewerNotes}
            </Text>
          </Box>
        </Row>
      )}
      {showEditableItems && (
        <>
          <Flex width={1} mt={16}>
            <SelectButton
              mb={0}
              dynamic={!wide}
              value={reviewerRating}
              options={FEASIBILITY_STUDY_ITEM_RATINGS_OPTIONS}
              label="Select rating"
              onChange={onChangeRating}
            />
          </Flex>
          {!reviewerRating && isRequested && <Error>Required</Error>}

          {showCalculatedCost ? (
            <Flex mt={16}>
              <Box width={180}>
                <TextField
                  money
                  required={isRequested}
                  mb={0}
                  value={adjustedCost}
                  leftIcon="dollar"
                  label="Calculated cost"
                  onChange={debouncedChangeAdjustedCost}
                  css={adjustedCost ? {} : {borderColor: dangerLight}}
                />
              </Box>
            </Flex>
          ) : null}
          {reviewerRating && (
            <Flex mt={16}>
              <Textarea
                mb={0}
                rows={1}
                label="Enter notes"
                value={notes || ''}
                onChange={notesChangeHandler}
              />
            </Flex>
          )}
        </>
      )}
    </Wrapper>
  );
};

StudyItem.propTypes = {
  id: PropTypes.string,
  adjustedCost: PropTypes.number,
  cost: PropTypes.number,
  descriptionOfWork: PropTypes.string,
  returned: PropTypes.bool,
  completed: PropTypes.bool,
  editable: PropTypes.bool,
  reviewerNotes: PropTypes.string,
  reviewerRating: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChangeAdjustedCost: PropTypes.func.isRequired,
  onChangeCost: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onChangeNotes: PropTypes.func.isRequired,
  onChangeRating: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isRequested: PropTypes.bool,
};

export default StudyItem;

import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {validate as isValidUUID} from 'uuid';

import {getFullName} from '@renofi/utilities/src/data';

import {NO_NAME, UUID_NAME_REGEX} from './constants';
import {MentionsWrapper, UserBadge} from './styled';

const Mentions = ({children, users = [], userId, truncate, preWrap}) => {
  const split = useMemo(() => children?.split(UUID_NAME_REGEX), [children]);

  if (!children) return null;

  return (
    <MentionsWrapper preWrap={preWrap} truncate={truncate}>
      {split.map((item, index) => {
        if (!item) {
          return null;
        }

        if (isValidUUID(item)) {
          const user = users.find(({id}) => id === item);
          const fullName = getFullName(user, NO_NAME);
          const isUser = item === userId;
          return (
            <UserBadge
              key={`${item}-${index}`}
              isUser={isUser}>{`@${fullName}`}</UserBadge>
          );
        }

        return item;
      })}
    </MentionsWrapper>
  );
};

Mentions.propTypes = {
  children: PropTypes.string,
  users: PropTypes.array,
  userId: PropTypes.string,
  truncate: PropTypes.bool,
  preWrap: PropTypes.bool,
};

export default Mentions;

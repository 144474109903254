import React, {useMemo, useState, useCallback} from 'react';

import PropTypes from 'prop-types';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {Container} from 'modules/viewer-v2';

import {
  Box,
  Flex,
  Text,
  Textarea,
  TextField,
} from '@renofi/components-internal';
import {Tabs} from '@renofi/components-internal/src/next';
import {isUrl} from '@renofi/utilities/src/validators';
import useDebounce from '@renofi/utilities/src/useDebounce';

import {getTabs} from '../viewer/utils';

import Complete from './components/Complete';
import ContractReview from './components/ContractReview';
import FeasibilityStudy from './components/FeasibilityStudy';

const Feasibility = ({
  commentary,
  complete,
  validation,
  feasibilityTab: tab,
  layout,
  onChangeCommentary,
  onUpdateCommentary,
  onChangeFeasibilityTab,
  updateFeasibilityReview,
  feasibilityReview,
  xactRemodelUrl,
}) => {
  const [xactUrl, setXactUrl] = useState(xactRemodelUrl || '');
  const [invalidUrl, setInvalidUrl] = useState(false);
  const updateCommentary = useDebounce(onUpdateCommentary, 500);
  const tabs = useMemo(
    () =>
      getTabs({
        validation,
        feasibilityReview,
      }),
    [validation, feasibilityReview],
  );

  const wide = isSplitWideLayout(layout);
  const padding = wide ? 72 : 116;

  const onUpdateFeasibility = useCallback(({xactUrl, invalidUrl}) => {
    if (!invalidUrl) {
      updateFeasibilityReview({xactRemodelUrl: xactUrl});
    }
  }, []);

  const debounceUpdateFeasibility = useDebounce(onUpdateFeasibility, 500);
  const onXactRemodelChange = useCallback((value) => {
    const isInvalidUrl = !isUrl(value) && Boolean(value);
    setInvalidUrl(isInvalidUrl);
    setXactUrl(value);
  }, []);

  return (
    <Box pb={complete ? 0 : padding}>
      <Container
        p={16}
        css={{borderTop: 'none'}}
        header="Contract Commentary and other notes">
        <Textarea
          mt={20}
          mb={0}
          rows={6}
          resizable
          value={commentary}
          label=""
          onChange={onChangeCommentary}
          onKeyUp={() => updateCommentary(commentary)}
        />
      </Container>

      <Container header={<Text mr={2}>XactRemodel project URL</Text>}>
        <Flex alignItems="center" justifyContent="center" px={3}>
          <TextField
            value={xactUrl}
            inputValue={xactUrl}
            mb={0}
            onChange={onXactRemodelChange}
            onKeyUp={() => debounceUpdateFeasibility({xactUrl, invalidUrl})}
            error={invalidUrl ? 'Invalid URL' : null}
            errorBorder={invalidUrl}
          />
        </Flex>
      </Container>

      <Container
        p={0}
        header={
          <Tabs
            value={tab}
            tabs={tabs}
            onChange={onChangeFeasibilityTab}
            wide={wide}
          />
        }>
        {tab === 'contractReview' && (
          <ContractReview isCostEstimateDisabled={false} />
        )}
        {tab === 'feasibilityStudy' && <FeasibilityStudy />}
      </Container>
      {!complete && <Complete />}
    </Box>
  );
};

Feasibility.propTypes = {
  commentary: PropTypes.string,
  complete: PropTypes.bool,
  validation: PropTypes.object,
  feasibilityReview: PropTypes.object,
  groupedStudyItems: PropTypes.object,
  feasibilityTab: PropTypes.string,
  layout: PropTypes.string,
  onChangeCommentary: PropTypes.func.isRequired,
  onUpdateCommentary: PropTypes.func.isRequired,
  onChangeFeasibilityTab: PropTypes.func.isRequired,
  updateFeasibilityReview: PropTypes.func.isRequired,
  xactRemodelUrl: PropTypes.string,
};

export default Feasibility;

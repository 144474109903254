import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  width: '100%',
  minHeight: 70,
  flexDirection: 'column',
  backgroundColor: basic80,
  '&:first-of-type': {
    borderRadius: '4px 4px 0px 0px',
  },
  '&:last-of-type': {
    borderRadius: '0px 0px 4px 4px',
  },
});

export const Row = styled(Flex)({
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const Cell = styled(Flex)({
  alignItems: 'center',
});

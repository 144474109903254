import * as React from 'react';

import PropTypes from 'prop-types';

const SvgSmallPanel = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6572 9.25H1.99969V18C1.99969 19.3807 3.11898 20.5 4.49969 20.5H15.6572V9.25Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 9.25H17V20.5H19.5C20.8807 20.5 22 19.3807 22 18V9.25Z"
        fill={color}
      />
      <path
        d="M2 5.5C2 4.11929 3.11929 3 4.5 3H19.5C20.8807 3 22 4.11929 22 5.5V8H2V5.5Z"
        fill={color}
      />
    </svg>
  );
};

SvgSmallPanel.propTypes = {
  color: PropTypes.string,
};
export default SvgSmallPanel;

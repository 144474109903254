import {gql} from '@apollo/client';

import {projectReport} from '../partials';

export default gql`
  mutation createProjectReport($columns: [String!]!, $description: String, $filter: ProjectsSearchFilterInputObject, $name: String!, $query: String, $sort: ProjectsSearchSortInputObject) {
    createProjectReport(columns: $columns, description: $description, filter: $filter, name: $name, query: $query, sort: $sort) {
      report {
        ${projectReport}
      }
    }
  }
`;

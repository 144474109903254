import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SET_GCDD_REVIEW_PRIORITY} from '../../../mutations';

export default function useSetGcddReviewPriority({onCompleted = noop} = {}) {
  const [setGcddReviewPriority, {data, loading, error}] = useMutation(
    SET_GCDD_REVIEW_PRIORITY,
    {
      onCompleted,
    },
  );
  const response = pathOr(
    null,
    ['setGcddReviewPriority', 'gcddReview', 'priority'],
    data,
  );

  return {setGcddReviewPriority, response, loading, error};
}

import React from 'react';

import PropTypes from 'prop-types';
import {useViewerLayout} from 'api';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {ButtonGroup, SelectField} from '@renofi/components-internal';

const SelectButton = ({
  dynamic,
  label,
  options,
  placeholder = 'Select',
  value,
  onChange,
  onHover = () => {},
  ...props
}) => {
  const {layout} = useViewerLayout();
  const wide = isSplitWideLayout(layout);
  return (
    <>
      {wide && (
        <ButtonGroup
          dynamic={dynamic}
          value={value}
          options={options}
          label={label}
          onChange={onChange}
          onHover={onHover}
          {...props}
        />
      )}
      {!wide && (
        <SelectField
          label={label}
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        />
      )}
    </>
  );
};

SelectButton.propTypes = {
  dynamic: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ]),
    ),
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onHover: PropTypes.func,
};

export default SelectButton;

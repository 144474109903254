import {filter, propEq} from 'ramda';

const getMostRecentReport = (documents = []) => {
  const [mostRecent] = filter(
    propEq('documentType', 'appraisal_report_document'),
    documents,
  ).sort((l, r) => new Date(r.createdAt) - new Date(l.createdAt));
  return mostRecent;
};

export default getMostRecentReport;

import {find, pipe, propEq, propOr} from 'ramda';

import {taskTypes} from '@renofi/utilities/src/enums';

export default (project = {}) => {
  if (!project?.tasks?.length) {
    return null;
  }

  return pipe(
    propOr([], 'tasks'),
    find(propEq('taskType', taskTypes.personal_info_and_residency)),
    propOr(null, 'personalInfoAndResidency'),
  )(project);
};

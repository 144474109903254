import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_WEB_REVIEWS_SCORE} from '../../../mutations';

export default function useSubmitWebReviewsScore({onCompleted = noop} = {}) {
  const [submitWebReviewsScore, {data, loading, error}] = useMutation(
    SUBMIT_WEB_REVIEWS_SCORE,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitWebReviewsScore', 'gcddReview'], data);

  return {submitWebReviewsScore, response, loading, error};
}

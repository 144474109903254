/* eslint-disable max-lines */
import {gql} from '@apollo/client';

const facet = `{
  count
  value
}`;

const projectUser = `{
  id
  firstName
  lastName
}`;

const monthFacet = `{
  count
  month {
    month
    year
  }
}`;

const processorFacet = `{
  count
  projectUser ${projectUser}
}`;

const quarterFacet = `{
  count
  quarter {
    quarter
    year
  }
}`;

export default gql`
  query projectsSearchFacets($limit: Int = 0) {
    projectsSearch(limit: $limit) {
      collection {
        id
      }
      metadata {
        facets {
          ackDeltaTaskStatus ${facet}
          additionalDocumentsTaskStatus ${facet}
          additionalEligibilityEvidenceTaskStatus ${facet}
          advisorFinalCallScheduleTaskStatus ${facet}
          appraisalComparableSalesTaskStatus ${facet}
          appraisalPropertyDetailsTaskStatus ${facet}
          appraisalPropertyPhotosTaskStatus ${facet}
          appraisalRecentTransactionsTaskStatus ${facet}
          appraisalRenovationDetailsTaskStatus ${facet}
          appraisalStatus ${facet}
          assetsTaskStatus ${facet}
          borrowersOnboardingTaskStatus ${facet}
          certificateAcknowledgementTaskStatus ${facet}
          contractReviewStatus ${facet}
          contractorInfoTaskStatus ${facet}
          employmentHistoryTaskStatus ${facet}
          estimatedCloseMonth ${monthFacet}
          estimatedCloseQuarter ${quarterFacet}
          feasibilityReviewStatus ${facet}
          feasibilityReviewUncompleted ${facet}
          gcddReviewStatus ${facet}
          governmentIssuedIdTaskStatus ${facet}
          hasActualRenovationStartDate ${facet}
          hasAllTasksOutstanding ${facet}
          hasAppraisalOutstandingTasks ${facet}
          hasContractorOutstandingTasks ${facet}
          hasEligibilityOutstandingTasks ${facet}
          hasExpectedRenovationStartDate ${facet}
          hasRenovationOutstandingTasks ${facet}
          homeInsuranceTaskStatus ${facet}
          incomeTaskStatus ${facet}
          insuranceCoverageProofTaskStatus ${facet}
          lastActionType {
            count
            type
          }
          lender {
            count
            lender {
              name
              id
            }
          }
          loanApplicationStatus ${facet}
          loanClosedMonth ${monthFacet}
          loanClosedQuarter ${quarterFacet}
          loanType ${facet}
          mortgageStatementTaskStatus ${facet}
          outstandingTasks ${facet}
          pendingReviewTasks ${facet}
          personalInfoAndResidencyTaskStatus ${facet}
          processor ${processorFacet}
          productDetailsConfirmationTaskStatus ${facet}
          projectAssociate {
            count
            projectUser ${projectUser}
          }
          projectOwner {
            count
            projectUser ${projectUser}
          }
          raas ${facet}
          realEstateOwnedTaskStatus ${facet}
          rejectedTasks ${facet}
          renovationContractTaskStatus ${facet}
          renovationPlanTaskStatus ${facet}
          ruStatus ${facet}
          seniorProcessor ${processorFacet}
          smsConsentRenovationProgressTaskStatus ${facet}
          softCreditCheckTaskStatus ${facet}
          stage {
            count
            stageId
            stageName
          }
          state ${facet}
          taxReturnsDocsTaskStatus ${facet}
          x1003InProgress ${facet}

        }
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_REFERENCE_SCORE} from '../../../mutations';

export default function useSubmitReferenceScore({onCompleted = noop} = {}) {
  const [submitReferenceScore, {data, loading, error}] = useMutation(
    SUBMIT_REFERENCE_SCORE,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitReferenceScore', 'reference'], data);

  return {submitReferenceScore, response, loading, error};
}

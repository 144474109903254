import React, {useCallback, useEffect, useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import styled from '@emotion/styled';
import {REASONS_TO_MESSAGE_BORROWER} from 'lib/borrower/constants';
import groupTaskOptionsByFacets from 'lib/groupTaskOptionsByFacets';

import {
  Box,
  Button,
  Flex,
  Modal,
  Text,
  SelectField,
  Textarea,
} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {basic55, basic80} from '@renofi/theme/src/colors';
import useDebounce from '@renofi/utilities/src/useDebounce';

import Element from '../Element';

import {REASONS_OPTIONS} from './constants';
import {Container} from './styled';
import RequestAdditionalDocumentsPreview from './components/RequestAdditionalDocumentsPreview';

const StyledModal = styled(Modal)({
  minHeight: '420px',
});

const DEFAULT_HEADER = 'Message borrower';

const getHeader = ({showReasonDropdown, reason}) => {
  if (showReasonDropdown) {
    return DEFAULT_HEADER;
  }

  return REASONS_OPTIONS[reason] || DEFAULT_HEADER;
};

const Message = ({
  allTasks = [],
  type,
  theme = 'light',
  active,
  reason,
  loading,
  disabled,
  documents,
  tasks,
  rejectionOptions,
  rejectionReason,
  messageContent,
  onCancel,
  onSubmit,
  onChangeReason,
  onAddTask,
  onChangeMessageContent,
  onChangeRejectionReason,
  onRemoveTask,
  showPreview,
  showReasonDropdown,
}) => {
  const [groupedTasks, setGroupedTasks] = useState([]);
  const [message, setMessage] = useState('');
  const header = getHeader({showReasonDropdown, reason});
  const showSelectReasonDropdown =
    type !== 'contractor' &&
    reason !== REASONS_TO_MESSAGE_BORROWER.RETURN &&
    showReasonDropdown;
  const debouncedChangeMessageContent = useDebounce(
    onChangeMessageContent,
    400,
  );

  const getAcceptTitle = () => {
    if (!showPreview && reason === REASONS_TO_MESSAGE_BORROWER.REQUEST) {
      return 'Next';
    }
    if (reason === REASONS_TO_MESSAGE_BORROWER.RETURN) {
      return 'Send message in 1 hour';
    }
    return 'Send message';
  };

  const onChangeMessage = useCallback((newValue) => {
    setMessage(newValue);
    debouncedChangeMessageContent(newValue);
  }, []);

  const calculatedGroupedTasks = useMemo(() => {
    return groupTaskOptionsByFacets({tasks: allTasks});
  }, [allTasks.length]);

  useEffect(() => {
    setGroupedTasks(calculatedGroupedTasks);
  }, [calculatedGroupedTasks]);

  useEffect(() => {
    setMessage('');
  }, [active]);

  return (
    <ThemeProvider theme={theme}>
      <StyledModal
        clickBackdropToClose={false}
        show={active}
        onClose={onCancel}
        header={
          reason === REASONS_TO_MESSAGE_BORROWER.RETURN
            ? 'Reject document'
            : header
        }
        footer={
          <Flex justifyContent="flex-end" alignItems="center">
            <Box mr="4px">
              <Button variant="secondary" onClick={() => onCancel()}>
                Cancel
              </Button>
            </Box>
            <Box ml="4px">
              <Button
                loading={loading}
                disabled={disabled}
                onClick={() => onSubmit()}>
                {getAcceptTitle()}
              </Button>
            </Box>
          </Flex>
        }>
        {showPreview ? (
          <RequestAdditionalDocumentsPreview
            message={messageContent}
            taskId={tasks[0]?.id || null}
          />
        ) : (
          <Flex flexDirection="column" justifyContent="flex-start">
            {showSelectReasonDropdown && (
              <SelectField
                value={reason}
                options={REASONS_OPTIONS}
                label="Select a reason for messaging the borrower"
                placeholder="Select a reason"
                onChange={onChangeReason}
              />
            )}
            {reason === REASONS_TO_MESSAGE_BORROWER.REMIND && (
              <>
                <SelectField
                  mb={isEmpty(tasks) ? 32 : '8px'}
                  value={null}
                  options={groupedTasks}
                  placeholder="Select tasks"
                  onChange={onAddTask}
                />
                {!isEmpty(tasks) && (
                  <Container>
                    {tasks.map(({id, title}) => (
                      <Element key={id} onRemove={() => onRemoveTask(id)}>
                        {title}
                      </Element>
                    ))}
                  </Container>
                )}
              </>
            )}
            {reason === REASONS_TO_MESSAGE_BORROWER.REQUEST && (
              <>
                <SelectField
                  mb={isEmpty(tasks) ? 32 : '8px'}
                  value={tasks[0]?.id || null}
                  label="Relating to"
                  options={groupedTasks}
                  placeholder="Select task"
                  onChange={onAddTask}
                />
              </>
            )}
            {type === 'contractor' && (
              <>
                <SelectField
                  value={rejectionReason}
                  options={rejectionOptions}
                  label="Select a reason for messaging the borrower"
                  placeholder=" Select reason"
                  onChange={onChangeRejectionReason}
                />
                <SelectField
                  value={tasks[0].id}
                  options={groupedTasks}
                  label="Task affected"
                  placeholder="Select task"
                  onChange={onAddTask}
                  disabled
                />
                <Textarea
                  value={message}
                  label="Message"
                  onChange={onChangeMessage}
                />
              </>
            )}
            {reason === REASONS_TO_MESSAGE_BORROWER.RETURN && (
              <>
                <Box
                  style={{
                    color: basic55,
                    fontSize: 14,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}>
                  Document
                </Box>
                {!isEmpty(documents) && (
                  <Container>
                    {documents.map(({id, fileName}) => (
                      <Text ml="4px" key={id} color={basic80}>
                        {fileName}
                      </Text>
                    ))}
                  </Container>
                )}
                <SelectField
                  value={rejectionReason || ''}
                  options={rejectionOptions}
                  label="Reason for returning this document to the Borrower"
                  placeholder=" Select reason"
                  onChange={onChangeRejectionReason}
                />
              </>
            )}
            {reason && (
              <Textarea
                value={message}
                label="Message"
                onChange={onChangeMessage}
              />
            )}
          </Flex>
        )}
      </StyledModal>
    </ThemeProvider>
  );
};

Message.propTypes = {
  allTasks: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  theme: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  reason: PropTypes.string,
  active: PropTypes.bool,
  rejectionReason: PropTypes.string,
  messageContent: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  rejectionOptions: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddTask: PropTypes.func.isRequired,
  onChangeReason: PropTypes.func.isRequired,
  onChangeRejectionReason: PropTypes.func.isRequired,
  onChangeMessageContent: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onRemoveTask: PropTypes.func.isRequired,
  showPreview: PropTypes.bool,
  showReasonDropdown: PropTypes.bool,
};

export default Message;

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REQUEST_REFERENCE_CONTACT_CONFIRMATION} from '../../../mutations';

export default function useRequestReferenceContactConfirmation({
  onCompleted = noop,
} = {}) {
  const [requestReferenceContactConfirmation, {data, loading, error}] =
    useMutation(REQUEST_REFERENCE_CONTACT_CONFIRMATION, {
      onCompleted,
    });

  const success = pathOr(
    null,
    ['requestReferenceContactConfirmation', 'success'],
    data,
  );

  return {requestReferenceContactConfirmation, success, loading, error};
}

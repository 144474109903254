import React from 'react';

import {Link, Box} from '@renofi/components-internal';

import bbbSite from './img/bbb.png';

function BbbSite() {
  const bbbHref = 'https://www.bbb.org/';

  return (
    <Box mt={4}>
      <Link href={bbbHref} target="_blank">
        <img src={bbbSite} alt="Better Business Bureau" />
      </Link>
    </Box>
  );
}

export default BbbSite;

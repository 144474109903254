import {gql} from '@apollo/client';

export default gql`
  query feasibilityReviewState {
    feasibilityReviewState @client {
      id
      changeOrderProcedureApproved
      changeOrderProcedureNotes
      clearDescriptionOfWorkApproved
      clearDescriptionOfWorkNotes
      clearGuaranteeApproved
      clearGuaranteeNotes
      clearGuaranteeRating
      complexityGrading
      contactInfoApproved
      contactInfoNotes
      contractTimelineApproved
      contractTimelineNotes
      contractTimelineRating
      costEstimateSuppliedApproved
      costEstimateSuppliedNotes
      estimatedDuration
      feasibilityStudyItems {
        id
        adjustedCost
        cost
        descriptionOfWork
        reviewerNotes
        reviewerRating
        source
        type
      }
      generalCommentaryAndNotes
      homeownerResponsibilityCompleted
      inScopeAdjustedTotalRating
      inScopeTotalRating
      outOfScopeCompleted
      termsOfPaymentApproved
      termsOfPaymentNotes
      termsOfPayments {
        id
        amount
        comment
        specialMaterialCosts
      }
    }
  }
`;

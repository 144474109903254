import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_CORRESPONDENCE_MESSAGES} from '../../queries';

export default function useCorrespondenceMessages({
  fetchPolicy = 'no-cache',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_CORRESPONDENCE_MESSAGES,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const correspondenceMessages = propOr(null, 'correspondenceMessages', data);

  return {data, error, fetch, correspondenceMessages, loading};
}

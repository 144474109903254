import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REVIEW_DOCUMENT} from '../../../mutations';

export default function useReviewDocument({onCompleted = noop} = {}) {
  const [reviewDocument, {data, loading, error}] = useMutation(
    REVIEW_DOCUMENT,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['reviewDocument', 'document'], data);

  return {reviewDocument, response, loading, error};
}

import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';
import {basic75, basic80} from '@renofi/theme';

export const OuterWrapper = styled(Box)({
  flex: 1,
  boxShadow: `-1px 0px 0px ${basic75}, -10px 0px 4px rgba(0, 0, 0, 0.25)`,
  background: basic80,
  position: 'relative',
});

export const InnerWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,

  overflow: 'visible auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

import React, {Fragment, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {keys} from 'ramda';

import SelectField from '@renofi/components-internal/src/SelectField';
import noop from '@renofi/utilities/src/noop';

import Tab from './components/Tab';
import {TabsContainer} from './styled';

const Tabs = ({
  activeColor,
  containerCss = {},
  onChange = noop,
  tabComponent: TabComponent = Tab,
  tabs,
  tabCss = {},
  value = null,
  wide = true,
}) => {
  const [current, setCurrent] = useState(value);

  const onClickTab = (key) => {
    setCurrent(key);
    onChange(key);
  };

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  const finalTabs = useMemo(() => {
    if (Array.isArray(tabs)) {
      return tabs.reduce((obj, tab) => {
        return {...obj, [tab.value]: tab.label};
      }, {});
    }

    return tabs;
  }, [tabs]);

  return (
    <Fragment>
      {wide ? (
        <TabsContainer height="100%" alignItems="flex-end" css={containerCss}>
          {keys(finalTabs).map((key) => (
            <TabComponent
              activeColor={activeColor}
              active={key === current}
              key={key}
              id={key}
              css={tabCss}
              onClick={() => onClickTab(key)}>
              {finalTabs[key]}
            </TabComponent>
          ))}
        </TabsContainer>
      ) : (
        <TabsContainer width={1} height="100%" alignItems="center">
          <SelectField
            flat
            mb={0}
            pl={0}
            value={current}
            options={finalTabs}
            onChange={onClickTab}
          />
        </TabsContainer>
      )}
    </Fragment>
  );
};

Tabs.propTypes = {
  activeColor: PropTypes.string,
  containerCss: PropTypes.object,
  onChange: PropTypes.func,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    PropTypes.object,
  ]).isRequired,
  tabComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  tabCss: PropTypes.object,
  value: PropTypes.string,
  wide: PropTypes.bool,
};

export default Tabs;

import React from 'react';

import PropTypes from 'prop-types';

function ViewCorrespondenceMessage({data}) {
  return <div style={{whiteSpace: 'pre-wrap'}}>{data?.bodyText}</div>;
}

ViewCorrespondenceMessage.propTypes = {
  data: PropTypes.shape({
    bodyText: PropTypes.string,
  }),
};

export default ViewCorrespondenceMessage;

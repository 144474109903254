import {filter, keys, isNil, map, pipe} from 'ramda';
import {useCurrentSource} from 'api/hooks';
import {
  validateStudyItems,
  validateCostEstimate,
} from 'lib/validateFeasibilityStudy';

import {CONTRACT_REVIEW_MAP} from '../constants';

const useFeasibilityValidation = ({feasibilityReview, groupedStudyItems}) => {
  const {isReviewer} = useCurrentSource();
  const {homeownerResponsibilityCompleted, outOfScopeCompleted} =
    feasibilityReview || {};

  const feasibilityStudy = [];
  const {inScopeStudyItems, homeownerStudyItems, outOfScopeStudyItems} =
    groupedStudyItems;
  const isInScopeValid = validateStudyItems({
    items: inScopeStudyItems,
    isReviewer,
    isInScope: true,
  });

  if (!isInScopeValid) {
    feasibilityStudy.push('In scope');
  }

  const isHomeownerStudyItemsValid = validateStudyItems({
    items: homeownerStudyItems,
    isReviewer,
    isInScope: false,
  });

  if (
    (isReviewer && !homeownerResponsibilityCompleted) ||
    !isHomeownerStudyItemsValid
  ) {
    feasibilityStudy.push('In scope but homeowners responsibility');
  }

  const isOutOfScopeStudyItemsValid = validateStudyItems({
    items: outOfScopeStudyItems,
    isReviewer,
    isInScope: false,
  });

  if ((isReviewer && !outOfScopeCompleted) || !isOutOfScopeStudyItemsValid) {
    feasibilityStudy.push('Out of scope');
  }

  const contractReview = pipe(
    keys,
    filter((key) => isNil(feasibilityReview[key])),
    map((key) => CONTRACT_REVIEW_MAP[key]),
  )(CONTRACT_REVIEW_MAP);

  const hasEmptyPayments = feasibilityReview?.termsOfPayments?.find(
    ({amount}) => isNil(amount),
  );

  if (hasEmptyPayments) {
    contractReview.push('Incomplete terms of payment');
  }

  const costReview = [];
  if (!validateCostEstimate(feasibilityReview)) {
    costReview.push('Detailed cost estimate supplied');
  }

  return {
    validation: {contractReview, feasibilityStudy, costReview},
  };
};

export default useFeasibilityValidation;

import {pick, propOr} from 'ramda';
import {withReviewerFeasibilityReview} from 'modules/feasibility-reviewer';

import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from '@renofi/recompose/src';
import {
  withFeasibilityReturnPreview,
  withReturnFeasibilityReview,
} from '@renofi/graphql/src/hoc';

import Component from './Component';

export default compose(
  withReviewerFeasibilityReview(),
  withReturnFeasibilityReview(),
  withFeasibilityReturnPreview(),
  withState('complete', 'setComplete', propOr(false, 'complete')),
  withState('details', 'setDetails', ''),
  withState('loading', 'setLoading', false),
  withState('preview', 'setPreview', null),
  withState('reason', 'setReason', null),
  withHandlers({
    getEmailPreview:
      ({feasibilityReview, project, getFeasibilityReturnPreview, setPreview}) =>
      async () => {
        const params = {
          feasibilityReview: {
            reviewerId: feasibilityReview?.reviewer?.id,
            ...pick(
              [
                'id',
                'changeOrderProcedureApproved',
                'clearDescriptionOfWorkApproved',
                'clearGuaranteeApproved',
                'contactInfoApproved',
                'contractTimelinesApproved',
                'costEstimateSuppliedApproved',
                'contractReviewStatus',
                'generalCommentaryAndNotes',
                'feasibilityStudyStatus',
                'inScopeTotalRating',
                'inScopeAdjustedTotalRating',
                'termsOfPaymentApproved',
                'uncompletedReason',
                'uncompletedDetails',
              ],
              feasibilityReview,
            ),
          },
          project: {
            ...pick(
              [
                'id',
                'city',
                'state',
                'streetAddressOne',
                'streetAddressTwo',
                'zipCode',
              ],
              project,
            ),
            borrowerId: project?.primaryApplicant?.id,
          },
        };
        const preview = await getFeasibilityReturnPreview(params);
        setPreview(preview);
      },
  }),
  lifecycle({
    async componentDidMount() {
      const {complete, getEmailPreview} = this.props;
      if (!complete) {
        return;
      }
      await getEmailPreview();
    },
  }),
  withHandlers({
    onChangeDetails:
      ({setDetails}) =>
      (value) => {
        setDetails(value);
      },
    onChangeReason:
      ({setReason}) =>
      (value) => {
        setReason(value);
      },
    onSubmit:
      ({
        complete,
        details,
        reason,
        feasibilityReviewId: id,
        getEmailPreview,
        onClose,
        returnFeasibilityReview,
        setComplete,
        setLoading,
      }) =>
      async () => {
        if (!complete) {
          await getEmailPreview();
          setComplete(true);
          return;
        }
        setLoading(true);
        try {
          await returnFeasibilityReview({
            id,
            uncompletedDetails: details,
            uncompletedReason: reason,
          });
          onClose();
        } finally {
          setLoading(false);
        }
      },
  }),
  withProps(({details, reason}) => ({
    disabled: !(details && reason),
  })),
)(Component);

export const TABS = {
  summary: 'RU Summary',
  scope: 'Scope',
  complexity: 'Complexity',
  budget: 'Budget',
};

export const complexityTitles = [
  '<=10% of the home sqft is being renovated',
  'Up to 15% of the home sqft is being renovated',
  'Up to 20% of the home is being renovated',
  'Up to 25% of the home is being renovated',
  'Up to 30% of the home is being renovated',
  'Up to 35% of the home is being renovated',
  'Up to 50% of the home is being renovated',
  'Up to 50% of the home’s interior sqft is being renovated',
  'Up to 70% of the home’s interior sqft is being renovated',
  'Over 70% of the homes sqft are being renovated',
];

export const complexityDetails = [
  'Cosmetic upgrade limited to 1 room (No walls being opened, no plumbing changes, no electrical changes)',
  'Cosmetic upgrade limited to 2 rooms (No walls being opened, no plumbing changes, no electrical changes) If the scope of the reno is replacing windows and/or a door(s) that would fit in this also.',
  'Multiple rooms being affected that may involve the moving/fixing/upgrading of plumbing or electrical (not both). Still no structural/foundation changes are occurring. Knocking down non-load bearing wall is ok. Exterior remodel that doesn’t include the roof falls in this bucket.',
  'Multiple rooms being affected that may involve the moving/fixing/upgrading of plumbing and/or electrical (Can be both). Still no structural/foundation changes are occurring. Knocking down non-load bearing wall is ok. Exterior remodel that does include the roof falls in this bucket.',
  'Multiple rooms being affected that may involve the moving/fixing/upgrading of plumbing and/or electrical (Can be both). Knocking down load bearing walls is ok for this bucket but no additions.',
  'Multiple rooms being affected that may involve the moving/fixing/upgrading of plumbing and/or electrical (Can be both). Small addition, no more than 250 sqft, falls into this bucket.',
  'Entire floor is being renovated (doesn’t matter which floor). 5 or more rooms being renovated. Includes plumbing and electrical upgrades. Mid-size addition, no more than 500 sqft, falls into this bucket.',
  'Major interior and exterior remodel. Includes more than 5 rooms being renovated inside and full exterior remodel. Home is open to weather for extended period of time. (IE New roof, siding, new kitchen, floors throughout, bathrooms)',
  'Major interior and exterior remodel. Includes more than 5 rooms being renovated inside and full exterior remodel. Home is open to weather for extended period of time. (IE. New roof, siding, new kitchen, floors throughout, bathrooms).',
  'Full gut job. Major structural changes involving major additions, over 500 sqft, and/or foundation work being done. Home is exposed to weather for multiple weeks.',
];

export const budgetLabels = [
  'Up to $25,000.00',
  'Up to $50,000.00',
  'Up to $75,000.00',
  'Up to $100,000.00',
  'Up to $150,000.00',
  'Up to $200,000.00',
  'Up to $300,000.00',
  'Up to $400,000.00',
  'Up to $500,000.00',
  'Over $500,000.00',
];

import React, {useCallback, useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {useViewerLayout, useCurrentSource} from 'api/hooks';
import {validateStudyItems} from 'lib/validateFeasibilityStudy';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {
  Box,
  Button,
  Flex,
  Label,
  Money,
  SelectField,
  Text,
} from '@renofi/components-internal';
import {basic55, white} from '@renofi/theme/src/colors';
import {Pencil} from '@renofi/icons';

import SelectButton from '../../../SelectButton';
import Rating from '../StudyRating';

import {Container, Edit, Totals} from './styled';
import {RATING_OPTIONS, NUMBER_OF_ROWS_OPTIONS, PENDING} from './constants';

const SummaryFull = ({
  editable,
  loading,
  totalAdjustedRating,
  inScopeStudyItems,
  totalRating,
  onAddRows,
  onChangeTotalRating,
  studyItems,
}) => {
  const [edit, setEdit] = useState(false);
  const [rows, setRows] = useState(1);
  const [timer, setTimer] = useState(null);

  const {layout} = useViewerLayout();
  const {isReviewer} = useCurrentSource();

  const wide = isSplitWideLayout(layout);
  const justifyContent = wide ? 'flex-end' : 'space-between';

  const getFinalRating = useCallback(
    (isStudyItemsValid) => {
      if (isEmpty(inScopeStudyItems) || !isStudyItemsValid) {
        return PENDING;
      }

      return totalAdjustedRating || totalRating;
    },
    [inScopeStudyItems, totalAdjustedRating, totalRating],
  );

  const isStudyItemsValid = validateStudyItems({
    items: inScopeStudyItems,
    isReviewer,
    isInScope: true,
  });
  const finalRating = getFinalRating(isStudyItemsValid);
  const isEditDisabled = !editable || !isStudyItemsValid;

  const onRatingChange = useCallback(
    (value) => {
      if (value === finalRating) return;

      if (timer) {
        clearTimeout(timer);
      }

      onChangeTotalRating(value);

      const timeout = setTimeout(() => {
        setEdit(false);
      }, 700);

      setTimer(timeout);
    },
    [finalRating, timer],
  );

  const onEditClick = useCallback(() => {
    setEdit(!edit);
  }, [edit]);

  const totalCost = useMemo(
    () => studyItems.reduce((total, {cost = 0}) => total + cost, 0),
    [studyItems],
  );
  const totalCalculatedCost = useMemo(
    () =>
      studyItems.reduce(
        (total, {adjustedCost, cost = 0}) => total + (adjustedCost ?? cost),
        0,
      ),
    [studyItems],
  );

  return (
    <Box mt={16}>
      {editable && (
        <Flex
          px={16}
          width={1}
          mt={wide ? 0 : '8px'}
          alignItems="center"
          justifyContent={justifyContent}>
          <Box width={wide ? 60 : 100} mr={16}>
            <SelectField
              mb={0}
              value={rows}
              options={NUMBER_OF_ROWS_OPTIONS}
              onChange={setRows}
            />
          </Box>
          <Button loading={loading} onClick={() => onAddRows(rows)}>
            Add rows
          </Button>
        </Flex>
      )}
      <Totals editable={editable} wide={wide}>
        <Box>
          <Label>Total calculated cost</Label>
          <Flex mt="4px" alignItems="center">
            <Text fontSize={24} lineHeight="29px">
              <Money value={totalCalculatedCost} />
            </Text>
          </Flex>
        </Box>
        <Box>
          <Label>Total Contractor Cost</Label>
          <Text mt="4px" fontSize={24} lineHeight="29px">
            <Money value={totalCost} />
          </Text>
        </Box>
        <Box>
          <Label>Total rating</Label>
          <Flex mt="4px" alignItems="center">
            <Rating rating={finalRating}>{finalRating}</Rating>
            <Edit
              disabled={isEditDisabled}
              active={edit}
              ml={16}
              onClick={onEditClick}>
              <Pencil width={20} height={20} color={edit ? white : basic55} />
            </Edit>
          </Flex>
        </Box>
      </Totals>
      {edit && (
        <Box px={16}>
          <Container>
            <SelectButton
              mb={0}
              dynamic={!wide}
              value={totalAdjustedRating || totalRating}
              options={RATING_OPTIONS}
              label="Override total rating "
              onChange={onRatingChange}
            />
          </Container>
        </Box>
      )}
    </Box>
  );
};

SummaryFull.propTypes = {
  editable: PropTypes.bool,
  loading: PropTypes.bool,
  totalAdjustedRating: PropTypes.string,
  inScopeStudyItems: PropTypes.array,
  studyItems: PropTypes.array,
  totalRating: PropTypes.string,
  onAddRows: PropTypes.func.isRequired,
  onChangeTotalRating: PropTypes.func.isRequired,
};

export default SummaryFull;

import {Box, Flex} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';

import NextPrevButton from './components/NextPrevButton';

const TabsContainer = ({
  children,
  isPrevDisabled,
  isNextDisabled,
  onClickArrow,
}) => {
  return (
    <Flex
      pl={2}
      pr={2}
      pt={2}
      minHeight={60}
      width={1}
      backgroundColor={basic80}>
      <Box
        ml={2}
        flex="inherit"
        css={{
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        width={1}>
        {children}
      </Box>
      <Flex mr={0} flexDirection="column">
        <NextPrevButton
          back
          disabled={isPrevDisabled}
          onClick={() => onClickArrow(false)}
        />
        <NextPrevButton
          back={false}
          disabled={isNextDisabled}
          onClick={() => onClickArrow(true)}
        />
      </Flex>
    </Flex>
  );
};

export default TabsContainer;

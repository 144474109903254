import {flatten} from 'ramda';
import {facetTypes} from 'lib/groupTasksIntoFacets';

export const FACET_TYPES = {
  [facetTypes.eligibility]: [
    'homeowner_eligibility',
    'mortgage_statement',
    'government_issued_id',
  ],
  [facetTypes.appraisal]: ['appraisal'],
  [facetTypes.feasibility]: [
    'feasibility_contract_analysis',
    'renovation_contract',
    'renovation_plan',
  ],
  [facetTypes.insurance]: ['home_insurance', 'insurance_review'],
  [facetTypes.gcdd]: ['gcdd', 'contractor_license'],
  renovation: [
    'renovation_contract',
    'renovation_plan',
    'sms_consent_renovation_progress',
  ],
};

export const DOC_TYPE_TASK_DICTIONARY = {
  mortgage_statement: 'Most recent mortgage statement',

  feasibility_contract_analysis: 'Feasibility/Contract Analysis',
  renovation_contract:
    'Preliminary or signed renovation contract between you and your contractor, with a detailed cost estimate',
  renovation_plan: 'Renovation plans, blueprints, renderings',

  insurance_review: 'Insurance Review',

  gcdd: 'Contractor Due Diligence',
  contractor_license: 'Your contractor’s details and license',
  government_issued_id: 'Government issued ID',

  sms_consent_renovation_progress:
    'Consent to periodically receive SMS reminders from RenoFi asking about renovation progress',
};

export const FLATTENED_FACET_TYPES = flatten(Object.values(FACET_TYPES));

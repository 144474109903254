import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {basic55, successLight} from '@renofi/theme/src/colors';

const Approve = ({active, disabled, loading, ...props}) => {
  const [hover, setHover] = useState(false);
  const color = hover ? successLight : basic55;
  return (
    <span
      css={{
        cursor: disabled ? 'default' : 'pointer',
        opacity: loading || disabled ? 0.5 : 1,
      }}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}>
      {active ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM7 12.5L10.5 16L17.09 9.41L15.68 8L10.5 13.17L8.41 11.09L7 12.5Z"
            fill="#89E193"
          />
        </svg>
      ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            d="M7 12.5L10.5 16L17.09 9.41L15.68 8L10.5 13.17L8.41 11.09L7 12.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5Z"
            fill={color}
          />
        </svg>
      )}
    </span>
  );
};

Approve.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Approve;

export const FEASIBILITY_REVIEW_FIELDS_FOR_PREVIEW = [
  'changeOrderProcedureApproved',
  'clearDescriptionOfWorkApproved',
  'clearGuaranteeApproved',
  'contactInfoApproved',
  'contractTimelinesApproved',
  'costEstimateSuppliedApproved',
  'generalCommentaryAndNotes',
  'inScopeTotalRating',
  'inScopeAdjustedTotalRating',
  'termsOfPaymentApproved',
  'uncompletedReason',
  'uncompletedDetails',
];

export const PROJECT_FIELDS_FOR_PREVIEW = [
  'id',
  'city',
  'renofiId',
  'state',
  'streetAddressOne',
  'streetAddressTwo',
  'zipCode',
];

export const APPLICANT_FOR_PREVIEW = [
  'id',
  'email',
  'firstName',
  'lastName',
  'middleName',
  'nameSuffix',
  'phoneNumber',
];

export const ACTIONS = {
  PREVIEW: 'preview',
  PREPARE: 'prepare',
};

import {gql} from '@apollo/client';

import {projectReport} from '../partials';

export default gql`
  mutation updateProjectReport($columns: [String!]!, $description: String, $filter: ProjectsSearchFilterInputObject, $id: ID!, $name: String!, $query: String, $sort: ProjectsSearchSortInputObject) {
    updateProjectReport(columns: $columns, description: $description, filter: $filter, id: $id, name: $name, query: $query, sort: $sort) {
      report {
        ${projectReport}
      }
    }

  }
`;

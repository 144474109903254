import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {GET_INTERNAL_USERS_LIST} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useInternalUsersQuickSearch({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {data, fetch, loading, error} = useToggledQuery({
    lazy,
    query: GET_INTERNAL_USERS_LIST,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const internalUsers = pathOr([], ['internalUsers', 'collection'], data);

  return {fetch, internalUsers, data, loading, error};
}

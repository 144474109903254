import {gql} from '@apollo/client';

import {borrowersAndPropertyInformation} from '@renofi/graphql/src/partials';

export default gql`
  query projectShallowTaskDocuments($id: ID!) {
    projectById(id: $id) {
      id
      borrower {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      city
      county
      state
      streetAddressOne
      streetAddressTwo
      tasks {
        documents {
          id
          documentType
          fileName
          objectName
        }
        facet
        status
        taskType
        title
      }
      zipCode
    }
  }
`;

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {actionRegular, basic55} from '@renofi/theme';

export const Wrapper = styled.div({
  flex: 1,
  position: 'relative',
});

export const InnerWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  textAlign: 'center',

  overflow: 'visible auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const ItemContent = styled.div({
  display: 'inline-block',
  textAlign: 'initial',
  height: '100%',
  // margin: '32px auto',
});

export const PrevNextBtn = styled(Flex)(({active, prev = false}) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    background: active ? actionRegular : basic55,
    borderRadius: 40,

    position: 'absolute',
    [prev ? 'left' : 'right']: 32,
    top: 'calc(50% - 40px)',
    cursor: active ? 'pointer' : 'not-allowed',
  };
});

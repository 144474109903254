import {propOr} from 'ramda';

export default ({task = {}, component: TabContent}) => {
  const documents = propOr([], 'documents', task);

  return documents.map((doc) => ({
    value: doc.id,
    label: <TabContent doc={doc} />,
  }));
};

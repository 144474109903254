import {pathOr} from 'ramda';

import {GET_INSURANCE_CARRIERS} from '../queries';

import useQuery from './useQuery';

export default function useInsuranceCarriers({
  fetchPolicy = 'cache-first',
} = {}) {
  const {data, error, loading, refetch} = useQuery(GET_INSURANCE_CARRIERS, {
    fetchPolicy,
  });
  const insuranceCarriers = pathOr([], ['insuranceCarriers'], data);

  return {error, insuranceCarriers, loading, refetch};
}

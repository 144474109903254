import React from 'react';

import {isEmpty} from 'ramda';
import PropTypes from 'prop-types';
import {useViewerLayout} from 'api/hooks';
import {
  isSplitWideLayout,
  isFullWidthLayout,
  isSplitNarrowLayout,
} from 'lib/layoutHelper';
import FullWidth from 'modules/viewer-v2/icons/FullWidth';
import SplitWide from 'modules/viewer-v2/icons/SplitWide';
import SplitNarrow from 'modules/viewer-v2/icons/SplitNarrow';

import {basic55, successLight} from '@renofi/theme/src/colors';
import {useSystemState} from '@renofi/graphql/src/hooks';
import {Flex, Text} from '@renofi/components-internal';

import {Value, Wrapper} from './styled';

const Layout = ({sticky = false}) => {
  const {mutations} = useSystemState();
  const {layout, onChangeLayout} = useViewerLayout();

  const isFullWidth = isFullWidthLayout(layout);
  const isSplitNarrow = isSplitNarrowLayout(layout);
  const saving = !isEmpty(mutations);

  return (
    <Wrapper sticky={sticky}>
      {!isFullWidth && (
        <>
          <Value
            active={isSplitWideLayout(layout)}
            onClick={() => onChangeLayout('split_wide')}>
            <SplitWide />
          </Value>
          <Value
            active={isSplitNarrow}
            onClick={() => onChangeLayout('split_narrow')}>
            <SplitNarrow />
          </Value>
        </>
      )}
      <Value
        active={isFullWidth}
        onClick={() => {
          onChangeLayout('full_width');
        }}>
        <FullWidth />
      </Value>
      {!isFullWidth && (
        <Flex
          ml={16}
          flexGrow={1}
          alignItems="center"
          justifyContent="space-between">
          <Text
            css={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 0,
              ...(isSplitNarrow ? {maxWidth: 100} : {}),
            }}>
            Input review details
          </Text>
          <Text
            id="viewer-auto-save-status"
            fontSize={14}
            lineHeight="17px"
            color={saving ? successLight : basic55}
            css={{flexShrink: 0}}>
            {saving ? 'Saving...' : 'Saved'}
          </Text>
        </Flex>
      )}
    </Wrapper>
  );
};

Layout.propTypes = {
  sticky: PropTypes.bool,
};

export default Layout;

import React, {useContext, useMemo} from 'react';

import PropTypes from 'prop-types';
import {filter, propEq} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {useProject} from '@renofi/graphql';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {getTabTitleDetails, isTaskReviewed} from '../../../../utils';
import {useViewerNavigation} from '../../../../hooks';
import {getVariantStatusForTasks} from '../../utils';
import ViewerV2Context from '../../../../context';
import Group from '../Group';
import Tab from '../Tab';

const TasksFacet = ({facet, includeWithNoDocs = true}) => {
  const {onGroupPathsUpdate} = useContext(ViewerV2Context);

  const {params} = useViewerNavigation();
  const {projectId, itemId} = params;
  const {project, loading} = useProject({variables: {id: projectId}});

  const tasks = useMemo(() => {
    const tasks = filter(propEq('facet', facet), project?.tasks || []);
    if (includeWithNoDocs) {
      return tasks;
    }

    return tasks.filter((t) => Boolean(t?.documents?.length));
  }, [facet, includeWithNoDocs, project?.tasks?.length]);
  const [variant, facetStatus] = getVariantStatusForTasks(tasks);

  if (loading || !tasks.length) {
    return null;
  }

  return (
    <Group
      onUpdate={(data) => onGroupPathsUpdate(facet, 'task', data)}
      status={facetStatus}
      statusVariant={variant}
      title={humanizeSnakeCase(facet)}>
      {tasks.map((task) => {
        const taskStatus = isTaskReviewed(task, project)
          ? TASK_STATUSES.COMPLETED
          : task.status;
        const [title, details] = getTabTitleDetails({...task, type: 'task'});

        const basePath = `/viewer-v2/${projectId}/task/${task.id}`;

        return (
          <Tab
            active={task.id === itemId}
            details={details}
            title={title}
            status={taskStatus}
            key={task.id}
            path={basePath}
          />
        );
      })}
    </Group>
  );
};

TasksFacet.propTypes = {
  facet: PropTypes.string.isRequired,
  includeWithNoDocs: PropTypes.bool,
};

export default TasksFacet;

const adding = 'Adding';
const converting = 'Converting';
const expanding = 'Expanding';
const fixing = 'Fixing';
const no_changes = 'No changes';
const refinishing = 'Refinishing';
const removing = 'Removing';
const renovating = 'Renovating';
const repairing = 'Repairing';
const replacing = 'Replacing';

export const bathrooms = {
  no_changes,
  renovating,
  removing,
  converting,
};

export const basement = {
  fully_finished: 'Fully finished',
  partially_finished: 'Partially finished',
  maintenance: 'Maintenance',
};

export const bedrooms = {
  no_changes,
  renovating,
  removing,
  converting,
};

export const bonusRoom = {
  adding,
  renovating,
  converting,
};

export const deck = {
  adding,
  renovating,
  replacing,
  expanding,
  removing,
};

export const diningRoom = {
  adding,
  renovating,
  converting,
};

export const fireplaces = {
  no_changes,
  renovating,
  removing,
};

export const garages = {
  no_changes,
  renovating,
  removing,
  expanding,
};

export const hvac = {
  no_changes,
  removing,
  replacing,
  expanding,
};

export const kitchen = {
  adding_kitchen: 'Adding kitchen',
  cabinets: 'Cabinets',
  counters: 'Counters',
  appliances: 'Appliances',
  flooring: 'Flooring',
  lighting: 'Lighting',
  layout_floor_plan: 'Layout / Floor plan',
  windows: 'Windows',
  fixtures: 'Fixtures',
  plumbing: 'Plumbing',
  electrical_wiring: 'Electrical wiring',
  backsplash: 'Backsplash',
};

export const landscaping = {
  adding,
  renovating,
  removing,
};

export const laundryRoom = {adding, renovating};

export const livingRoom = {
  adding,
  renovating,
  converting,
};

export const mudRoom = {
  adding,
  renovating,
  converting,
};

export const office = {
  adding,
  renovating,
  converting,
};

export const outerBuildings = {
  adding,
  renovating,
  repairing,
  removing,
};

export const painting = {
  interior: 'Interior',
  exterior: 'Exterior',
  interior_exterior: 'Interior and Exterior',
};

export const patio = {
  adding,
  renovating,
  replacing,
  expanding,
  removing,
};

export const pool = {
  adding,
  repairing,
  replacing,
  expanding,
  refinishing,
  removing,
};

export const porch = {
  adding,
  renovating,
  replacing,
  expanding,
  removing,
};

export const roofing = {fixing, adding, replacing};

export const siding = {replacing, fixing};

export const structuralChanges = {
  open_concept: 'Open concept',
  repairing: 'Repairing',
  other: 'Other',
};

export const windows = {
  adding,
  replacing,
  removing,
};

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_OVERALL_SCORE} from '../../../mutations';

export default function useSubmitOverallScore({onCompleted = noop} = {}) {
  const [submitOverallScore, {abort, data, loading, error}] = useMutation(
    SUBMIT_OVERALL_SCORE,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitOverallScore', 'gcddReview'], data);

  return {abort, submitOverallScore, response, loading, error};
}

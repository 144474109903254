import React from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';
import {dangerLight, successLight} from '@renofi/theme/src/colors';

const PercentageLabel = ({percent, adjusted, first = false, last = false}) => {
  if (!percent) {
    return null;
  }

  if (adjusted) {
    const color =
      (adjusted < 8 && last) || (adjusted > 35 && first)
        ? dangerLight
        : successLight;

    return <Text color={color}>Adjusted to {adjusted}%</Text>;
  }

  if (percent < 8 && last) {
    return <Text color={dangerLight}>Lower than preferred</Text>;
  }

  if (percent > 35 && first) {
    return <Text color={dangerLight}>Higher than preferred</Text>;
  }

  return null;
};

PercentageLabel.propTypes = {
  percent: PropTypes.number,
  adjusted: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
};

export default PercentageLabel;

import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';
import {useViewerLayout} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {
  Box,
  Button,
  Flex,
  Label,
  Money,
  SelectField,
  Text,
} from '@renofi/components-internal';
import {
  basic55,
  dangerLight,
  white,
  successLight,
} from '@renofi/theme/src/colors';

const options = range(1, 10);

const SummaryExtra = ({loading, renovationBudget, onAddRows, studyItems}) => {
  const {layout} = useViewerLayout();

  const totalCost = useMemo(() => {
    return studyItems.reduce((total, {cost}) => total + cost, 0);
  }, [studyItems]);

  const [rows, setRows] = useState(1);
  const wide = isSplitWideLayout(layout);
  const mt = wide ? 0 : '8px';
  const alignItems = wide ? 'center' : 'flex-start';
  const flexDirection = wide ? 'row' : 'column';
  const budgetDiff = renovationBudget - totalCost;
  const diffSign = budgetDiff > 0 ? '+' : '';

  return (
    <Box mt={16}>
      <Flex
        px={16}
        width={1}
        alignItems={alignItems}
        flexDirection={flexDirection}
        justifyContent="space-between">
        <Box mt={mt}>
          <Label>Total Contractor Cost</Label>
          <Flex mt="4px" alignItems="center">
            <Text fontSize={18} lineHeight="22px" color={white}>
              <Money value={totalCost} />
            </Text>
          </Flex>
        </Box>
        <Box mt={mt}>
          <Label>Renovation budget</Label>
          <Flex mt="4px" alignItems="center">
            <Text
              fontSize={18}
              lineHeight="22px"
              color={budgetDiff >= 0 ? successLight : dangerLight}>
              <Money value={renovationBudget} />
            </Text>
            <Text ml="4px" fontSize={18} lineHeight="22px" color={basic55}>
              ({diffSign}
              <Money value={budgetDiff} />)
            </Text>
          </Flex>
        </Box>
        <Flex mt={mt} justifyContent="flex-end" alignItems="center">
          <Box width={wide ? 60 : 100} mr={16}>
            <SelectField
              mb={0}
              value={rows}
              options={options}
              onChange={(value) => setRows(value)}
            />
          </Box>
          <Button loading={loading} onClick={() => onAddRows(rows)}>
            Add rows
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

SummaryExtra.propTypes = {
  loading: PropTypes.bool,
  renovationBudget: PropTypes.number,
  onAddRows: PropTypes.func.isRequired,
  studyItems: PropTypes.arrayOf(
    PropTypes.shape({
      cost: PropTypes.number,
    }),
  ),
};

export default SummaryExtra;

import withCurrentProject from 'lib/withCurrentProject';
import withFeasibilityReview from 'lib/withFeasibilityReview';
import {ContractReview} from 'modules/viewer-v2';

import {compose} from '@renofi/recompose/src';

export default compose(
  withCurrentProject(),
  withFeasibilityReview(),
)(ContractReview);

import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';

export const Comment = styled.pre({
  fontSize: 16,
  lineHeight: '19px',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
});

export const Wrapper = styled(Box)(({wide, hasPaddingBottom}) => ({
  paddingBottom: hasPaddingBottom ? (wide ? 72 : 116) : 0,
}));

import React, {useCallback, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import {useViewerLayout} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {
  Box,
  Flex,
  Text,
  Textarea,
  TextField,
  MoneyField,
  NumberField,
} from '@renofi/components-internal';
import numberWithOrdinal from '@renofi/utilities/src/numberWithOrdinal';
import useDebounce from '@renofi/utilities/src/useDebounce';

import MenuButton from '../MenuButton';

import PercentageLabel from './PercentageLabel';
import {calculatePercentage, calculateAdjusted} from './utils';
import {Wrapper} from './styled';

const PaymentRow = ({
  amount,
  comment,
  last,
  canBeDeleted,
  ordinalNumber,
  renovationBudget,
  specialMaterialCosts,
  onChangeAmount,
  onChangeComment,
  onChangeSpecialMaterialCost,
  onRemove,
  disabled,
}) => {
  const {layout} = useViewerLayout();
  const wide = isSplitWideLayout(layout);

  const [percentage, adjustedPercentage] = useMemo(() => {
    const percentage = calculatePercentage({amount, renovationBudget});

    const adjustedPercentage = calculateAdjusted({
      amount,
      renovationBudget,
      specialMaterialCosts,
    });

    return [percentage, adjustedPercentage];
  }, [amount, renovationBudget, specialMaterialCosts]);

  const place = numberWithOrdinal(ordinalNumber);

  const amountLabel = (
    <Text color="white">{last ? 'Final payment' : `${place} payment`}</Text>
  );

  const getStyles = useCallback(() => {
    return {
      alignItems: wide ? 'flex-end' : 'flex-start',
      flexDirection: wide ? 'row' : 'column',
      marginX: wide ? '8px' : 0,
      marginY: wide ? 0 : '8px',
    };
  }, [wide]);

  const toggleComment = useCallback(() => {
    const commentValue = isNil(comment) ? '' : null;

    onChangeComment(commentValue);
  }, [comment]);

  const changePercentage = useDebounce((value) => {
    if (isNil(renovationBudget)) {
      return;
    }

    const calculatedAmount = renovationBudget * (value / 100);
    onChangeAmount(calculatedAmount);
  }, 1000);

  const {alignItems, flexDirection, marginX, marginY} = getStyles();

  return (
    <Wrapper>
      <Flex width={1} alignItems={alignItems} flexDirection={flexDirection}>
        <Box mr={marginX} marginBottom={marginY}>
          <MoneyField
            active
            mb={0}
            value={amount}
            label={amountLabel}
            onChange={onChangeAmount}
            disabled={disabled}
          />
        </Box>
        <Flex alignItems="flex-end" mb={marginY}>
          <Flex height={44} alignItems="center">
            <Text mr="8px">OR</Text>
          </Flex>
          <Box mr={marginX}>
            <NumberField
              active
              mb={0}
              leftIcon="percent"
              label={
                <PercentageLabel
                  percent={percentage}
                  adjusted={adjustedPercentage}
                  first={ordinalNumber === 1}
                  last={last}
                />
              }
              value={percentage}
              onChange={changePercentage}
              disabled={disabled}
            />
          </Box>
        </Flex>
        <Flex alignItems="flex-end" mt={marginY} mb={marginY}>
          <Box mr={20}>
            <TextField
              money
              mb={0}
              value={specialMaterialCosts}
              label={
                <Text css={{whiteSpace: 'nowrap', overflow: 'visible'}}>
                  Special material costs
                </Text>
              }
              onChange={onChangeSpecialMaterialCost}
              disabled={disabled}
            />
          </Box>
          <Flex height={44} alignItems="center">
            <MenuButton
              full={canBeDeleted}
              onComment={toggleComment}
              onRemove={onRemove}
              disabled={disabled}
            />
          </Flex>
        </Flex>
      </Flex>
      {!isNil(comment) && (
        <Flex mt="8px" pr={wide ? 52 : 0}>
          <Textarea
            mb={0}
            value={comment}
            onChange={onChangeComment}
            disabled={disabled}
          />
        </Flex>
      )}
    </Wrapper>
  );
};

PaymentRow.propTypes = {
  amount: PropTypes.number,
  comment: PropTypes.string,
  last: PropTypes.bool,
  canBeDeleted: PropTypes.bool,
  ordinalNumber: PropTypes.number,
  renovationBudget: PropTypes.number,
  specialMaterialCosts: PropTypes.number,
  onChangeAmount: PropTypes.func.isRequired,
  onChangeComment: PropTypes.func.isRequired,
  onChangeSpecialMaterialCost: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PaymentRow;

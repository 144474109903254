import React from 'react';

import PropTypes from 'prop-types';

import {TextField as TextFieldComponent} from '@renofi/components-internal';

import {Icon} from './styled';
import dollar from './dollar.svg';
import dollarRed from './dollar-red.svg';
import email from './email.svg';
import emailRed from './email-red.svg';
import phone from './phone.svg';
import phoneRed from './phone-red.svg';
import location from './location.svg';
import locationRed from './location-red.svg';

const iconsMap = {dollar, email, phone, location};
const redIconsMap = {
  dollar: dollarRed,
  email: emailRed,
  location: locationRed,
  phone: phoneRed,
};

const TextField = ({error, icon, label, ...props}) => (
  <TextFieldComponent
    error={error}
    label={label}
    leftIcon={
      icon && <Icon icon={error ? redIconsMap[icon] : iconsMap[icon]} />
    }
    {...props}
  />
);

TextField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  help: PropTypes.node,
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
};

export default TextField;

import React, {useRef} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {Viewer} from '@renofi/components-internal/src/DocumentViewer';

const PdfDocumentView = ({doc}) => {
  const {projectId} = useParams();
  const ref = useRef();
  if (!doc) {
    return null;
  }

  return (
    <div css={{width: 850, height: '100%', overflow: 'hidden'}} ref={ref}>
      <Viewer
        hasControls
        visible
        selectable
        prev={false}
        next={false}
        width={850}
        document={doc}
        onClick={(event) => event.stopPropagation()}
        projectId={projectId}
      />
    </div>
  );
};

PdfDocumentView.propTypes = {
  doc: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default PdfDocumentView;

import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_CONTRACTOR_LICENSES} from '../../queries';

export default function useContractorLicenses({
  fetchPolicy = 'no-cache',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_CONTRACTOR_LICENSES,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const contractorLicenses = propOr(null, 'contractorLicenses', data);

  return {error, fetch, contractorLicenses, loading};
}

import {isNil, reject} from 'lodash';

import {getAllHistoryDocumentIds} from '@renofi/modules-internal';
import {taskTypes} from '@renofi/utilities/src/enums';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

const getUnassignedAssetDocuments = ({documents = [], ...task} = {}) => {
  const {assetDetails = []} = task;
  const assetDocumentIds = assetDetails.reduce(
    (arr, a) => arr.concat((a.documents || []).map((d) => d.id)),
    [],
  );

  return documents.filter((doc) => !assetDocumentIds.includes(doc.id));
};

const getUnassignedEmploymentDocuments = ({documents, ...task} = {}) => {
  return (documents || []).filter((d) => {
    const historyDocs = getAllHistoryDocumentIds(task);
    return !historyDocs.includes(d.id);
  });
};

const getAllProperties = ({
  subjectProperty,
  additionalProperties = [],
} = {}) => {
  return [
    {...subjectProperty, isSubjectProperty: true},
    ...additionalProperties,
  ];
};

const getUnassignedRealEstateDocuments = ({documents = [], ...task}) => {
  const {realEstateOwned} = task || {};
  const allProperties = getAllProperties(realEstateOwned || {});
  const reoDocumentIds = allProperties.reduce((arr, p) => {
    return arr.concat((p?.documents || []).map((d) => d.id));
  }, []);

  return documents.filter((doc) => !reoDocumentIds.includes(doc.id));
};

const getUnassignedIncomeDocuments = ({documents = [], ...task}) => {
  const {incomeSources = []} = task || {};
  const incomeDocIds = incomeSources.reduce(
    (arr, i) => arr.concat((i.documents || []).map((d) => d.id)),
    [],
  );

  return documents.filter((doc) => !incomeDocIds.includes(doc.id));
};

export const getTaskDataSetType = ({taskType} = {}) => {
  switch (true) {
    case taskType === taskTypes.assets:
      return 'asset_detail';
    case taskType === taskTypes.real_estate_owned:
      return 'real_estate_owned_property';
    case taskType === taskTypes.employment_history:
      return 'employment_history';
    case taskType === taskTypes.income:
      return 'income_source';
    default:
      return null;
  }
};

export const getOrphanDocumentsByTaskDataSetType = ({
  taskDataSetType,
  task,
} = {}) => {
  switch (taskDataSetType) {
    case 'asset_detail':
      return getUnassignedAssetDocuments(task);
    case 'employment_history':
      return getUnassignedEmploymentDocuments(task);
    // case 'feasibility_review':
    case 'income_source':
      return getUnassignedIncomeDocuments(task);
    case 'real_estate_owned_property':
      return getUnassignedRealEstateDocuments(task);
    // case 'underwriting_condition':
    default:
      return [];
  }
};

export const getTargetOptionsByTaskDataSetType = ({taskDataSetType, task}) => {
  switch (taskDataSetType) {
    case 'asset_detail':
      const assetDetails = task?.assetDetails || [];
      return assetDetails.map((a) => {
        const type = humanizeSnakeCase(a.assetType);
        return {
          label: reject([type, a.financialInstitution], isNil).join(', '),
          value: a.id,
        };
      });
    case 'employment_history':
      const employmentHistory = task?.employmentHistories || [];
      return employmentHistory.map(({employerName, id}) => ({
        label: employerName,
        value: id,
      }));
    // case 'feasibility_review':
    case 'income_source':
      const {incomeSources = []} = task || {};
      return incomeSources.map((is) => {
        const role = is.borrowerRole === 'coborrower' ? ' (coborrower)' : '';
        return {
          label: `${humanizeSnakeCase(is.incomeType)}${role}`,
          value: is.id,
        };
      }, []);
    case 'real_estate_owned_property':
      return getAllProperties(task?.realEstateOwned || {}).map((e) => ({
        label: e.isSubjectProperty ? 'Subject property' : e.streetAddressOne,
        value: e.id,
      }));
    // case 'underwriting_condition':
    default:
      return [];
  }
};

import {
  allPass,
  chain,
  complement,
  fromPairs,
  isEmpty,
  pipe,
  toPairs,
} from 'ramda';

import isPlainObject from '@renofi/utilities/src/isPlainObject';

export default (obj = {}) => {
  const isChildObject = allPass([isPlainObject, complement(isEmpty)]);
  const translatePairs = ([k, v]) =>
    isChildObject(v) ? traverse(v) : [[k, v]];
  const traverse = pipe(toPairs, chain(translatePairs));

  return fromPairs(traverse(obj));
};

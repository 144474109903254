import {taskTypes} from '@renofi/utilities/src/enums';

export const TASK_TYPES_WITH_DATA_SETS = [
  taskTypes.assets,
  taskTypes.employment_history,
  taskTypes.income,
  taskTypes.real_estate_owned,
];

export const TASK_DATA_SETS = {
  [taskTypes.assets]: 'assets', // {assets|employment records|other sources of income|properties}
  [taskTypes.employment_history]: 'employment records',
  [taskTypes.income]: 'other sources of income',
  [taskTypes.real_estate_owned]: 'properties',
};

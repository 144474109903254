import {useSettings} from 'api/hooks';

const useFeasibilityTab = (storage) => {
  const {settings, updateSettings} = useSettings();

  const feasibilityTab = settings?.viewerFeasibilityTab;

  const onChangeFeasibilityTab = (viewerFeasibilityTab) => {
    updateSettings({viewerFeasibilityTab});
    storage.setItem('viewer:feasibilityTab', viewerFeasibilityTab);
  };

  return {feasibilityTab, onChangeFeasibilityTab};
};

export default useFeasibilityTab;

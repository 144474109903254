import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {last, isNil} from 'ramda';
import prettifyAppraisalOrderStatus from 'lib/prettifyAppraisalOrderStatus';

import {DateFormat, Box, Flex} from '@renofi/components-internal';
import {formatDateTime} from '@renofi/utilities/src/dates';
import InfoIcon from '@renofi/icons/src/Info';
import {actionLight} from '@renofi/theme';

import {
  StatusBarItemContainer,
  StatusBarItemLabel,
  StatusBarItemValue,
  StatusBarWrapper,
} from '../styled';

const AppraisalStatusBar = ({appraisalOrders = []}) => {
  const [currentAppraisalOrder, setCurrentAppraisalOrder] = useState(null);
  const {
    amc = {},
    createdAt,
    expectedDeliveryOn,
    inspectionAppointmentAt,
    inspectionCompletedAt,
    reportReceivedAt,
  } = currentAppraisalOrder || {};

  const inspectionDate = inspectionCompletedAt || inspectionAppointmentAt;
  const deliveryDate = reportReceivedAt || expectedDeliveryOn;
  const hasInspectionDate = !isNil(inspectionDate);
  const hasDeliveryDate = !isNil(deliveryDate);

  useEffect(() => {
    setCurrentAppraisalOrder(last(appraisalOrders));
  }, [appraisalOrders]);

  if (!currentAppraisalOrder) {
    return null;
  }

  return (
    <StatusBarWrapper>
      <Flex alignItems="center">
        <InfoIcon css={{transform: 'rotate(180deg)'}} color={actionLight} />

        <Box css={{color: actionLight}} ml={3}>
          {prettifyAppraisalOrderStatus(currentAppraisalOrder)}
        </Box>
      </Flex>

      <Flex>
        <StatusBarItemContainer>
          <StatusBarItemLabel>Order date</StatusBarItemLabel>
          <StatusBarItemValue>
            {createdAt && formatDateTime(new Date(createdAt))}
          </StatusBarItemValue>
        </StatusBarItemContainer>

        <StatusBarItemContainer>
          <StatusBarItemLabel>Inspection date</StatusBarItemLabel>
          <StatusBarItemValue>
            {hasInspectionDate ? <DateFormat value={inspectionDate} /> : 'TBA'}
          </StatusBarItemValue>
        </StatusBarItemContainer>

        <StatusBarItemContainer>
          <StatusBarItemLabel>Delivery date</StatusBarItemLabel>
          <StatusBarItemValue>
            {hasDeliveryDate ? (
              <DateFormat
                time={Boolean(reportReceivedAt)}
                value={deliveryDate}
              />
            ) : (
              'TBA'
            )}
          </StatusBarItemValue>
        </StatusBarItemContainer>

        {amc && (
          <StatusBarItemContainer>
            <StatusBarItemLabel>AMC</StatusBarItemLabel>
            <StatusBarItemValue>
              {amc.name || amc.code || 'AMC'}
            </StatusBarItemValue>
          </StatusBarItemContainer>
        )}
      </Flex>
    </StatusBarWrapper>
  );
};

AppraisalStatusBar.propTypes = {
  projectId: PropTypes.string.isRequired,
  appraisalOrders: PropTypes.arrayOf(
    PropTypes.shape({
      amc: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      }),
      createdAt: PropTypes.string.isRequired,
      expectedDeliveryOn: PropTypes.string,
      id: PropTypes.string.isRequired,
      inspectionAppointmentAt: PropTypes.string,
      inspectionCompletedAt: PropTypes.string,
      reportReceivedAt: PropTypes.string,
      status: PropTypes.string.isRequired,
    }),
  ),
};

export default AppraisalStatusBar;

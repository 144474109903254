import styled from '@emotion/styled';

import {Box, Flex} from '@renofi/components-internal';

import close from './close.svg';

export const Remove = styled(Box)({
  width: 12,
  height: 12,
  cursor: 'pointer',
  backgroundImage: `url('${close}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
});

export const Wrapper = styled(Flex)({
  alignItems: 'center',
  borderRadius: 19,
  padding: '8px 16px',
  backgroundColor: 'rgba(69,181,206,0.1)',
  color: '#212529',
  fontSize: 15,
  lineHeight: 1,
  minHeight: 32,
  margin: 4,
});

import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';

export const ItemTitle = styled(Text)({
  fontSize: 18,
  fontWeight: 'bold',
  padding: 16,
  borderTop: '1px solid #D8D8D8',
  borderLeft: '1px solid #D8D8D8',
  borderRight: '1px solid #D8D8D8',
});

export const ItemCard = styled.div({
  padding: '0 32px 32px 32px',
  marginBottom: 8,
  '&:first-of-type': {
    paddingTop: 32,
  },
});

import React from 'react';

const Conditions = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4185_59815"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4185_59815)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.049 8.138L9.8 15.387L5.851 11.449L4.3 13L9.8 18.5L18.6 9.7L17.049 8.138ZM12 2C5.928 2 1 6.928 1 13C1 19.072 5.928 24 12 24C18.072 24 23 19.072 23 13C23 6.928 18.072 2 12 2ZM11.968 22.5C6.71927 22.5 2.46802 18.2488 2.46802 13C2.46802 7.75125 6.71927 3.5 11.968 3.5C17.2168 3.5 21.468 7.75125 21.468 13C21.468 18.2488 17.2168 22.5 11.968 22.5Z"
          fill="#212F49"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.049 8.138L9.80005 15.387L5.85105 11.449L4.30005 13L9.80005 18.5L18.6 9.7L17.049 8.138Z"
          fill="#FF5253"
        />
      </g>
    </svg>
  );
};

export default Conditions;

import {map, toLower} from 'ramda';

import {WEBSITE_DEFAULTS} from './constants';

const pr = new Intl.PluralRules('en-US', {type: 'ordinal'});

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const formatOrdinals = (n) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

const isDefaultWebsite = (website = '') =>
  map(toLower, WEBSITE_DEFAULTS).includes(website.toLowerCase());

export const isOtherWebsite = (website) =>
  website && !isDefaultWebsite(website);

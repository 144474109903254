import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {CANCEL_APPRAISAL_ORDER} from '../../mutations';
import useMutation from '../useMutation';

export default function useCancelAppraisalOrder({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [cancelAppraisalOrder, {data, loading, error}] = useMutation(
    CANCEL_APPRAISAL_ORDER,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = pathOr(null, ['cancelAppraisalOrder', 'success'], data);

  return {cancelAppraisalOrder, response, loading, error};
}

import {makeVar} from '@apollo/client';

export const debtToIncomeVar = makeVar({
  actualMonthlyTotal: 0,
  totalMonthlyDebt: 0,
});

export const setDebToIncome = (params = {}) =>
  debtToIncomeVar({...debtToIncomeVar(), ...params});

export const debtToIncomePolicy = {
  read() {
    return debtToIncomeVar();
  },
};

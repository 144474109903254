import {
  borrowers,
  dueDiligenceRequests,
  lenders,
  feasibilityPipeline,
} from 'modules/routes';

import PERMISSIONS from '@renofi/utilities/src/permissions';

export const PATHS = [
  {
    label: 'Borrowers',
    path: borrowers(),
    permissions: [PERMISSIONS.BLUEPRINT_VIEW, PERMISSIONS.BLUEPRINT_EDIT],
  },
  {
    label: 'Lenders',
    path: lenders(),
    permissions: [PERMISSIONS.BLUEPRINT_VIEW, PERMISSIONS.BLUEPRINT_EDIT],
  },
  {
    label: 'DD requests',
    path: dueDiligenceRequests(),
    permissions: [PERMISSIONS.GCDD_VIEW, PERMISSIONS.GCDD_EDIT],
  },
  {
    label: 'Feasibility',
    path: feasibilityPipeline(),
    permissions: [PERMISSIONS.FEASIBILITY_ADMIN],
  },
];

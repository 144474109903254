export default `
applicantName
coapplicantName
content
createdAt
id
applicantSsnSuffix
coapplicantSsnSuffix
taskId
updatedAt
`;

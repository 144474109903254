import React from 'react';

import PropTypes from 'prop-types';

const SvgInfoSolid = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M6.02148 0.5C2.98392 0.5 0.521484 2.96243 0.521484 6C0.521484 9.03757 2.98392 11.5 6.02148 11.5C9.05905 11.5 11.5215 9.03757 11.5215 6C11.5215 2.96243 9.05905 0.5 6.02148 0.5ZM5.47148 8.75L5.47148 7.65L6.57148 7.65L6.57148 8.75H5.47148ZM5.47148 3.25V6.55H6.57148V3.25H5.47148Z"
          fill="#FF5253"
          id="info_solid_svg__a"
        />
      </defs>
      <use
        fill={color}
        xlinkHref="#info_solid_svg__a"
        transform="matrix(1 0 0 -1 0 12)"
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgInfoSolid.propTypes = {
  color: PropTypes.string,
};
export default SvgInfoSolid;

import React from 'react';

import PropTypes from 'prop-types';

import {useNotifications} from '@renofi/utilities';

import {Box, ButtonLink, Flex, Icon, Text} from '../index';

const DownloadZipNotification = ({fileUrl}) => {
  const {removeAll} = useNotifications();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center">
        <Text fontSize={15} mr={16}>
          If download didn't start automatically{' '}
          <ButtonLink ml="8px" secondary href={fileUrl} target="_blank">
            Download manually
          </ButtonLink>
        </Text>
      </Flex>
      <Box css={{cursor: 'pointer'}} p="8px" onClick={() => removeAll()}>
        <Icon color="white" name="cross" />
      </Box>
    </Flex>
  );
};

DownloadZipNotification.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default DownloadZipNotification;

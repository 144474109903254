import React from 'react';

export default (props) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H0V12C0 13.1046 0.895431 14 2 14H8V5Z"
        fill="#737373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H9V14H14C15.1046 14 16 13.1046 16 12V5Z"
        fill="#CCCCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H14C15.1046 0 16 0.89543 16 2V4H0V2C0 0.89543 0.89543 0 2 0Z"
        fill="#737373"
      />
    </svg>
  );
};

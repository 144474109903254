import {assoc, defaultTo, evolve, map, pick, pipe} from 'ramda';
import {v4 as uuid} from 'uuid';

export default pipe(
  pick([
    'id',
    'changeOrderProcedureApproved',
    'changeOrderProcedureNotes',
    'clearDescriptionOfWorkApproved',
    'clearDescriptionOfWorkNotes',
    'clearGuaranteeApproved',
    'clearGuaranteeNotes',
    'clearGuaranteeRating',
    'completedBy',
    'complexityGrading',
    'contactInfoApproved',
    'contactInfoNotes',
    'contractTimelineApproved',
    'contractTimelineNotes',
    'contractTimelineRating',
    'costEstimateSuppliedApproved',
    'costEstimateSuppliedNotes',
    'estimatedDuration',
    'feasibilityStudyItems',
    'generalCommentaryAndNotes',
    'homeownerResponsibilityCompleted',
    'inScopeAdjustedTotalRating',
    'inScopeTotalRating',
    'outOfScopeCompleted',
    'termsOfPaymentApproved',
    'termsOfPaymentNotes',
    'termsOfPayments',
    'uncompletedDetails',
    'uncompletedReason',
    'xactRemodelUrl',
  ]),
  evolve({
    feasibilityStudyItems: pipe(
      defaultTo([]),
      map(
        pick([
          'id',
          'adjustedCost',
          'cost',
          'descriptionOfWork',
          'reviewerNotes',
          'reviewerRating',
          'source',
          'type',
        ]),
      ),
    ),
    termsOfPayments: pipe(
      defaultTo([]),
      map(pick(['amount', 'comment', 'specialMaterialCosts'])),
      map((item) => assoc('id', uuid(), item)),
    ),
  }),
);

import withCurrentProject from 'lib/withCurrentProject';
import withFeasibilityReview from 'lib/withFeasibilityReview';
import {
  withGroupedFeasibilityStudyItems,
  withFeasibilityStudyItems,
} from 'modules/feasibility/viewer';
import {FeasibilityStudy} from 'modules/viewer-v2';

import {compose} from '@renofi/recompose/src';

export default compose(
  withCurrentProject(),
  withFeasibilityReview(),
  withFeasibilityStudyItems(),
  withGroupedFeasibilityStudyItems(),
)(FeasibilityStudy);

import React, {useContext} from 'react';

import {keys} from 'ramda';
import {GCDD_REVIEW_TABS} from 'modules/viewer-v2/constants';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {useGcddReviewsByProjectId} from '@renofi/graphql';

import {
  getGcddReviewContractorStatus,
  getGcddReviewQuestionnaireStatus,
} from '../../../../utils';
import ViewerV2Context from '../../../../context';
import Group from '../Group';
import Tab from '../Tab';
import {getGcddReviewStatus} from '../../utils';

const TABS = keys(GCDD_REVIEW_TABS);

const getStatusByKey = (gcddReview, key) => {
  switch (key) {
    case 'contractor':
      return getGcddReviewContractorStatus(gcddReview);
    case 'questionnaire':
      return getGcddReviewQuestionnaireStatus(gcddReview);
    default:
      return null;
  }
};

const GcddReviews = () => {
  const {onGroupPathsUpdate} = useContext(ViewerV2Context);
  const {params, search} = useViewerNavigation();
  const {projectId, itemId} = params;
  const {gcddReviews} = useGcddReviewsByProjectId({variables: {projectId}});

  return gcddReviews.map((gcddReview) => {
    const isGcddReviewCompleted = Boolean(gcddReview.completedAt);
    const facetStatus = getGcddReviewStatus(isGcddReviewCompleted);
    const [newVariant, newStatus] = facetStatus;
    const groupKey = `gcdd:${gcddReview.id}`;

    return (
      <Group
        onUpdate={(data) => onGroupPathsUpdate(groupKey, 'gcdd_review', data)}
        status={newStatus}
        statusVariant={newVariant}
        key={gcddReview.id}
        title="CDD">
        {TABS.map((key) => {
          const title = GCDD_REVIEW_TABS[key];
          const details = gcddReview?.contractor?.businessName;
          const status = getStatusByKey(gcddReview, key);
          const active = gcddReview.id === itemId && search?.tab === key;

          return (
            <Tab
              active={active}
              key={key}
              details={details}
              path={`/viewer-v2/${projectId}/gcdd_review/${gcddReview.id}?tab=${key}`}
              status={status}
              title={title}
            />
          );
        })}
      </Group>
    );
  });
};

export default GcddReviews;

import React from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';

import SelectButton from './SelectButton';

const grades = range(1, 11);

const Grading = ({options = grades, ...props}) => {
  return <SelectButton options={options} {...props} />;
};

Grading.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number),
};

export default Grading;

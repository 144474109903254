import useToggledQuery from '../useToggledQuery';
import {GET_PRODUCT_DETAILS_CONFIRMATION_SUMMARY} from '../../queries';

export default function useProductDetailsConfirmationSummary({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PRODUCT_DETAILS_CONFIRMATION_SUMMARY,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const summary = data?.productDetailsConfirmationSummary || null;

  return {error, fetch, summary, loading};
}

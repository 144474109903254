import queryString from '@renofi/utilities/src/queryString';

/**
 * Find the new "group" ID we need to iterate to (task, gcdd_review, whatever)
 */
const getNewGroupId = ({activePath, forward, allGroupPaths}) => {
  const currentIndex = allGroupPaths.findIndex((path) => {
    return path === activePath;
  });
  let newIndex;
  if (forward) {
    newIndex = currentIndex === allGroupPaths.length - 1 ? 0 : currentIndex + 1;
  } else {
    newIndex = currentIndex === 0 ? allGroupPaths.length - 1 : currentIndex - 1;
  }

  return allGroupPaths[newIndex] || null;
};

/**
 * Figure out what next or prev item we need to open: groupId, itemsFilter?
 */
export default (params = {}) => {
  const {forward, itemsFilterCache, search} = params;
  const hasNoItemsFilters = !itemsFilterCache;
  const iterateBy = forward ? 1 : -1;

  // No filters like borrowerRole? Just jump to prev/next group
  if (hasNoItemsFilters) {
    return getNewGroupId(params);
  }

  const [itemsFilterKey, itemsFilterIds = []] = itemsFilterCache;
  const currentFilterId = search[itemsFilterKey];
  const filterIndex = itemsFilterIds.indexOf(currentFilterId);
  const newItemsFilterId =
    filterIndex > -1 ? itemsFilterIds[filterIndex + iterateBy] : null;

  // If no new itemsFilter key found => last one so move to new Group
  if (!newItemsFilterId) {
    return getNewGroupId(params);
  }
  const qString = queryString.stringify({
    [itemsFilterKey]: newItemsFilterId,
  });

  return `?${qString}`;
};

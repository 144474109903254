import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {Flex, SelectField, Textarea} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';

const REASONS = [
  {
    label: 'Factual error',
    value: 'factual',
    template: '',
  },
  {
    label: 'Add Information/Explanation',
    value: 'addInfo',
    template: '',
  },
  {
    label: 'Review of Value Conclusion',
    value: 'review',
    template: '',
  },
  {
    label: 'Other',
    value: 'other',
    template: '',
  },
];

function RejectAppraisalModal({onChange}) {
  const [comments, setComments] = useState('');
  const [reason, setReason] = useState(null);

  const onChangeMessage = (newComments) => {
    setComments(newComments);
    onChange({comments, reason});
  };

  const onChangeReason = (newReason) => {
    setReason(newReason);
    onChange({comments, reason});

    const matchedReason = REASONS.find(({value}) => value === newReason);
    if (matchedReason) {
      setComments(matchedReason.template);
    }
  };

  return (
    <ThemeProvider theme="light">
      <Flex flexDirection="column">
        <SelectField
          placeholder="Select..."
          value={reason}
          options={REASONS}
          onChange={onChangeReason}
        />
        <Textarea value={comments} label="Message" onChange={onChangeMessage} />
      </Flex>
    </ThemeProvider>
  );
}

RejectAppraisalModal.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RejectAppraisalModal;

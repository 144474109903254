import {pathOr} from 'ramda';

import {SENT_EMAILS} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useSentEmails({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: SENT_EMAILS,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const sentEmails = pathOr([], ['sentEmails', 'collection'], data);
  const metadata = pathOr(null, ['sentEmails', 'metadata'], data);

  return {fetch, sentEmails, metadata, data, loading, error};
}

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REMOVE_WEB_REVIEW} from '../../../mutations';

export default function useRemoveWebReview({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [removeWebReview, {data, loading, error}] = useMutation(
    REMOVE_WEB_REVIEW,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['removeWebReview', 'gcddReview'], data);

  return {removeWebReview, response, loading, error};
}

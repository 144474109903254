import {compose} from '@renofi/recompose/src';
import {withProject} from '@renofi/graphql/src/hoc';
import spreadProps from '@renofi/utilities/src/spreadProps';

import withProjectId from './withProjectId';

export default ({spread = false} = {}) =>
  compose(
    withProjectId(),
    withProject(),
    spreadProps(spread ? ['project'] : []),
  );

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic80, basic88, white} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)(({sticky}) => ({
  width: '100%',
  height: 60,
  color: white,
  fontSize: 16,
  lineHeight: '19px',
  backgroundColor: basic80,
  alignItems: 'center',
  padding: '0 12px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: sticky ? 'sticky' : 'static',
  top: sticky ? 0 : 'auto',
  zIndex: 1,
}));

export const Value = styled(Flex)(
  {
    height: 24,
    width: 24,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 4px',
    flexShrink: 0,
    ':hover': {
      background: basic88,
    },
  },
  ({active}) => ({
    background: active ? basic88 : 'transparent',
  }),
);

import styled from '@emotion/styled';

import {Dropdown, Flex, Link} from '@renofi/components-internal';
import {mediaQuery} from '@renofi/theme';
import omitProps from '@renofi/utilities/src/omitProps';

export const Blueprint = styled(Flex)(
  mediaQuery({
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
    paddingLeft: [8, 16],
    paddingRight: [8, 16],
    marginLeft: [8, 20],
    borderLeft: '1px solid rgba(255,255,255,0.1)',
    borderRight: '1px solid rgba(255,255,255,0.1)',
  }),
);

export const NavTab = styled(omitProps(['active'])(Link))(
  mediaQuery({
    display: 'flex',
    alignItems: 'center',
    padding: ['0 4px', '0 16px'],
    margin: '0 16px',
    height: 70,
    fontSize: [12, 14],
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    ':hover': {
      textDecoration: 'none',
      color: '#FFFFFF',
    },
  }),
  ({active}) => ({
    color: active ? '#FFFFFF' : 'rgba(255,255,255,0.5)',
    borderBottom: active ? 'solid 4px #FF5253' : 'solid 4px transparent',
  }),
);

export const Menu = styled(Dropdown)({
  zIndex: 1009,
});

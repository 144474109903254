import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import {REQUIRED_INSURANCE_FIELDS} from 'modules/viewer-v2';

import {Button, Flex, Tooltip, Text} from '@renofi/components-internal';
import {basic20, basic80} from '@renofi/theme';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {List, ListItem} from './styled';

const TOOLTIP_STYLE = {
  left: -50,
  width: 330,
  fontSize: 14,
  textAlign: 'left',
  lineHeight: '17px',
  zIndex: 1050,
};

function MarkAsCompletedButton({
  disabled,
  insuranceReview,
  isCoverageProofReceived,
  onClick,
}) {
  const [missingFields, setMissingFields] = useState([]);
  const [proofRequestFields, setProofRequestFields] = useState([]);
  const showDocApproval = Boolean(proofRequestFields.length);
  const showTooltip = Boolean(missingFields.length);

  useEffect(() => {
    if (isNil(insuranceReview)) {
      return;
    }

    const {coverageProofRequestScheduledAt, coverageProofRequestedAt} =
      insuranceReview;
    let proofRequestData = [];
    const fields = REQUIRED_INSURANCE_FIELDS.reduce(
      (arr, key) =>
        isNil(insuranceReview[key]) ? arr.concat(humanizeSnakeCase(key)) : arr,
      [],
    );

    // Proof is still scheduled, or has gone out
    if (coverageProofRequestScheduledAt || coverageProofRequestedAt) {
      proofRequestData.push('Proof of insurance');
    }

    // Proof request sent, but yet to receive uploaded proof from borrower
    if (coverageProofRequestedAt && !isCoverageProofReceived) {
      proofRequestData.push(
        'Written proof of insurance coverage on carrier letterhead',
      );
    }
    setProofRequestFields(proofRequestData);

    setMissingFields(fields);
  }, [insuranceReview, isCoverageProofReceived]);

  return showTooltip ? (
    <Tooltip
      place="top"
      style={TOOLTIP_STYLE}
      delayHide={250}
      content={
        <Flex p={2} flexDirection="column" justifyContent="flex-start">
          <Text color={basic20} mb={16}>
            The items listed below are required before marking the facet as
            completed.
          </Text>

          <Text fontSize={16} fontWeight="bold">
            Insurance review
          </Text>

          <List>
            {missingFields.map((f) => (
              <ListItem key={f}>{f}</ListItem>
            ))}
          </List>
          {showDocApproval && (
            <Fragment>
              <Text fontSize={16} fontWeight="bold">
                Document approval
              </Text>

              <List>
                {proofRequestFields.map((f) => (
                  <ListItem key={f}>{f}</ListItem>
                ))}
              </List>
            </Fragment>
          )}
        </Flex>
      }
      css={{backgroundColor: basic80}}>
      <span>
        <Button fakeDisabled>Mark as completed</Button>
      </span>
    </Tooltip>
  ) : (
    <Button disabled={disabled} onClick={onClick}>
      Mark as completed
    </Button>
  );
}

MarkAsCompletedButton.propTypes = {
  disabled: PropTypes.bool,
  insuranceReview: PropTypes.shape({
    coverageProofRequestScheduledAt: PropTypes.string,
    coverageProofRequestedAt: PropTypes.string,
  }),
  isCoverageProofReceived: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default MarkAsCompletedButton;

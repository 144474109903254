import {concat, pick} from 'lodash';

const GROSS_INCOME_PROPS = [
  'grossMonthlyIncomeBonus',
  'grossMonthlyIncomeCommission',
  'grossMonthlyIncomeMilitaryEntitlements',
  'grossMonthlyIncomeOther',
  'grossMonthlyIncomeOvertime',
];

const ADJUSTED_INCOME_PROPS = [
  'adjustedGrossMonthlyIncomeBonus',
  'adjustedGrossMonthlyIncomeCommission',
  'adjustedGrossMonthlyIncomeMilitaryEntitlements',
  'adjustedGrossMonthlyIncomeOther',
  'adjustedGrossMonthlyIncomeOvertime',
];

const COMMON_PROPS = [
  'businessRole',
  'city',
  'coborrowerEmploymentHistory',
  'currentEmployment',
  'employerName',
  'noEmploymentDetails',
  'partTimeIncome',
  'selfEmployed',
  'startDate',
  'state',
  'streetAddress',
  'zipCode',
];

const CURRENT_PROPS = [
  ...COMMON_PROPS,
  'employedByParty',
  'ownershipShareAmount',
  'phoneNumber',
  'timeInRoleAsMonths',
];

const PREVIOUS_PROPS = [...COMMON_PROPS, 'endDate'];

export default (employment = {}, isEditing = false) => {
  const baseIncome = isEditing
    ? 'adjustedGrossMonthlyIncomeBase'
    : 'grossMonthlyIncomeBase';
  const otherIncomeProps = isEditing
    ? ADJUSTED_INCOME_PROPS
    : GROSS_INCOME_PROPS;

  const baseProps = employment?.currentEmployment
    ? concat(CURRENT_PROPS, otherIncomeProps)
    : PREVIOUS_PROPS;

  return pick(employment, concat(baseProps, baseIncome));
};

import {useToggledQuery} from '@renofi/graphql';

import {USER_BY_ID} from '../../queries';

export default function useUserById({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: USER_BY_ID,
    options: {
      fetchPolicy,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const user = data?.userById || {};

  return {fetch, user, data, loading, error, refetching};
}

import {useLocation, useParams} from 'react-router-dom';

import {useQueryString} from '@renofi/utilities/src/hooks';

export default function useGetCurrentTab() {
  const {tab = 'contractor'} = useQueryString();
  const {itemId} = useParams();
  const {pathname} = useLocation();

  // If viewer-V2, current tab is defined via query-string ?tab=[blah]
  if (pathname.includes('viewer-v2')) {
    return [itemId, tab];
  }

  return (itemId || '').split(':');
}

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {UPDATE_PROJECT} from '../mutations';

import useMutation from './useMutation';

export default function useUpdateProject({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [updateProject, {data, loading, error}] = useMutation(UPDATE_PROJECT, {
    onCompleted,
    refetchQueries,
    ...options,
  });
  const response = pathOr(false, ['updateProject', 'success'], data);

  return {updateProject, response, loading, error};
}

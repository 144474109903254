import {map, pathOr, pipe} from 'ramda';

import {useToggledQuery} from '@renofi/graphql/src/hooks';
import noop from '@renofi/utilities/src/noop';
import {toCamelCase} from '@renofi/utilities/src/string';

import {GET_INTROSPECT_PROJECTS_SEARCH} from '../../../queries';

const extractSortKeys = pipe(
  pathOr([], ['introspectProjectsSearch', 'sorts']),
  map(({name}) => toCamelCase(name)),
);

export default function useIntrospectProjectsSearch({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_INTROSPECT_PROJECTS_SEARCH,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const filters = pathOr([], ['introspectProjectsSearch', 'filters'], data);
  const sorts = extractSortKeys(data);

  return {fetch, filters, sorts, data, loading, error, refetching};
}

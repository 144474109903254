import React from 'react';

import PropTypes from 'prop-types';
import {withViewerLayout} from 'api/hoc';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {Flex} from '@renofi/components-internal';

import Container from '../Container';

import {Wrapper} from './styled';

const Complete = ({children, layout, ...props}) => {
  const wide = isSplitWideLayout(layout);
  return (
    <Wrapper wide={wide} {...props}>
      <Container inverse reverse mb={0} mt={16} py={20}>
        <Flex
          px={16}
          alignItems="center"
          justifyContent="space-between"
          flexDirection={wide ? 'row' : 'column'}>
          {children}
        </Flex>
      </Container>
    </Wrapper>
  );
};

Complete.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string,
};

export default withViewerLayout()(Complete);

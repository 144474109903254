export const getWebsiteLinks = ({
  streetAddress = '',
  city = '',
  state = '',
  zipCode = '',
}) => {
  const location = encodeURIComponent(
    `${streetAddress}, ${city}, ${state}, US`,
  );
  const formattedCity = encodeURIComponent(
    `${city} ${state}`?.replaceAll(' ', '-')?.toLowerCase(),
  );
  const googleHref = `https://www.google.com/maps/search/general+contractor+near+${location}`;
  const yelpHref = `https://www.yelp.com/search?find_desc=Contractors+-+General&find_loc=${location}`;
  const buildZoomHref = `https://www.buildzoom.com/${formattedCity}/general-contractors`;
  const angiesListHref = 'https://www.angi.com/';
  const houzzHref = `https://www.houzz.com/professionals/query/general-contractors?tid=11786&aid=${zipCode}`;

  return {
    googleHref,
    yelpHref,
    buildZoomHref,
    angiesListHref,
    houzzHref,
  };
};

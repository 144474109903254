import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {find, isEmpty, propEq} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import PdfDocumentView from 'modules/viewer-v2/components/PdfDocumentView';

import QuestionnaireDetails from './QuestionnaireDetails';

const Questionnaire = ({gcddReview}) => {
  const {search} = useViewerNavigation();
  const {documentId} = search || {};
  const questionnaireDocs = gcddReview?.returnedQuestionnaireDocuments || [];

  const doc = useMemo(
    () => find(propEq('id', documentId), questionnaireDocs),
    [documentId, questionnaireDocs?.length],
  );

  if (!doc || isEmpty(doc)) {
    return <QuestionnaireDetails gcddReview={gcddReview} />;
  }

  return <PdfDocumentView doc={doc} />;
};

Questionnaire.propTypes = {
  gcddReview: PropTypes.shape({
    returnedQuestionnaireDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
};

export default Questionnaire;

import {assoc, propEq, map, prop} from 'ramda';

export const facetTypes = {
  additional_documents: 'additional_documents',
  eligibility: 'eligibility',
  feasibility: 'feasibility',
  appraisal: 'appraisal',
  insurance: 'insurance',
  gcdd: 'gcdd',
  productConfirmation: 'product_confirmation',
  other: 'other',
};

export default ({tasks, includeOrphans = false}) => {
  const grouped = [
    {
      value: facetTypes.eligibility,
      label: 'Eligibility',
      tasks: tasks.filter(propEq('facet', 'eligibility')),
    },
    {
      value: facetTypes.feasibility,
      label: 'Feasibility',
      tasks: tasks
        .filter(({taskType}) =>
          [
            'appraisal_property_photos',
            'renovation_contract',
            'renovation_plan',
            'sms_consent_renovation_progress',
          ].includes(taskType),
        )
        .map(assoc('facet', 'feasibility')),
    },
    {
      value: facetTypes.insurance,
      label: 'Insurance',
      tasks: tasks.filter(propEq('facet', 'insurance')),
    },
    {
      value: facetTypes.appraisal,
      label: 'Appraisal',
      tasks: tasks.filter(({taskType}) =>
        [
          'appraisal_comparable_sales',
          'appraisal_property_details',
          'appraisal_property_photos',
          'appraisal_renovation_details',
          'appraisal_recent_transactions',
        ].includes(taskType),
      ),
    },
    {
      value: facetTypes.gcdd,
      label: 'CDD',
      tasks: tasks
        .filter(propEq('facet', 'contractor'))
        .map(assoc('facet', 'gcdd')),
    },
  ];

  if (!includeOrphans) {
    return grouped;
  }

  const taskTypes = grouped.reduce(
    (arr, {tasks}) => arr.concat(map(prop('taskType'), tasks)),
    [],
  );
  const orphans = tasks.filter(({taskType}) => !taskTypes.includes(taskType));
  return grouped.concat([
    {
      value: facetTypes.other,
      label: 'Other',
      tasks: orphans,
    },
  ]);
};

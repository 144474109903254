import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {sort} from 'ramda';

import fullName, {fullNamesFromArray} from '@renofi/utilities/src/fullName';
import {formatDate} from '@renofi/utilities/src/dates';
import {getFullResidenceAddress} from '@renofi/utilities/src/places';

import {
  ItemContainer,
  Header,
  ItemCard,
  ItemDetail,
  ItemTitle,
  ItemName,
  ItemRow,
} from '../../../../styled';
import {CITIZENSHIP_TYPE} from '../../../../../../project/components/TaskItem/constants';

import PersonalAndResidencyAddress from './PersonalAndResidencyAddress';

const sortByCurrent = (left) => (left.currentAddress ? -1 : 1);

function PersonalAndResidencyDetails({
  isMainBorrower,
  firstName,
  middleName,
  lastName,
  nameSuffix,
  maritalStatus,
  citizenshipType,
  dateOfBirth,
  addresses,
  dependentsNumber,
  dependentsAges,
  mailingAddress,
  alternativeNames,
  phoneNumber,
}) {
  const sortedAddresses = useMemo(
    () => sort(sortByCurrent, addresses),
    [addresses?.length],
  );

  return (
    <ItemContainer flexDirection="column">
      <Header>
        <ItemTitle>Residence history</ItemTitle>
      </Header>
      <ItemCard>
        <ItemRow>
          <ItemName>Full legal name</ItemName>
          <ItemDetail>
            {fullName({firstName, middleName, lastName, nameSuffix})}
          </ItemDetail>
        </ItemRow>

        {!isMainBorrower && phoneNumber ? (
          <ItemRow>
            <ItemName>Phone number</ItemName>
            <ItemDetail>{phoneNumber}</ItemDetail>
          </ItemRow>
        ) : null}

        {alternativeNames?.length ? (
          <ItemRow>
            <ItemName>Alternative Names</ItemName>
            <ItemDetail>{fullNamesFromArray(alternativeNames)}</ItemDetail>
          </ItemRow>
        ) : null}

        <ItemRow>
          <ItemName>Marital status</ItemName>
          <ItemDetail>{maritalStatus}</ItemDetail>
        </ItemRow>

        <ItemRow>
          <ItemName>Dependents</ItemName>
          <ItemDetail>{dependentsNumber}</ItemDetail>
        </ItemRow>

        <ItemRow>
          <ItemName>Dependents Ages</ItemName>
          <ItemDetail>{dependentsAges?.join(', ')}</ItemDetail>
        </ItemRow>

        <ItemRow>
          <ItemName>Date of birth</ItemName>
          <ItemDetail>{formatDate(dateOfBirth)}</ItemDetail>
        </ItemRow>

        <ItemRow>
          <ItemName>Citizenship</ItemName>
          <ItemDetail>
            {CITIZENSHIP_TYPE[citizenshipType || 'us_citizen']}
          </ItemDetail>
        </ItemRow>
      </ItemCard>

      {sortedAddresses.map((address) => (
        <ItemCard key={address.id} css={{paddingTop: 0}}>
          <PersonalAndResidencyAddress
            address={address}
            isMainBorrower={isMainBorrower}
          />
        </ItemCard>
      ))}

      <ItemCard css={{paddingTop: 0}}>
        <ItemRow>
          <ItemName>Mailing address</ItemName>
          <ItemDetail>
            {mailingAddress
              ? getFullResidenceAddress(mailingAddress)
              : 'Same as current address'}
          </ItemDetail>
        </ItemRow>
      </ItemCard>
    </ItemContainer>
  );
}

PersonalAndResidencyDetails.propTypes = {
  isMainBorrower: PropTypes.bool,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  nameSuffix: PropTypes.string,
  maritalStatus: PropTypes.string,
  citizenshipType: PropTypes.string,
  dateOfBirth: PropTypes.string,
  addresses: PropTypes.array,
  dependentsNumber: PropTypes.number,
  dependentsAges: PropTypes.array,
  alternativeNames: PropTypes.array,
  mailingAddress: PropTypes.object,
  phoneNumber: PropTypes.string,
};

export default PersonalAndResidencyDetails;

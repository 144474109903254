import React, {useEffect} from 'react';

import {filter, head, pathOr, pipe, propOr} from 'ramda';
import {useProjectDetails} from 'api';
import {FEASIBILITY_TASK_TYPES} from 'modules/viewer-v2';
import {ViewerFeasibility} from 'modules/feasibility';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {Loader} from '@renofi/components-internal';

import FeasibilityStatusBar from '../FeasibilityStatusBar';
import {DataTypeViewport, ItemView} from '../Layout';

import ItemsBar from './components/ItemsBar';
import FeasibilityReviewItemView from './components/ItemView';

const FeasibilityReviewType = () => {
  const {history, params, search} = useViewerNavigation();
  const {itemId, projectId} = params || {};
  const {taskId} = search || {};
  const {loading, project} = useProjectDetails({variables: {id: projectId}});

  useEffect(() => {
    const firstTask = pipe(
      propOr([], 'tasks'),
      filter(({taskType}) => FEASIBILITY_TASK_TYPES.includes(taskType)),
      head,
    )(project);
    const firstTaskId = firstTask?.id;
    const firstDocId = pathOr(null, ['documents', 0, 'id'], firstTask);

    if (!taskId && firstTaskId) {
      let location = `?taskId=${firstTaskId}`;
      if (firstDocId) {
        location += `&documentId=${firstDocId}`;
      }
      history.replace(location);
    }
  }, [taskId, project?.tasks?.length]);

  if (loading) {
    return <Loader />;
  }

  if (!itemId || !projectId) {
    return null;
  }

  return (
    <DataTypeViewport
      sidebar={
        <ViewerFeasibility feasibilityReviewId={itemId} projectId={projectId} />
      }
      statusBar={<FeasibilityStatusBar feasibilityReviewId={itemId} />}>
      <ItemsBar />
      <ItemView itemKey="documentId">
        <FeasibilityReviewItemView />
      </ItemView>
    </DataTypeViewport>
  );
};

export default FeasibilityReviewType;

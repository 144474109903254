import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {
  basic55,
  basic75,
  basic80,
  dangerLight,
  successLight,
  warningLight,
} from '@renofi/theme/src/colors';

function getStyles(rating) {
  switch (rating) {
    case 'pending':
      return {
        backgroundColor: 'transparent',
        color: warningLight,
        border: `solid 1px ${basic75}`,
      };
    case 'reasonable':
      return {
        backgroundColor: successLight,
        color: basic80,
        border: `solid 1px ${successLight}`,
      };
    case 'overstated':
    case 'understated':
      return {
        backgroundColor: dangerLight,
        color: basic80,
        border: `solid 1px ${dangerLight}`,
      };
    default:
      return {
        backgroundColor: basic55,
        color: basic80,
        border: `solid 1px ${basic55}`,
      };
  }
}

export default styled(Flex)(
  {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 4,
  },
  ({rating, small}) => ({
    ...getStyles(rating),
    height: small ? 14 : 30,
    fontSize: small ? 10 : 16,
    padding: small ? '0 4px' : '0 8px',
    lineHeight: small ? '14px' : '21px',
  }),
);

import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {find, pathOr, pipe, propEq} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import PdfDocumentView from 'modules/viewer-v2/components/PdfDocumentView';

import {useProject} from '@renofi/graphql';

const GcddDocument = ({gcddReview}) => {
  const {params, search} = useViewerNavigation();
  const {projectId} = params;
  const {documentId, tab} = search || {};

  const {loading} = useProject({variables: {id: projectId}});

  const doc = useMemo(() => {
    return pipe(
      pathOr(null, ['contractor', tab]),
      find(propEq('id', documentId)),
    )(gcddReview);
  }, [documentId, tab, gcddReview]);

  if (!doc || loading) {
    return null;
  }

  return <PdfDocumentView doc={doc} />;
};

GcddDocument.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default GcddDocument;

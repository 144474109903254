import styled from '@emotion/styled';

import {
  actionExtraLight,
  warningExtraLight,
  actionDark,
  warningDark,
} from '@renofi/theme/src/colors';

export const MentionsWrapper = styled.div(({truncate, preWrap}) => ({
  ...(truncate
    ? {
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      }
    : {}),
  ...(preWrap
    ? {
        whiteSpace: 'pre-wrap',
      }
    : {}),
}));

export const UserBadge = styled.span(({isUser}) => {
  return {
    background: isUser ? warningExtraLight : actionExtraLight,
    color: isUser ? warningDark : actionDark,
    padding: 2,
    borderRadius: 3,
    whiteSpace: 'nowrap',
  };
});

export const TASK_TYPES = {
  PRODUCT_DETAILS_CONFIRMATION: 'product_details_confirmation',
  ADVISOR_FINAL_CALL_SCHEDULE: 'advisor_final_call_schedule',
  CERTIFICATE_ACKNOWLEDGEMENT: 'certificate_acknowledgement',
  APPRAISAL_COMPARABLE_SALES: 'appraisal_comparable_sales',
  APPRAISAL_RECENT_TRANSACTIONS: 'appraisal_recent_transactions',
  SMS_CONSENT_RENOVATION_PROGRESS: 'sms_consent_renovation_progress',
};

export const CITIZENSHIP_TYPE = {
  us_citizen: 'U.S. citizen',
  non_permanent_resident_alien: 'Non-Permanent resident alien',
  permanent_resident_alien: 'Permanent resident alien',
};

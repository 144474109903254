import {prop, propOr} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {TAB_TYPES} from '../constants';

import isTaskReviewed from './isTaskReviewed';

export default function getTabStatus({currentFacet, item, project}) {
  const itemType = prop('type', item);
  const typeName = propOr(null, '__typename', item);

  switch (true) {
    case itemType === TAB_TYPES.gcddReview:
      return prop('status', item);
    case itemType === TAB_TYPES.task:
    case typeName === 'Task':
      return isTaskReviewed(item, project)
        ? TASK_STATUSES.COMPLETED
        : prop('status', item);
    case currentFacet === 'feasibility':
    default:
      return null;
  }
}

import React, {useContext, useEffect} from 'react';

import {Route, Switch} from 'react-router-dom';
import {either, isEmpty, isNil, pathOr} from 'ramda';
import asyncComponent from 'lib/asyncComponent';

import {Loader} from '@renofi/components-internal';
import {useGetUser} from '@renofi/graphql';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {getLocation} from '@renofi/utilities/src/window';

const Review = asyncComponent(
  () => import(/* webpackChunkName: "review" */ '../Review'),
);

const NotFound = asyncComponent(
  () => import(/* webpackChunkName: "not-found" */ 'modules/not-found'),
);

const isNullish = either(isNil, isEmpty);

const Root = () => {
  const storage = useContext(StorageContext);
  const location = getLocation();
  const {getUser, loading} = useGetUser({lazy: true});

  useEffect(() => {
    (async () => {
      const rsp = await getUser();
      const user = pathOr(null, ['data', 'userById'], rsp);

      if (isNullish(user)) {
        storage.clear();
        if (location.pathname) {
          storage.setItem('path:redirect', location.pathname);
        }
        logoutWithRedirect();
      }
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path="/not-found" component={NotFound} />
      <Route exact path="/" component={Review} />
      <Route exact path="/:taskId/:documentId?" component={Review} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Root;

import {isEmpty, isNil} from 'ramda';
import {FEASIBILITY_STUDY_ITEM_RATINGS} from 'lib/feasibilityReview/constants';

const OVERSTATED_AND_UNDERSTATED = [
  FEASIBILITY_STUDY_ITEM_RATINGS.OVERSTATED,
  FEASIBILITY_STUDY_ITEM_RATINGS.UNDERSTATED,
];

export const validateCostEstimate = ({
  costEstimateSuppliedApproved,
  costEstimateSuppliedNotes,
}) => {
  if (isNil(costEstimateSuppliedApproved)) return false;
  if (costEstimateSuppliedApproved === false && !costEstimateSuppliedNotes) {
    return false;
  }
  return true;
};

export const validateStudyItems = ({items, isReviewer, isInScope}) => {
  if (!isReviewer && isEmpty(items)) {
    return false;
  }

  return items.every(
    ({cost, adjustedCost, reviewerRating, descriptionOfWork, editable}) => {
      const isValidCost = Number.isInteger(cost) && cost > 0;
      const isValidAdjustedCost = Number.isInteger(adjustedCost);

      const isOverstatedOrUnderstated =
        OVERSTATED_AND_UNDERSTATED.includes(reviewerRating);

      if (!editable && isReviewer && isInScope) {
        if (isNil(reviewerRating)) {
          return false;
        }

        if (isOverstatedOrUnderstated) {
          return isValidAdjustedCost;
        }
      }

      if (!editable) return true;

      if (isReviewer && isInScope) {
        if (isNil(reviewerRating)) {
          return false;
        }

        if (isOverstatedOrUnderstated) {
          return (
            Boolean(descriptionOfWork) && isValidCost && isValidAdjustedCost
          );
        }
      }

      return Boolean(descriptionOfWork) && isValidCost;
    },
  );
};

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {SUBMIT_APPRAISAL_ORDER} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitAppraisalOrder({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitAppraisalOrder, {data, loading, error}] = useMutation(
    SUBMIT_APPRAISAL_ORDER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(
    null,
    ['submitAppraisalOrder', 'appraisalOrder'],
    data,
  );

  return {submitAppraisalOrder, response, loading, error};
}

import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {basic55, dangerLight} from '@renofi/theme/src/colors';

const Discard = ({active, disabled, loading, ...props}) => {
  const [hover, setHover] = useState(false);
  const color = hover ? dangerLight : basic55;
  return (
    <span
      css={{
        cursor: disabled ? 'default' : 'pointer',
        opacity: loading || disabled ? 0.5 : 1,
      }}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}>
      {active ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM15.9929 7L17 8.00714L13.0071 12L17 15.9929L15.9929 17L12 13.0071L8.00714 17L7 15.9929L10.9929 12L7 8.00714L8.00714 7L12 10.9929L15.9929 7Z"
            fill="#FFA8A8"
          />
        </svg>
      ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 8.00714L15.9929 7L12 10.9929L8.00714 7L7 8.00714L10.9929 12L7 15.9929L8.00714 17L12 13.0071L15.9929 17L17 15.9929L13.0071 12L17 8.00714Z"
            fill={color}
          />
        </svg>
      )}
    </span>
  );
};

Discard.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Discard;

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';

export const Wrapper = styled(Flex)(
  {
    flexDirection: 'column',
    backgroundColor: basic80,
    marginBottom: 4,
    padding: 16,
  },
  ({first, last}) => ({
    ...(first ? {borderRadius: '4px 4px 0 0'} : {}),
    ...(last ? {borderRadius: '0 0 4px 4px'} : {}),
  }),
);

import React from 'react';

import PropTypes from 'prop-types';

import ValidationListItem from './ValidationListItem';

const ValidationList = ({validation}) => {
  const {costReview, contractReview, feasibilityStudy, documentsReview} =
    validation;

  return (
    <>
      <ValidationListItem title="Cost estimate" validation={costReview} />
      <ValidationListItem title="Contract review" validation={contractReview} />
      <ValidationListItem
        title="Feasibility study"
        validation={feasibilityStudy}
      />
      <ValidationListItem
        title="Documents requiring approval"
        validation={documentsReview}
      />
    </>
  );
};

ValidationList.propTypes = {
  action: PropTypes.string,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  loading: PropTypes.bool,
  validation: PropTypes.object.isRequired,
};

export default ValidationList;

import {compose, withHandlers} from '@renofi/recompose/src';
import {withApollo, withQuery} from '@renofi/graphql/src/hoc';

import {GET_FEASIBILITY_REVIEW_STATE} from '../queries';

export default () =>
  compose(
    withApollo,
    withQuery({
      query: GET_FEASIBILITY_REVIEW_STATE,
      props: ({data, ...props}) => ({
        feasibilityReviewState: data?.feasibilityReviewState,
        ...props,
      }),
    }),
    withHandlers({
      readFeasibilityReviewState:
        ({client}) =>
        () => {
          const {feasibilityReviewState} = client.readQuery({
            query: GET_FEASIBILITY_REVIEW_STATE,
          });
          return feasibilityReviewState;
        },
    }),
    withHandlers({
      updateFeasibilityReviewState:
        ({client, readFeasibilityReviewState}) =>
        (partial) => {
          const feasibilityReviewState = readFeasibilityReviewState();
          client.writeQuery({
            query: GET_FEASIBILITY_REVIEW_STATE,
            data: {
              feasibilityReviewState: {
                ...feasibilityReviewState,
                ...partial,
              },
            },
          });
        },
    }),
  );

import {
  EMPLOYMENT_HISTORY_TASK_TYPE,
  PERSONAL_INFO_TASK_TYPE,
} from 'modules/viewer-v2';

export default (task = {}) => {
  const isEmploymentTask = task?.taskType === EMPLOYMENT_HISTORY_TASK_TYPE;
  const isPersonalInfoTask = task?.taskType === PERSONAL_INFO_TASK_TYPE;

  switch (true) {
    case isPersonalInfoTask:
      return 'borrowerRole';
    case isEmploymentTask:
    case task?.canHaveDocument:
      return 'documentId';
    default:
      return null;
  }
};

import {compose, withHandlers} from '@renofi/recompose/src';

import {REMOVE_FEASIBILITY_STUDY_ITEM} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(REMOVE_FEASIBILITY_STUDY_ITEM, {
      name: 'removeFeasibilityStudyItem',
    }),
    withHandlers({
      removeFeasibilityStudyItem:
        ({removeFeasibilityStudyItem}) =>
        async (id) => {
          const result = await removeFeasibilityStudyItem({variables: {id}});
          return result?.data?.removeFeasibilityStudyItem?.success;
        },
    }),
  );

import {
  HOURS_PER_DAY,
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_HOUR,
} from './constants';

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const diffDaysAndHours = (date1, date2) => {
  const hours = Math.floor((date2 - date1) / MILLISECONDS_PER_HOUR);

  if (hours <= 0) {
    return '—';
  }

  if (hours < HOURS_PER_DAY) {
    return `${maybePluralize(hours, 'hour')}`;
  }

  const days = Math.floor(hours / HOURS_PER_DAY);
  const remainingHours = hours % HOURS_PER_DAY;
  const hourPart =
    remainingHours > 0 ? `${maybePluralize(remainingHours, 'hour')}` : '';

  return `${maybePluralize(days, 'day')} ${hourPart}`;
};

export const formatStageLastChangedAt = (date1, date2 = new Date()) => {
  const diffTime = Math.abs(date2.valueOf() - new Date(date1).valueOf());
  const days = diffTime / MILLISECONDS_PER_DAY;
  const hours = (days % 1) * HOURS_PER_DAY;
  if (days < 1) {
    return `${Math.round(hours)} hrs`;
  }
  return `${Math.round(days)} days, ${Math.round(hours)}hrs`;
};

import ViewerContext from 'modules/viewer-v2/context';

import {compose, withContext, withHandlers} from '@renofi/recompose/src';
import {
  withAppraisalOrders,
  withAppraisalOrderRevisionRequest,
  withSubmitOrderComments,
} from '@renofi/graphql/src/hoc';

import Component from './Component';

export default compose(
  withContext(ViewerContext),
  withAppraisalOrders(),
  withAppraisalOrderRevisionRequest(),
  withSubmitOrderComments(),
  withHandlers({
    onConfirmRevision:
      ({
        getAppraisalOrders,
        projectId,
        submitOrderComments,
        submitRevisionRequest,
      }) =>
      async ({appraisalOrder, canRequestRevision, comments, onClose}) => {
        const {id: appraisalOrderId} = appraisalOrder;
        const mutation = canRequestRevision
          ? submitRevisionRequest
          : submitOrderComments;

        await mutation({appraisalOrderId, comments});
        await getAppraisalOrders({projectId});
        onClose();
      },
  }),
)(Component);

import {filter, prop, propSatisfies, sortBy} from 'ramda';

export const VALID_CONTRACTOR_DOC_TYPES = [
  'contractor_general_liability_insurance',
  'contractor_license',
  'detailed_cost_estimate',
  'other',
  'renovation_contract',
  'renovation_plan',
  'unclassified',
];

const isValidType = (group) => VALID_CONTRACTOR_DOC_TYPES.includes(group);

export default ({documentTypes = [], source}) => {
  const sorted = sortBy(prop('label'))(documentTypes);
  if (source !== 'contractor') {
    return sorted;
  }

  return filter(propSatisfies(isValidType, 'value'), sorted);
};

import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../../useToggledQuery';
import {GET_TASK_TEMPLATES} from '../../../queries';

export default function useTaskTemplates({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, error, loading, fetch} = useToggledQuery({
    query: GET_TASK_TEMPLATES,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });
  const taskTemplates = propOr([], 'taskTemplates', data);
  return {taskTemplates, loading, error, fetch};
}

const VARIANTS_BY_STATUS = {
  cancelled_by_amc: 'danger',
  cancelled_by_renofi: 'danger',
  completed: 'successInverse',
  correction_requested: 'warning',
  created: 'info',
  inspection_completed: 'info',
  inspection_scheduled: 'success',
  on_hold: 'warning',
  processing: 'warning',
  rejected: 'danger',
  submission_failed: 'danger',
  submitted: 'info',
};

export default ({status}) => VARIANTS_BY_STATUS[status] || 'success';

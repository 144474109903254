import React, {useContext, useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, isNil, pathOr} from 'ramda';
import {GCDD_TABS} from 'lib/gcdd';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import {ItemTabsBar} from 'modules/viewer-v2/components/Layout';

import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../../context';

import {
  QUESTIONNAIRE_TAB,
  getQuestionnaireTabs,
  getReviewDocuments,
  getReferencesTabs,
} from './utils';

const GcddReviewItemsBar = ({gcddReview}) => {
  const {getItemIdByDirection} = useContext(ViewerV2Context);
  const {history, search} = useViewerNavigation();
  const {documentId, tab} = search || {};
  const itemKey = tab === GCDD_TABS.REFERENCES ? 'referenceId' : 'documentId';

  const {currentValue, tabs} = useMemo(() => {
    switch (tab) {
      case GCDD_TABS.QUESTIONNAIRE:
        return getQuestionnaireTabs({gcddReview, search});
      case GCDD_TABS.LICENSES:
        return getReviewDocuments({gcddReview, propKey: 'licenses', search});
      case GCDD_TABS.INSURANCES:
        return getReviewDocuments({gcddReview, propKey: 'insurances', search});
      case GCDD_TABS.REFERENCES:
        return getReferencesTabs({gcddReview, search});
      default:
        return {};
    }
  }, [gcddReview, search]);

  useEffect(() => {
    const isDocumentTab = tab === 'licenses' || tab === 'insurances';

    if (isDocumentTab && !documentId) {
      const documents = pathOr(null, ['contractor', tab], gcddReview);
      const to = queryString.stringify({
        ...search,
        documentId: getItemIdByDirection(documents),
      });
      history.replace(`?${to}`);
    }
  }, [tab, tabs]);

  const onChangeTab = (id) => {
    let to;

    switch (tab) {
      case GCDD_TABS.LICENSES:
      case GCDD_TABS.INSURANCES:
      case GCDD_TABS.QUESTIONNAIRE:
        to = queryString.stringify({
          tab,
          documentId: id !== QUESTIONNAIRE_TAB ? id : undefined,
        });
        return history.push(`?${to}`);
      case GCDD_TABS.REFERENCES:
        to = queryString.stringify({
          tab,
          referenceId: id,
        });
        return history.push(`?${to}`);
      default:
        return;
    }
  };

  if (isEmpty(tabs) || isNil(tabs)) {
    return null;
  }

  return (
    <ItemTabsBar
      itemKey={itemKey}
      onChange={onChangeTab}
      tabs={tabs}
      value={currentValue}
    />
  );
};

GcddReviewItemsBar.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default GcddReviewItemsBar;

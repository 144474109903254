import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr} from 'ramda';

import {ModalWithButtons, Modal} from '@renofi/components-internal';
import isFunction from '@renofi/utilities/src/isFunction';

import Context from '../../context';

const noop = () => {};

function Modals({children}) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [rollup, setRollup] = useState({});
  const showModal = !isEmpty(modalProps);

  useEffect(() => {
    const {submitDisabled = noop} = modalProps;
    setDisabled(Boolean(submitDisabled(rollup)));
  }, [modalProps, rollup]);

  const onClose = useCallback(() => {
    setModalProps({});
    setLoading(false);
    setDisabled(false);
    setRollup({});
  });

  const onAccept = useCallback(() => {
    if (modalProps.onAccept) {
      modalProps.onAccept({...rollup, onClose, setLoading});
    }
  }, [modalProps, rollup]);

  const onReject = useCallback(() => {
    if (modalProps.onReject) {
      modalProps.onReject(rollup);
    }
  }, [modalProps, rollup]);

  const acceptLabel = useMemo(() => {
    const label = propOr('', 'acceptLabel', modalProps);
    return isFunction(label) ? label(rollup) : label;
  }, [modalProps, rollup]);

  const providerValue = {
    loading,
    onClose,
    setLoading,
    setModalProps,
  };

  const {
    component: Component,
    componentProps,
    hasButtons,
    ...restOfProps
  } = modalProps;
  const ModalComponent = hasButtons ? ModalWithButtons : Modal;

  return (
    <Context.Provider value={providerValue}>
      {children}
      {showModal && (
        <ModalComponent
          id="modalsContainer"
          loading={loading}
          lock
          fixed
          width={600}
          show={showModal}
          onClose={() => setModalProps({})}
          disabled={disabled}
          {...restOfProps}
          acceptLabel={acceptLabel}
          {...(hasButtons
            ? {
                onAccept: onAccept,
                onReject: onReject,
              }
            : {})}>
          {Component && <Component {...componentProps} onChange={setRollup} />}
        </ModalComponent>
      )}
    </Context.Provider>
  );
}

Modals.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Modals);

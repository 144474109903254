import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {RequiredText} from 'modules/viewer-v2';

import {SelectField, TextField} from '@renofi/components-internal';
import {useInsuranceCarriers} from '@renofi/graphql';

const OTHER_CARRIER = 'RENOFI_OTHER_CARRIER';

function InsuranceCarriers({carrier, isFormDisabled, onChange}) {
  const [selectedCarrier, setSelectedCarrier] = useState(carrier || null);
  const [showOther, setShowOther] = useState(false);
  const [otherCarrier, setOtherCarrier] = useState('');
  const {insuranceCarriers, loading} = useInsuranceCarriers();

  useEffect(() => {
    if (selectedCarrier !== OTHER_CARRIER) {
      setSelectedCarrier(carrier);
    }
  }, [carrier]);

  const onChangeCarrier = (value) => {
    setSelectedCarrier(value);
    setShowOther(value === OTHER_CARRIER);

    if (value && value !== OTHER_CARRIER) {
      setOtherCarrier('');
      onChange(value);
    }
  };

  const onChangeOther = (value) => {
    setOtherCarrier(value);
    if (value) {
      onChange(value);
    }
  };

  return (
    <Fragment>
      <SelectField
        disabled={loading || isFormDisabled}
        label="Carrier"
        mb={0}
        value={selectedCarrier}
        placeholder="Select carrier"
        options={insuranceCarriers
          .map((v) => ({
            label: v,
            value: v,
          }))
          .concat({
            label: 'Other',
            value: OTHER_CARRIER,
          })}
        onChange={onChangeCarrier}
      />
      {showOther && (
        <TextField
          disabled={isFormDisabled}
          help={<RequiredText show={!otherCarrier}>required</RequiredText>}
          label="If other, please specify"
          mt={3}
          onChange={onChangeOther}
          value={otherCarrier}
        />
      )}
    </Fragment>
  );
}

InsuranceCarriers.propTypes = {
  carrier: PropTypes.string,
  isFormDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default InsuranceCarriers;

import {
  MESSAGE_RETURN_DOCUMENT_REASON,
  MESSAGE_DELETE_DOCUMENT,
  MESSAGE_REQUEST_MORE_INFO,
  MESSAGE_REFERENCE_UNRESPONSIVE,
  MESSAGE_OTHER,
} from 'lib/gcdd';

const isContractorMessageDisabled = ({
  message,
  otherReason,
  reason,
  returnReason,
  selectedDocuments = [],
  selectedReferences = [],
}) => {
  const hasNoMessage = !message;

  switch (reason) {
    case MESSAGE_RETURN_DOCUMENT_REASON:
      return !selectedDocuments.length || !returnReason || hasNoMessage;
    case MESSAGE_REQUEST_MORE_INFO:
      return hasNoMessage;
    case MESSAGE_DELETE_DOCUMENT:
      return false;
    case MESSAGE_REFERENCE_UNRESPONSIVE:
      return !selectedReferences.length || hasNoMessage;
    case MESSAGE_OTHER:
      return !otherReason || hasNoMessage;
    default:
      return true;
  }
};

export default isContractorMessageDisabled;

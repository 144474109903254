import styled from '@emotion/styled';
import {isNil} from 'ramda';

import {Badge, Flex, TextField} from '@renofi/components-internal';
import {
  actionExtraLight,
  actionRegular,
  basic80,
  dangerRegular,
  white,
} from '@renofi/theme/src/colors';

export const Popover = styled(Flex)(({inline}) => ({
  width: inline ? 'auto' : 450,
  borderRadius: 4,
  backgroundColor: white,
  boxShadow: '0 5px 10px 0 rgba(22,53,81,0.2)',
  flexDirection: 'column',
  color: basic80,
  maxHeight: '90vh',
  overflowY: 'scroll',
}));

export const Project = styled(Flex)(
  {
    width: '100%',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: actionExtraLight,
    },
  },
  ({active}) => ({
    backgroundColor: active ? actionExtraLight : white,
  }),
);

export const SearchField = styled(TextField)({
  width: 350,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  marginBottom: 0,
});

export const StatusBadge = styled(Badge)(
  {
    fontSize: 11,
    lineHeight: '15px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  ({closed}) => ({
    ...(!isNil(closed)
      ? {
          borderColor: closed ? dangerRegular : actionRegular,
          color: closed ? dangerRegular : actionRegular,
        }
      : {}),
  }),
);

import styled from '@emotion/styled';

import {Button} from '@renofi/components-internal';

export const Trigger = styled(Button)({
  width: 50,
  height: 50,
  padding: 0,
  borderRadius: 50,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',

  position: 'fixed',
  bottom: 8,
  right: 8,
  zIndex: 3,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

import {isNil} from 'ramda';

import formatMoney from '@renofi/utilities/src/formatMoney';

import {
  YEARS_AS_CONTRACTOR_MAPPER,
  NUMBER_OF_COMPLETED_RENOVATIONS_MAPPER,
  LAST_TWO_YEARS_PERCENTAGE_MAPPER,
} from './constants';

export function stringifyWithNullCheck(
  attribute,
  presentString = 'Yes',
  absentString = 'No',
) {
  if (attribute === null) {
    return '';
  }
  return attribute ? presentString : absentString;
}

export const roundOffEstCost = (estimatedCostOfWork, percentage) => {
  if (isNil(estimatedCostOfWork) || isNil(percentage)) {
    return 'N/A';
  }

  const cost = Number(estimatedCostOfWork) * percentage;
  return formatMoney(Math.round(cost / 1000) * 1000, {fractional: false});
};

export const mapYearsAsContractor = (value) => {
  return YEARS_AS_CONTRACTOR_MAPPER[value];
};

export const mapNumberOfCompletedRenovations = (value) => {
  return NUMBER_OF_COMPLETED_RENOVATIONS_MAPPER[value] || '';
};

export const mapPercentageInLastTwoYears = (value) => {
  return LAST_TWO_YEARS_PERCENTAGE_MAPPER[value] || value;
};

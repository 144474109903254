import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_PROJECT_ACTIONS} from '../../queries';

export default function useProjectActions({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECT_ACTIONS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const projectActions = data?.projectActions || [];

  return {fetch, projectActions, data, loading, error, refetching};
}

import {compose, withHandlers} from '@renofi/recompose/src';

import {ADD_FEASIBILITY_STUDY_ITEM} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(ADD_FEASIBILITY_STUDY_ITEM, {
      name: 'addFeasibilityStudyItem',
    }),
    withHandlers({
      addFeasibilityStudyItem:
        ({addFeasibilityStudyItem}) =>
        async (variables) => {
          const result = await addFeasibilityStudyItem({variables});
          return result?.data?.addFeasibilityStudyItem?.feasibilityStudyItem;
        },
    }),
  );

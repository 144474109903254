/* eslint-disable max-lines */
import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {
  all,
  filter,
  find,
  flatten,
  isEmpty,
  map,
  pick,
  pipe,
  propEq,
  propOr,
} from 'ramda';
import {facetTypes} from 'lib/groupTasksIntoFacets';
import {
  REQUIRED_INSURANCE_FIELDS,
  hasAnyNilFields,
  Col,
  Container,
  RequiredText,
  RowItem,
  Row,
  StatusBadge,
} from 'modules/viewer-v2';

import {
  Box,
  Checkbox,
  DateField,
  Flex,
  Loader,
  Text,
  TextField,
} from '@renofi/components-internal';
import InfoIcon from '@renofi/icons/src/Info';
import {actionLight, basic75, basic88, dangerLight} from '@renofi/theme';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {
  useInsuranceReview,
  useMarkInsuranceReviewAsCompleted,
  useScheduleInsuranceCoverageProofRequest,
  useSubmitInsuranceReview,
} from '@renofi/graphql';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {getUtcDate} from '@renofi/utilities/src/dates';

import CoverageProofRequestStatus from './components/CoverageProofRequestStatus';
import InsuranceCarriers from './components/InsuranceCarriers';
import MarkAsCompletedButton from './components/MarkAsCompletedButton';
import headerIcon from './container-header-24x24.png';
import {
  AppraisalValueRow,
  LabelNote,
  StickyFooter,
  WrittenProofSwitch,
} from './styled';

const pickKeysForMutation = (ir) =>
  pick(
    [
      ...REQUIRED_INSURANCE_FIELDS,
      'coverageDuringRenovationPeriod',
      'expansiveRenovation',
      'largeAmountRenovation',
      'renovationIncludesRoofWorks',
      'renovationIncludesStructuralChanges',
    ],
    ir,
  );

function InsuranceReviewContent({project}) {
  const {
    appraiserAfterRenovationValue,
    appraiserAsIsValue,
    id: projectId,
  } = project;
  // GQL fun
  const response = useInsuranceReview({projectId});
  const {scheduleInsuranceCoverageProofRequest} =
    useScheduleInsuranceCoverageProofRequest();
  const {submitInsuranceReview, loading: isSubmitting} =
    useSubmitInsuranceReview();
  const {markInsuranceReviewAsCompleted, loading: isCompleting} =
    useMarkInsuranceReviewAsCompleted();

  const debouncedSubmitInsuranceReview = useDebounce(
    submitInsuranceReview,
    500,
  );

  const [insuranceReview, setInsuranceReview] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [allDocsAccepted, setAllDocsAccepted] = useState(false);

  const [isCoverageProofReceived, setIsCoverageProofReceived] = useState(false);

  useEffect(() => {
    setInsuranceReview(response.insuranceReview);
    setSubmitDisabled(hasAnyNilFields(response.insuranceReview));
  }, [response.insuranceReview, response.loading]);

  // Extract the insurance task.documents; check all are accepted.
  useEffect(() => {
    const tasks = filter(propEq('facet', facetTypes.insurance), project.tasks);
    setAllDocsAccepted(
      pipe(
        pipe(map(propOr([], 'documents')), flatten),
        all(propEq('status', 'accepted')),
      )(tasks),
    );

    setIsCoverageProofReceived(
      !pipe(
        find(propEq('taskType', 'insurance_coverage_proof')),
        propOr([], 'documents'),
        isEmpty,
      )(tasks),
    );
  }, [project.tasks]);

  const onChangeProp = (key, value, submit = false) => {
    const updated = {
      ...insuranceReview,
      [key]: value,
    };

    setInsuranceReview(updated);
    setSubmitDisabled(hasAnyNilFields(updated));

    const mutation = submit
      ? submitInsuranceReview
      : debouncedSubmitInsuranceReview;
    mutation({
      variables: {
        projectId,
        insuranceReview: pickKeysForMutation(updated),
      },
    });
  };

  const onClickCompleted = () => {
    markInsuranceReviewAsCompleted({variables: {id: insuranceReview.id}});
  };

  const onClickSchedule = () => {
    const schedule = !coverageProofRequestScheduledAt;
    const {id} = insuranceReview;

    scheduleInsuranceCoverageProofRequest({variables: {id, schedule}});
  };

  if (response?.loading) {
    return <Loader />;
  }

  const {
    carrier,
    completedAt,
    coverageDuringRenovationPeriod,
    coverageProofReceivedAt,
    coverageProofRequestScheduledAt,
    coverageProofRequestedAt,
    dwellingCoverage,
    largeAmountRenovation,
    policyDateVisible,
    policyEffectiveDate,
    renovationIncludesRoofWorks,
    renovationIncludesStructuralChanges,
    id,
  } = insuranceReview || {};
  const isCompleted = Boolean(completedAt);
  const isCoverageTooLow = dwellingCoverage < appraiserAsIsValue;
  const isAnyCheckboxChecked =
    largeAmountRenovation ||
    renovationIncludesStructuralChanges ||
    renovationIncludesRoofWorks;
  const isFormDisabled = isCompleted || isSubmitting || isCompleting;
  const isCheckboxDisabled =
    isFormDisabled || !!coverageProofRequestScheduledAt;
  const isDwellingDisabled = isCompleted || isCompleting;

  return (
    <Fragment>
      <Container
        header={
          <Row alignItems="center">
            <Box height={24}>
              <img src={headerIcon} />
            </Box>
            <Text ml={12}>Insurance review</Text>
            <StatusBadge ml="8px" variant={isCompleted ? 'success' : 'warning'}>
              {isCompleted ? 'Reviewed' : 'Pending review'}
            </StatusBadge>
          </Row>
        }>
        <Row>
          <Col>
            <InsuranceCarriers
              carrier={carrier}
              disabled={isFormDisabled}
              onChange={(v) => onChangeProp('carrier', v)}
            />
          </Col>

          <Col>
            <TextField
              money
              disabled={isDwellingDisabled}
              info={
                isCoverageTooLow ? (
                  <Text color={dangerLight}>
                    Exceeds insurance dwelling coverage. Home owner notified.
                  </Text>
                ) : null
              }
              help={
                <RequiredText show={!dwellingCoverage}>required</RequiredText>
              }
              label="Dwelling coverage"
              leftIcon="dollar"
              onChange={(v) => onChangeProp('dwellingCoverage', Number(v))}
              value={dwellingCoverage}
              key={id}
            />
          </Col>
        </Row>
        <AppraisalValueRow py={3}>
          <Col>
            <LabelNote>Coverage required post renovation</LabelNote>
            {appraiserAfterRenovationValue
              ? formatMoney(appraiserAfterRenovationValue)
              : 'Pending appraisal'}
          </Col>
        </AppraisalValueRow>
        <RowItem
          disabled={isFormDisabled}
          value={policyDateVisible}
          onAccept={() => onChangeProp('policyDateVisible', true, true)}
          onReject={() => onChangeProp('policyDateVisible', false, true)}>
          <span>Policy date visible</span>
        </RowItem>
        <Row css={{backgroundColor: basic88}} mb={-3} pt={3}>
          <Col>
            <DateField
              disabled={isFormDisabled}
              help={
                <RequiredText show={!policyEffectiveDate}>
                  required
                </RequiredText>
              }
              label="Policy effective date"
              value={getUtcDate(policyEffectiveDate)}
              onChange={(v) =>
                onChangeProp('policyEffectiveDate', v.toISOString(), true)
              }
            />
          </Col>
        </Row>
      </Container>

      <RowItem
        css={{borderTop: `1px solid ${basic75}`}}
        disabled={isFormDisabled}
        showButtons={isCoverageProofReceived}
        value={coverageDuringRenovationPeriod}
        onAccept={() =>
          onChangeProp('coverageDuringRenovationPeriod', true, true)
        }
        onReject={() =>
          onChangeProp('coverageDuringRenovationPeriod', false, true)
        }>
        <span>Coverage during the renovation period</span>
      </RowItem>

      <Container css={{backgroundColor: basic88}}>
        <Row mb={2}>
          <Col width={1}>
            Required if any of the following criteria are met:
          </Col>
        </Row>
        <Row my={2}>
          <Col width={1}>
            <Checkbox
              checked={largeAmountRenovation}
              disabled={isCheckboxDisabled}
              label={<Text>Renovation is over 250K</Text>}
              onChange={(v) => onChangeProp('largeAmountRenovation', v, true)}
            />
          </Col>
        </Row>
        <Row my={2}>
          <Col width={1}>
            <Checkbox
              checked={renovationIncludesStructuralChanges}
              disabled={isCheckboxDisabled}
              label={<Text>Renovation includes structural changes</Text>}
              onChange={(v) =>
                onChangeProp('renovationIncludesStructuralChanges', v, true)
              }
            />
          </Col>
        </Row>
        <Row my={2}>
          <Col width={1}>
            <Checkbox
              checked={renovationIncludesRoofWorks}
              disabled={isCheckboxDisabled}
              label={<Text>Renovation includes work to the roof</Text>}
              onChange={(v) => onChangeProp('renovationIncludesRoofWorks', v)}
            />
          </Col>
        </Row>

        <Row mt={3} px={3} css={{color: actionLight}}>
          <InfoIcon css={{transform: 'rotate(180deg)'}} color={actionLight} />

          <Text ml={2}>
            Request written proof on carrier letterhead (email will do) that
            confirms the property is covered during the renovation period.
          </Text>
        </Row>

        <Row mt={3} pt={3} css={{borderTop: `1px solid ${basic75}`}}>
          {!coverageProofReceivedAt && !coverageProofRequestedAt && (
            <Flex px={3} alignItems="center">
              <Text mr={3} fontWeight="bold">
                Request written proof
              </Text>
              <WrittenProofSwitch
                checked={!!coverageProofRequestScheduledAt}
                disabled={!isAnyCheckboxChecked || isFormDisabled}
                onClick={onClickSchedule}
              />
            </Flex>
          )}
          <CoverageProofRequestStatus
            coverageProofReceivedAt={coverageProofReceivedAt}
            coverageProofRequestScheduledAt={coverageProofRequestScheduledAt}
            coverageProofRequestedAt={coverageProofRequestedAt}
          />
        </Row>
      </Container>

      {!isCompleted && (
        <StickyFooter p={3}>
          <Col>Review all documents/info before marking as Completed</Col>
          <Col css={{display: 'flex', justifyContent: 'flex-end'}}>
            <MarkAsCompletedButton
              disabled={isFormDisabled || submitDisabled || !allDocsAccepted}
              insuranceReview={insuranceReview}
              isCoverageProofReceived={isCoverageProofReceived}
              onClick={onClickCompleted}
            />
          </Col>
        </StickyFooter>
      )}
    </Fragment>
  );
}

InsuranceReviewContent.propTypes = {
  project: PropTypes.shape({
    appraiserAfterRenovationValue: PropTypes.number,
    appraiserAsIsValue: PropTypes.number,
    id: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            status: PropTypes.string,
          }),
        ),
        facet: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default InsuranceReviewContent;

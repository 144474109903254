import React from 'react';

import PropTypes from 'prop-types';
import {Approve, Discard} from 'modules/viewer-v2/icons';

import {Box, Flex} from '@renofi/components-internal';

import {Content, Wrapper} from './styled';

const noop = () => {};

const RowItem = ({
  children,
  disabled,
  value,
  onAccept = noop,
  onReject = noop,
  showButtons = true,
  ...props
}) => {
  const accepted = value === true;
  const rejected = value === false;
  return (
    <Wrapper {...props}>
      <Content>{children}</Content>
      {showButtons && (
        <Flex alignItems="center">
          <Box height={24} ml={16}>
            <Discard
              active={rejected}
              disabled={disabled}
              onClick={(e) => !disabled && onReject(e)}
            />
          </Box>
          <Box height={24} ml={16}>
            <Approve
              active={accepted}
              disabled={disabled}
              onClick={(e) => !disabled && onAccept(e)}
            />
          </Box>
        </Flex>
      )}
    </Wrapper>
  );
};

RowItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  showButtons: PropTypes.bool,
  value: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

export default RowItem;

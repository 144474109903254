import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_CONTRACTOR_INSURANCES} from '../../queries';

export default function useContractorInsurances({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_CONTRACTOR_INSURANCES,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const contractorInsurances = propOr(null, 'contractorInsurances', data);

  return {error, fetch, contractorInsurances, loading};
}

import {gql} from '@apollo/client';

export default gql`
  query projectExceptionRequests($id: ID!) {
    projectById(id: $id) {
      id
      exceptionRequests {
        id
        approvedAt
        approvedById
        approvedBy {
          id
          email
          firstName
          lastName
        }
        comments {
          id
          addedById
          addedBy {
            id
            email
            firstName
            lastName
          }
          createdAt
          message
          source
          subject
          updatedAt
        }
        compensatingFactors
        createdAt
        deniedAt
        deniedById
        deniedBy {
          id
          email
          firstName
          lastName
        }
        lenderId
        lender {
          id
          applyUrl
          contactInformation1
          contactInformation2
          friendlyId
          joinUrl
          logoUrl
          name
        }
        metrics {
          id
          createdAt
          exceptional
          name
          notes
          updatedAt
          value
        }
        renofiNotes
        renovationScope
        status
        title
        updatedAt
      }
    }
  }
`;

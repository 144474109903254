import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {validateUuidProp} from '@renofi/utilities/src/react';

import PersonalAndResidencyDetails from './PersonalAndResidencyDetails';

const PersonalAndResidency = ({task}) => {
  const {search} = useViewerNavigation();
  const {borrowerRole = 'borrower'} = search || {};
  const personalInfoAndResidency = task?.personalInfoAndResidency || {};

  const details = useMemo(() => {
    if (borrowerRole === 'coborrower') {
      return {
        firstName: personalInfoAndResidency.coborrowerFirstName,
        middleName: personalInfoAndResidency.coborrowerMiddleName,
        lastName: personalInfoAndResidency.coborrowerLastName,
        nameSuffix: personalInfoAndResidency.coborrowerNameSuffix,
        maritalStatus: personalInfoAndResidency.coborrowerMaritalStatus,
        citizenshipType: personalInfoAndResidency.coborrowerCitizenshipType,
        dateOfBirth: personalInfoAndResidency.coborrowerDateOfBirth,
        addresses:
          personalInfoAndResidency.coborrowerResidentialAddresses || [],
        dependentsNumber: personalInfoAndResidency.coborrowerDependentsNumber,
        dependentsAges: personalInfoAndResidency.coborrowerDependentsAges,
        mailingAddress: personalInfoAndResidency.coborrowerMailingAddress,
        alternativeNames: personalInfoAndResidency.coborrowerAlternativeNames,
        phoneNumber: personalInfoAndResidency.coborrowerPhoneNumber,
      };
    }
    return {
      ...personalInfoAndResidency,
      addresses: personalInfoAndResidency.borrowerResidentialAddresses || [],
      alternativeNames: personalInfoAndResidency.borrowerAlternativeNames,
      mailingAddress: personalInfoAndResidency.borrowerMailingAddress,
    };
  }, [personalInfoAndResidency?.id, borrowerRole]);

  if (!personalInfoAndResidency || isEmpty(personalInfoAndResidency)) {
    return null;
  }

  return <PersonalAndResidencyDetails {...details} />;
};

PersonalAndResidency.propTypes = {
  task: PropTypes.shape({
    personalInfoAndResidency: PropTypes.shape({
      id: validateUuidProp,
    }),
  }),
};

export default PersonalAndResidency;

import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {propOr, isEmpty, is} from 'ramda';

import {Box} from '@renofi/components-internal';

import {Item, List, ItemTitle, SubTitle} from './styled';

const ValidationListItem = ({
  validation = [],
  title,
  hasNestedItems = false,
}) => {
  const isAllItemsValid = !hasNestedItems && isEmpty(validation);
  const isAllNestedItemsValid =
    hasNestedItems && validation.every(({items}) => isEmpty(items));

  if (isAllItemsValid || isAllNestedItemsValid) {
    return null;
  }

  return (
    <>
      <ItemTitle>{title}</ItemTitle>
      <Box mb={24}>
        <List>
          {validation.map((item, i) => {
            const isItemAString = is(String, item);
            const subItems = propOr([], 'items', item);

            if (isItemAString) {
              return <Item key={i}>{item}</Item>;
            }

            if (isEmpty(subItems)) {
              return null;
            }

            return (
              <Fragment key={i}>
                <SubTitle>{item.subTitle}</SubTitle>

                {subItems.map((subItem) => (
                  <Item key={subItem}>{subItem}</Item>
                ))}
              </Fragment>
            );
          })}
        </List>
      </Box>
    </>
  );
};

ValidationListItem.propTypes = {
  hasNestedItems: PropTypes.bool,
  title: PropTypes.string,
  validation: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        subTitle: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
      }),
    ]),
  ),
};

export default ValidationListItem;

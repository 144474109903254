import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {usePreview} from '@renofi/utilities/src/hooks';
import {Box} from '@renofi/components-internal';

function RequestAdditionalDocumentsPreview(props) {
  const {getTaskAdditionalRequestPreview, message, project, taskId} = props;
  const [preview, setPreview] = useState(null);
  usePreview(preview, 'taskAdditionalRequestBorrowerPreview');

  useEffect(() => {
    (async () => {
      const {primaryApplicant, tasks} = project;
      const borrowerId = primaryApplicant.id;
      const task = tasks.find((t) => taskId === t.id);
      const taskAdditionalRequest = {message};

      const result = await getTaskAdditionalRequestPreview({
        borrowerId,
        task,
        taskAdditionalRequest,
      });
      setPreview(result);
    })();
  }, []);

  return (
    <Box
      css={{
        height: 'calc(100vh - 200px)',
        overflow: 'auto',
      }}>
      <div id="taskAdditionalRequestBorrowerPreview" />
    </Box>
  );
}

RequestAdditionalDocumentsPreview.propTypes = {
  getTaskAdditionalRequestPreview: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  project: PropTypes.shape({
    primaryApplicant: PropTypes.object,
    borrower: PropTypes.shape({
      id: PropTypes.string,
    }),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  taskId: PropTypes.string,
};

export default RequestAdditionalDocumentsPreview;

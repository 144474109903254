import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Box,
  Modal,
  SelectField,
} from '@renofi/components-internal';
import {GET_FEASIBILITY_REVIEWERS} from '@renofi/graphql/src';
import {useAssignFeasibilityReview} from '@renofi/graphql/src/hooks';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {Provider as ThemeProvider} from '@renofi/theme/src';

import {SelectFieldBox} from './styled';

const AssignFeasibilityAnalystModal = ({
  onClose,
  users = [],
  selectedUserId,
  feasibilityReviewId,
}) => {
  const {addNotification} = useNotifications();
  const [value, setValue] = useState(selectedUserId);
  const header = 'Assign Feasibility Analyst';
  const refetchQuery = {
    query: GET_FEASIBILITY_REVIEWERS,
  };
  const {assignFeasibilityReview, loading} = useAssignFeasibilityReview({
    refetchQueries: [refetchQuery],
  });
  const isAssignDisabled = selectedUserId === value;
  const isAssignAnalystLoading = loading;

  const options = useMemo(() => {
    const userOptions = users?.map((user) => ({
      label: user?.label,
      value: user?.value,
    }));
    return [...userOptions] || [];
  }, [users]);

  const handleRequestFeasibilityReview = async () => {
    try {
      await assignFeasibilityReview({
        variables: {
          id: feasibilityReviewId,
          feasibilityReviewerId: value,
        },
      });
      onClose();
      addNotification({
        variant: 'success',
        content: 'Feasibility Analyst successfully assigned',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to assign Feasibility Analyst',
      });
    }
  };

  const handleAgentOnaChange = (value) => {
    setValue(value);
  };

  return (
    <Modal
      disableEscape
      clickBackdropToClose={false}
      lock
      fixed
      p={24}
      width={600}
      show
      footer={
        <Flex justifyContent="flex-end" alignItems="center">
          <Box mr="4px">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box ml="4px">
            <Button
              disabled={isAssignAnalystLoading || isAssignDisabled}
              loading={isAssignAnalystLoading}
              onClick={handleRequestFeasibilityReview}>
              Assign
            </Button>
          </Box>
        </Flex>
      }
      header={header}
      onClose={onClose}>
      <ThemeProvider theme="light">
        <SelectFieldBox flex={1} mx={2} mb={95}>
          <SelectField
            label="Assign to"
            onChange={handleAgentOnaChange}
            options={options}
            placeholder="Select Analyst"
            value={value}
          />
        </SelectFieldBox>
      </ThemeProvider>
    </Modal>
  );
};

AssignFeasibilityAnalystModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array,
  selectedUserId: PropTypes.string,
  feasibilityReviewId: PropTypes.string,
};

export default AssignFeasibilityAnalystModal;

import {pathOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_WEB_REVIEWS} from '../../queries';

export default function useWebReviews({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_WEB_REVIEWS,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const webReviews = pathOr([], ['webReviews', 'collection'], data);
  const metadata = pathOr(null, ['webReviews', 'metadata'], data);

  return {error, fetch, metadata, webReviews, loading};
}

import {gql} from '@apollo/client';

import {projectReport} from '../partials';

export default gql`
  mutation cloneProjectReport($id: ID!, $name: String) {
    cloneProjectReport(id: $id, name: $name) {
      report {
        ${projectReport}
      }
    }
  }
`;

export const home = () => '/';
export const login = () => '/login';
export const borrowers = () => '/borrowers-pipeline';
export const feasibilityPipeline = () => `/feasibility-pipeline`;
export const lenders = () => '/lenders';
export const internalUsers = () => '/users';
export const myAccount = () => '/my-account';

//gcdd
export const dueDiligenceRequests = () => '/dd-requests';

import {filter, propEq} from 'ramda';
import {withViewerLayout} from 'api/hoc';

import {
  compose,
  withContext,
  withProps,
  withState,
} from '@renofi/recompose/src';

import {isTaskReviewed} from '../../../../utils';
import ViewerContext from '../../../../context';

import Component from './Component';

export default compose(
  withViewerLayout(),
  withContext(ViewerContext),
  withProps(({facet, project}) => {
    const projectTasks = project?.tasks || [];
    const tasks = filter(propEq('facet', facet), projectTasks);
    const disabled = !tasks.every(isTaskReviewed);
    return {disabled, tasks};
  }),
  withState('loading', 'setLoading', false),
)(Component);

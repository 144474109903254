import {compose, withHandlers} from '@renofi/recompose/src';

import {UPDATE_FEASIBILITY_STUDY_ITEM} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(UPDATE_FEASIBILITY_STUDY_ITEM, {
      name: 'updateFeasibilityStudyItem',
    }),
    withHandlers({
      updateFeasibilityStudyItem:
        ({updateFeasibilityStudyItem}) =>
        async (variables) => {
          const result = await updateFeasibilityStudyItem({variables});
          return result?.data?.updateFeasibilityStudyItem?.feasibilityStudyItem;
        },
    }),
  );

import {pathOr, propOr} from 'ramda';

import {formatDate} from '@renofi/utilities/src/dates';

export const getStartEndDate = (gcddReview) => {
  const timeline = pathOr(null, ['project', 'timeline'], gcddReview);

  const startDate = propOr(null, 'expectedRenovationStart', timeline);
  const start = startDate ? formatDate(new Date(startDate)) : 'N/A';

  const endDate = propOr(null, 'expectedRenovationCompletionOn', timeline);
  const end = endDate ? formatDate(new Date(endDate)) : 'N/A';

  return `${start} - ${end}`;
};

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {PRESENT_PRODUCT_OPTIONS_TO_BORROWER} from '../../mutations';

export default function usePresentProductOptionsToBorrower({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [presentProductOptionsToBorrower, {data, loading, error}] = useMutation(
    PRESENT_PRODUCT_OPTIONS_TO_BORROWER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['presentProductOptionsToBorrower'], data);
  return {presentProductOptionsToBorrower, response, loading, error};
}

import {toPairs} from 'ramda';

import {ACTIONS} from './constants';

export const getActions = ({canComplete, canRequestSecond, canUpdate}) => {
  return toPairs(ACTIONS)
    .filter(([value]) => {
      if (value === 'complete' && !canComplete) return false;
      if (value === 'request' && !canRequestSecond) return false;
      if (value === 'update' && !canUpdate) return false;
      return true;
    })
    .map(([value, label]) => ({
      label,
      value,
    }));
};

import {isNil, propOr, isEmpty} from 'ramda';
import {REFERENCE_TYPES} from 'lib/gcdd';
import {getObjectNameForDocument} from 'lib/document';

export const getStateLicenseValidationItems = (license) => {
  const licenseItems = [];

  const fileName = propOr(null, 'fileName', license);
  const expiryDate = propOr(null, 'expiryDate', license);
  const reviewedAt = propOr(null, 'reviewedAt', license);
  const rejectedAt = propOr(null, 'rejectedAt', license);

  const objectName = getObjectNameForDocument(license);
  const hasUploadedFile = Boolean(fileName) && Boolean(objectName);

  if (hasUploadedFile && !expiryDate) {
    licenseItems.push('Expiry Date');
  }

  if (hasUploadedFile && !reviewedAt && !rejectedAt) {
    licenseItems.push('Reject or Approve document');
  }

  return licenseItems;
};

export const getInsuranceValidationItems = (questionnaire) => {
  const {
    contractorGeneralLiabilityInsurance: insurance,
    hasWorkersCompInsurance,
  } = questionnaire || {};

  const insuranceItems = [
    {
      subTitle: 'General liability',
      items: [],
    },
    {
      subTitle: 'Workers comp',
      items: [],
    },
  ];
  const [generalLiability, workersComp] = insuranceItems;

  if (isNil(insurance)) {
    return insuranceItems;
  }

  const {
    expiryDate,
    namesMatch,
    limitEachOccurrence,
    limitAggregate,
    workersCompDetailsAvailableInGeneralLiability,
    limitWorkersCompensation,
    reviewedAt,
    rejectedAt,
  } = insurance || {};

  if (isNil(namesMatch)) {
    generalLiability.items.push('Name on cert matches actual name');
  }

  if (isNil(limitEachOccurrence)) {
    generalLiability.items.push('Each occurrence');
  }

  if (isNil(limitAggregate)) {
    generalLiability.items.push('Aggregate');
  }

  if (isNil(expiryDate)) {
    generalLiability.items.push('Expiry Date');
  }

  if (isNil(reviewedAt) && isNil(rejectedAt)) {
    generalLiability.items.push('Reject or Approve document');
  }

  // workersComp
  const isEachAccidentInvalid =
    !isNil(hasWorkersCompInsurance) &&
    workersCompDetailsAvailableInGeneralLiability &&
    isNil(limitWorkersCompensation);

  if (isEachAccidentInvalid) {
    workersComp.items.push('Each accident');
  }

  return insuranceItems;
};

export const getReferenceValidationItems = (references) => {
  if (isNil(references) || isEmpty(references)) {
    return [];
  }

  const referenceItems = [];

  const subcontractor = references.find(
    ({type}) => type === REFERENCE_TYPES.SUBCONTRACTOR,
  );

  if (subcontractor && isNil(subcontractor.score)) {
    referenceItems.push('Subcontractor score');
  }

  const clients = references.filter(
    ({type}) => type === REFERENCE_TYPES.CLIENT,
  );

  if (!isEmpty(clients)) {
    clients.forEach(({score}, index) => {
      if (!score) {
        referenceItems.push('Client ' + (index + 1) + ' score');
      }
    });
  }

  return referenceItems;
};

export const getWebReviewValidationItems = (score) => {
  const scoreItems = [];

  if (!score) {
    scoreItems.push('Score');
  }

  return scoreItems;
};

export const getBetterBusinessBureauItems = (isListed, grade) => {
  const bureauItems = [];

  if (isNil(isListed)) {
    bureauItems.push('Listed on the BBB');
  }

  if (isListed && isNil(grade)) {
    bureauItems.push('BBB Grade');
  }

  return bureauItems;
};

import {propEq} from 'ramda';
import {useCurrentSource} from 'api/hooks';
import {
  useHasFeasibilityEditPermission,
  FEASIBILITY_STUDY_ITEM_TYPES,
} from 'modules/viewer-v2';

const useGroupedFeasibilityStudyItems = (feasibilityReview) => {
  const {currentSource, isInternal} = useCurrentSource();
  const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();

  const feasibilityStudyItems = feasibilityReview?.feasibilityStudyItems || [];
  const isCompleted = Boolean(feasibilityReview?.completedBy);

  const mappedItems = feasibilityStudyItems.map((item) => {
    const isSameSource = currentSource === item.source;
    const editable =
      (isSameSource || isInternal) &&
      hasFeasibilityEditPermission &&
      !isCompleted;

    return {...item, editable};
  });

  const groupedStudyItems = {
    inScopeStudyItems: mappedItems.filter(
      propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE),
    ),
    outOfScopeStudyItems: mappedItems.filter(
      propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.OUT_OF_SCOPE),
    ),
    homeownerStudyItems: mappedItems.filter(
      propEq('type', FEASIBILITY_STUDY_ITEM_TYPES.HOMEOWNER_RESPONSIBILITY),
    ),
  };

  return groupedStudyItems;
};

export default useGroupedFeasibilityStudyItems;

export default (layout) => {
  switch (layout) {
    case 'split_wide':
      return [600, 600];
    case 'split_narrow':
      return [300, 300];
    default:
      return [56, 0];
  }
};

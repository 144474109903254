export default `
  id
  clonedFrom {
    id
  }
  columns
  createdAt
  createdBy {
    id
    firstName
    lastName
  }
  description
  filter
  name
  query
  sort
  updatedAt
`;

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_QUESTIONNAIRE_SCORE} from '../../../mutations';

export default function useSubmitQuestionnaireScore({onCompleted = noop} = {}) {
  const [submitQuestionnaireScore, {data, loading, error}] = useMutation(
    SUBMIT_QUESTIONNAIRE_SCORE,
    {
      onCompleted,
    },
  );
  const response = pathOr(
    null,
    ['submitQuestionnaireScore', 'questionnaire'],
    data,
  );

  return {submitQuestionnaireScore, response, loading, error};
}

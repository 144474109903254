import {useContext, useEffect, useState} from 'react';

import {propOr} from 'ramda';

import {useFeasibilityReviewById} from '@renofi/graphql';
import castIfNullish from '@renofi/utilities/src/castIfNullish';
import {useQueryString} from '@renofi/utilities/src/hooks';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {deleteCookie, getCookie} from '@renofi/utilities/src/cookies';

const FEASIBILITY_TASK_TYPES = [
  'appraisal_property_photos',
  'renovation_contract',
  'renovation_plan',
  // 'sms_consent_renovation_progress', // Not visible in V1, even though code extracts it??
];

export default () => {
  const storage = useContext(StorageContext);
  const search = useQueryString();
  const {
    feasibilityReview,
    fetch: fetchReview,
    loading,
  } = useFeasibilityReviewById({lazy: true});

  const [reviewId, setReviewId] = useState(null);
  const [noIdFound, setNoIdFound] = useState(null);
  const project = propOr(null, 'project', feasibilityReview);

  const projectId = propOr(null, 'id', project);

  const tasks = (project?.tasks || []).filter(({taskType}) =>
    FEASIBILITY_TASK_TYPES.includes(taskType),
  );

  useEffect(() => {
    const cookieId = castIfNullish(getCookie('feasibilityReviewId'));
    const storageId = castIfNullish(storage.getItem('feasibility:id'));
    const queryStringId =
      search?.feasibility_id ||
      search?.feasibility_review_id ||
      search?.feasibilityId ||
      search?.feasibilityReviewId;
    const feasibilityReviewId = cookieId || storageId || queryStringId;

    setReviewId(feasibilityReviewId);
    storage.setItem('feasibility:id', feasibilityReviewId);

    if (!!cookieId) {
      deleteCookie('feasibilityReviewId');
    }
    // After all that, if no reviewId found, set error
    setNoIdFound(!castIfNullish(feasibilityReviewId));
  }, []);

  useEffect(() => {
    const id = castIfNullish(reviewId);
    if (Boolean(id)) {
      fetchReview({variables: {id}});
    }
  }, [reviewId]);

  return {
    feasibilityReviewId: feasibilityReview?.id,
    feasibilityReview,
    loading,
    noIdFound,
    project,
    projectId,
    tasks,
  };
};

import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {without, isEmpty} from 'ramda';

import {
  Box,
  Card,
  Flex,
  Link,
  Switch,
  Table,
  Text,
} from '@renofi/components-internal';
import File from '@renofi/icons/src/File';

import {findUrl} from '../utils';
import {FileUrl} from '../styled';

function PropertyPhotosTable({
  propertyPhotos,
  selectedPhotos,
  setSelectedPhotos,
  signedUrls,
}) {
  const onTogglePhoto = useCallback(
    (id) => {
      const newPhotos = selectedPhotos.includes(id)
        ? without([id], selectedPhotos)
        : selectedPhotos.concat(id);

      setSelectedPhotos(newPhotos);
    },
    [selectedPhotos, setSelectedPhotos],
  );

  if (isEmpty(propertyPhotos)) {
    return null;
  }

  return (
    <Card mt={3} p={0}>
      <Table condensed>
        <thead>
          <tr>
            <th colSpan={2}>Photos</th>
            <th css={{width: 120, textAlign: 'right !important'}}>Send</th>
          </tr>
        </thead>
        <tbody>
          {propertyPhotos.map(
            ({id, homeownerDescription, fileName, objectName}) => {
              const isPhotoSelected = selectedPhotos.includes(id);

              return (
                <tr key={id}>
                  <td>
                    <Flex alignItems="center">
                      <Box mr={20}>
                        <File height={16} />
                      </Box>
                      <Box>{homeownerDescription}</Box>
                    </Flex>
                  </td>
                  <td>
                    <FileUrl>
                      <Link blank href={findUrl(objectName)(signedUrls)}>
                        {fileName}
                      </Link>
                    </FileUrl>
                  </td>
                  <td>
                    <Flex
                      width={1}
                      justifyContent="flex-end"
                      alignItems="center">
                      <Switch
                        checked={isPhotoSelected}
                        onClick={() => onTogglePhoto(id)}
                      />
                      <Text ml={16}>{isPhotoSelected ? 'Yes' : 'No'}</Text>
                    </Flex>
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    </Card>
  );
}

PropertyPhotosTable.propTypes = {
  propertyPhotos: PropTypes.array,
  selectedPhotos: PropTypes.array,
  setSelectedPhotos: PropTypes.func,
  signedUrls: PropTypes.array,
};

export default PropertyPhotosTable;

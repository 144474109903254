import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {AUTO_TRANSMIT_TO_LENDER} from '../../../mutations';

export default function useAutoTransmitToLender({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [autoTransmitToLender, {data, loading, error}] = useMutation(
    AUTO_TRANSMIT_TO_LENDER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['autoTransmitToLender'], data);
  return {autoTransmitToLender, response, loading, error};
}

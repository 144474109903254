import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_REVIEW_NOTES} from '../../../mutations';

export default function useSubmitReviewNotes({onCompleted = noop} = {}) {
  const [submitReviewNotes, {data, loading, error}] = useMutation(
    SUBMIT_REVIEW_NOTES,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitReviewNotes', 'gcddReview'], data);

  return {submitReviewNotes, response, loading, error};
}

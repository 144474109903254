import {v4} from 'uuid';
import {all, isNil, none, pathEq, pathOr, propEq} from 'ramda';
import {
  COMPANY_DETAILS_PATHS,
  CONTACT_DETAILS_PATHS,
  hasAllValues,
  isEligibilityComplete,
  isExperienceComplete,
  isReferencesComplete,
  isSignatureComplete,
} from 'lib/gcdd/validators';

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

const mapIds = (item) => ({...item, id: item.id || v4()});

export default function getProgressFromGcddReview(gcddReview) {
  const {contractor, questionnaire, webReviewsScore} = gcddReview;
  const {bbbCheckedAt} = contractor;
  const {contractorReminderSentAt, lastSentAt} = questionnaire;
  const references = pathOr([], ['questionnaire', 'references'], gcddReview);
  const allReferencesPassed = all(propEq('score', 'pass'), references);

  const isInsuranceReviewed = pathEq(
    ['contractorGeneralLiabilityInsurance', 'reviewResult'],
    'pass',
    questionnaire,
  );
  const isLicenseReviewed = pathEq(
    ['contractorLicense', 'reviewResult'],
    'pass',
    questionnaire,
  );
  const isQuestionnaireReviewed = questionnaire?.score === 'pass';
  // ^^ this assumes insurance, license, questionnaire all have .score values
  const isWebReviewReviewed = webReviewsScore === 'pass';

  const reminder1Sent = Boolean(contractorReminderSentAt);
  const reminder2Sent = Boolean(
    lastSentAt && lastSentAt !== contractorReminderSentAt,
  );

  const questionnaireChildren = [
    {
      value: 'Contact details',
      complete: hasAllValues(CONTACT_DETAILS_PATHS, gcddReview),
    },
    {
      value: 'Company details',
      complete: hasAllValues(COMPANY_DETAILS_PATHS, gcddReview),
    },
    {
      value: 'Eligibility',
      complete: isEligibilityComplete(questionnaire),
    },
    {
      value: 'Experience',
      complete: isExperienceComplete(questionnaire),
    },
    {
      value: 'References',
      complete: isReferencesComplete(questionnaire),
    },
    {
      value: 'Signature',
      complete: isSignatureComplete(questionnaire),
    },
  ].map(mapIds);
  const isQuestionnaireComplete = questionnaireChildren.every(
    ({complete}) => complete,
  );

  return [
    {
      value: 'Questionnaire sent',
      complete: reminder1Sent && reminder2Sent,
      children: [
        {
          value: '1st reminder email sent',
          complete: reminder1Sent,
        },
        {
          value: '2nd reminder email sent',
          complete: reminder2Sent,
        },
      ].map(mapIds),
    },
    {
      value: 'Questionnaire in progress',
      complete: isQuestionnaireComplete,
      children: questionnaireChildren,
    },
    {
      value: 'Reference questionnaire sent',
      complete: Boolean(references?.length) && none(isNil, references),
      children: references.map((ref, i) => ({
        children: ref.confirmationRequestedAt
          ? [
              {
                complete: Boolean(ref.confirmedAt),
                id: `${ref.id}:${i}`,
                value: '1st reminder email sent',
              },
            ]
          : [],
        complete: Boolean(ref.score),
        id: ref.id,
        value: humanizeSnakeCase(ref.type),
      })),
    },
    {
      value: 'Due dilligence process',
      complete:
        isQuestionnaireReviewed &&
        isLicenseReviewed &&
        isInsuranceReviewed &&
        allReferencesPassed &&
        isWebReviewReviewed &&
        bbbCheckedAt,
      children: [
        {
          value: 'Contractor questionnaire reviewed',
          complete: isQuestionnaireReviewed,
        },
        {
          value: 'State licsense review completed',
          complete: isLicenseReviewed,
        },
        {
          value: 'Insurance review completed',
          complete: isInsuranceReviewed,
        },
        {
          value: 'Reference review completed',
          complete: allReferencesPassed,
        },
        {
          value: 'Web review completed',
          complete: isWebReviewReviewed,
        },
        {
          value: 'BBB check completed',
          complete: !!bbbCheckedAt,
        },
      ].map(mapIds),
    },
    {
      value: 'Returned to RU as completed',
      completed: Boolean(gcddReview.completedAt),
    },
  ].map(mapIds);
}

import {withCurrentSource} from 'api/hoc';
import withFeasibilityReview from 'lib/withFeasibilityReview';
import {isFeasibilityReviewerSource} from 'lib/sourceHelper';
import {withReviewerFeasibilityReview} from 'modules/feasibility-reviewer';
import ViewerContext from 'modules/viewer-v2/context';

import {
  withFeasibilityRequestPreview,
  withGetSignedUrls,
} from '@renofi/graphql/src/hoc';
import {compose, branch, withContext} from '@renofi/recompose/src';

import Component from './Component';

export default compose(
  withCurrentSource(),
  withFeasibilityRequestPreview(),
  branch(
    ({currentSource}) => isFeasibilityReviewerSource(currentSource),
    withReviewerFeasibilityReview(),
    withFeasibilityReview(),
  ),
  withContext(ViewerContext),
  withGetSignedUrls(),
)(Component);

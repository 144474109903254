export const INSURANCE_PROPS = [
  'limitAggregate',
  'limitEachOccurrence',
  'limitWorkersCompensation',
  'workersCompDetailsAvailableInGeneralLiability',
];

export const DOCUMENT_TYPES = {
  WORKERS_COMP_INSURANCE: 'contractor_workers_comp_insurance',
  GENERAL_LIABILITY_INSURANCE: 'contractor_general_liability_insurance',
};

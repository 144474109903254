import React from 'react';

import PropTypes from 'prop-types';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import AppraisalStatusBar from '../../AppraisalStatusBar';

const TaskStatusBar = ({projectId, task}) => {
  const {params} = useViewerNavigation();
  const {dataType} = params || {};
  const {facet} = task || {};

  switch (true) {
    // we show appraisal tasks in Feasibility so "facet" may not be enough.
    case dataType === 'task' && facet === 'appraisal':
      return <AppraisalStatusBar projectId={projectId} />;
    default:
      return null;
  }
};

TaskStatusBar.propTypes = {
  projectId: PropTypes.string,
  task: PropTypes.shape({
    facet: PropTypes.string,
  }),
};

export default TaskStatusBar;

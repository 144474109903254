import React, {memo, useState} from 'react';

import PropTypes from 'prop-types';
import {range} from 'ramda';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {
  Box,
  Button,
  Flex,
  Label,
  Money,
  SelectField,
  Text,
} from '@renofi/components-internal';
import compareKeys from '@renofi/utilities/src/compareKeys';

const options = range(1, 10);

const SummaryBasic = ({editable, layout, loading, totalCost, onAddRows}) => {
  const [rows, setRows] = useState(1);
  const wide = isSplitWideLayout(layout);
  const alignItems = wide ? 'center' : 'flex-start';
  const flexDirection = wide ? 'row' : 'column';
  const mt = wide ? 0 : '8px';
  return (
    <Box mt={16}>
      <Flex
        px={16}
        width={1}
        alignItems={alignItems}
        flexDirection={flexDirection}
        justifyContent="space-between">
        <Box mt={mt}>
          <Label>Total calculated cost</Label>
          <Flex mt="4px" alignItems="center">
            <Text fontSize={16} lineHeight="19px">
              <Money value={totalCost} />
            </Text>
          </Flex>
        </Box>
        {editable && (
          <Flex mt={mt} justifyContent="flex-end" alignItems="center">
            <Box width={wide ? 60 : 100} mr={16}>
              <SelectField
                mb={0}
                value={rows}
                options={options}
                onChange={(value) => setRows(value)}
              />
            </Box>
            <Button loading={loading} onClick={() => onAddRows(rows)}>
              Add rows
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

SummaryBasic.propTypes = {
  editable: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  totalCost: PropTypes.number.isRequired,
  onAddRows: PropTypes.func.isRequired,
};

export default memo(SummaryBasic, (prev, next) =>
  compareKeys(['layout', 'loading', 'totalCost'], prev, next),
);

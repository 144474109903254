import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const OuterWrapper = styled(Flex)({
  flex: 1,
  flexDirection: 'column',
});

export const MainContentWrapper = styled(Flex)(({offset = 0}) => ({
  width: `calc(100% - ${offset}px)`,
  flexDirection: 'column',
  transition: 'width 200ms ease-out',
}));

export const InnerWrapper = styled(Flex)({
  flex: 1,
  position: 'relative',
});

export const ADDITIONAL_PROPERTIES = [
  'streetAddressOne',
  'streetAddressTwo',
  'city',
  'propertyType',
  'currentOccupancy',
  'currentOccupancyOther',
  'intendedOccupancy',
  'propertyStatus',
  'yearPurchased',
  'marketValue',
  'mortgageCompany',
  'mortgageStatementDate',
  'mortgageAccountNumber',
  'mortgageInterestRate',
  'mortgageBalance',
  'mortgageMonthlyPayment',
  'mortgageBalanceSecondLien',
  'mortgageBalanceThirdLien',
  'annualMortgageTaxes',
  'annualInsurancePremium',
  'annualPrivateMortgageInsurance',
  'rentalIncome',
  'homeownersAssociationFee',
];

export const SUBJECT_PROPERTIES = [
  'propertyType',
  'yearPurchased',
  'mortgageStatementDate',
  'mortgageAccountNumber',
  'mortgageInterestRate',
  'mortgageBalance',
  'mortgageMonthlyPayment',
  'annualMortgageTaxes',
  'annualInsurancePremium',
  'homeownersAssociationFee',
  'mortgageCompany',
];

export const OTHER = 'Other';

import * as React from 'react';

import PropTypes from 'prop-types';

const SvgTransmit = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={22}
      height={18}
      viewBox="0 0 22 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-3h24v24H-1z" />
        <path
          d="M20 0H2C.89 0 0 .89 0 2v14c0 1.11.89 2 2 2h18c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2zm0 16.02H2V1.98h18v14.04zM11 7V5l4 4-4 4v-2H7V7h4z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgTransmit.propTypes = {
  color: PropTypes.string,
};
export default SvgTransmit;

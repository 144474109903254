import styled from '@emotion/styled';

import {basic75} from '@renofi/theme';

export const Wrapper = styled.div(
  {
    minWidth: 100,
    minHeight: 52,
    padding: 16,
    borderRadius: 4,
    backgroundColor: basic75,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    bottom: 'calc(100% + 8px)',
    ':after': {
      width: 0,
      height: 0,
      position: 'absolute',
      content: '""',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: `4px solid ${basic75}`,
      bottom: -4,
    },
  },
  ({tailPosition = 'center', width}) => ({
    ':after': {
      ...(tailPosition === 'center'
        ? {
            marginLeft: -4,
            left: '50%',
          }
        : {}),
      ...(tailPosition === 'left' ? {left: 24} : {}),
      ...(tailPosition === 'right' ? {right: 24} : {}),
    },
    width,
  }),
);

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src/colors';

export const Container = styled(Flex)({
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
});

export const Photo = styled(Flex)(
  {
    position: 'relative',
    justifyContent: 'center',
    marginTop: 32,
    ':last-of-type': {
      marginBottom: 32,
    },
  },
  ({width}) => ({
    width: '100%',
    '> img': {
      width,
      minHeight: 200,
    },
  }),
);

export const Controls = styled(Flex)({
  position: 'absolute',
  width: '100%',
  height: 60,
  top: 0,
  left: 0,
  right: 0,
  opacity: 0.9,
  backgroundColor: basic80,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
});

import React, {useMemo, useState, useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useProjectDetails, useViewerLayout} from 'api/hooks';
import {isSplitWideLayout, isFullWidthLayout} from 'lib/layoutHelper';
import {Container, useHasFeasibilityEditPermission} from 'modules/viewer-v2';

import {
  Box,
  Flex,
  Text,
  Textarea,
  TextField,
} from '@renofi/components-internal';
import {Tabs} from '@renofi/components-internal/src/next';
import File from '@renofi/icons/src/File';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {isUrl} from '@renofi/utilities/src/validators';
import {basic55} from '@renofi/theme';

import {
  useFeasibilityValidation,
  useFeasibilityTab,
  useGroupedFeasibilityStudyItems,
} from './hooks';
import ContractReview from './components/ContractReview';
import FeasibilityStudy from './components/FeasibilityStudy';
import Actions from './components/Actions';
import Request from './components/Request';
import {
  calculateCanRequestSecond,
  calculateCanUpdate,
  getTabs,
  isActionsDropdownEnabled,
} from './utils';
import {Comment, Wrapper} from './styled';

const TASK_TYPES = [
  'appraisal_property_photos',
  'renovation_contract',
  'renovation_plan',
];

const Feasibility = ({
  feasibilityReview,
  feasibilityReviews,
  updateFeasibilityReview,
  storage,
}) => {
  const {feasibilityTab: tab, onChangeFeasibilityTab} =
    useFeasibilityTab(storage);

  const groupedStudyItems = useGroupedFeasibilityStudyItems(feasibilityReview);

  const validation = useFeasibilityValidation({
    feasibilityReview,
    groupedStudyItems,
  });

  const {projectId} = useParams();
  const {project} = useProjectDetails({variables: {id: projectId}});
  const {layout} = useViewerLayout();

  const {
    id: feasibibilityReviewId,
    xactRemodelUrl,
    returnedAt,
    completedAt,
    requestedAt,
    canceledAt,
    reviewer,
    generalCommentaryAndNotes = '',
  } = feasibilityReview || {};

  const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();

  const [xactUrl, setXactUrl] = useState(xactRemodelUrl || '');
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [commentary, setCommentary] = useState(generalCommentaryAndNotes || '');

  const debouncedUpdateFeasibilityReview = useDebounce(
    updateFeasibilityReview,
    300,
  );

  useEffect(() => {
    setXactUrl(xactRemodelUrl || '');
    setInvalidUrl(false);
    setCommentary(generalCommentaryAndNotes || '');
  }, [feasibibilityReviewId]);

  const onCommentChange = useCallback((comment) => {
    setCommentary(comment);
    debouncedUpdateFeasibilityReview({
      generalCommentaryAndNotes: comment,
    });
  }, []);

  const onUpdateFeasibility = useCallback(({xactUrl, invalidUrl}) => {
    if (!invalidUrl) {
      updateFeasibilityReview({xactRemodelUrl: xactUrl});
    }
  }, []);

  const debounceUpdateFeasibility = useDebounce(onUpdateFeasibility, 500);

  const onXactRemodelChange = useCallback((value) => {
    const isInvalidUrl = !isUrl(value) && Boolean(value);
    setInvalidUrl(isInvalidUrl);
    setXactUrl(value);
  }, []);

  const full = isFullWidthLayout(layout);
  const wide = isSplitWideLayout(layout);

  const isReturned = Boolean(returnedAt);
  const isCompleted = Boolean(completedAt);
  const isRequested = Boolean(requestedAt);
  const isCanceled = Boolean(canceledAt);

  const canComplete = isReturned && !isCompleted;
  const projectTasks = project?.tasks || [];

  const tasks = useMemo(
    () => projectTasks.filter(({taskType}) => TASK_TYPES.includes(taskType)),
    [projectTasks],
  );

  const canUpdate = useMemo(
    () =>
      calculateCanUpdate({
        feasibilityReview,
        tasks,
      }),
    [returnedAt, completedAt, requestedAt, reviewer, tasks?.length],
  );
  const canRequestSecond = calculateCanRequestSecond({
    feasibilityReview,
    feasibilityReviews,
  });

  const isActionsVisible =
    !isCanceled && (canComplete || canRequestSecond || canUpdate);
  const isActionsBtnEnabled = isActionsDropdownEnabled({
    feasibilityReview,
    hasFeasibilityEditPermission,
  });

  const tabs = useMemo(
    () =>
      getTabs({
        validation,
        feasibilityReview,
      }),
    [feasibilityReview, validation],
  );

  const isCostEstimateDisabled = isCompleted && !hasFeasibilityEditPermission;

  return (
    <Wrapper wide={wide} hasPaddingBottom={!isRequested || isActionsVisible}>
      <Container
        py={0}
        header={
          <Flex alignItems="center">
            <Box height={24}>
              <File color={basic55} />
            </Box>
            <Text ml={12}>Construction expert commentary and notes</Text>
          </Flex>
        }>
        <Box px={16}>
          {!hasFeasibilityEditPermission && <Comment>{commentary}</Comment>}
          {hasFeasibilityEditPermission && (
            <Textarea
              mb={0}
              rows={10}
              resizable
              value={commentary}
              onChange={onCommentChange}
              active
            />
          )}
        </Box>
      </Container>

      <Container header={<Text mr={2}>XactRemodel project URL</Text>}>
        <Flex alignItems="center" justifyContent="center" px={3}>
          <TextField
            active
            value={xactUrl}
            inputValue={xactUrl}
            mb={0}
            onChange={onXactRemodelChange}
            onKeyUp={() => debounceUpdateFeasibility({xactUrl, invalidUrl})}
            error={invalidUrl ? 'Invalid URL' : null}
            errorBorder={invalidUrl}
            disabled={isCompleted}
          />
        </Flex>
      </Container>

      <Container
        p={0}
        header={
          <Tabs
            value={tab}
            tabs={tabs}
            onChange={onChangeFeasibilityTab}
            wide={wide}
          />
        }>
        {tab === 'contractReview' && (
          <ContractReview isCostEstimateDisabled={isCostEstimateDisabled} />
        )}
        {tab === 'feasibilityStudy' && <FeasibilityStudy />}
      </Container>
      {!isRequested && !full && <Request />}
      {isActionsVisible && !full && <Actions disabled={!isActionsBtnEnabled} />}
    </Wrapper>
  );
};

Feasibility.propTypes = {
  feasibilityReview: PropTypes.shape({
    costEstimateSuppliedApproved: PropTypes.bool,
    costEstimateSuppliedNotes: PropTypes.string,
    xactRemodelUrl: PropTypes.string,
    id: PropTypes.string,
    returnedAt: PropTypes.string,
    completedAt: PropTypes.string,
    requestedAt: PropTypes.string,
    reviewer: PropTypes.shape({
      id: PropTypes.string,
    }),
    generalCommentaryAndNotes: PropTypes.string,
  }),
  feasibilityReviews: PropTypes.array,
  updateFeasibilityReview: PropTypes.func.isRequired,
  storage: PropTypes.object,
};

export default Feasibility;

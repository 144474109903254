import styled from '@emotion/styled';

import {actionLight, basic55, basic75} from '@renofi/theme';
import {Box, Flex} from '@renofi/components-internal';

export const Wrapper = styled(Flex)({
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: basic75,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const Container = styled(Flex)({
  width: 4,
  height: 20,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const Dot = styled(Box)(
  {
    width: 4,
    height: 4,
    borderRadius: '50%',
  },
  ({active}) => ({
    backgroundColor: active ? actionLight : basic55,
  }),
);

export const Tooltip = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 52,
    borderRadius: 4,
    backgroundColor: basic75,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    bottom: 'calc(100% + 8px)',
    right: '50%',
    ':after': {
      width: 0,
      height: 0,
      position: 'absolute',
      content: '""',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: `4px solid ${basic75}`,
      bottom: -4,
      marginLeft: -4,
      left: '50%',
    },
  },
  ({wide}) => ({
    width: wide ? 100 : 50,
    marginRight: wide ? -50 : -25,
  }),
);

export const Action = styled(Flex)({
  width: 40,
  height: 40,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {ACCEPT_REPORT} from '../mutations';

import useMutation from './useMutation';

export default function useAcceptReport({onCompleted = noop} = {}) {
  const [acceptReport, {data, loading, error}] = useMutation(ACCEPT_REPORT, {
    onCompleted,
  });
  const response = pathOr(null, ['acceptReport'], data);

  return {acceptReport, response, loading, error};
}

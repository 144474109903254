import React from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';
import {dangerLight} from '@renofi/theme';

const RequiredText = ({show}) => {
  return show ? (
    <Text fontSize={12} color={dangerLight}>
      required
    </Text>
  ) : null;
};

RequiredText.propTypes = {
  show: PropTypes.bool,
};

export default RequiredText;

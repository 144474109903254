import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {without, isEmpty, find, propEq} from 'ramda';

import {useEnums} from '@renofi/graphql';
import {
  Box,
  Card,
  Flex,
  Link,
  Switch,
  Table,
  Text,
} from '@renofi/components-internal';
import File from '@renofi/icons/src/File';

import {findUrl} from '../utils';
import {FileUrl} from '../styled';

function DocumentsTable({
  reassign,
  selectedFiles,
  setSelectedFiles,
  signedUrls,
  documents,
}) {
  const {documentTypes} = useEnums();

  const onToggleFile = useCallback(
    (id) => {
      const newSelectedFiles = selectedFiles.includes(id)
        ? without([id], selectedFiles)
        : selectedFiles.concat(id);

      setSelectedFiles(newSelectedFiles);
    },
    [selectedFiles, setSelectedFiles],
  );

  if (isEmpty(documents) || reassign) {
    return null;
  }

  return (
    <Card p={0}>
      <Table condensed>
        <thead>
          <tr>
            <th colSpan={2}>Documents</th>
            <th css={{width: 120, textAlign: 'right !important'}}>Send</th>
          </tr>
        </thead>
        <tbody>
          {documents.map(({id, documentType, fileName, objectName}) => {
            const isFileSelected = selectedFiles.includes(id);
            const docType = find(propEq('value', documentType), documentTypes);

            return (
              <tr key={id}>
                <td>
                  <Flex alignItems="center">
                    <Box mr={20}>
                      <File height={16} />
                    </Box>
                    <Box>{docType?.label || documentType}</Box>
                  </Flex>
                </td>
                <td>
                  <FileUrl>
                    <Link blank href={findUrl(objectName)(signedUrls)}>
                      {fileName}
                    </Link>
                  </FileUrl>
                </td>
                <td>
                  <Flex width={1} justifyContent="flex-end" alignItems="center">
                    <Switch
                      checked={isFileSelected}
                      onClick={() => onToggleFile(id)}
                    />
                    <Text ml={16}>{isFileSelected ? 'Yes' : 'No'}</Text>
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
}

DocumentsTable.propTypes = {
  documentTypes: PropTypes.object,
  documents: PropTypes.array,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
  signedUrls: PropTypes.array,
  reassign: PropTypes.bool,
};

export default DocumentsTable;

import React from 'react';

import PropTypes from 'prop-types';
import {isNumber} from 'lodash';
import {useParams} from 'react-router-dom';
import {useProjectDetails} from 'api';

import {Alert, Text} from '@renofi/components-internal';
import {taskTypes} from '@renofi/utilities/src/enums';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import formatMoney from '@renofi/utilities/src/formatMoney';

import {TASK_DATA_SETS, TASK_TYPES_WITH_DATA_SETS} from './constants';
import {DataTable} from './styled';

const getDataSetsByTaskType = (
  {taskType, ...task},
  {streetAddressOne} = {},
) => {
  const {
    assetDetails = [],
    employmentHistories = [],
    incomeSources,
    realEstateOwned = {},
  } = task;
  switch (taskType) {
    case taskTypes.assets:
      return assetDetails.map((data) => ({
        name: data.financialInstitution,
        type: data.assetType,
        documents: data.documents || [],
        value: data.id,
      }));
    case taskTypes.employment_history:
      return employmentHistories.map((data) => ({
        name: data.employerName,
        type: data.businessRole,
        documents: data.documents || [],
        value: data.id,
      }));
    case taskTypes.income:
      return incomeSources.map((data) => ({
        name: data.incomeType,
        type: data.grossMonthlyIncome,
        documents: data.documents || [],
        value: data.id,
      }));
    case taskTypes.real_estate_owned:
      const {
        additionalProperties = [],
        soldProperties = [],
        subjectProperty,
      } = realEstateOwned;
      const properties = [
        subjectProperty,
        ...additionalProperties,
        ...soldProperties,
      ];
      return properties.map((data) => {
        const isSubject = data.__typename === 'SubjectProperty';

        return {
          name: isSubject ? streetAddressOne : data.streetAddressOne,
          type: data.__typename,
          documents: data.documents || [],
          value: data.id,
        };
      });
    default:
      return [];
  }
};

function DeleteTask({task}) {
  const {taskType, title} = task;
  const {projectId} = useParams();
  const {project} = useProjectDetails({variables: {id: projectId}});
  const isDataSetType = TASK_TYPES_WITH_DATA_SETS.includes(taskType);
  const dataSets = isDataSetType ? getDataSetsByTaskType(task, project) : [];
  const noun = TASK_DATA_SETS[taskType];

  return (
    <>
      <Text>Are you sure you want to delete the {title} task?</Text>
      {Boolean(dataSets.length) ? (
        <Alert
          mt={3}
          flexDirection="column"
          alignItems="flex-start"
          variant="warning">
          <Text mb={3}>
            Deleting this task will also delete the following number of related{' '}
            {noun}:
          </Text>
          <DataTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>
                  {taskType === taskTypes.income
                    ? 'Gross monthly income'
                    : 'Type'}
                </th>
                <th>Associated documents</th>
              </tr>
            </thead>
            <tbody>
              {dataSets.map(({type, ...dataSet}) => {
                const finalType = isNumber(type)
                  ? formatMoney(type, {fractional: false})
                  : humanizeSnakeCase(type);
                return (
                  <tr key={dataSet.value}>
                    <td>{humanizeSnakeCase(dataSet.name)}</td>
                    <td>{finalType}</td>
                    <td>{dataSet.documents.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </DataTable>
        </Alert>
      ) : null}
    </>
  );
}

DeleteTask.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default DeleteTask;

import {sortBy, identity, prop} from 'ramda';

import {compose, defaultProps, mapProps} from '@renofi/recompose/src';

import {GET_LENDERS} from '../queries';

import withQuery from './withQuery';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    defaultProps({page: 1, limit: 100}),
    withQuery({
      query: GET_LENDERS,
      props: ({data: {lenders}, ...props}) => {
        return {
          lenders: {
            ...lenders,
            collection: sortBy(prop('name'), lenders.collection),
          },
          ...props,
        };
      },
      options,
    }),
    mapProps(mapper),
  );

import React from 'react';

import PropTypes from 'prop-types';

import {getFullResidenceAddress} from '@renofi/utilities/src/places';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {diffYearMonthDay, formatDate} from '@renofi/utilities/src/dates';

import {ItemDetail, ItemName, ItemRow} from '../../../../styled';

const PersonalAndResidencyAddress = ({isMainBorrower, address = {}}) => {
  const {currentAddress, residenceFrom, residenceTo} = address;
  const {diffText: timeAtAddress} = diffYearMonthDay(
    residenceFrom,
    residenceTo,
  );
  return (
    <>
      <ItemRow>
        <ItemName>{currentAddress ? 'Current' : 'Former'} address</ItemName>
        <ItemDetail>{getFullResidenceAddress(address)}</ItemDetail>
      </ItemRow>

      <ItemRow>
        <ItemName>Housing type</ItemName>
        <ItemDetail>{address?.housingType}</ItemDetail>
      </ItemRow>

      <ItemRow>
        <ItemName>Monthly expense</ItemName>
        <ItemDetail>
          {address?.monthlyExpenses
            ? formatMoney(address?.monthlyExpenses, {fractional: false})
            : ''}
        </ItemDetail>
      </ItemRow>

      <ItemRow>
        <ItemName>Start date</ItemName>
        <ItemDetail>{formatDate(address?.residenceFrom)}</ItemDetail>
      </ItemRow>

      <ItemRow>
        <ItemName>End date</ItemName>
        <ItemDetail>{formatDate(address?.residenceTo)}</ItemDetail>
      </ItemRow>

      <ItemRow>
        <ItemName>How long at address</ItemName>
        <ItemDetail>{timeAtAddress}</ItemDetail>
      </ItemRow>

      {address.mailingAddress ? (
        <ItemRow>
          <ItemName>Mailing address</ItemName>
          <ItemDetail>
            {getFullResidenceAddress(
              address[
                isMainBorrower
                  ? address.borrowerMailingAddress
                  : address.coborrowerMailingAddress
              ],
            )}
          </ItemDetail>
        </ItemRow>
      ) : null}
    </>
  );
};

PersonalAndResidencyAddress.propTypes = {
  isMainBorrower: PropTypes.bool,
  address: PropTypes.object,
};

export default PersonalAndResidencyAddress;

import React from 'react';

import PropTypes from 'prop-types';

import {Button, Flex, Box, Modal} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {useCancelFeasibilityReview} from '@renofi/graphql/src/hooks';
import {useNotifications} from '@renofi/utilities/src/hooks';

const CancelFeasibilityReviewModal = ({
  onClose,
  project,
  feasibilityReview,
}) => {
  const {id, cancelable} = feasibilityReview;
  const borrowerFullName = project?.primaryApplicant?.fullName;

  const {addNotification} = useNotifications();
  const {cancelFeasibilityReview, loading} = useCancelFeasibilityReview();
  const isCancelationLoading = loading;

  const onClickCancelFeasibilityReview = async () => {
    try {
      await cancelFeasibilityReview({variables: {id: id}});
      addNotification({
        variant: 'success',
        content: 'The feasibility review has been canceled',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to cancel the feasibility review',
      });
    } finally {
      onClose();
    }
  };

  const header = 'Cancel Feasibility Review';
  const footer = (
    <Flex justifyContent="flex-end" alignItems="center">
      <Box mr="4px">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Box>
      <Box ml={1}>
        <Button
          disabled={isCancelationLoading || !cancelable}
          loading={isCancelationLoading}
          onClick={onClickCancelFeasibilityReview}>
          Cancel Feasibility Review
        </Button>
      </Box>
    </Flex>
  );

  return (
    <Modal
      disableEscape
      clickBackdropToClose={false}
      lock
      fixed
      p={24}
      width={600}
      show
      header={header}
      footer={footer}
      onClose={onClose}>
      <ThemeProvider>
        <Flex>
          Are you sure you want to delete the feasibility review for{' '}
          {borrowerFullName}?
        </Flex>
      </ThemeProvider>
    </Modal>
  );
};

CancelFeasibilityReviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    primaryApplicant: PropTypes.object,
  }),
  feasibilityReview: PropTypes.shape({
    id: PropTypes.string,
    cancelable: PropTypes.bool,
  }),
};

export default CancelFeasibilityReviewModal;

/* eslint-disable max-lines */
import React, {useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {isNil, isEmpty, range} from 'ramda';
import {useCurrentSource, useViewerLayout} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {isInternalSource} from 'lib/sourceHelper';
import {
  useHasFeasibilityEditPermission,
  FEASIBILITY_STUDY_ITEM_TYPES,
} from 'modules/viewer-v2';

import {white} from '@renofi/theme';
import {Alert, Box, Checkbox, Flex} from '@renofi/components-internal';

import Container from '../Container';

import StudyItem from './components/StudyItem';
import SummaryBasic from './components/SummaryBasic';
import SummaryExtra from './components/SummaryExtra';
import SummaryFull from './components/SummaryFull';
import {
  Section,
  Table,
  ContractorCostCell,
  DescriptionOfWorkCell,
} from './styled';
import {hideEmptyFeasibilityItems, calculateTotalRating} from './utils';

const FeasibilityStudy = ({
  groupedStudyItems,
  project,

  updateFeasibilityReview,
  feasibilityReview,

  readFeasibilityReviewState,
  feasibilityReviewId,
  addFeasibilityStudyItems,
  removeFeasibilityStudyItem,
  updateFeasibilityStudyItem,
}) => {
  const {layout} = useViewerLayout();
  const {isInternal, isReviewer} = useCurrentSource();
  const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();

  const [loading, setLoading] = useState(false);

  const {
    homeownerResponsibilityCompleted,
    inScopeAdjustedTotalRating,
    inScopeTotalRating,
    outOfScopeCompleted,
    returnedAt,
    completedAt,
    requestedAt,
    reviewer,
  } = feasibilityReview || {};
  const {inScopeStudyItems, outOfScopeStudyItems, homeownerStudyItems} =
    groupedStudyItems;

  const onChangeTotalRating = async () => {
    const {feasibilityStudyItems} = readFeasibilityReviewState();
    const inScopeTotalRating = calculateTotalRating(feasibilityStudyItems);
    await updateFeasibilityReview({inScopeTotalRating});
  };

  useEffect(() => {
    if (isNil(inScopeTotalRating)) {
      onChangeTotalRating();
    }
  }, []);

  const isRequested = Boolean(requestedAt && reviewer);
  const isReturned = Boolean(returnedAt);
  const isCompleted = Boolean(completedAt);

  const wide = isSplitWideLayout(layout);

  const onAddStudyItems = async (type, max = 1) => {
    setLoading(type);
    try {
      const rows = range(0, max);
      const items = rows.map(() => ({
        feasibilityReviewId,
        feasibilityStudyItem: {},
        type,
      }));
      await addFeasibilityStudyItems(items);
    } finally {
      setLoading(null);
    }
  };

  const onRemoveStudyItem = async (id) => {
    await removeFeasibilityStudyItem(id);
  };

  const onChangeAdjustedCost = async (id, value) => {
    const adjustedCost = value || value === 0 ? parseFloat(value) : null;
    await updateFeasibilityStudyItem(id, {adjustedCost});
    await onChangeTotalRating();
  };

  const onChangeCost = async (id, value) => {
    const cost = value ? parseFloat(value) : null;
    await updateFeasibilityStudyItem(id, {cost});
    await onChangeTotalRating();
  };

  const onChangeDescription = async (id, descriptionOfWork) => {
    updateFeasibilityStudyItem(id, {descriptionOfWork});
  };

  const onChangeRating = async (id, reviewerRating) => {
    await updateFeasibilityStudyItem(id, {
      reviewerRating,
      ...(reviewerRating === 'reasonable' ? {adjustedCost: null} : {}),
    });
    await onChangeTotalRating();
  };

  const onChangeNotes = async (id, reviewerNotes) => {
    updateFeasibilityStudyItem(id, {reviewerNotes});
  };

  const onChangeTotalAdjustedRating = async (inScopeAdjustedTotalRating) => {
    updateFeasibilityReview({inScopeAdjustedTotalRating});
  };

  const hasItemFromRenofi = useMemo(
    () => inScopeStudyItems.some(({source}) => isInternalSource(source)),
    [inScopeStudyItems],
  );

  const editable = isReviewer || (hasFeasibilityEditPermission && !isCompleted);
  const isVisibleForInternalUsers = isInternal && !isReturned;
  const isVisibleForReviewers = isReviewer && !isReturned;

  const inScopeItems = hideEmptyFeasibilityItems(inScopeStudyItems);
  const homeownerItems = hideEmptyFeasibilityItems(homeownerStudyItems);
  const outOfScopeItems = hideEmptyFeasibilityItems(outOfScopeStudyItems);

  return (
    <>
      <Container px={0} inverse header="In scope">
        {isReviewer && (
          <Alert py={0} mb={16}>
            {hasItemFromRenofi
              ? 'Review each item and select a rating. Enter notes if requested.'
              : 'Add line items. Enter notes if requested.'}
          </Alert>
        )}
        <Table>
          {isVisibleForInternalUsers && (
            <Flex mb="8px">
              <DescriptionOfWorkCell wide={wide}>
                Description of work
              </DescriptionOfWorkCell>
              <ContractorCostCell wide={wide}>
                Contractor cost
              </ContractorCostCell>
            </Flex>
          )}
          {inScopeItems.map((item) => (
            <StudyItem
              key={item.id}
              {...item}
              returned={isReturned}
              onChangeAdjustedCost={(value) =>
                onChangeAdjustedCost(item.id, value)
              }
              onChangeCost={(value) => onChangeCost(item.id, value)}
              onChangeDescription={(value) =>
                onChangeDescription(item.id, value)
              }
              onChangeNotes={(value) => onChangeNotes(item.id, value)}
              onChangeRating={(value) => onChangeRating(item.id, value)}
              onRemove={() => onRemoveStudyItem(item.id)}
              isRequested={isRequested}
            />
          ))}
        </Table>
        {(isReviewer || isReturned) && (
          <SummaryFull
            inScopeStudyItems={inScopeItems}
            editable={editable}
            loading={loading === FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE}
            studyItems={inScopeItems}
            totalRating={inScopeTotalRating}
            totalAdjustedRating={inScopeAdjustedTotalRating}
            onAddRows={(rows) =>
              onAddStudyItems(FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE, rows)
            }
            onChangeTotalRating={(value) => onChangeTotalAdjustedRating(value)}
          />
        )}
        {isVisibleForInternalUsers && (
          <SummaryExtra
            renovationBudget={project.renovationCost}
            studyItems={inScopeItems}
            loading={loading === FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE}
            onAddRows={(rows) =>
              onAddStudyItems(FEASIBILITY_STUDY_ITEM_TYPES.IN_SCOPE, rows)
            }
          />
        )}
      </Container>
      <Container px={0} inverse header="In scope but homeowner responsibility">
        {isReviewer && (
          <Alert py={0} mb={16}>
            Review/Edit/Add line items. Mark as completed when you’re finished.
          </Alert>
        )}
        <Table>
          {isVisibleForInternalUsers && !isEmpty(homeownerItems) && (
            <Flex mb="8px">
              <DescriptionOfWorkCell wide={wide}>
                Description of work
              </DescriptionOfWorkCell>
              <ContractorCostCell wide={wide}>
                Contractor cost
              </ContractorCostCell>
            </Flex>
          )}
          {homeownerItems.map(({id, ...item}) => (
            <StudyItem
              key={id}
              {...item}
              returned={isReturned}
              completed={isCompleted}
              onChangeAdjustedCost={(value) => onChangeAdjustedCost(id, value)}
              onChangeCost={(value) => onChangeCost(id, value)}
              onChangeDescription={(value) => onChangeDescription(id, value)}
              onChangeNotes={(value) => onChangeNotes(id, value)}
              onChangeRating={(value) => onChangeRating(id, value)}
              onRemove={() => onRemoveStudyItem(id)}
              isRequested={isRequested}
            />
          ))}
        </Table>
        <SummaryBasic
          editable={editable}
          studyItems={homeownerItems}
          loading={
            loading === FEASIBILITY_STUDY_ITEM_TYPES.HOMEOWNER_RESPONSIBILITY
          }
          onAddRows={(rows) =>
            onAddStudyItems(
              FEASIBILITY_STUDY_ITEM_TYPES.HOMEOWNER_RESPONSIBILITY,
              rows,
            )
          }
        />
        {isVisibleForReviewers && (
          <Box width={1} px={16} mt={32}>
            <Section>
              <Checkbox
                color={white}
                checked={homeownerResponsibilityCompleted}
                label="Mark section as completed"
                onChange={(value) =>
                  updateFeasibilityReview({
                    homeownerResponsibilityCompleted: value,
                  })
                }
              />
            </Section>
          </Box>
        )}
      </Container>
      <Container px={0} inverse header="Out of scope">
        {isVisibleForReviewers && (
          <Alert py={0} mb={16}>
            Review/Edit/Add line items. Mark as completed when you’re finished.
          </Alert>
        )}
        <Table>
          {isVisibleForInternalUsers && !isEmpty(outOfScopeItems) && (
            <Flex mb="8px">
              <DescriptionOfWorkCell wide={wide}>
                Description of work
              </DescriptionOfWorkCell>
              <ContractorCostCell wide={wide}>
                Contractor cost
              </ContractorCostCell>
            </Flex>
          )}
          {outOfScopeItems.map(({id, ...item}) => (
            <StudyItem
              key={id}
              {...item}
              returned={isReturned}
              onChangeAdjustedCost={(value) => onChangeAdjustedCost(id, value)}
              onChangeCost={(value) => onChangeCost(id, value)}
              onChangeDescription={(value) => onChangeDescription(id, value)}
              onChangeNotes={(value) => onChangeNotes(id, value)}
              onChangeRating={(value) => onChangeRating(id, value)}
              onRemove={() => onRemoveStudyItem(id)}
              isRequested={isRequested}
            />
          ))}
        </Table>
        <SummaryBasic
          editable={editable}
          studyItems={outOfScopeItems}
          loading={loading === FEASIBILITY_STUDY_ITEM_TYPES.OUT_OF_SCOPE}
          onAddRows={(rows) =>
            onAddStudyItems(FEASIBILITY_STUDY_ITEM_TYPES.OUT_OF_SCOPE, rows)
          }
        />
        {isVisibleForReviewers && (
          <Box width={1} px={16} mt={32}>
            <Section>
              <Checkbox
                color={white}
                checked={outOfScopeCompleted}
                label="Mark section as completed"
                onChange={(value) =>
                  updateFeasibilityReview({
                    outOfScopeCompleted: value,
                  })
                }
              />
            </Section>
          </Box>
        )}
      </Container>
    </>
  );
};

FeasibilityStudy.propTypes = {
  readFeasibilityReviewState: PropTypes.func,
  addFeasibilityStudyItems: PropTypes.func,
  removeFeasibilityStudyItem: PropTypes.func,
  updateFeasibilityStudyItem: PropTypes.func,
  feasibilityReviewId: PropTypes.string,
  updateFeasibilityReview: PropTypes.func.isRequired,
  project: PropTypes.shape({
    renovationCost: PropTypes.number,
  }),
  feasibilityReview: PropTypes.shape({
    homeownerResponsibilityCompleted: PropTypes.bool,
    inScopeAdjustedTotalRating: PropTypes.string,
    inScopeTotalRating: PropTypes.string,
    outOfScopeCompleted: PropTypes.bool,
    returnedAt: PropTypes.string,
    completedAt: PropTypes.string,
    requestedAt: PropTypes.string,
    reviewer: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  groupedStudyItems: PropTypes.shape({
    inScopeStudyItems: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        editable: PropTypes.bool,
        cost: PropTypes.number,
        descriptionOfWork: PropTypes.string,
      }),
    ),
    outOfScopeStudyItems: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        editable: PropTypes.bool,
        cost: PropTypes.number,
        descriptionOfWork: PropTypes.string,
      }),
    ),
    homeownerStudyItems: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        editable: PropTypes.bool,
        cost: PropTypes.number,
        descriptionOfWork: PropTypes.string,
      }),
    ),
  }),
};

export default FeasibilityStudy;

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

export default ({amcStatus, status} = {}) => {
  let result = amcStatus || status || 'ordered';

  if (result === 'completed') {
    result = 'in_review';
  }

  return humanizeSnakeCase(result);
};

import {propOr} from 'ramda';

import {DOCUMENT_TYPES} from './constants';

export const validateForm = (insurance) => {
  const documentType = propOr(null, 'documentType', insurance);
  const {
    limitWorkersCompensation,
    workersCompDetailsAvailableInGeneralLiability:
      isWorkersCompIncludedInGeneralLiability,
    limitAggregate,
    limitEachOccurrence,
  } = insurance;

  const isGeneralLiabilityInsurance =
    documentType === DOCUMENT_TYPES.GENERAL_LIABILITY_INSURANCE;
  const isWorkersCompInsurance =
    documentType === DOCUMENT_TYPES.WORKERS_COMP_INSURANCE;
  const isEachAccidentValid = limitWorkersCompensation > 0;

  if (isWorkersCompInsurance) {
    return isEachAccidentValid;
  }

  if (isGeneralLiabilityInsurance) {
    const isLiabilityFieldsValid =
      limitAggregate > 0 && limitEachOccurrence > 0;

    if (isWorkersCompIncludedInGeneralLiability) {
      return isLiabilityFieldsValid && isEachAccidentValid;
    }

    return isLiabilityFieldsValid;
  }

  return false;
};

import {gql} from '@apollo/client';

export default gql`
  query introspectProjectsSearch {
    introspectProjectsSearch {
      filters {
        facetName
        name
        type
      }
      sorts {
        name
      }
    }
  }
`;

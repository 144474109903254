import {omit} from 'ramda';

export default ({
  isFinalInspection,
  projectId,
  orderParams = {},
  amcCode,
  appraisalProduct,
  previousAppraisalOrderId,
  selected = [],
}) => {
  const appraisalProductId = appraisalProduct?.id;

  let variables = {
    projectId,
    amcCode,
    details: {
      ...omit(['propertyDetails'], orderParams),
      appraisalProductId,
    },
    documentIds: selected,
  };

  if (isFinalInspection) {
    variables.previousAppraisalOrderId = previousAppraisalOrderId;
    variables.propertyDetails = orderParams?.propertyDetails;
  }

  return variables;
};

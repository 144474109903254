import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {pick, omit} from 'ramda';

import {Flex} from '@renofi/components-internal';

import Arrow from './icons/Arrow';
import Comment from './icons/Comment';
import {Content, Header, Toggle, Wrapper} from './styled';

const paddingKeys = ['p', 'pb', 'pl', 'pr', 'pt', 'px', 'py'];

const Container = ({
  children,
  collapsible = true,
  comment = false,
  header,
  inverse,
  reverse,
  toggleCommentSection,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  return (
    <Wrapper
      mb={16}
      open={open}
      inverse={inverse}
      reverse={reverse}
      {...omit(paddingKeys, props)}>
      {header && (
        <Header inverse={inverse}>
          <Flex alignItems="center" height="100%" ml={16} flexGrow={1}>
            {header}
          </Flex>
          {collapsible && (
            <Toggle
              inverse={inverse}
              open={open}
              onClick={() => setOpen(!open)}>
              <Arrow />
            </Toggle>
          )}
          {comment && (
            <Toggle open onClick={toggleCommentSection}>
              <Comment />
            </Toggle>
          )}
        </Header>
      )}
      <Content pb={16} pt={24} {...pick(paddingKeys, props)}>
        {children}
      </Content>
    </Wrapper>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  comment: PropTypes.bool,
  toggleCommentSection: PropTypes.func,
  header: PropTypes.node,
  inverse: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default Container;

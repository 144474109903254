import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {UPDATE_TASK} from '../../mutations';

export default function useUpdateTask({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateTask, {data, loading, error}] = useMutation(UPDATE_TASK, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['updateTask'], data);
  return {updateTask, response, loading, error};
}

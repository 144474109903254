import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const Wrapper = styled(Flex)({
  minWidth: 250,
  height: '50px !important',

  margin: '0 4px',
  padding: '0 16px',

  alignItems: 'center',

  fontSize: 12,
  lineHeight: '19px',
  cursor: 'pointer',
  backgroundColor: '#f0f',
  borderRadius: '4px 4px 0px 0px',

  whiteSpace: 'nowrap',
  overflow: 'hidden',

  '@media (max-width: 600px)': {
    width: '100%',
  },
});

import styled from '@emotion/styled';

export const DataTable = styled.table({
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'collapse',
  'th, td': {
    textAlign: 'left',
    padding: 8,
  },
  tr: {
    'th:first-of-type, td:first-of-type': {
      paddingLeft: 0,
    },
    'th:last-of-type, td:last-of-type': {
      paddingRight: 0,
    },
  },
});

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const Container = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
  gap: 24,
  margin: '20px 0',
});

export const Item = styled(Flex)({
  justifyContent: 'center',
  alignItems: 'center',
  background: 'white',
  height: '17%',
  width: '36vw',
});

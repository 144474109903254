import {isNil, reject} from 'lodash';

import {taskTypes} from '@renofi/utilities/src/enums';

export default (project) => {
  const {tasks = []} = project || {};
  if (!tasks?.length) {
    return [];
  }

  const reoTask = tasks.find((t) => t.taskType === taskTypes.real_estate_owned);
  const {realEstateOwned} = reoTask || {};
  const {
    additionalProperties = [],
    soldProperties = [],
    subjectProperty,
  } = realEstateOwned || {};
  const properties = reject(
    [subjectProperty, ...additionalProperties, ...soldProperties],
    isNil,
  );

  return properties;
};

import * as React from 'react';

import PropTypes from 'prop-types';

const SvgReject = ({color = '#FF5253', ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <defs>
        <path
          d="M16 7.007 14.993 6 11 9.993 7.007 6 6 7.007 9.993 11 6 14.993 7.007 16 11 12.007 14.993 16 16 14.993 12.007 11 16 7.007z"
          id="reject_svg__a"
        />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle fill={color} cx={11} cy={11} r={11} />
        <use fill="#FFF" xlinkHref="#reject_svg__a" />
      </g>
    </svg>
  );
};

SvgReject.propTypes = {
  color: PropTypes.string,
};
export default SvgReject;

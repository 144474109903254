import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {actionRegular, basic75, basic80} from '@renofi/theme/src/colors';

export const Totals = styled(Flex)({}, ({editable, wide}) => ({
  borderTop: editable ? '1px solid #424242' : '',
  marginTop: editable ? 24 : 0,
  width: wide ? 'auto' : '100%',
  flexDirection: wide ? 'row' : 'column',
  justifyContent: wide ? 'space-between' : 'flex-start',
  alignItems: wide ? 'center' : 'flex-start',
  padding: wide ? '24px 32px 8px' : '24px 24px 8px',
  paddingTop: editable ? 24 : 16,
}));

export const Edit = styled(Flex)(
  {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  ({active, disabled}) => ({
    backgroundColor: active ? actionRegular : basic75,
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
);

export const Container = styled(Flex)({
  width: '100%',
  padding: '16px 20px',
  borderRadius: 4,
  backgroundColor: basic80,
  marginTop: 12,
});

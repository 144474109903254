import isNumber from '@renofi/utilities/src/isNumber';

const isExperienceComplete = (questionnaire) => {
  const {
    hasBeenInvolvedInLawsuit,
    involvedInLawsuitDetails,
    hasTaxLiens,
    taxLiensDetails,
    hasEverFiledForBankruptcy,
    bankruptcyDetails,
    percentageCompletedRenovationsAmountRanges,
    percentageOfCompletedRenovationsIn30PercentAmountRange,
  } = questionnaire;

  const isAnyProjectSelected =
    questionnaire.typicalRenovationProjects?.length > 0;
  const isOtherSelected =
    questionnaire.typicalRenovationProjects?.includes('other');
  const isOtherDetailsFilled =
    questionnaire.typicalRenovationProjectsOtherDetails?.length > 0;
  const isRenovationValuesComplete =
    Boolean(percentageOfCompletedRenovationsIn30PercentAmountRange) ||
    (percentageCompletedRenovationsAmountRanges?.length &&
      percentageCompletedRenovationsAmountRanges.every(isNumber));

  return (
    // from step 1
    questionnaire.yearsAsAContractor >= 0 &&
    questionnaire.numberOfCompletedRenovations &&
    isRenovationValuesComplete &&
    questionnaire.percentageBusinessWithResidentialInLast2y &&
    questionnaire.percentageBusinessWithCommercialInLast2y &&
    // from step 2
    questionnaire.avgNumberOfRenovationsPerYear &&
    questionnaire.avgPriceOfProjects &&
    ((isAnyProjectSelected && !isOtherSelected) ||
      (isOtherSelected && isOtherDetailsFilled)) &&
    // from step 3
    (hasBeenInvolvedInLawsuit === false ||
      (hasBeenInvolvedInLawsuit && involvedInLawsuitDetails?.length > 0)) &&
    (hasTaxLiens === false || (hasTaxLiens && taxLiensDetails?.length > 0)) &&
    (hasEverFiledForBankruptcy === false ||
      (hasEverFiledForBankruptcy && bankruptcyDetails?.length > 0))
  );
};

export default isExperienceComplete;

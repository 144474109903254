import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {SET_APPRAISAL_COMPARABLE_SALE_URL_SHAREABLE} from '../../mutations';
import {GET_PROJECT} from '../../queries';
import useMutation from '../useMutation';

export default function useSetAppraisalComparableSaleUrlShareable({
  onCompleted = noop,
} = {}) {
  const [setAppraisalComparableSaleUrlShareable, {data, loading, error}] =
    useMutation(SET_APPRAISAL_COMPARABLE_SALE_URL_SHAREABLE, {
      onCompleted,
      refetchQueries: [GET_PROJECT],
      awaitRefetchQueries: true,
    });

  const response = pathOr(
    null,
    ['setAppraisalComparableSaleUrlShareable'],
    data,
  );
  return {setAppraisalComparableSaleUrlShareable, response, loading, error};
}

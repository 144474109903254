import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {UPDATE_LICENSE} from '../../../mutations';

export default function useUpdateLicense({onCompleted = noop} = {}) {
  const [updateLicense, {abort, data, loading, error}] = useMutation(
    UPDATE_LICENSE,
    {
      onCompleted,
    },
  );
  const license = pathOr(null, ['updateLicense', 'license'], data);

  return {abort, updateLicense, license, loading, error};
}

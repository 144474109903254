import React from 'react';

import PropTypes from 'prop-types';

import {ClickOutside} from '@renofi/components-internal';

import {Wrapper} from './styled';

const noop = () => {};

const Tooltip = ({children, onClose = noop, ...props}) => {
  return (
    <ClickOutside onClickOutside={onClose}>
      <Wrapper {...props}>{children}</Wrapper>
    </ClickOutside>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  tailPosition: PropTypes.string,
  onClose: PropTypes.func,
};

export default Tooltip;

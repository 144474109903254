import {useEffect, useMemo} from 'react';

import {useParams} from 'react-router-dom';
import {last, propOr} from 'ramda';
import {useApolloClient} from '@apollo/client';
import {GET_FEASIBILITY_REVIEW_STATE} from 'api';

import {useFeasibilityReviews} from '@renofi/graphql';

import convertReviewToCacheState from './convertReviewToCacheState';

export default (props = {}) => {
  const {projectId, ...params} = useParams();
  const client = useApolloClient();

  const feasibilityReviewId =
    props?.feasibilityReviewId || params?.feasibilityReviewId;
  const {feasibilityReviews = []} = useFeasibilityReviews({
    variables: {projectId},
  });

  const feasibilityReview = useMemo(() => {
    return feasibilityReviewId
      ? feasibilityReviews.find((r) => r.id === feasibilityReviewId)
      : last(feasibilityReviews);
  }, [feasibilityReviews, feasibilityReviewId]);

  const getFromCache = () => {
    const data = client.readQuery({
      query: GET_FEASIBILITY_REVIEW_STATE,
    });
    return propOr({}, 'feasibilityReviewState', data);
  };

  const updateToCache = (data) => {
    client.writeQuery({
      query: GET_FEASIBILITY_REVIEW_STATE,
      data: {
        feasibilityReviewState: {
          ...getFromCache(),
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    if (!feasibilityReview) {
      return;
    }

    const cached = getFromCache();
    if (cached?.id !== feasibilityReviewId) {
      updateToCache(convertReviewToCacheState(feasibilityReview));
    }
  }, [feasibilityReviewId, feasibilityReview]);

  return {
    feasibilityReview,
    feasibilityReviewId: feasibilityReview?.id,
    updateFeasibilityReviewState: updateToCache,
    returned: Boolean(feasibilityReview?.returnedAt),
    completed: Boolean(feasibilityReview?.completedBy),
  };
};

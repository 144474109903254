import validateRealEstateOwnedData from './validateRealEstateOwnedData';

export default (
  {allDocumentsAccepted, canHaveDocument, taskType, realEstateOwned},
  {currentMortgageBalance, currentMortgagePayment, mortgageStatementDate} = {},
) => {
  const areDocumentsValid =
    (canHaveDocument && allDocumentsAccepted) || !canHaveDocument;

  switch (taskType) {
    case 'mortgage_statement': {
      const isMortgageDataValid = Boolean(
        currentMortgageBalance &&
          currentMortgagePayment &&
          mortgageStatementDate,
      );

      return areDocumentsValid && isMortgageDataValid;
    }
    case 'real_estate_owned': {
      const isRealEstateValid = validateRealEstateOwnedData(realEstateOwned);

      return areDocumentsValid && isRealEstateValid;
    }
    default:
      return false;
  }
};

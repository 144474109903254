import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {ASSIGN_INTERNAL_ACCOUNT} from '../../mutations';
import useMutation from '../useMutation';

export default function useAssignInternalAccount({onCompleted = noop} = {}) {
  const [assignInternalAccount, {data, loading, error}] = useMutation(
    ASSIGN_INTERNAL_ACCOUNT,
    {
      onCompleted,
    },
  );

  const response = propOr(null, 'assignInternalAccount', data);
  return {assignInternalAccount, response, loading, error};
}

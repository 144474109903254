import React, {useEffect, useState, useMemo, useContext} from 'react';

import PropTypes from 'prop-types';
import {map, prop, pipe, propOr, flatten, uniqBy} from 'ramda';
import {useParams} from 'react-router-dom';
import {useCurrentSource, useProjectDetails} from 'api/hooks';
import {getContractorDocsByProject} from 'lib/document';
import {FEASIBILITY_TASK_TYPES} from 'modules/viewer-v2';

import {ModalWithButtons, Text} from '@renofi/components-internal';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {usePreview, useNotifications} from '@renofi/utilities/src/hooks';
import {GET_FEASIBILITY_REVIEWS} from '@renofi/graphql/src/queries';
import {
  useRequestFeasibilityReview,
  useRequestSecondFeasibilityReview,
} from '@renofi/graphql/src/hooks';

import {ACTIONS} from './constants';
import {
  getObjectNames,
  getAllPhotos,
  mapPropertyPhotos,
  getParamsForPreview,
} from './utils';
import PropertyPhotosTable from './components/PropertyPhotosTable';
import DocumentsTable from './components/DocumentsTable';

function OrderAnalysisModal(props) {
  const {addNotification} = useNotifications();
  const {isReviewer} = useCurrentSource();
  const {projectId} = useParams();

  const {project} = useProjectDetails({variables: {id: projectId}});
  const tasks = propOr([], 'tasks', project);

  const {
    getSignedUrls,
    onClose,
    reassign,
    second,
    feasibilityReviewId,
    feasibilityReview,
    getFeasibilityRequestPreview,
  } = props;

  const documents = useMemo(() => {
    const feasibilityTasks = tasks.filter(({taskType}) =>
      FEASIBILITY_TASK_TYPES.includes(taskType),
    );
    const contractorsDocs = getContractorDocsByProject({tasks});
    const docs = pipe(map(propOr([], 'documents')), flatten)(feasibilityTasks);

    return uniqBy(prop('id'), [...docs, ...contractorsDocs]);
  }, [tasks]);

  const storage = useContext(StorageContext);

  const [action, setAction] = useState(ACTIONS.PREPARE);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState(
    map(prop('id'))(documents),
  );
  const [propertyPhotos, setPropertyPhotos] = useState(getAllPhotos({tasks}));
  const [signedUrls, setSignedUrls] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  usePreview(preview, 'feasibilityReview');

  const {requestFeasibilityReview} = useRequestFeasibilityReview();
  const {requestSecondFeasibilityReview} = useRequestSecondFeasibilityReview({
    refetchQueries: [GET_FEASIBILITY_REVIEWS],
  });

  // componentDidMount
  useEffect(() => {
    (async () => {
      const propertyPhotosObjectNames = getObjectNames(propertyPhotos);
      const documentsObjectNames = getObjectNames(documents);

      const allSignedUrls = await getSignedUrls([
        ...propertyPhotosObjectNames,
        ...documentsObjectNames,
      ]);

      setSignedUrls(allSignedUrls);
      setPropertyPhotos(mapPropertyPhotos(propertyPhotos, allSignedUrls));
      setSelectedPhotos(map(prop('id'))(propertyPhotos));
    })();
  }, []);

  const orderFeasibilityAnalysis = async ({
    reviewer,
    selectedFiles,
    selectedPhotos,
  }) => {
    setLoading(true);

    try {
      await requestFeasibilityReview({
        variables: {
          id: feasibilityReviewId,
          feasibilityReviewerId: reviewer,
          documentIds: selectedFiles,
          propertyPhotoIds: selectedPhotos,
        },
      });
      addNotification({
        type: 'snackbar',
        variant: 'success',
        content: 'Feasibility analysis is ordered successfully',
      });

      if (isReviewer && reassign) {
        storage.clear();
        logoutWithRedirect();
      }
    } catch (error) {
      addNotification({
        type: 'snackbar',
        variant: 'danger',
        content: 'Failed to order feasibility analysis',
      });
    } finally {
      setLoading(false);
      setAction(null);
      onClose();
    }
  };

  const orderSecondFeasibilityAnalysis = async ({
    reviewer,
    selectedFiles,
    selectedPhotos,
  }) => {
    setLoading(true);

    try {
      await requestSecondFeasibilityReview({
        variables: {
          sourceFeasibilityReviewId: feasibilityReviewId,
          feasibilityReviewerId: reviewer,
          documentIds: selectedFiles,
          propertyPhotoIds: selectedPhotos,
        },
      });
      addNotification({
        type: 'snackbar',
        variant: 'success',
        content: '2nd feasibility analysis is ordered successfully',
      });
    } catch (error) {
      addNotification({
        type: 'snackbar',
        variant: 'danger',
        content: 'Failed to order 2nd feasibility analysis',
      });
    } finally {
      setLoading(false);
      setAction(null);
      onClose();
    }
  };

  const previewFeasibilityRequest = async ({
    propertyPhotos,
    reviewer,
    selectedFiles,
    selectedPhotos,
  }) => {
    setLoading(true);

    const params = getParamsForPreview({
      feasibilityReviewId,
      reviewer,
      feasibilityReview,
      project,
      documents,
      propertyPhotos,
      selectedFiles,
      selectedPhotos,
    });

    try {
      const preview = await getFeasibilityRequestPreview(params);
      setAction(ACTIONS.PREVIEW);
      setPreview(preview);
    } catch (error) {
      addNotification({
        type: 'snackbar',
        variant: 'danger',
        content: 'Failed to fetch feasibility request preview',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async ({propertyPhotos, selectedFiles, selectedPhotos}) => {
    const params = {
      propertyPhotos,
      selectedFiles,
      selectedPhotos,
    };

    const shouldOrderRequest = reassign || action === ACTIONS.PREVIEW;
    const shouldPreviewRequest = !reassign && action === ACTIONS.PREPARE;
    const shouldOrderSecondAnalysis = !reassign && second;

    if (shouldOrderRequest) {
      const orderAnalysisFn = shouldOrderSecondAnalysis
        ? orderSecondFeasibilityAnalysis
        : orderFeasibilityAnalysis;

      await orderAnalysisFn(params);
    }

    if (shouldPreviewRequest) {
      await previewFeasibilityRequest(params);
    }
  };

  const onAccept = () => {
    onSubmit({propertyPhotos, selectedFiles, selectedPhotos});
  };

  const isPrepare = action === ACTIONS.PREPARE;
  const acceptLabel = reassign ? 'Reassign' : isPrepare ? 'Next' : 'Order';

  return (
    <ModalWithButtons
      lock
      fixed
      show
      mb={24}
      width={800}
      minHeight={380}
      loading={loading}
      acceptLabel={acceptLabel}
      onAccept={onAccept}
      onCancel={onClose}
      onClose={onClose}
      header={`${reassign ? 'Reassign' : 'Order'} Feasibility Analysis`}>
      <ThemeProvider theme="light">
        {isPrepare ? (
          <>
            {!reassign && <Text mb={16}>Project contents</Text>}
            <DocumentsTable
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              signedUrls={signedUrls}
              reassign={reassign}
              documents={documents}
            />
            <PropertyPhotosTable
              propertyPhotos={propertyPhotos}
              selectedPhotos={selectedPhotos}
              setSelectedPhotos={setSelectedPhotos}
              signedUrls={signedUrls}
            />
          </>
        ) : null}
        {action === ACTIONS.PREVIEW && <div id="feasibilityReview" />}
      </ThemeProvider>
    </ModalWithButtons>
  );
}

OrderAnalysisModal.propTypes = {
  documentTypes: PropTypes.object,
  getSignedUrls: PropTypes.func,
  reassign: PropTypes.bool,
  onClose: PropTypes.func,

  second: PropTypes.bool,
  feasibilityReviewId: PropTypes.string,
  storage: PropTypes.object,
  feasibilityReview: PropTypes.object,
  getFeasibilityRequestPreview: PropTypes.func,
};

export default OrderAnalysisModal;

import {GET_LOAN_TYPE_VALUES} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useLoanTypeValues({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LOAN_TYPE_VALUES,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const loanTypeValues = data?.loanTypeValues || [];

  return {error, fetch, loanTypeValues, loading};
}

import React, {useMemo, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  ClickOutside,
  Flex,
  Icon,
  Text,
  TextField,
} from '@renofi/components-internal';
import {actionRegular, basic20, white} from '@renofi/theme';
import noop from '@renofi/utilities/src/noop';

import {Container, Item, ArrowIconBox, Arrow} from './styled';

const DropdownButton = ({
  arrowColor = white,
  arrowCss = {},
  containerCss = {},
  options,
  onSelect = noop,
  buttonLabel = 'Choose an action',
  buttonVariant = 'danger',
  position = 'top',
  onButtonClick = noop,
  disabled = false,
  value: selectedValue = null,
  searchable = false,
  selectedIcon = 'check',
  unselectedIcon = 'bulletList',
  variant = 'primary',
  searchInputProps = {},
  showSelected = false,
  skipToggle = false,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const ref = useRef();

  const toggleHandler = () => {
    setActive(!active);
  };

  const onClickItem = (value) => {
    setActive(false);
    onSelect(value);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation();
    toggleHandler();
  };

  const handleButtonClick = () => {
    if (skipToggle) {
      return onButtonClick();
    }

    toggleHandler();
  };

  // If the "outside" element clicked on is this component
  // do nothing; as we're already toggling the dropdown.
  const onClickOutside = (e) => {
    const container = ref?.current;
    if (!container?.contains(e.target)) {
      toggleHandler();
    }
  };

  const availableOptions = useMemo(() => {
    if (!searchable) {
      return options;
    }

    return options.filter((opt) => {
      return opt.label.toLowerCase().match(searchBy.toLowerCase());
    });
  }, [options, searchable, searchBy]);

  const [{label, value} = {}] = options || [];
  return (
    <div css={{position: 'relative'}} ref={ref}>
      {active && (
        <ClickOutside onClickOutside={onClickOutside}>
          <div>
            <Container position={position} variant={variant} css={containerCss}>
              {searchable && (
                <Box px={3}>
                  <TextField
                    active
                    canClear={Boolean(searchBy)}
                    clearValue=""
                    leftIcon="glass"
                    my={2}
                    onChange={setSearchBy}
                    placeholder="Search"
                    value={searchBy}
                    {...searchInputProps}
                  />
                </Box>
              )}
              {availableOptions.map(({label, value, disabled = false}) => {
                const isSelected = value === selectedValue;
                return (
                  <Item
                    px={3}
                    py={0}
                    key={value}
                    selected={isSelected}
                    variant={variant}
                    disabled={disabled}
                    onClick={() => onClickItem(value)}>
                    {showSelected && (
                      <Icon
                        forceEmpty
                        width={16}
                        name={isSelected ? selectedIcon : unselectedIcon}
                        color={isSelected ? actionRegular : basic20}
                      />
                    )}
                    <Box ml={showSelected ? 2 : 0}>{label}</Box>
                  </Item>
                );
              })}
            </Container>
          </div>
        </ClickOutside>
      )}
      {options.length > 1 && (
        <Button
          variant={buttonVariant}
          {...props}
          css={{padding: 0}}
          onClick={handleButtonClick}
          disabled={disabled}>
          <Flex>
            <Text ml={16} mr={12}>
              {buttonLabel}
            </Text>
            <ArrowIconBox
              disabled={disabled}
              onClick={handleDropdownIconClick}
              css={arrowCss}>
              <Arrow
                active={active}
                color={disabled ? 'rgba(0, 0, 0, 0.2)' : arrowColor}
              />
            </ArrowIconBox>
          </Flex>
        </Button>
      )}
      {options.length === 1 && (
        <Button
          css={{padding: 0}}
          disabled={disabled}
          variant={buttonVariant}
          {...props}
          onClick={() => onSelect(value)}>
          {label}
        </Button>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  arrowColor: PropTypes.string,
  arrowCss: PropTypes.object,
  containerCss: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  buttonVariant: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  searchInputProps: PropTypes.object,
  selectedIcon: PropTypes.string,
  showSelected: PropTypes.bool,
  skipToggle: PropTypes.bool,
  unselectedIcon: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
};

export default DropdownButton;

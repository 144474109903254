import styled from '@emotion/styled';
import Row from 'modules/viewer-v2/components/Row';

import {basic75, basic88} from '@renofi/theme/src/colors';

export const StickyFooter = styled(Row)({
  background: basic88,
  padding: 16,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${basic75}`,
  boxShadow: `-1px 0px 0px ${basic75}, -10px 0px 4px rgba(0, 0, 0, 0.25)`,
  position: 'sticky',
  bottom: 0,
});

import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Box, Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme';
import {formatDateTime} from '@renofi/utilities/src/dates';

function CoverageProofRequestStatus({
  coverageProofReceivedAt,
  coverageProofRequestScheduledAt,
  coverageProofRequestedAt,
}) {
  const isScheduled = Boolean(coverageProofRequestScheduledAt);

  return (
    <Fragment>
      {isScheduled && (
        <Box mx={2}>
          <Text color={basic55}>Proof request scheduled</Text>
          <Box>{formatDateTime(new Date(coverageProofRequestScheduledAt))}</Box>
        </Box>
      )}
      <Box mx={2}>
        <Text color={basic55}>Proof requested</Text>
        <Box>
          {coverageProofRequestedAt
            ? formatDateTime(new Date(coverageProofRequestedAt))
            : '—'}
        </Box>
      </Box>
      {coverageProofReceivedAt && (
        <Box>
          <Text color={basic55}>Proof received</Text>
          <Box>{formatDateTime(new Date(coverageProofReceivedAt))}</Box>
        </Box>
      )}
    </Fragment>
  );
}

CoverageProofRequestStatus.propTypes = {
  coverageProofReceivedAt: PropTypes.string,
  coverageProofRequestScheduledAt: PropTypes.string,
  coverageProofRequestedAt: PropTypes.string,
};

export default CoverageProofRequestStatus;

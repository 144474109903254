import React from 'react';

import PropTypes from 'prop-types';

import {Box, Card, Flex, Table, Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

import {Empty} from './styled';

const RenovationDetails = ({empty, values}) => {
  return empty ? (
    <Flex
      width={1}
      height="100%"
      alignItems="center"
      flexDirection="column"
      justifyContent="center">
      <Empty />
      <Text color={basic55} fontSize={16} lineHeight="19px" mt={16}>
        Homeowner hasn’t completed this task yet
      </Text>
    </Flex>
  ) : (
    <Flex mt={32} pb={32} width={1} justifyContent="center">
      <Box css={{maxWidth: 860}}>
        <Card title="Post-reno details (subject-to)">
          <Table bordered condensed>
            <thead>
              <tr>
                <th>
                  <Text fontWeight="bold">Details</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {values.map(({label, value}) => (
                <tr key={label}>
                  <td>
                    <Flex width={1} alignItems="center">
                      <Text width={246} css={{flexShrink: 0}}>
                        {label}
                      </Text>
                      <Text>{value}</Text>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Box>
    </Flex>
  );
};

RenovationDetails.propTypes = {
  empty: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object),
};

export default RenovationDetails;

import React, {Fragment, useContext, useEffect} from 'react';

import {find, head, isEmpty, keys, last} from 'lodash';

import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../context';
import Appraisal from '../Appraisal';
import {useTaskByCurrentItemId, useViewerNavigation} from '../../hooks';
import {ItemView, DataTypeViewport} from '../Layout';
import Eligibility from '../Eligibility';

import {
  InsuranceReview,
  ItemsBar,
  ItemView as TaskItemView,
  StatusBar,
} from './components';
import {getItemKeyByTask} from './utils';
import useTabsByTaskType from './useTabsByTaskType';

const TaskType = () => {
  const {allGroupPaths, isLeftToRight} = useContext(ViewerV2Context);
  const {project, task} = useTaskByCurrentItemId();
  const {history, params, search} = useViewerNavigation();
  const {id: projectId, tasks = []} = project || {};
  const facet = task?.facet;
  const {itemId} = params;
  const itemKey = getItemKeyByTask(task);

  const isEligibility = facet === 'eligibility';
  const isInsurance = facet === 'insurance';
  const isAppraisal = facet === 'appraisal';

  const {currentTab, flattened, tabs} = useTabsByTaskType();

  const redirectToNextGroup = (forward = true) => {
    const {itemId} = params;
    const currentIndex = allGroupPaths.findIndex((p) => p.includes(itemId));
    const isLastIndex = currentIndex === allGroupPaths?.length - 1;
    const isFirstIndex = currentIndex === 0;

    switch (true) {
      case forward && isLastIndex:
        return history.push(allGroupPaths[0]);
      case !forward && isFirstIndex:
        return history.push(allGroupPaths[allGroupPaths.length - 1]);
      case forward:
        return history.push(allGroupPaths[currentIndex + 1]);
      case !forward:
        return history.push(allGroupPaths[currentIndex - 1]);

      default:
        return null;
    }
  };

  const onAcceptComplete = ({taskType}) => {
    const isAdditionalDocsTask = taskType === 'additional_documents';
    if (isAdditionalDocsTask) {
      onClickPrevNext();
    }
  };

  const onClickPrevNext = ({forward = true} = {}) => {
    const index = flattened.findIndex(({query}) => {
      return keys(query).every((key) => query[key] === search[key]);
    });
    const isFirstItem = index <= 0;
    const isLastItem = index === flattened.length - 1;
    let newItem, qString;

    switch (true) {
      case forward && isLastItem:
      case !forward && isFirstItem:
        return redirectToNextGroup(forward);
      case forward:
        newItem = flattened[index + 1];
        break;
      case !forward:
        newItem = flattened[index - 1];
        break;
      default:
        return null;
    }

    if (newItem?.query) {
      qString = queryString.stringify(newItem.query);
      history.push(`?${qString}`);
    }
  };

  useEffect(() => {
    // If there are Tasks, but :itemId doesn't match any
    // => goto the first available group
    const item = find(tasks, ['id', itemId]);
    if (Boolean(tasks.length && !item)) {
      return redirectToNextGroup();
    }

    // If no QueryString, fetch the first/last
    if (isEmpty(search) && !currentTab) {
      const firstItem = isLeftToRight ? head(flattened) : last(flattened);
      if (firstItem?.query) {
        const qString = queryString.stringify(firstItem?.query);
        history.replace(`?${qString}`);
      }
    }
  }, [
    JSON.stringify({currentTab, search, flattened, tasks}),
    isLeftToRight,
    itemId,
  ]);

  if (!project && !task) {
    return null;
  }

  return (
    <DataTypeViewport
      sidebar={
        <Fragment>
          {isInsurance && <InsuranceReview project={project} />}
          {isEligibility && <Eligibility project={project} />}
          {isAppraisal && <Appraisal projectId={projectId} />}
        </Fragment>
      }
      statusBar={<StatusBar projectId={projectId} task={task} />}>
      <ItemsBar
        currentTab={currentTab}
        flattened={flattened}
        onAcceptComplete={onAcceptComplete}
        tabs={tabs}
        task={task}
      />

      <ItemView itemKey={itemKey} onClickPrevNext={onClickPrevNext}>
        <TaskItemView task={task} />
      </ItemView>
    </DataTypeViewport>
  );
};

export default TaskType;

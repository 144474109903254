import {useApolloClient} from '@apollo/client';
import {mergeDeepRight, propOr} from 'ramda';

import {useQuery} from '@renofi/graphql/src/hooks';

import {GET_APPLICATION_STATE} from '../../queries';

const useSettings = () => {
  const client = useApolloClient();
  const {data} = useQuery(GET_APPLICATION_STATE);
  const application = propOr({}, 'application', data);
  const settings = propOr({}, 'settings', application);

  const updateSettings = (partial) => {
    const updated = mergeDeepRight(settings, partial);
    client.writeQuery({
      query: GET_APPLICATION_STATE,
      data: {
        application: {
          ...application,
          settings: updated,
        },
      },
    });
  };

  return {settings, updateSettings};
};

export default useSettings;

import styled from '@emotion/styled';

import omitProps from '@renofi/utilities/src/omitProps';
import {Link as RouterLink, Text} from '@renofi/components-internal';
import {
  basic80,
  dangerLight,
  successLight,
  warningRegular,
  white,
} from '@renofi/theme/src/colors';

const FLEX_STYLES = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  maxWidth: 160,
  height: 55,
  margin: '0 2px',
  padding: 8,
  color: white,
  fontSize: 12,
  lineHeight: '14px',
  justifyContent: 'space-between',
  border: '1px solid rgba(128, 207, 255, 0.3)',
  borderRadius: '4px 4px 0px 0px',
  userSelect: 'none',
};

export const Link = styled(omitProps(['active', 'dragging'])(RouterLink))(
  {
    ...FLEX_STYLES,
    textDecoration: 'none',
    ':hover': {
      color: '#FFFFFF',
      textDecoration: 'none',
    },
  },
  ({active, dragging, to}) => ({
    background: active ? basic80 : '#00A0FF',
    ...(active
      ? {
          borderColor: basic80,
        }
      : {}),
    // border: `1px solid ${active ? basic80 : 'rgba(128, 207, 255, 0.3)'}`,
    ...(!(active || dragging) && to
      ? {
          ':hover': {
            background: '#404040',
            border: '1px solid #404040',
          },
        }
      : {}),
    cursor: to ? 'pointer' : 'default',
  }),
);

export const DisabledLink = styled.span({
  ...FLEX_STYLES,
});

export const Label = styled(Text)({
  width: '100%',
  color: '#FFFFFF',
  fontSize: 16,
  lineHeight: '19px',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  userSelect: 'none',
});

export const Details = styled(Text)({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  userSelect: 'none',
});

export const Dot = styled.div(
  {
    flexShrink: 0,
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  ({status}) => ({
    background: dangerLight,
    ...(status === 'danger' ? {background: dangerLight} : {}),
    ...(status === 'processing' ? {background: warningRegular} : {}),
    ...(status === 'completed' ? {background: successLight} : {}),
  }),
);

const isEligibilityComplete = (questionnaire) => {
  const {
    hasLicense,
    licenseNumber,
    contractorLicense,
    noLicenseReasons,
    noLicenseReasonsOther,
    contractorGeneralLiabilityInsurance,
    hasWorkersCompInsurance,
    contractorWorkersCompInsurance,
    workersCompIncludedInGlid,
    noInsuranceReasons,
    noInsuranceReasonsOther,
  } = questionnaire;
  const isOtherSelected = noLicenseReasons?.includes('other');
  const isNoInsuranceOtherSelected = noInsuranceReasons?.includes('other');

  const isLicensePartValid =
    (hasLicense && contractorLicense !== null && licenseNumber) ||
    (hasLicense === false &&
      noLicenseReasons?.length > 0 &&
      (!isOtherSelected ||
        (isOtherSelected && noLicenseReasonsOther.length > 0)));

  const isGeneralLiabilityValid = contractorGeneralLiabilityInsurance !== null;

  const isCompInsuranceValid =
    questionnaire.hasW2Employees === false ||
    (hasWorkersCompInsurance && contractorWorkersCompInsurance !== null) ||
    (hasWorkersCompInsurance && workersCompIncludedInGlid) ||
    (hasWorkersCompInsurance === false &&
      noInsuranceReasons?.length > 0 &&
      (!isNoInsuranceOtherSelected ||
        (isNoInsuranceOtherSelected && noInsuranceReasonsOther?.length > 0)));

  return isLicensePartValid && isGeneralLiabilityValid && isCompInsuranceValid;
};

export default isEligibilityComplete;

import {filter, flatten, map, pipe, prop, propEq, propOr, uniqBy} from 'ramda';

import {getContractorTaskByProject} from '../task';

const getContractorsDocuments = (key) =>
  pipe(propOr([], 'contractorInformations'), map(propOr([], key)), flatten);

export default (project = {}, key = 'documents') => {
  if (key === 'archivedDocuments') {
    return pipe(
      propOr([], key),
      filter(propEq('source', 'contractor')),
    )(project);
  }

  const task = getContractorTaskByProject(project);
  const contractorsDocs = getContractorsDocuments(key)(task);
  const taskDocuments = propOr([], key, task);
  return uniqBy(prop('id'), [...contractorsDocs, ...taskDocuments]);
};

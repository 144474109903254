import {filter, groupBy, pipe, prop, propEq, propOr} from 'ramda';

import capitalize from '@renofi/utilities/src/capitalize';

import {ALL_FACETS} from './renovation/constants';

const getGroupedOptions = pipe(
  filter(propEq('canHaveDocument', true)),
  groupBy(prop('facet')),
);

/**
 * @param {Object} project
 * @param {Object[]}} project.tasks
 */
export default ({tasks = []} = {}) => {
  const grouped = getGroupedOptions(tasks);
  return ALL_FACETS.reduce((arr, facet) => {
    const group = propOr([], facet, grouped);
    return arr.concat({
      label: capitalize(facet),
      options: group.map(({id, title}) => ({label: title, value: id})),
    });
  }, []);
};

import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {SCORE_BUTTON_OPTIONS, useCanEditGcddReview} from 'lib/gcdd';
import {Container, StatusBadge} from 'modules/viewer-v2/components/Layout';
import Row from 'modules/viewer-v2/components/Row';
import Col from 'modules/viewer-v2/components/Col';

import useDebounce from '@renofi/utilities/src/useDebounce';
import {
  useSubmitWebReviewsScore,
  useWebReviews,
  useSubmitReviewNotes,
} from '@renofi/graphql';
import {
  ButtonGroup,
  Checkbox,
  Text,
  Textarea,
} from '@renofi/components-internal';

import WebReviewList from './WebReviewList';

function WebReviews({gcddReview, onGcddReviewUpdate}) {
  const {
    completedAt,
    id: gcddReviewId,
    webReviewsNotFound,
    webReviewsScore,
    webReviewsScoreNotes,
    licenseNotes,
    referencesNotes,
    insuranceNotes,
  } = gcddReview;
  const {canEdit} = useCanEditGcddReview(gcddReview);

  const [score, setScore] = useState(Boolean(webReviewsScore));
  const [reviewsNotFound, setReviewsNotFound] = useState(webReviewsNotFound);
  const [comment, setComment] = useState(webReviewsScoreNotes);
  const [reviewNotes, setReviewNotes] = useState(
    propOr('', 'webReviewsNotes', gcddReview),
  );
  const [showComments, setShowComments] = useState(Boolean(reviewNotes));

  const {submitWebReviewsScore, loading: isSubmitting} =
    useSubmitWebReviewsScore();
  const {
    fetch: fetchWebReviews,
    webReviews,
    loading: isWebReviewsLoading,
  } = useWebReviews({lazy: true});
  const {submitReviewNotes} = useSubmitReviewNotes();
  const hasWebReviews = Boolean(webReviews.length);
  const isScoreDisabled = !canEdit || isWebReviewsLoading || isSubmitting;
  const isCompleted =
    hasWebReviews && webReviews.every(({rating}) => Boolean(rating));
  const isCheckboxDisabled =
    Boolean(completedAt) ||
    isWebReviewsLoading ||
    isSubmitting ||
    hasWebReviews;

  useEffect(() => {
    fetchWebReviews({variables: {gcddReviewId}});
  }, [gcddReviewId]);

  useEffect(() => {
    setScore(webReviewsScore);
  }, [webReviewsScore]);

  const debouncedSubmitReviewNotes = useDebounce(async (variables) => {
    await submitReviewNotes({variables});
    onGcddReviewUpdate();
  }, 1000);

  const onChangeNotes = (value) => {
    setReviewNotes(value);
    debouncedSubmitReviewNotes({
      gcddReviewId,
      licenseNotes,
      insuranceNotes,
      referencesNotes,
      webReviewsNotes: value,
    });
  };

  const debouncedUpdateWebReview = useDebounce(async (variables) => {
    await submitWebReviewsScore({variables});
    onGcddReviewUpdate();
  }, 300);

  const onChangeNotFound = async (notFound) => {
    setReviewsNotFound(notFound);
    await submitWebReviewsScore({
      variables: {
        gcddReviewId,
        webReviewsNotFound: notFound,
        webReviewsScore: score,
        webReviewsScoreNotes: comment,
      },
    });
    if (!notFound) {
      fetchWebReviews();
    }
    onGcddReviewUpdate();
  };

  const onChangeScore = async (newScore) => {
    const isToggle = newScore === score;
    setScore(newScore);
    await submitWebReviewsScore({
      variables: {
        gcddReviewId,
        webReviewsNotFound: Boolean(reviewsNotFound),
        webReviewsScore: isToggle ? null : newScore,
        webReviewsScoreNotes: comment,
      },
    });
    onGcddReviewUpdate();
  };

  const onChangeComment = async (newComment) => {
    setComment(newComment);
    debouncedUpdateWebReview({
      gcddReviewId,
      webReviewsNotFound: Boolean(reviewsNotFound),
      webReviewsScore: score,
      webReviewsScoreNotes: newComment,
    });
  };

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Web reviews</Text>
          <StatusBadge variant={isCompleted ? 'success' : 'warning'}>
            {isCompleted ? 'Reviewed' : 'Pending review'}
          </StatusBadge>
        </Row>
      }
      collapsible={false}
      comment
      toggleCommentSection={() => setShowComments(!showComments)}>
      <Fragment>
        {showComments && (
          <Row>
            <Col width={1}>
              <Textarea
                label="Add a section comment"
                help="Comments are visible to the lender"
                rows={4}
                value={reviewNotes}
                onChange={onChangeNotes}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col width={1}>
            <ButtonGroup
              active
              disabled={isScoreDisabled}
              label="Score"
              onChange={onChangeScore}
              options={SCORE_BUTTON_OPTIONS}
              value={score}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              checked={reviewsNotFound}
              onChange={onChangeNotFound}
              label="No reviews found"
              disabled={isCheckboxDisabled}
            />
          </Col>
        </Row>
        {reviewsNotFound && (
          <Row css={{marginTop: 15}}>
            <Col width={1}>
              <Textarea
                label="Comment"
                name="comment"
                value={comment}
                onChange={onChangeComment}
                required
              />
            </Col>
          </Row>
        )}
        {!reviewsNotFound && !isWebReviewsLoading && (
          <WebReviewList
            reviews={webReviews}
            gcddReviewId={gcddReviewId}
            onGcddReviewUpdate={onGcddReviewUpdate}
          />
        )}
      </Fragment>
    </Container>
  );
}

WebReviews.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    webReviewsNotFound: PropTypes.bool,
    webReviewsScore: PropTypes.string,
    webReviewsScoreSubmittedAt: PropTypes.string,
    licenseNotes: PropTypes.string,
    referencesNotes: PropTypes.string,
    insuranceNotes: PropTypes.string,
    webReviewsScoreNotes: PropTypes.string,
  }),
  onGcddReviewUpdate: PropTypes.func.isRequired,
};

export default WebReviews;

import React from 'react';

import AppraisalWidget from 'modules/viewer-v2/components/Appraisal';

import {Loader} from '@renofi/components-internal';

import {useViewerNavigation} from '../../hooks';
import AppraisalStatusBar from '../AppraisalStatusBar';
import {ItemView, DataTypeViewport} from '../Layout';
import PdfDocumentView from '../PdfDocumentView';
import {useProjectDocuments} from '../../../../api';

import ItemsBar from './components/ItemsBar';

const AppraisalReportType = () => {
  const {search, params} = useViewerNavigation();
  const {documentId} = search || {};
  const {projectId} = params;
  const {project, loading} = useProjectDocuments({variables: {id: projectId}});

  const appraisalDocs = project?.documents?.filter(
    (doc) =>
      doc.documentType === 'appraisal' ||
      doc.documentType === 'completion_certificate',
  );
  const selectedDocument = appraisalDocs?.find((doc) => doc?.id === documentId);

  if (!project) {
    return null;
  } else if (loading) {
    return <Loader />;
  }

  return (
    <DataTypeViewport
      sidebar={<AppraisalWidget projectId={projectId} />}
      statusBar={<AppraisalStatusBar projectId={projectId} />}>
      <ItemsBar projectId={projectId} appraisalDocs={appraisalDocs} />
      <ItemView>
        <PdfDocumentView
          key={selectedDocument?.fileName}
          doc={selectedDocument}
        />
      </ItemView>
    </DataTypeViewport>
  );
};

export default AppraisalReportType;

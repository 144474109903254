import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {ASSIGN_FEASIBILITY_REVIEW} from '../../../mutations';

export default function useAssignFeasibilityReview({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [assignFeasibilityReview, {data, loading, error}] = useMutation(
    ASSIGN_FEASIBILITY_REVIEW,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = propOr(null, 'assignFeasibilityReview', data);
  return {assignFeasibilityReview, response, loading, error};
}

import React, {useContext, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';

import {Box, Flex} from '@renofi/components-internal';

import ViewerV2Context from '../../../../context';

import {Details, DisabledLink, Dot, Label, Link} from './styled';

function Tab({active, details, disabled, dragging, path, status, title}) {
  const {setActivePath, setIsLeftToRight, totalGroupPaths} =
    useContext(ViewerV2Context);
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();

  const enabled = Boolean(path) && !disabled;
  const Wrapper = enabled ? Link : DisabledLink;

  // Observer to check when/if an element appears in the viewport.
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  // Only scroll if Tab is active AND it isn't visible in the viewport
  useEffect(() => {
    if (active) {
      setActivePath(path);
    }
    if (ref && active && !isIntersecting) {
      setTimeout(() => ref.current.scrollIntoView({inline: 'end'}), 300);
    }
  }, [active, path, totalGroupPaths]);

  return (
    <span ref={ref}>
      <Wrapper
        active={active}
        disabled={disabled}
        dragging={dragging}
        onClick={() => setIsLeftToRight(true)}
        to={path}>
        <Flex alignItems="baseline" width={1}>
          <Label title={title}>{title}</Label>
          {status ? (
            <Box ml="8px">
              <Dot status={status} />
            </Box>
          ) : null}
        </Flex>
        <Details title={details}>{details}</Details>
      </Wrapper>
    </span>
  );
}

Tab.propTypes = {
  active: PropTypes.bool,
  details: PropTypes.node,
  disabled: PropTypes.bool,
  dragging: PropTypes.bool,
  path: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.node,
};

export default Tab;

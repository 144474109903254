import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {REJECT_DOCUMENTS} from '../mutations';
import {GET_PROJECT} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(REJECT_DOCUMENTS, {
      name: 'rejectDocuments',
    }),
    withHandlers({
      rejectDocuments:
        ({client, rejectDocuments}) =>
        async ({projectId, documents, message, delayedMessage}) => {
          const response = await rejectDocuments({
            variables: {
              documents,
              message,
              delayedMessage,
            },
          });
          const success = pathOr(
            null,
            ['data', 'rejectDocumentsWithMessage', 'success'],
            response,
          );
          if (success) {
            const result = await client.query({
              query: GET_PROJECT,
              variables: {id: projectId},
              fetchPolicy: 'no-cache',
            });
            const project = pathOr(null, ['data', 'projectById'])(result);
            client.writeQuery({
              query: GET_PROJECT,
              variables: {
                id: projectId,
              },
              data: {
                projectById: project,
              },
            });
          }
        },
    }),
  );

import * as React from 'react';

import PropTypes from 'prop-types';

const SvgCompany = ({color = '#CCC', ...props}) => {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.25v-3H.5v13.5h15V3.25H8zm-4.5 9H2v-1.5h1.5v1.5zm0-3H2v-1.5h1.5v1.5zm0-3H2v-1.5h1.5v1.5zm0-3H2v-1.5h1.5v1.5zm3 9H5v-1.5h1.5v1.5zm0-3H5v-1.5h1.5v1.5zm0-3H5v-1.5h1.5v1.5zm0-3H5v-1.5h1.5v1.5zm7.5 9H8v-1.5h1.5v-1.5H8v-1.5h1.5v-1.5H8v-1.5h6v7.5zm-1.5-6H11v1.5h1.5v-1.5zm0 3H11v1.5h1.5v-1.5z"
        fill={color}
      />
    </svg>
  );
};

SvgCompany.propTypes = {
  color: PropTypes.string,
};
export default SvgCompany;

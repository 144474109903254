import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {MARK_1003_PREP_AS_COMPLETED} from '../../mutations';

export default function useMark1003PrepAsCompleted({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [mark1003PrepAsCompleted, {data, loading, error}] = useMutation(
    MARK_1003_PREP_AS_COMPLETED,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['mark1003PrepAsCompleted'], data);
  return {mark1003PrepAsCompleted, response, loading, error};
}

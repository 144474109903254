import React from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme';

import {ButtonLinkStyled} from './styled';

const ButtonLink = (props) => {
  const {styles} = useTheme('button', {
    defaultVariant: 'primary',
    ...props,
  });

  return <ButtonLinkStyled {...props} css={styles} />;
};

ButtonLink.propTypes = {
  propName: PropTypes.string.isRequired,
};

export default ButtonLink;

const PROPERTY_CONTACT_DETAILS_FIELDS = [
  'contactFirstName',
  'contactLastName',
  'contactEmail',
  'contactHomePhone',
];

export const PROPERTY_ADDRESS_FIELDS = [
  'city',
  'county',
  'propertyType',
  'state',
  'streetAddressOne',
  'zipCode',
];

export const REQUIRED_APPRAISAL_ORDER_FIELDS = [
  'amcCode',
  'formType',
  ...PROPERTY_CONTACT_DETAILS_FIELDS,
];

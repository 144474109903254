import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

export const NUMBER_OF_QUESTIONNAIRE_STEPS = 6;

export const NO_BBB_GRADE = 'no_bbb_grade';

export const SCORES = {
  FAIL: 'fail',
  PASS: 'pass',
  CONSIDER: 'consider',
};

export const BBB_GRADES = {
  [NO_BBB_GRADE]: humanizeSnakeCase(NO_BBB_GRADE),
  a_plus: 'A+',
  a: 'A',
  a_minus: 'A-',
  b_plus: 'B+',
  b: 'B',
  b_minus: 'B-',
  c_plus: 'C+',
  c: 'C',
  c_minus: 'C-',
  d_plus: 'D+',
  d: 'D',
  d_minus: 'D-',
  f: 'F',
};

export const BUSINESS_TYPES = {
  c_corp: 'c_corp',
  llc: 'llc',
  other: 'other',
  partnership: 'partnership',
  s_corp: 's_corp',
  sole_proprieter: 'sole_proprieter',
};

export const QUESTIONNAIRE_STATUSES = {
  in_progress: 'in_progress',
  returned: 'returned',
  unopened: 'unopened',
};

export const SCORE_VARIANTS = {
  consider: 'warning',
  fail: 'dangerInverse',
  pass: 'successInverse',
};

export const SCORE_BUTTON_OPTIONS = [
  {label: 'Pass', value: SCORES.PASS},
  {label: 'Consider', value: SCORES.CONSIDER},
  {label: 'Fail', value: SCORES.FAIL},
];

export const MESSAGE_DELETE_DOCUMENT = 'delete_document';
export const MESSAGE_RETURN_DOCUMENT_REASON = 'return_document';
export const MESSAGE_REQUEST_MORE_INFO = 'request_more_information';
export const MESSAGE_REFERENCE_UNRESPONSIVE = 'reference_unresponsive';
export const MESSAGE_OTHER = 'other';

export const MESSAGE_REASONS = {
  [MESSAGE_DELETE_DOCUMENT]: 'Delete document',
  [MESSAGE_RETURN_DOCUMENT_REASON]: 'Return document to the contractor',
  [MESSAGE_REQUEST_MORE_INFO]: 'Request for more information',
  [MESSAGE_REFERENCE_UNRESPONSIVE]: 'Reference is unresponsive',
  [MESSAGE_OTHER]: 'Other',
};

export const DOC_RETURN_REASONS = {
  expired: 'Expired',
  illegible: 'Illegible',
  incomplete: 'Incomplete',
  incorrect: 'Incorrect',
  other: 'Other',
};

export const NO_INSURANCE_REASONS = [
  {
    value: 'my_employees_are_office_based',
    label: 'My employees are office based',
  },
  {
    value: 'other',
    label: 'Other reason',
  },
];

export const NO_LICENSE_REASONS = [
  {
    value: 'not_required_in_my_state',
    label: 'Not required in my state',
  },
  {
    value: 'not_required_for_line_of_work',
    label: 'Not required for my line of work',
  },
  {
    value: 'misplaced',
    label: 'I have misplaced it',
  },
  {
    value: 'expired',
    label: 'It has expired',
  },
  {
    value: 'other',
    label: 'Other reason',
  },
];

export const GCDD_TABS = {
  CONTRACTOR: 'contractor',
  QUESTIONNAIRE: 'questionnaire',
  LICENSES: 'licenses',
  INSURANCES: 'insurances',
  REFERENCES: 'references',
  REVIEWS: 'reviews',
  BBB: 'bbb',
};

export const REFERENCE_TYPES = {
  CLIENT: 'client',
  SUBCONTRACTOR: 'subcontractor',
};

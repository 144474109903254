import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REQUEST_FEASIBILITY_REVIEW} from '../../../mutations';
import {GET_FEASIBILITY_REVIEWS} from '../../../queries';

export default function useRequestFeasibilityReview({
  onCompleted = noop,
  refetchQueries = [GET_FEASIBILITY_REVIEWS],
} = {}) {
  const [requestFeasibilityReview, {abort, data, loading, error}] = useMutation(
    REQUEST_FEASIBILITY_REVIEW,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = propOr(null, 'requestFeasibilityReview', data);
  return {requestFeasibilityReview, response, loading, error, abort};
}

import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../../useToggledQuery';
import {GET_FEASIBILITY_REVIEW} from '../../../queries';

export default function useFeasibilityReviewById({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = true,
  ...options
} = {}) {
  const {data, error, loading, fetch} = useToggledQuery({
    query: GET_FEASIBILITY_REVIEW,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const feasibilityReview = propOr(null, 'feasibilityReviewById', data);
  return {feasibilityReview, loading, error, fetch};
}

import logger from '../../logger';
import noop from '../../noop';
import {unsubscribe} from '../pusher';

import useChannel from './useChannel';

export default function useProjectAssociatedWithOpportunity(
  projectId,
  onUpdate = noop,
) {
  const channelName =
    projectId && `project_associated_with_opportunity--project=${projectId}`;

  const onProjectAssociatedWithOpportunity = async (event) => {
    logger.debug('PROJECT_ASSOCIATED_WITH_OPPORTUNITY', event);

    onUpdate(event);
    unsubscribe(channelName);
  };

  useChannel({
    channelName,
    onUpdate: onProjectAssociatedWithOpportunity,
  });

  return {};
}

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {UPDATE_INSURANCE} from '../../../mutations';

export default function useUpdateInsurance({onCompleted = noop} = {}) {
  const [updateInsurance, {abort, data, loading, error}] = useMutation(
    UPDATE_INSURANCE,
    {onCompleted},
  );
  const insurance = pathOr(null, ['updateInsurance', 'insurance'], data);

  return {abort, updateInsurance, insurance, loading, error};
}

import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {
  actionLight,
  actionRegular,
  actionDark20,
} from '@renofi/theme/src/colors';

export const Actions = styled(Flex)({
  backgroundColor: actionRegular,
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'absolute',
  right: 0,
  top: 0,
  width: 30,
  height: '100%',
  userSelect: 'none',
});

export const Action = styled(Flex)(
  {
    width: 28,
    height: 28,
    backgroundColor: actionDark20,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ({disabled, flipped}) => ({
    transform: `rotate(${flipped ? 90 : -90}deg)`,
    ...(disabled ? {'& > *': {opacity: 0.3}} : {}),
  }),
);

export const Comment = styled(Flex)({
  border: `2px solid ${actionLight}`,
  borderRadius: 4,
  width: 48,
  height: 48,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const NavBarWrapper = styled(Flex)({
  // minWidth: '100%',
  height: 90,
  background: actionRegular,
  paddingRight: 36,
  position: 'relative',
  alignItems: 'flex-end',
});

export const ScrollBox = styled.div({
  display: 'flex',
  width: 'calc(100% - 36px)',
  alignItems: 'flex-end',
  height: 90,
  overflow: 'hidden',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const Wrapper = styled.div({
  width: '100%',
  position: 'relative',
  background: actionRegular,
});

import {isNil} from 'ramda';

import formatMoney from '@renofi/utilities/src/formatMoney';

const calculateDifference = (estimatedValue, actualValue) => {
  if (isNil(estimatedValue) || isNil(actualValue)) {
    return '';
  }

  const diff = actualValue - estimatedValue;
  const formatted = formatMoney(diff, {fractional: false});
  const sign = diff > 0 ? '+' : '';

  return `(${sign}${formatted})`;
};

const getFormattedValue = (value) => {
  if (isNil(value)) {
    return '';
  }

  return formatMoney(value, {fractional: false});
};

export const getInitialNoteBody = (project = {}) => {
  const {
    projectAssociate = {},
    renofiAdvisor,
    statedCurrentHomeValue,
    estimatedRenovationHomeValue,
    actualCurrentHomeValue,
    actualRenovationHomeValue,
  } = project || {};

  const hasAdvisorUserId = renofiAdvisor?.userId;
  const hasAssociateUserId = projectAssociate?.userId;

  const renofiAdvisorName = hasAdvisorUserId
    ? `{{${renofiAdvisor.userId}|${renofiAdvisor.name}}}`
    : '';
  const projectAssociateName = hasAssociateUserId
    ? `{{${projectAssociate.userId}|${projectAssociate?.name}}}`
    : '';

  const estimatedAsIsValue = getFormattedValue(statedCurrentHomeValue);
  const estimatedARVValue = getFormattedValue(estimatedRenovationHomeValue);
  const actualAsIsValue = getFormattedValue(actualCurrentHomeValue);
  const actualARVValue = getFormattedValue(actualRenovationHomeValue);

  const asIsValueDiff = calculateDifference(
    statedCurrentHomeValue,
    actualCurrentHomeValue,
  );
  const arvValueDiff = calculateDifference(
    estimatedRenovationHomeValue,
    actualRenovationHomeValue,
  );

  const names = [renofiAdvisorName, projectAssociateName]
    .filter((val) => val)
    .join(', ');

  return `${names}

Estimated values
As-is value: ${estimatedAsIsValue}
ARV value: ${estimatedARVValue}

Actual values
As-is value: ${actualAsIsValue} ${asIsValueDiff}
ARV value: ${actualARVValue} ${arvValueDiff}

${renofiAdvisorName} Please reach out to the borrower to discuss the below expected 
appraised value. The appraisal is available in the Secure portal under 
the Appraisal task.`;
};

import styled from '@emotion/styled';

import {Flex, Box, Text} from '@renofi/components-internal';
import {
  actionLight,
  basic10,
  basic15,
  basic55,
  basic80,
  dangerLight,
  white,
} from '@renofi/theme';

export const ItemContainer = styled(Flex)({
  width: 600,
  marginTop: 32,
  background: white,
  border: `1px solid ${basic10}`,
  borderRadius: 4,
  color: basic80,
});

export const Header = styled(Box)({
  borderBottom: `1px solid ${basic15}`,
  padding: 16,
});

export const ItemCard = styled(Box)(
  {
    padding: 32,
    marginBottom: 8,
  },
  ({css}) => ({
    ...css,
  }),
);

export const ItemTitle = styled(Text)({
  marginBottom: 8,
  fontSize: 18,
  fontWeight: 'bold',
  marginLeft: 16,
});

export const ItemRow = styled(Flex)({
  padding: 16,
  borderTop: `1px solid ${basic15}`,
  borderLeft: `1px solid ${basic15}`,
  borderRight: `1px solid ${basic15}`,
  justifyContent: 'space-between',
  gap: 10,

  ':first-of-type': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  ':last-of-type': {
    borderBottom: `1px solid ${basic15}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
});

export const ItemName = styled(Box)({
  maxWidth: '60%',
  fontSize: 14,
});

export const ItemDetail = styled(Text)(
  {
    color: basic55,
    fontSize: 14,
    textTransform: 'capitalize',
  },
  ({css}) => ({
    ...css,
  }),
);

export const NoDataContainer = styled(Flex)({
  marginTop: 228,
  fontSize: 18,
  color: '#737373',
});

export const StatusBarItemLabel = styled(Text)(({danger}) => ({
  color: danger ? dangerLight : actionLight,
  lineHeight: '14px',
  fontSize: 12,
  paddingBottom: 2,
}));

export const StatusBarItemValue = styled(Text)({
  fontSize: 14,
  lineHeight: '17px',
});

export const StatusBarItemContainer = styled(Flex)({
  flexDirection: 'column',
  textAlign: 'left',
  marginRight: 40,
});

export const StatusBarWrapper = styled(Flex)({
  height: 60,
  background: basic80,
  boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
  alignItems: 'center',
  padding: '0 16px',
  justifyContent: 'space-between',
});

import {propOr} from 'ramda';

import {useGetUser} from '@renofi/graphql/src/hooks';
import {canEditGcdd, canViewGcdd} from '@renofi/utilities/src/permissions';

export default function useHasGcddPermissions() {
  const {user} = useGetUser();
  const permissions = propOr([], 'permissions', user);
  const hasPermission = canViewGcdd(user) || canEditGcdd(user);

  return {hasPermission, permissions};
}

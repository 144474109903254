import styled from '@emotion/styled';

import {basic75, white} from '@renofi/theme';
import {Box, Flex} from '@renofi/components-internal';

export const Wrapper = styled(Flex)({
  height: 60,
  width: '100%',
  borderBottom: `solid 1px ${basic75}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  color: white,
  fontSize: 16,
  lineHeight: '19px',
  padding: '0 16px',
});

export const Content = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

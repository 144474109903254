import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {TRANSMIT_RENOVATION_READY_FILE} from '../../../mutations';

export default function useTransmitRenovationReadyFile(
  onCompleted = noop,
  refetchQueries = [],
) {
  const [transmitRenovationReadyFile, {data, loading, error}] = useMutation(
    TRANSMIT_RENOVATION_READY_FILE,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = propOr(null, 'transmitRenovationReadyFile', data);
  return {transmitRenovationReadyFile, response, loading, error};
}

import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';

export const InfoText = styled(Text)({
  marginTop: 6,
  marginBottom: 24,
  padding: 12,
  backgroundColor: '#40404065',
  borderRadius: 4,
  fontSize: 16,
  height: 45,
  color: '#73737370',
});

export const LabelText = styled(Text)({
  fontSize: 15,
  color: '#73737370',
});

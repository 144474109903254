import {propOr} from 'ramda';
import {
  COMPANY_DETAILS_PATHS,
  CONTACT_DETAILS_PATHS,
  hasAllValues,
  isEligibilityComplete,
  isExperienceComplete,
  isReferencesComplete,
  isSignatureComplete,
} from 'lib/gcdd/validators';

export default function getGcddReviewQuestionnaireStatus(gcddReview = {}) {
  const questionnaire = propOr(null, 'questionnaire', gcddReview);
  const isCompleted =
    hasAllValues(CONTACT_DETAILS_PATHS, gcddReview) &&
    hasAllValues(COMPANY_DETAILS_PATHS, gcddReview) &&
    isEligibilityComplete(questionnaire) &&
    isExperienceComplete(questionnaire) &&
    isReferencesComplete(questionnaire) &&
    isSignatureComplete(questionnaire);

  return isCompleted ? 'completed' : 'processing';
}

import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {isNil, join, reject} from 'ramda';

import {Flex} from '@renofi/components-internal';
import RenofiIcon from '@renofi/icons/src/Renofi';
import {dangerRegular, actionRegular} from '@renofi/theme';
import ExternalIcon from '@renofi/icons/src/External';
import {ReconfContext} from '@renofi/utilities/src/reconf';

import {Address, External, Content, Details, Wrapper} from './styled';

function BorrowerDetails({project}) {
  const {salesforceOpportunityId} = project;
  const name = project?.primaryApplicant?.fullName;
  const {config} = useContext(ReconfContext) || {};
  const {baseSalesforceUrl} = config || {};

  const addressParts = [
    project.streetAddressOne,
    project.streetAddressTwo,
    project.city,
    project.county,
    project.state,
    project.zipCode,
  ];
  const address = join(', ', reject(isNil, addressParts));
  const fullDetails = join(', ', [name, address]);

  return (
    <Wrapper p={3}>
      <RenofiIcon color={dangerRegular} />
      <Content data-testid="borrowerDetails" ml={2} title={fullDetails}>
        <Flex alignItems="flex-end">
          <Details>{name}</Details>
          {salesforceOpportunityId && (
            <External
              objectType="Opportunity"
              objectId={salesforceOpportunityId}
              salesforceBaseUrl={baseSalesforceUrl}>
              Open Salesforce opportunity
              <ExternalIcon
                css={{height: 16, width: 16, verticalAlign: -2, marginLeft: 8}}
                color={actionRegular}
              />
            </External>
          )}
        </Flex>
        <Address>{address}</Address>
      </Content>
    </Wrapper>
  );
}

BorrowerDetails.propTypes = {
  project: PropTypes.object,
};

export default BorrowerDetails;

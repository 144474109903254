import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';

import house from './house.svg';

export const Empty = styled(Box)({
  width: 240,
  height: 140,
  backgroundImage: `url('${house}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
});

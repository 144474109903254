import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {useViewerLayout} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {Complete as Wrapper, Tooltip, ValidationList} from 'modules/viewer-v2';

import {basic20, basic80, white} from '@renofi/theme/src/colors';
import {
  Box,
  Button,
  Checkbox,
  MouseHover,
  Text,
} from '@renofi/components-internal';

import Uncompleted from '../Uncompleted';

const Complete = ({loading, validation, feasibilityReview}) => {
  const {layout} = useViewerLayout();
  const wide = isSplitWideLayout(layout);

  const [force, setForce] = useState(false);
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);

  const {contractReview, feasibilityStudy, costReview} = validation;
  const disabled =
    !isEmpty(contractReview) ||
    !isEmpty(feasibilityStudy) ||
    !isEmpty(costReview);

  return (
    <>
      <Wrapper>
        <Text
          fontSize={12}
          lineHeight="14px"
          mb={wide ? 0 : 16}
          textAlign={wide ? 'left' : 'center'}>
          Review all documents/info before returning to RenoFi
        </Text>
        <MouseHover
          type="div"
          hideDelay={500}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => {}}>
          <>
            {disabled && hover && (
              <Tooltip
                width={wide ? 330 : 280}
                css={{right: wide ? 100 : 10}}
                tailPosition="right"
                onClose={() => setHover(false)}>
                <Text fontSize={20} mb={12}>
                  Checklist
                </Text>
                <Text mb={16} color={basic20} fontSize={14} lineHeight="17px">
                  The items listed below are required before returning to RenoFi
                  as completed.
                </Text>

                <ValidationList
                  feasibilityReview={feasibilityReview}
                  validation={validation}
                />

                <Box>
                  <Checkbox
                    checked={force}
                    css={{
                      borderRadius: 4,
                      padding: '12px 16px',
                      backgroundColor: basic80,
                    }}
                    color={white}
                    fontSize={14}
                    lineHeight="17px"
                    label={
                      <Text ml="8px">
                        I’m unable to complete the analysis with the documents
                        provided
                      </Text>
                    }
                    onChange={() => setForce(!force)}
                  />
                </Box>
              </Tooltip>
            )}
            <Button
              variant="danger"
              loading={loading}
              disabled={disabled && !force}
              onClick={() => setModal(true)}>
              {disabled ? 'Return to RenoFi uncompleted' : 'Return to RenoFi'}
            </Button>
          </>
        </MouseHover>
      </Wrapper>
      {modal && (
        <Uncompleted complete={!disabled} onClose={() => setModal(false)} />
      )}
    </>
  );
};

Complete.propTypes = {
  loading: PropTypes.bool,
  feasibilityReview: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
};

export default Complete;

import {
  withReviewerFeasibilityStudyItems,
  withReviewerGroupedFeasibilityStudyItems,
  withReviewerFeasibilityReview,
} from 'modules/feasibility-reviewer';
import {FeasibilityStudy} from 'modules/viewer-v2';

import {compose} from '@renofi/recompose/src';

export default compose(
  withReviewerFeasibilityReview(),
  withReviewerFeasibilityStudyItems(),
  withReviewerGroupedFeasibilityStudyItems(),
)(FeasibilityStudy);

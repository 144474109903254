import withFeasibilityReview from 'lib/withFeasibilityReview';
import {withValidation} from 'modules/feasibility/hoc';
import {withGroupedFeasibilityStudyItems} from 'modules/feasibility/viewer';

import {compose} from '@renofi/recompose/src';

import Component from './Component';

//withGroupedFeasibilityStudyItems is required for withValidation HOC

export default compose(
  withFeasibilityReview(),
  withGroupedFeasibilityStudyItems(),
  withValidation(),
)(Component);

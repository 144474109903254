import {filter, keys, isNil, map, pick, pipe, propOr} from 'ramda';
import {withCurrentSource} from 'api/hoc';
import {
  validateStudyItems,
  validateCostEstimate,
} from 'lib/validateFeasibilityStudy';
import {isFeasibilityReviewerSource} from 'lib/sourceHelper';

import {compose, withProps} from '@renofi/recompose/src';

const contractReviewMap = {
  contactInfoApproved: 'Contact info for contractor and homeowner',
  contractTimelineApproved: 'Timelines & duration',
  termsOfPaymentApproved: 'Terms of payment',
  clearDescriptionOfWorkApproved: 'Clear description of work',
  changeOrderProcedureApproved: 'Change order procedure',
  clearGuaranteeApproved: 'Clear Guarantee',
};

export default () =>
  compose(
    withCurrentSource(),
    withProps(
      pipe(
        propOr({}, 'feasibilityReview'),
        pick([
          'feasibilityStudyItems',
          'homeownerResponsibilityCompleted',
          'outOfScopeCompleted',
        ]),
      ),
    ),
    withProps(
      ({
        feasibilityReview,
        homeownerResponsibilityCompleted,
        groupedStudyItems,
        outOfScopeCompleted,
        currentSource,
      }) => {
        const isReviewer = isFeasibilityReviewerSource(currentSource);
        const feasibilityStudy = [];
        const {inScopeStudyItems, homeownerStudyItems, outOfScopeStudyItems} =
          groupedStudyItems;
        const isInScopeValid = validateStudyItems({
          items: inScopeStudyItems,
          isReviewer,
          isInScope: true,
        });

        if (!isInScopeValid) {
          feasibilityStudy.push('In scope');
        }

        const isHomeownerStudyItemsValid = validateStudyItems({
          items: homeownerStudyItems,
          isReviewer,
          isInScope: false,
        });

        if (
          (isReviewer && !homeownerResponsibilityCompleted) ||
          !isHomeownerStudyItemsValid
        ) {
          feasibilityStudy.push('In scope but homeowners responsibility');
        }

        const isOutOfScopeStudyItemsValid = validateStudyItems({
          items: outOfScopeStudyItems,
          isReviewer,
          isInScope: false,
        });

        if (
          (isReviewer && !outOfScopeCompleted) ||
          !isOutOfScopeStudyItemsValid
        ) {
          feasibilityStudy.push('Out of scope');
        }

        const contractReview = pipe(
          keys,
          filter((key) => isNil(feasibilityReview[key])),
          map((key) => contractReviewMap[key]),
        )(contractReviewMap);

        const hasEmptyPayments = feasibilityReview?.termsOfPayments?.find(
          ({amount}) => isNil(amount),
        );
        if (hasEmptyPayments) {
          contractReview.push('Incomplete terms of payment');
        }

        const costReview = [];
        if (!validateCostEstimate(feasibilityReview)) {
          costReview.push('Detailed cost estimate supplied');
        }

        return {
          validation: {contractReview, feasibilityStudy, costReview},
        };
      },
    ),
  );

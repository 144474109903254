import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_BBB_GRADE} from '../../../mutations';

export default function useSubmitBbbGrade({onCompleted = noop} = {}) {
  const [submitBbbGrade, {data, loading, error}] = useMutation(
    SUBMIT_BBB_GRADE,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['submitBbbGrade', 'contractor'], data);

  return {submitBbbGrade, response, loading, error};
}

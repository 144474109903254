import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {CHANGE_TASK_STATUS} from '../../../mutations';
import {GET_PROJECT} from '../../../queries';
import useMutation from '../../useMutation';

export default function useChangeTaskStatus({
  awaitRefetchQueries = true,
  onCompleted = noop,
  refetchQueries = [GET_PROJECT],
} = {}) {
  const [changeTaskStatus, {data, loading, error}] = useMutation(
    CHANGE_TASK_STATUS,
    {
      onCompleted,
      refetchQueries,
      awaitRefetchQueries,
    },
  );

  const response = pathOr(null, ['changeTaskStatus'], data);
  return {changeTaskStatus, response, loading, error};
}

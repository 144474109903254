import React, {Fragment} from 'react';

import {Container} from 'modules/viewer-v2/components/Layout';
import Row from 'modules/viewer-v2/components/Row';
import Col from 'modules/viewer-v2/components/Col';

import {Box, Text} from '@renofi/components-internal';
import InfoIcon from '@renofi/icons/src/Clock';
import {basic55} from '@renofi/theme/src/colors';

function GcddReviewHistory() {
  return (
    <Container
      header={
        <Row alignItems="center">
          <Box height={24}>
            <InfoIcon />
          </Box>
          <Text ml={12}>CDD History</Text>
        </Row>
      }>
      <Fragment>
        <Row>
          <Col>
            <Text color={basic55}>Last DD</Text>
            <Box mt={1}>---</Box>
          </Col>
          <Col>
            <Text color={basic55}>Last full DD</Text>
            <Box mt={1}>---</Box>
          </Col>
        </Row>
        <Row mt={4}>
          <Col>
            <Text color={basic55}># of RenoFi projects</Text>
            <Box mt={1}>---</Box>
          </Col>
          <Col>
            <Text color={basic55}>Average RenoFi project size</Text>
            <Box mt={1}>---</Box>
          </Col>
        </Row>
      </Fragment>
    </Container>
  );
}

export default GcddReviewHistory;

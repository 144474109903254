import {pipe, dissoc, evolve, map, pick, isNil} from 'ramda';

export const prepareFeasibilityReviewForSubmit = (
  feasibilityReviewState,
  partial,
) => {
  const feasibilityReview = {...feasibilityReviewState, ...partial};

  const result = pipe(
    dissoc('id'),
    dissoc('__typename'),
    dissoc('feasibilityStudyItems'),
    evolve({
      termsOfPayments: map(pick(['amount', 'comment', 'specialMaterialCosts'])),
    }),
  )(feasibilityReview);

  return result;
};

export const removeEmptyPayments = (payments) => {
  return {
    ...payments,
    termsOfPayments: payments.termsOfPayments.filter(
      ({amount}) => !isNil(amount),
    ),
  };
};

export const FEASIBILITY_STUDY_ITEM_RATINGS = {
  REASONABLE: 'reasonable',
  OVERSTATED: 'overstated',
  UNDERSTATED: 'understated',
};

export const FEASIBILITY_STUDY_ITEM_RATINGS_OPTIONS = {
  [FEASIBILITY_STUDY_ITEM_RATINGS.REASONABLE]: 'Reasonable',
  [FEASIBILITY_STUDY_ITEM_RATINGS.OVERSTATED]: 'Overstated',
  [FEASIBILITY_STUDY_ITEM_RATINGS.UNDERSTATED]: 'Understated',
};

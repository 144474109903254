import {NUMBER_OF_QUESTIONNAIRE_STEPS, QUESTIONNAIRE_STATUSES} from 'lib/gcdd';

import {diffDates} from '@renofi/utilities/src/dates';
import formatRelativeTime from '@renofi/utilities/src/formatRelativeTime';

export default function getQuestionnaireStatusDescription({
  firstOpenedAt,
  returnedAt,
  submittedStep,
}) {
  switch (true) {
    case Boolean(returnedAt):
      return {
        description: `Returned ${formatRelativeTime(
          diffDates(new Date(returnedAt)) * -1,
        )}`,
        status: QUESTIONNAIRE_STATUSES.returned,
      };
    case submittedStep >= 1 && submittedStep < NUMBER_OF_QUESTIONNAIRE_STEPS:
      return {
        description: `In process (${submittedStep} of ${NUMBER_OF_QUESTIONNAIRE_STEPS} steps completed)`,
        status: QUESTIONNAIRE_STATUSES.in_progress,
      };
    case firstOpenedAt:
    default:
      return {
        description: 'Unopened',
        status: QUESTIONNAIRE_STATUSES.unopened,
      };
  }
}

import {isNil} from 'ramda';

export function hideEmptyFeasibilityItems(items) {
  return items.filter(
    ({editable, cost, descriptionOfWork}) =>
      editable || !isNil(cost) || !isNil(descriptionOfWork),
  );
}

const TOTAL_COST_RATE_LIMIT = 0.2;

export const calculateTotalRating = (feasibilityStudyItems) => {
  const totalCost = feasibilityStudyItems.reduce(
    (total, {cost = 0}) => total + cost,
    0,
  );
  const totalCalculatedCost = feasibilityStudyItems.reduce(
    (total, {adjustedCost, cost = 0}) => total + (adjustedCost ?? cost),
    0,
  );
  const totalCostRate = totalCalculatedCost / totalCost;

  if (1 - totalCostRate > TOTAL_COST_RATE_LIMIT) {
    return 'overstated';
  }

  if (totalCostRate - 1 > TOTAL_COST_RATE_LIMIT) {
    return 'understated';
  }

  return 'reasonable';
};

import React, {useContext, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {find, isEmpty, omit, pathOr, propEq} from 'ramda';
import {AddGcddReference} from 'modules/modals';
import ViewerContext from 'modules/viewer-v2/context';

import {DateFormat, Flex, Link, Modal, Text} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {isIsoDate} from '@renofi/utilities/src/dates';
import {
  GET_PROJECT_TASKS,
  GET_REFERENCES,
  useDeleteReference,
  useUpdateReference,
  useReferenceResponses,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {validateUuidProp} from '@renofi/utilities/src/react';

import {
  Header,
  ItemDetail,
  ItemName,
  ItemRow,
  NoDataContainer,
  ItemContainer,
} from '../../../../../styled';

import ModalFooter from './ModalFooter/Component';
import {OMIT_PROPS} from './utils';
import {ItemTitle, ItemCard} from './styled';

function Reference({gcddReview, referenceId}) {
  const allReferences = pathOr([], ['questionnaire', 'references'], gcddReview);
  const reference = find(propEq('id', referenceId), allReferences);
  const noData = !reference || isEmpty(reference);
  const questionnaireId = pathOr(null, ['questionnaire', 'id'], gcddReview);

  const humanisedType = humanizeSnakeCase(reference?.type);
  const {onGcddReviewUpdate} = useContext(ViewerContext);

  const {fetch: fetchResponses, referenceResponses} = useReferenceResponses({
    lazy: true,
  });

  const currentResponse = referenceResponses.find(
    (response) => response.referenceId === reference.id,
  );

  const {addNotification} = useNotifications();
  const isManualEntry = reference?.manualEntryDetails !== null;
  const contractorId = gcddReview?.contractor?.id;

  const [open, setOpen] = useState(false);
  const [remove, setRemove] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const {loading: isUpdating, updateReference} = useUpdateReference();

  const isSaveButtonDisabled = isUpdating || !isValid || !isFormDirty;

  const {loading: isRemoving, deleteReference} = useDeleteReference({
    refetchQueries: [
      {
        query: GET_REFERENCES,
        variables: {
          contractorId,
          page: 1,
          limit: 10,
          questionnaireId,
        },
      },
      GET_PROJECT_TASKS,
    ],
  });

  useEffect(() => {
    if (questionnaireId) {
      fetchResponses({variables: {questionnaireId}});
    }
  }, [questionnaireId]);

  const handleOnViewRecord = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setRemove(false);
    setUpdatedData(null);
    setIsValid(true);
    setIsFormDirty(false);
  };

  const handleRemoveReference = () => {
    setRemove(true);
  };

  const handleReferenceOnChange = ({attributes: data, isValid}) => {
    setUpdatedData(data);
    setIsValid(isValid);
    setIsFormDirty(true);
  };

  const handleRemoveConfirmReference = async () => {
    try {
      await deleteReference({
        variables: {
          id: reference?.id,
        },
      });
      onGcddReviewUpdate();
      addNotification({
        variant: 'success',
        content: 'Reference successfully deleted',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Reference deletion failed',
      });
    }

    handleCloseModal();
  };

  const handleReferenceUpdate = async () => {
    try {
      await updateReference({
        variables: {
          attributes: omit(OMIT_PROPS, updatedData),
        },
      });
      onGcddReviewUpdate();
      addNotification({
        variant: 'success',
        content: 'Reference successfully updated',
      });
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Reference Update failed',
      });
    }

    handleCloseModal();
  };

  if (noData) {
    return <NoDataContainer>No reference received</NoDataContainer>;
  }

  return (
    <>
      <ItemContainer flexDirection="column">
        <Header>
          <Flex justifyContent="space-between">
            <Text fontSize={18}>{humanisedType} reference</Text>
            <Link onClick={handleOnViewRecord}>Edit</Link>
          </Flex>
        </Header>

        <Flex flexDirection="column">
          <ItemCard>
            <ItemTitle>Contact information</ItemTitle>
            <ItemRow>
              <ItemName>Name</ItemName>
              <ItemDetail>{reference?.name}</ItemDetail>
            </ItemRow>
            <ItemRow>
              <ItemName>Phone</ItemName>
              <ItemDetail>{reference?.phoneNumber}</ItemDetail>
            </ItemRow>
            <ItemRow>
              <ItemName>Email</ItemName>
              <ItemDetail>{reference?.email}</ItemDetail>
            </ItemRow>
          </ItemCard>

          {reference?.manualEntryDetails && (
            <ItemCard>
              <ItemTitle>Details</ItemTitle>
              <ItemRow>
                <ItemDetail css={{whiteSpace: 'pre-wrap'}}>
                  {reference?.manualEntryDetails}
                </ItemDetail>
              </ItemRow>
            </ItemCard>
          )}

          {currentResponse && (
            <ItemCard>
              <ItemTitle>Questionnaire</ItemTitle>
              {currentResponse?.responses?.map(({answer, questionParsed}) => {
                const isDate = isIsoDate(answer);

                return (
                  <ItemRow key={questionParsed}>
                    <ItemName>{questionParsed}</ItemName>
                    <ItemDetail css={{textAlign: 'right'}}>
                      {isDate ? <DateFormat value={answer} /> : answer}
                    </ItemDetail>
                  </ItemRow>
                );
              })}
            </ItemCard>
          )}
        </Flex>
      </ItemContainer>

      <Modal
        lock
        fixed
        p={24}
        width={600}
        css={{minHeight: 400}}
        show={open}
        footer={
          <ModalFooter
            onRemove={handleRemoveReference}
            onRemoveConfirm={handleRemoveConfirmReference}
            onClose={handleCloseModal}
            remove={remove}
            disabled={isRemoving || isUpdating}
            isRemoving={isRemoving}
            isUpdating={isUpdating}
            isSaveButtonVisible={isManualEntry}
            onUpdate={handleReferenceUpdate}
            isSaveButtonDisabled={isSaveButtonDisabled}
          />
        }
        header="Add reference"
        onClose={handleCloseModal}>
        <AddGcddReference
          initialReferenceValues={reference}
          action="update"
          onChange={handleReferenceOnChange}
        />
      </Modal>
    </>
  );
}

Reference.propTypes = {
  referenceId: validateUuidProp,
  gcddReview: PropTypes.shape({
    id: validateUuidProp,
    contractor: PropTypes.shape({
      id: validateUuidProp,
    }),
    questionnaire: PropTypes.shape({
      id: validateUuidProp,
      references: PropTypes.arrayOf(
        PropTypes.shape({
          id: validateUuidProp,
        }),
      ),
    }),
  }),
};

export default Reference;

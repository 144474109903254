import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {actionDark, actionRegular, basic55, basic75} from '@renofi/theme';

export const Badge = styled(Flex)(
  {
    padding: '8px 10px',
    alignItems: 'center',
    marginRight: 8,
    background: basic75,
    height: 32,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 16,
    fontSize: 12,
    lineHeight: '14px',
  },
  ({active, disabled}) => {
    return {
      background: active && !disabled ? actionDark : basic75,
      borderColor: active && !disabled ? actionRegular : 'transparent',
      opacity: disabled ? 0.5 : 1,
      '&:hover': {
        background: !disabled ? basic55 : 'inherit',
        cursor: disabled ? 'auto' : 'pointer',
        userSelect: disabled ? 'none' : 'auto',
      },
    };
  },
);

import {pathOr} from 'ramda';

import {compose} from '@renofi/recompose/src';
import {withQuery} from '@renofi/graphql/src/hoc';

import {GET_APPLICATION_STATE} from '../queries';

export default () =>
  compose(
    withQuery({
      query: GET_APPLICATION_STATE,
      props: ({data, ...props}) => {
        return {
          currentSource: pathOr(null, ['application', 'currentSource'])(data),
          ...props,
        };
      },
    }),
  );

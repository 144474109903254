import React from 'react';

import PropTypes from 'prop-types';

import {Link} from '@renofi/components-internal';

import googleReviews from './img/google-reviews.svg';
import yelp from './img/yelp.svg';
import buildZoom from './img/build-zoom.svg';
import angiesList from './img/angies-list.svg';
import houzz from './img/houzz.svg';
import {Container, Item} from './styled';
import {getWebsiteLinks} from './utils';

function SitesReview({contractor}) {
  const {googleHref, yelpHref, buildZoomHref, angiesListHref, houzzHref} =
    getWebsiteLinks(contractor);

  return (
    <Container>
      <Item>
        <Link href={googleHref} target="_blank">
          <img src={googleReviews} alt="googleReviews" />
        </Link>
      </Item>
      <Item>
        <Link href={yelpHref} target="_blank">
          <img src={yelp} alt="Yelp" />
        </Link>
      </Item>
      <Item>
        <Link href={buildZoomHref} target="_blank">
          <img src={buildZoom} alt="buildZoom" />
        </Link>
      </Item>
      <Item>
        <Link href={angiesListHref} target="_blank">
          <img src={angiesList} alt="Angies list" />
        </Link>
      </Item>
      <Item>
        <Link href={houzzHref} target="_blank">
          <img src={houzz} alt="houzz" />
        </Link>
      </Item>
    </Container>
  );
}

SitesReview.propTypes = {
  contractor: PropTypes.shape({
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default SitesReview;

import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';

import {ClickOutside, MouseHover} from '@renofi/components-internal';
import {actionLight, basic55} from '@renofi/theme';
import {Comment, Trash} from '@renofi/icons';

import {Action, Container, Dot, Tooltip, Wrapper} from './styled';

const MenuButton = ({full, onComment, onRemove}) => {
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  const mouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const mouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const toggleFocus = useCallback(() => {
    setFocus(!focus);
  }, [focus]);

  const commentHandler = useCallback(() => {
    setFocus(false);
    onComment();
  }, [onComment]);

  const removeHandler = useCallback(() => {
    setFocus(false);
    onRemove();
  }, [onRemove]);

  const active = focus || hover;

  return (
    <div css={{width: 32, position: 'relative'}}>
      {focus && (
        <ClickOutside onClickOutside={() => setFocus(false)}>
          <Tooltip wide={full}>
            <MouseHover>
              {({hover}) => (
                <Action>
                  <Comment
                    color={hover ? actionLight : basic55}
                    onClick={commentHandler}
                  />
                </Action>
              )}
            </MouseHover>
            {full && (
              <MouseHover>
                {({hover}) => (
                  <Action>
                    <Trash
                      color={hover ? actionLight : basic55}
                      onClick={removeHandler}
                    />
                  </Action>
                )}
              </MouseHover>
            )}
          </Tooltip>
        </ClickOutside>
      )}
      <Wrapper
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={toggleFocus}>
        <Container>
          <Dot active={active} />
          <Dot active={active} />
          <Dot active={active} />
        </Container>
      </Wrapper>
    </div>
  );
};

MenuButton.propTypes = {
  full: PropTypes.bool,
  onComment: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default MenuButton;

import {pathOr} from 'ramda';

import {GET_INSURANCE_REVIEW} from '../queries';

import useToggledQuery from './useToggledQuery';

export default function useInsuranceReview({
  projectId,
  fetchPolicy = 'cache-first',
  lazy = false,
}) {
  const response = useToggledQuery({
    lazy,
    query: GET_INSURANCE_REVIEW,
    options: {
      variables: {projectId},
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const insuranceReview = pathOr(null, ['insuranceReview'], data);

  return {error, fetch, insuranceReview, loading};
}

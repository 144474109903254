import React from 'react';

import PropTypes from 'prop-types';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {TaskItemActions} from '../../Layout';
import TabContent from '../../TaskType/components/TabContent';

const FeasibilityTabContent = ({doc}) => {
  const {search} = useViewerNavigation();
  const {taskId} = search || {};

  return (
    <TabContent
      extra={<TaskItemActions {...doc} taskId={taskId} />}
      title={doc.fileName}
    />
  );
};

FeasibilityTabContent.propTypes = {
  doc: PropTypes.shape({
    documentType: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  }),
};

export default FeasibilityTabContent;

import React from 'react';

import PropTypes from 'prop-types';

import {InnerWrapper, OuterWrapper} from './styled';

const SidebarContainer = ({children}) => {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
};

SidebarContainer.propTypes = {
  children: PropTypes.any,
};

export default SidebarContainer;

export const extendValidation = (tasks, validation) => {
  const documentsReview = tasks.reduce((memo, {canHaveDocument, documents}) => {
    if (!canHaveDocument) {
      return memo;
    }

    documents.forEach(({fileName, status}) => {
      if (status !== 'accepted') {
        memo.push(fileName);
      }
    });

    return memo;
  }, []);

  const feasibilityStudyCopy = [...validation.feasibilityStudy];

  return {
    ...validation,
    feasibilityStudy: feasibilityStudyCopy,
    documentsReview,
  };
};

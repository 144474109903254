import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REJECT_GCDD_DOCUMENTS} from '../../../mutations';

export default function useRejectGcddDocuments({onCompleted = noop} = {}) {
  const [rejectGcddDocuments, {data, loading, error}] = useMutation(
    REJECT_GCDD_DOCUMENTS,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['rejectGcddDocuments', 'questionnaire'], data);

  return {rejectGcddDocuments, response, loading, error};
}

import styled from '@emotion/styled';

import {Box, Flex} from '@renofi/components-internal';
import {basic75, basic80, basic88, white} from '@renofi/theme/src/colors';

export const Wrapper = styled(Box)(
  {
    minWidth: 300,
    flexGrow: 0,
    width: '100%',
    color: white,
    position: 'relative',
    borderBottom: `1px solid ${basic75}`,
    borderTop: `1px solid ${basic75}`,
  },
  ({inverse, open, reverse}) => ({
    height: open ? 'auto' : 60,
    overflow: open ? 'visible' : 'hidden',
    background: inverse ? basic88 : basic80,
    boxShadow: reverse
      ? '0px -2px 4px rgba(0, 0, 0, 0.25)'
      : '0px 4px 6px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(21, 21, 21, 0.3)',
  }),
);

export const Header = styled(Flex)(
  {
    width: '100%',
    height: 60,
    background: basic88,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontSize: 16,
    lineHeight: '19px',
  },
  ({inverse}) => ({
    background: inverse ? basic80 : basic88,
  }),
);

export const Toggle = styled(Flex)(
  {
    height: 60,
    width: 60,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({inverse, open}) => ({
    ...(open
      ? {borderLeft: `solid 1px ${inverse ? basic75 : basic80}`}
      : {
          borderRight: `solid 1px ${inverse ? basic75 : basic80}`,
          transform: 'rotate(180deg)',
        }),
  }),
);

export const Content = styled(Flex)({
  width: '100%',
  flexDirection: 'column',
});

import {
  equals,
  filter,
  find,
  flatten,
  identity,
  map,
  pipe,
  prop,
  propEq,
  propOr,
  sortBy,
} from 'ramda';
import withFeasibilityReview from 'lib/withFeasibilityReview';
import {FEASIBILITY_TASK_TYPES} from 'modules/viewer-v2';

import {compose, withContext, withProps} from '@renofi/recompose/src';

export default (ViewerContext) =>
  compose(
    withContext(ViewerContext),
    withFeasibilityReview(),
    withProps(({feasibilityReview, project}) => {
      const tasks = pipe(
        propOr([], 'tasks'),
        filter(({taskType}) => FEASIBILITY_TASK_TYPES.includes(taskType)),
      )(project);
      const documents = pipe(map(propOr([], 'documents')), flatten)(tasks);
      const propertyPhotos = pipe(
        find(propEq('taskType', 'appraisal_property_photos')),
        propOr([], 'appraisalPropertyPhotos'),
      )(tasks);

      return {
        documents,
        propertyPhotos,
        completed: Boolean(feasibilityReview?.completedAt),
        requested: Boolean(
          feasibilityReview?.requestedAt && feasibilityReview?.reviewer,
        ),
        returned: Boolean(feasibilityReview?.returnedAt),
      };
    }),
    withProps(
      ({
        completed,
        documents = [],
        feasibilityReview,
        feasibilityReviews,
        propertyPhotos = [],
        requested,
        returned,
      }) => ({
        canComplete: returned && requested && !completed,
        canRequest: !completed && !requested,
        canRequestSecond:
          requested &&
          returned &&
          !completed &&
          feasibilityReviews?.length === 1,
        canUpdate:
          requested &&
          !returned &&
          !completed &&
          (!equals(
            sortBy(identity, documents.map(prop('id'))),
            sortBy(
              identity,
              (feasibilityReview.documents.length
                ? feasibilityReview.documents
                : documents
              ).map(prop('id')),
            ),
          ) ||
            !equals(
              sortBy(identity, propertyPhotos.map(prop('id'))),
              sortBy(
                identity,
                (feasibilityReview.propertyPhotos.length
                  ? feasibilityReview.propertyPhotos
                  : propertyPhotos
                ).map(prop('id')),
              ),
            )),
      }),
    ),
  );

import {isNil, isEmpty} from 'ramda';

export const getReferenceStatus = (references = []) => {
  const areAllReviewed =
    !isEmpty(references) && references.every(({score}) => !isNil(score));
  const isOutstanding = isEmpty(references);

  if (areAllReviewed) {
    return {
      status: 'Reviewed',
      variant: 'success',
    };
  }

  if (isOutstanding) {
    return {
      status: 'Outstanding',
      variant: 'danger',
    };
  }

  return {
    status: 'Pending review',
    variant: 'warning',
  };
};

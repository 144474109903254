import {propOr} from 'ramda';

import useToggledQuery from '../../useToggledQuery';
import {GET_REFERENCE_RESPONSES} from '../../../queries';

export default function useReferenceResponses({
  fetchPolicy = 'no-cache',
  lazy = false,
  ...options
}) {
  const response = useToggledQuery({
    lazy,
    query: GET_REFERENCE_RESPONSES,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const referenceResponses = propOr([], 'referenceResponses', data);

  return {error, fetch, referenceResponses, loading};
}

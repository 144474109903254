import React from 'react';

import PropTypes from 'prop-types';
import {ItemsBarTabFileName} from 'modules/viewer-v2/components/Layout';

import {Flex} from '@renofi/components-internal';

const TabContent = ({extra, subTitle, title}) => {
  return (
    <Flex justifyContent="space-between" width={1}>
      <Flex flexDirection="column" maxWidth={180} css={{overflow: 'hidden'}}>
        <ItemsBarTabFileName>{title}</ItemsBarTabFileName>
        {subTitle && <ItemsBarTabFileName>{subTitle}</ItemsBarTabFileName>}
      </Flex>
      {extra && (
        <Flex alignItems="center" flexShrink={0}>
          {extra}
        </Flex>
      )}
    </Flex>
  );
};

TabContent.propTypes = {
  extra: PropTypes.node,
  subTitle: PropTypes.node,
  title: PropTypes.node,
};

export default TabContent;

import {makeVar, useReactiveVar} from '@apollo/client';

const propertyVar = makeVar(null);

const useProperty = () => {
  const activeProperty = useReactiveVar(propertyVar);

  const setProperty = (value) => {
    propertyVar(value);
  };

  return {activeProperty, setProperty};
};

export default useProperty;

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {SUBMIT_APPRAISAL_ORDER_FINAL_INSPECTION} from '../../mutations';
import useMutation from '../useMutation';

export default function useSubmitAppraisalOrderFinalInspection({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitAppraisalOrderFinalInspection, {data, loading, error}] =
    useMutation(SUBMIT_APPRAISAL_ORDER_FINAL_INSPECTION, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(
    null,
    ['submitContractorInformation', 'contractor'],
    data,
  );

  return {submitAppraisalOrderFinalInspection, response, loading, error};
}

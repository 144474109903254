export default function fullName({
  firstName,
  lastName,
  middleName,
  nameSuffix,
}) {
  return `${firstName} ${middleName ? middleName + ' ' : ''}${lastName}${
    nameSuffix ? ' ' + nameSuffix : ''
  }`;
}

export function fullNamesFromArray(names = []) {
  return (names?.map((name) => fullName(name)) || []).join(', ');
}

import {useAcceptAdditionalDocument, useAcceptDocument} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';

export default ({documentId, refetchQueries = [], taskType}) => {
  const {acceptDocument, loading: isAccepting} = useAcceptDocument({
    refetchQueries,
  });
  const {acceptAdditionalDocument, loading: isAcceptingAdditional} =
    useAcceptAdditionalDocument({refetchQueries});
  const {addNotification} = useNotifications();

  const isAdditionalDocsTask = taskType === 'additional_documents';
  const loading = isAccepting || isAcceptingAdditional;

  const onAcceptDocument = async () => {
    const mutation = isAdditionalDocsTask
      ? acceptAdditionalDocument
      : acceptDocument;

    const response = await mutation({variables: {id: documentId}});
    if (isAdditionalDocsTask && !response.errors) {
      addNotification({
        variant: 'success',
        content: 'Document successfully moved to the relevant task.',
      });
    }
    return response;
  };

  return {
    loading,
    onAcceptDocument,
  };
};

import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_GCDD_REVIEWS_BY_CONTRACTOR_ID} from '../../queries';

export default function useGcddReviewsByContractorId({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_GCDD_REVIEWS_BY_CONTRACTOR_ID,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const gcddReviewsByContractorId = propOr(
    null,
    'gcddReviewsByContractorId',
    data,
  );

  return {error, fetch, gcddReviewsByContractorId, loading};
}

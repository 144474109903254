export const OMIT_PROPS = [
  'confirmationRequestedAt',
  'confirmationRequestedById',
  'confirmationRequestedMessage',
  'confirmedAt',
  'createdAt',
  'gcddReview',
  'path',
  'score',
  'scoreSubmittedAt',
  'scoreSubmittedById',
  'status',
  'title',
  'updatedAt',
  'referenceType',
  'questionnaireId',
  '__typename',
];

import React from 'react';

import PropTypes from 'prop-types';

import {usePreview} from '@renofi/utilities/src/hooks';
import {Box} from '@renofi/components-internal';

function BorrowerInvitation({borrowerInvitedToProject}) {
  usePreview(borrowerInvitedToProject, 'invitation');

  return (
    <Box
      css={{
        height: '100%',
        overflow: 'auto',
      }}>
      <div id="invitation" />
    </Box>
  );
}

BorrowerInvitation.propTypes = {
  borrowerInvitedToProject: PropTypes.shape({
    content: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }),
};

export default BorrowerInvitation;

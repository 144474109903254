/* eslint-disable max-lines */
import React, {useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import {insert, isNil, isEmpty} from 'ramda';
import {v4 as uuid} from 'uuid';
import {useViewerLayout, useCurrentSource} from 'api/hooks';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {useHasFeasibilityEditPermission} from 'modules/viewer-v2';

import {
  Alert,
  AlertWithIcon,
  Box,
  Button,
  Flex,
  Increment,
  Label,
  Money,
  Text,
} from '@renofi/components-internal';
import {successLight, dangerLight} from '@renofi/theme';
import useDebounce from '@renofi/utilities/src/useDebounce';

import SelectButton from '../SelectButton';
import Container from '../Container';

import ReviewItem from './components/ReviewItem';
import PaymentRow from './components/PaymentRow';
import {
  TIMELINE_OPTIONS,
  GUARANTEE_OPTIONS,
  TIMELINE_DESCRIPTION_MAP,
  MIN_NUMBER_OF_ROWS_FOR_DELETE,
} from './constants';
import {Block} from './styled';
import {
  getStyles,
  calculateTotalFieldValue,
  getRenovationBudgetRemainingPercentage,
  parseNumber,
} from './utils';

const INITIAL_ITEMS = [
  {id: uuid(), amount: 0, comment: null, specialMaterialCosts: null},
  {id: uuid(), amount: 0, comment: null, specialMaterialCosts: null},
];

const ContractReview = ({
  project,
  updateFeasibilityReview,
  readFeasibilityReviewState,
  feasibilityReview,
  isCostEstimateDisabled,
}) => {
  const {
    costEstimateSuppliedApproved,
    costEstimateSuppliedNotes,
    changeOrderProcedureApproved,
    changeOrderProcedureNotes,
    clearDescriptionOfWorkApproved,
    clearDescriptionOfWorkNotes,
    clearGuaranteeApproved,
    clearGuaranteeNotes,
    clearGuaranteeRating,
    contactInfoApproved,
    contactInfoNotes,
    contractTimelineApproved,
    contractTimelineNotes,
    contractTimelineRating,
    estimatedDuration,
    termsOfPaymentApproved,
    termsOfPaymentNotes,
    termsOfPayments = [],
    completedBy,
  } = feasibilityReview || {};
  const {renovationCost: totalRenovationBudget} = project || {};

  const {isInternal, isReviewer} = useCurrentSource();
  const {layout} = useViewerLayout();
  const {hasFeasibilityEditPermission} = useHasFeasibilityEditPermission();

  const wide = isSplitWideLayout(layout);
  const {
    textAlign,
    alignItems,
    flexDirection,
    justifyContent,
    columnWidth,
    marginSide,
    radiusLeft,
    radiusRight,
  } = getStyles(wide);

  useEffect(() => {
    (async () => {
      if (isReviewer) {
        return;
      }

      if (termsOfPaymentApproved || !isEmpty(termsOfPayments)) {
        return;
      }

      const newTerms = termsOfPayments.concat(INITIAL_ITEMS);

      await updateFeasibilityReview({
        termsOfPayments: newTerms,
      });
    })();
  }, []);

  const onUpdatePaymentField = async (idToUpdate, field, value) => {
    const {termsOfPayments} = readFeasibilityReviewState();

    const updatedTerms = termsOfPayments.map((item) => {
      if (idToUpdate === item.id) {
        const newValue = field === 'comment' ? value : parseNumber(value);

        return {
          ...item,
          [field]: newValue,
        };
      }

      return item;
    });

    await updateFeasibilityReview({
      termsOfPayments: updatedTerms,
    });
  };

  const debouncedUpdatePayment = useDebounce((id, field, value) => {
    onUpdatePaymentField(id, field, value);
  }, 1000);

  const onAddPaymentRow = async () => {
    const NEW_ITEM = {
      id: uuid(),
      amount: 0,
      comment: null,
      specialMaterialCosts: null,
    };

    const newTerms = insert(
      termsOfPayments.length - 1,
      NEW_ITEM,
      termsOfPayments,
    );

    await updateFeasibilityReview({
      termsOfPayments: newTerms,
    });
  };

  const onRemovePaymentRow = async (idToRemove) => {
    const updatedTerms = termsOfPayments.filter(({id}) => id !== idToRemove);

    await updateFeasibilityReview({
      termsOfPayments: updatedTerms,
    });
  };

  const onChangeEstimatedDuration = useCallback((value) => {
    const numericValue = Number(value);
    const isInteger = Number.isInteger(numericValue);

    if (!isInteger) {
      return;
    }

    updateFeasibilityReview({estimatedDuration: numericValue});
  }, []);

  const onChangeCostEstimateNotes = (value) => {
    if (isInternal) {
      return;
    }

    updateFeasibilityReview({costEstimateSuppliedNotes: value});
  };

  const specialMaterialsTotal = calculateTotalFieldValue(
    'specialMaterialCosts',
    termsOfPayments,
  );
  const totalPaymentsAmount = calculateTotalFieldValue(
    'amount',
    termsOfPayments,
  );

  const renovationBudgetRemaining = totalRenovationBudget - totalPaymentsAmount;
  const budgetPercentage = getRenovationBudgetRemainingPercentage(
    renovationBudgetRemaining,
    totalRenovationBudget,
  );

  const completed = Boolean(completedBy);
  const disabled =
    (isReviewer && completed) ||
    (isInternal && (!hasFeasibilityEditPermission || completed));

  const convertedEstimatedDuration = estimatedDuration?.toString() || '';
  const canDeletePaymentRow =
    termsOfPayments?.length >= MIN_NUMBER_OF_ROWS_FOR_DELETE;
  const renovationBudgetColor =
    renovationBudgetRemaining >= 0 ? successLight : dangerLight;

  return (
    <>
      <ReviewItem
        disabled={isCostEstimateDisabled}
        label="Detailed cost estimate supplied"
        notes={costEstimateSuppliedNotes}
        isApproved={costEstimateSuppliedApproved}
        onChangeNotes={onChangeCostEstimateNotes}
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({costEstimateSuppliedApproved: value})
        }
      />

      <ReviewItem
        disabled={disabled}
        label="Contact info for contractor and homeowner"
        notes={contactInfoNotes}
        isApproved={contactInfoApproved}
        onChangeNotes={(value) =>
          updateFeasibilityReview({contactInfoNotes: value})
        }
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({contactInfoApproved: value})
        }
      />

      <ReviewItem
        disabled={disabled}
        label="Timelines and duration"
        notes={contractTimelineNotes}
        isApproved={contractTimelineApproved}
        onChangeNotes={(value) =>
          updateFeasibilityReview({contractTimelineNotes: value})
        }
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({contractTimelineApproved: value})
        }
      />
      <Container px={20} inverse>
        <Box>
          <Label>Estimated renovation duration (weeks)</Label>
        </Box>
        <Box width={170} mt="8px" mb={12}>
          <Increment
            disabled={disabled}
            value={convertedEstimatedDuration}
            emptyValue="0"
            onChange={onChangeEstimatedDuration}
          />
        </Box>
        <SelectButton
          disabled={disabled}
          dynamic={!wide}
          value={contractTimelineRating}
          options={TIMELINE_OPTIONS}
          label="Contract timeline details"
          onChange={(value) =>
            updateFeasibilityReview({contractTimelineRating: value})
          }
        />
        <Box pb={12}>
          <Label>Description</Label>
          {contractTimelineRating && (
            <Alert p={0} mt="4px">
              {TIMELINE_DESCRIPTION_MAP[contractTimelineRating]}
            </Alert>
          )}
        </Box>
      </Container>
      <Container mb={0} p={0} css={{borderBottom: 'none'}}>
        <ReviewItem
          disabled={disabled}
          label="Terms of payment"
          notes={termsOfPaymentNotes}
          isApproved={termsOfPaymentApproved}
          onChangeNotes={(value) =>
            updateFeasibilityReview({termsOfPaymentNotes: value})
          }
          onToggleIsApproved={(value) =>
            updateFeasibilityReview({termsOfPaymentApproved: value})
          }
          css={{borderBottom: 'none'}}
        />
      </Container>
      <Container mb={0} p={16} inverse>
        <Flex width={1} flexDirection={flexDirection}>
          <Block
            mr={marginSide}
            width={columnWidth}
            css={{borderRadius: radiusLeft}}>
            <Box>
              <Label>Total renovation budget</Label>
              <Text mt="4px" fontSize={32} lineHeight="38px">
                <Money value={totalRenovationBudget} />
              </Text>
            </Box>
          </Block>
          <Block
            ml={marginSide}
            width={columnWidth}
            css={{
              borderRadius: radiusRight,
            }}>
            <AlertWithIcon p={0} alignItems="flex-start">
              Percentages are based on a Total Renovation Budget excluding
              special materials
            </AlertWithIcon>
          </Block>
        </Flex>
        {!isEmpty(termsOfPayments) &&
          termsOfPayments.map(
            ({amount, comment, id, specialMaterialCosts}, index) => {
              const isLastItem = index === termsOfPayments.length - 1;
              const ordinalNumber = index + 1;

              return (
                <PaymentRow
                  key={id}
                  amount={amount}
                  comment={comment}
                  id={id}
                  specialMaterialCosts={specialMaterialCosts}
                  disabled={disabled}
                  ordinalNumber={ordinalNumber}
                  canBeDeleted={canDeletePaymentRow}
                  renovationBudget={totalRenovationBudget}
                  last={isLastItem}
                  onChangeAmount={(value) =>
                    debouncedUpdatePayment(id, 'amount', value || '0')
                  }
                  onChangeComment={(value) =>
                    debouncedUpdatePayment(id, 'comment', value)
                  }
                  onChangeSpecialMaterialCost={(value) =>
                    debouncedUpdatePayment(id, 'specialMaterialCosts', value)
                  }
                  onRemove={() => onRemovePaymentRow(id)}
                />
              );
            },
          )}
        <Block
          last
          width={1}
          alignItems={alignItems}
          flexDirection={flexDirection}
          justifyContent={justifyContent}>
          <Box>
            <Label>Renovation budget remaining</Label>
            <Text
              mt="4px"
              fontSize={24}
              lineHeight="29px"
              color={renovationBudgetColor}>
              <Money value={renovationBudgetRemaining} />{' '}
              {!isNil(budgetPercentage) && <>({budgetPercentage}%)</>}
            </Text>
          </Box>
          <Box mt={wide ? 0 : '8px'}>
            <Label textAlign={textAlign}>Special materials total</Label>
            <Text
              mt="4px"
              fontSize={24}
              lineHeight="29px"
              textAlign={textAlign}>
              <Money value={specialMaterialsTotal} />
            </Text>
          </Box>
        </Block>
        <Flex mt={12} justifyContent="flex-end">
          <Button disabled={disabled} onClick={onAddPaymentRow}>
            Add Payment
          </Button>
        </Flex>
      </Container>
      <ReviewItem
        disabled={disabled}
        label="Clear Description of Work"
        notes={clearDescriptionOfWorkNotes}
        isApproved={clearDescriptionOfWorkApproved}
        onChangeNotes={(value) =>
          updateFeasibilityReview({clearDescriptionOfWorkNotes: value})
        }
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({clearDescriptionOfWorkApproved: value})
        }
      />
      <ReviewItem
        disabled={disabled}
        label="Change order procedure"
        notes={changeOrderProcedureNotes}
        isApproved={changeOrderProcedureApproved}
        onChangeNotes={(value) =>
          updateFeasibilityReview({changeOrderProcedureNotes: value})
        }
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({changeOrderProcedureApproved: value})
        }
      />
      <ReviewItem
        disabled={disabled}
        label="Clear Guarantee"
        notes={clearGuaranteeNotes}
        isApproved={clearGuaranteeApproved}
        onChangeNotes={(value) =>
          updateFeasibilityReview({clearGuaranteeNotes: value})
        }
        onToggleIsApproved={(value) =>
          updateFeasibilityReview({clearGuaranteeApproved: value})
        }
      />
      <Container mb={0} px={20} inverse>
        <SelectButton
          dynamic
          disabled={disabled}
          value={clearGuaranteeRating}
          options={GUARANTEE_OPTIONS}
          label="Length of guarantee in months on craftsmanship"
          onChange={(value) =>
            updateFeasibilityReview({clearGuaranteeRating: value})
          }
        />
      </Container>
    </>
  );
};

ContractReview.propTypes = {
  isCostEstimateDisabled: PropTypes.bool,
  feasibilityReview: PropTypes.shape({
    completedAt: PropTypes.string,
    costEstimateSuppliedApproved: PropTypes.bool,
    costEstimateSuppliedNotes: PropTypes.string,
    changeOrderProcedureApproved: PropTypes.bool,
    changeOrderProcedureNotes: PropTypes.string,
    clearDescriptionOfWorkApproved: PropTypes.bool,
    clearDescriptionOfWorkNotes: PropTypes.string,
    clearGuaranteeApproved: PropTypes.bool,
    clearGuaranteeNotes: PropTypes.string,
    clearGuaranteeRating: PropTypes.string,
    contactInfoApproved: PropTypes.bool,
    contactInfoNotes: PropTypes.string,
    contractTimelineApproved: PropTypes.bool,
    contractTimelineNotes: PropTypes.string,
    contractTimelineRating: PropTypes.string,
    estimatedDuration: PropTypes.number,
    termsOfPaymentApproved: PropTypes.bool,
    termsOfPaymentNotes: PropTypes.string,
    termsOfPayments: PropTypes.arrayOf(PropTypes.object),
  }),
  project: PropTypes.shape({
    renovationCost: PropTypes.number,
  }),
  updateFeasibilityReview: PropTypes.func.isRequired,
  readFeasibilityReviewState: PropTypes.func,
};

export default ContractReview;

import styled from '@emotion/styled';

import {Badge} from '@renofi/components-internal';
import {
  actionDark,
  actionRegular,
  dangerRegular,
  successDark,
  warningRegular,
} from '@renofi/theme/src/colors';

export const RaaSBadge = styled(Badge)({
  borderColor: actionDark,
  color: actionDark,
});

export const WholesaleBadge = styled(Badge)({
  borderColor: actionRegular,
  color: actionRegular,
});

export const TpoBadge = styled(Badge)({
  borderColor: successDark,
  color: successDark,
});

export const B2b2cBadge = styled(Badge)({
  borderColor: warningRegular,
  color: warningRegular,
});

export const RftBadge = styled(Badge)({
  borderColor: dangerRegular,
  color: dangerRegular,
});

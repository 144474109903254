import {MILLISECONDS_PER_DAY} from './constants';

export const diffDays = (date1, date2) => {
  const diff = Math.floor((date2 - date1) / MILLISECONDS_PER_DAY);

  if (diff === 0) {
    return 'Today';
  }

  if (diff === 1) {
    return 'Yesterday';
  }

  return `${diff} days`;
};

const isSignatureComplete = ({
  signatoryBusinessName,
  signatoryName,
  signatoryTitle,
  signatureDate,
}) => {
  return Boolean(
    signatoryBusinessName && signatoryName && signatoryTitle && signatureDate,
  );
};

export default isSignatureComplete;

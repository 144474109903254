import React from 'react';

import {Flex, Text} from '@renofi/components-internal';

function RevertStatusModal() {
  return (
    <Flex
      css={{
        padding: '65px 25px',
      }}>
      <Text fontSize="24px" textAlign="center">
        Are you sure you want to revert to an uncompleted status?
      </Text>
    </Flex>
  );
}

export default RevertStatusModal;

export const WEBSITE_DEFAULTS = [
  'Angies list',
  'Buildzoom',
  'Google review',
  'Houzz',
  'Yelp',
];

export const WEBSITE_OTHER = 'Other';

const WEBSITE_VALUES = [...WEBSITE_DEFAULTS, WEBSITE_OTHER];

export const EMPTY_REVIEW = {
  id: null,
  website: '',
  url: '',
  rating: '',
  comment: '',
  createdAt: null,
};

export const WEBSITE_OPTIONS = WEBSITE_VALUES.map((item) => ({
  label: item,
  value: item,
}));

export const STAR_RATING_OPTIONS = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
];

export const DEBOUNCE_DELAY = 300;

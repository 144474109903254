import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {GCDD_TABS} from 'lib/gcdd';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import GcddDocument from './components/GcddDocument';
import ContractorDetails from './components/ContractorDetails';
import Reference from './components/Reference';
import BbbSite from './components/BbbSite';
import SitesReview from './components/SitesReview';
import Questionnaire from './components/Questionnaire';

const GcddReviewItemView = ({gcddReview}) => {
  const {history, search} = useViewerNavigation();
  const {tab, referenceId} = search || {};

  const questionnaire = propOr(null, 'questionnaire', gcddReview);
  const references = propOr([], 'references', questionnaire);

  useEffect(() => {
    const hasReferences = references.length;

    if (hasReferences && tab === GCDD_TABS.REFERENCES && !referenceId) {
      history.push(`?tab=references&referenceId=${references[0].id}`);
    }
  }, [tab, referenceId]);

  switch (search?.tab) {
    case GCDD_TABS.CONTRACTOR:
      return <ContractorDetails gcddReview={gcddReview} />;
    case GCDD_TABS.QUESTIONNAIRE:
      return <Questionnaire gcddReview={gcddReview} />;
    case GCDD_TABS.LICENSES:
    case GCDD_TABS.INSURANCES:
      return <GcddDocument gcddReview={gcddReview} />;
    case GCDD_TABS.REFERENCES:
      return <Reference gcddReview={gcddReview} referenceId={referenceId} />;
    case GCDD_TABS.REVIEWS:
      return <SitesReview contractor={gcddReview?.contractor} />;
    case GCDD_TABS.BBB:
      return <BbbSite />;
    default:
      return null;
  }
};

GcddReviewItemView.propTypes = {
  gcddReview: PropTypes.shape({
    contractor: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default GcddReviewItemView;

import styled from '@emotion/styled';

import {basic55, dangerLight} from '@renofi/theme/src/colors';

export const List = styled.ul({
  listStyle: 'none',
  paddingLeft: 16,
  paddingRight: 16,
});

export const ListItem = styled.li({
  marginBottom: 8,
  color: dangerLight,
  ':before': {
    display: 'inline-block',
    width: '1em',
    marginLeft: '-1em',
    color: basic55,
    content: '"•"',
    fontSize: 20,
    textAlign: 'center',
  },
});

import React, {Fragment, useContext, useEffect, useState} from 'react';

import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {isEmpty} from 'ramda';
import asyncComponent from 'lib/asyncComponent';
import {BORROWER_PATH, UUID_V4_REGEX} from 'lib/routes';
import {borrowers, dueDiligenceRequests} from 'modules/routes';
import {Header} from 'modules/layout';
import ViewerV2 from 'modules/viewer-v2';

import {AlertWithIcon, Box, Button} from '@renofi/components-internal';
import {FeatureFlags, Lender} from '@renofi/modules-internal';
import {useGetUser} from '@renofi/graphql/src/hooks';
import {
  canEditGcdd,
  canEditStandard,
  canViewGcdd,
  canViewStandard,
} from '@renofi/utilities/src/permissions';
import {useLatestVersion} from '@renofi/utilities/src/hooks';
import {setAnalyticsUser} from '@renofi/utilities/src/analytics';
import {Context as StorageContext} from '@renofi/utilities/src/storage';

import CannyFeedback from './components/CannyFeedback';
import FreezingWrapper from './FreezingWrapper';

const GcddReview = asyncComponent(
  () => import(/* webpackChunkName: "gcdd-review" */ 'modules/gcdd-review'),
);

const BorrowersPipeline = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "borrowers-pipeline" */ 'modules/borrowers-pipeline'
    ),
);

const UnderwritingConditions = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "underwriting-conditions" */ 'modules/underwriting-conditions'
    ),
);

const Project = asyncComponent(
  () => import(/* webpackChunkName: "project" */ 'modules/project'),
);

const Exception = asyncComponent(
  () => import(/* webpackChunkName: "exception" */ 'modules/exception'),
);

const Opportunity = asyncComponent(
  () => import(/* webpackChunkName: "lender" */ 'modules/opportunity'),
);

const Lenders = asyncComponent(
  () => import(/* webpackChunkName: "lenders" */ 'modules/lenders'),
);

const Users = asyncComponent(
  () => import(/* webpackChunkName: "users" */ 'modules/users'),
);

const MyAccount = asyncComponent(
  () => import(/* webpackChunkName: "users" */ 'modules/my-account'),
);

const UserDetails = asyncComponent(
  () => import(/* webpackChunkName: "user" */ 'modules/user-details'),
);

const NotFound = asyncComponent(
  () => import(/* webpackChunkName: "not-found" */ 'modules/not-found'),
);

const DDRequests = asyncComponent(
  () =>
    import(/* webpackChunkName: "projects" */ 'modules/due-diligence-requests'),
);

const FeasibilityPipeline = asyncComponent(
  () =>
    import(/* webpackChunkName: "projects" */ 'modules/feasibility-pipeline'),
);

const NotesWrapper = asyncComponent(
  () => import(/* webpackChunkName: "projects" */ 'modules/notes'),
);

const PrivateRoutes = () => {
  const [newVersion, showNewVersion] = useState(false);
  const {pathname = ''} = useLocation();
  const history = useHistory();
  const storage = useContext(StorageContext);

  const isV2Viewer = pathname.includes('viewer-v2');

  const {user} = useGetUser();
  const isAnalyst = canEditGcdd(user) || canViewGcdd(user);
  const isOpsUser = canEditStandard(user) || canViewStandard(user);
  const defaultRoute = isOpsUser
    ? borrowers()
    : isAnalyst
      ? dueDiligenceRequests()
      : borrowers();
  const {hasVersionChanged} = useLatestVersion();

  useEffect(() => {
    showNewVersion(hasVersionChanged);
  }, [hasVersionChanged]);

  useEffect(() => {
    if (user && !isEmpty(user)) {
      setAnalyticsUser(user);
    }
    const redirectPath = storage.getItem('path:redirect');

    if (redirectPath) {
      storage.removeItem('path:redirect');
      history.push(redirectPath);
    }
  }, []);

  return (
    <Fragment>
      {newVersion && (
        <AlertWithIcon variant="warning" alignItems="space-between">
          <Box as="span" mr={3}>
            A new version of the app is available. Please refresh the page to
            update.
          </Box>
          <Button ml={3} onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </AlertWithIcon>
      )}
      {!isV2Viewer && <Header />}
      <FreezingWrapper>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to={defaultRoute} />}
          />
          {/* Used for Property Details Wizard */}
          <Route
            exact
            path={`${BORROWER_PATH}/:tab(tasks)/wizard/:taskId/:step?`}
            component={Project}
          />
          {/* Comment end */}
          <Route
            exact
            path="/borrowers-pipeline/:projectReportId?"
            component={BorrowersPipeline}
          />
          <Route
            exact
            path="/borrowers/:status(preparing|in_progress|with_lender|closed_with_lender|closed|renovation|missing_renovation|in_1003)?">
            <Redirect to="/borrowers-pipeline" />
          </Route>
          <Route
            exact
            path="/underwriting-conditions/:id?"
            component={UnderwritingConditions}
          />
          <Route exact path="/notes/:noteId?">
            <NotesWrapper />
          </Route>
          <Route
            exact
            path={`${BORROWER_PATH}/exceptions/:action(create)/:tab?`}
            component={Exception}
          />
          <Route
            exact
            path={`${BORROWER_PATH}/exceptions/:action(copy|copy-clean)/:itemId/:tab?`}
            component={Exception}
          />
          <Route
            exact
            path={`${BORROWER_PATH}/exceptions/:exceptionId/:tab?/:commentId?`}
            component={Exception}
          />
          <Route path={`${BORROWER_PATH}/:tab?`} component={Project} />
          <Route
            path="/lenders/:id/:tab?"
            render={(props) => {
              // eslint-disable-next-line react/prop-types
              const basePath = `/lenders/${props?.match?.params?.id}`;
              const baseRoute = `/lenders/:id`;
              return (
                <Lender mode="internal" {...{basePath, baseRoute, ...props}} />
              );
            }}
          />
          <Route exact path="/lenders" component={Lenders} />
          <Route
            exact
            path="/opportunity/:opportunityId"
            component={Opportunity}
          />
          <Route path="/users/:id/:tab?" component={UserDetails} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/my-account" component={MyAccount} />

          <Route
            exact
            path="/feasibility-pipeline/:tab(ordered|assigned|returned|canceled)?"
            component={FeasibilityPipeline}
          />

          {/* GCDD routes */}
          <Route
            exact
            path="/dd-requests/:tab(questionnaires_sent|questionnaires_returned|duplicates|ready_for_review|review_completed)?"
            component={DDRequests}
          />
          <Route
            exact
            path={`/dd-requests/:gcddReviewId(${UUID_V4_REGEX})?`}
            component={GcddReview}
          />
          <Route
            exact
            path={`/dd-requests/:gcddReviewId(${UUID_V4_REGEX})/:tab?`}
            component={GcddReview}
          />
          <Route path="/viewer-v2/:projectId" component={ViewerV2} />

          <Route exact path="/feature-flags" component={FeatureFlags} />
          <Route component={NotFound} />
        </Switch>
      </FreezingWrapper>
      <CannyFeedback />
    </Fragment>
  );
};

export default React.memo(PrivateRoutes);

import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {SEND_CORRESPONDENCE_MESSAGE} from '../../mutations';

export default function useSendCorrespondenceMessage({
  onCompleted = noop,
} = {}) {
  const [sendCorrespondenceMessage, {data, loading, error}] = useMutation(
    SEND_CORRESPONDENCE_MESSAGE,
    {
      onCompleted,
    },
  );
  const response = pathOr(
    null,
    ['sendCorrespondenceMessage', 'correspondenceMessage'],
    data,
  );

  return {sendCorrespondenceMessage, response, loading, error};
}

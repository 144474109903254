import {propOr} from 'ramda';

import {formatDate} from '@renofi/utilities/src/dates';
import {dangerRegular, basic55} from '@renofi/theme';

export default (gcddReview = {}) => {
  const isCancelled = Boolean(gcddReview?.cancelledAt);
  const questionnaire = propOr({}, 'questionnaire', gcddReview);
  const {firstOpenedAt, returnedAt} = questionnaire;

  switch (true) {
    case isCancelled:
      return {
        status: 'Cancelled',
        date: formatDate(gcddReview?.cancelledAt),
        color: basic55,
      };
    case firstOpenedAt === null && returnedAt === null:
      return {
        status: 'Unopened',
        date: null,
        color: dangerRegular,
      };
    case Boolean(returnedAt):
      return {
        status: 'Completed',
        date: formatDate(returnedAt),
        color: basic55,
      };
    default:
      return {
        status: 'Opened',
        date: formatDate(firstOpenedAt),
        color: basic55,
      };
  }
};

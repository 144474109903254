import {identity, pathOr} from 'ramda';

import {compose, mapProps} from '@renofi/recompose/src';
import {withQuery} from '@renofi/graphql/src/hoc';

import {GET_APPLICATION_STATE} from '../queries';

import withResetMessage from './withResetMessage';
import withUpdateMessage from './withUpdateMessage';

export default ({mapper = identity, options = {}} = {}) =>
  compose(
    withResetMessage(),
    withUpdateMessage(),
    withQuery({
      query: GET_APPLICATION_STATE,
      props: ({data, ...props}) => ({
        message: pathOr({}, ['application', 'message'])(data),
        ...props,
      }),
      options,
    }),
    mapProps(mapper),
  );

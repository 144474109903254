import {useEffect, useState} from 'react';

import {useGetUser} from '@renofi/graphql/src/hooks';
import {canEditGcdd, canEditStandard} from '@renofi/utilities/src/permissions';

const useCanEditGcddReview = (gcddReview) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const {user} = useGetUser();

  const isAnalyst = canEditGcdd(user);
  const isOpsUser = canEditStandard(user);
  const canEdit = isAnalyst || isOpsUser;

  useEffect(() => {
    setIsCompleted(Boolean(gcddReview?.completedAt));
  }, [gcddReview]);

  return {canEdit, isAnalyst, isOpsUser, isCompleted};
};

export default useCanEditGcddReview;

import React, {useCallback, Fragment, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {BBB_GRADES, NO_BBB_GRADE, useCanEditGcddReview} from 'lib/gcdd';
import {Container, StatusBadge} from 'modules/viewer-v2/components/Layout';
import {Row} from 'modules/viewer-v2/components';

import {useSubmitBbbGrade} from '@renofi/graphql';
import {
  ButtonGroup,
  Box,
  Flex,
  Text,
  SelectField,
} from '@renofi/components-internal';

const OPTIONS = [
  {label: 'Listed', value: true},
  {label: 'Not listed', value: false},
];

function BetterBusinessBureau({gcddReview, onGcddReviewUpdate}) {
  const {canEdit} = useCanEditGcddReview(gcddReview);
  const {loading: isSubmittingBbb, submitBbbGrade} = useSubmitBbbGrade();

  const {contractor} = gcddReview;
  const {id, bbbListed, bbbGrade} = contractor;

  const [grade, setGrade] = useState(bbbGrade);
  const [listed, setListed] = useState(propOr(null, 'bbbListed', contractor));

  const onChangeListed = useCallback(
    (value) => {
      const currGrade = value ? grade : null;
      if (currGrade === null) setGrade(null);
      setListed(value);
      submitBbbGrade({
        variables: {
          bbbListed: value,
          bbbGrade: currGrade,
          contractorId: id,
        },
      });
      onGcddReviewUpdate();
    },
    [gcddReview],
  );

  const onChangeGrade = useCallback(
    (value) => {
      setGrade(value);
      submitBbbGrade({
        variables: {
          bbbListed,
          bbbGrade: value,
          contractorId: id,
        },
      });
      onGcddReviewUpdate();
    },
    [gcddReview],
  );

  const isCompleted = bbbListed === false || (bbbListed && bbbGrade);
  const isDisabled = isSubmittingBbb || !canEdit;

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Better Business Bureau</Text>
          <StatusBadge variant={isCompleted ? 'success' : 'warning'}>
            {isCompleted ? 'Reviewed' : 'Pending review'}
          </StatusBadge>
        </Row>
      }>
      <Fragment>
        <Row>
          <Flex width={1} mx={2}>
            <Box width={0.5} mr={2}>
              <ButtonGroup
                disabled={isDisabled}
                label="Listed on the BBB"
                options={OPTIONS}
                value={listed}
                onChange={(value) => {
                  onChangeListed(value);
                }}
              />
            </Box>
            <Box width={0.5} ml={2}>
              <SelectField
                disabled={isDisabled || !listed}
                label="Select BBB grade"
                options={BBB_GRADES}
                placeholder="Select BBB grade"
                value={grade}
                onChange={(value) => {
                  const newGrade = value === NO_BBB_GRADE ? null : value;
                  onChangeGrade(newGrade);
                }}
              />
            </Box>
          </Flex>
        </Row>
      </Fragment>
    </Container>
  );
}

BetterBusinessBureau.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    contractor: PropTypes.object,
  }),
  onGcddReviewUpdate: PropTypes.func.isRequired,
};
export default BetterBusinessBureau;

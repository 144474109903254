export const FEASIBILITY_REVIEW_STATUSES = {
  requested: 'requested',
  assigned: 'assigned',
  canceled: 'canceled',
  returned: 'returned',
  rejected: 'rejected',
  completed: 'completed',
};

export const FEASIBILITY_REVIEW_NAVBAR_PROPERTIES_BY_STATUSES = {
  requested: {
    color: 'info',
    label: 'Ordered',
  },
  canceled: {
    color: 'dangerInverse',
    label: 'Canceled',
  },
  returned: {
    color: 'infoInverse',
    label: 'Received',
  },
  rejected: {
    color: 'danger',
    label: 'Rejected',
  },
  completed: {
    color: 'successInverse',
    label: 'Completed',
  },
};

import {gql} from '@apollo/client';

import {projectReport} from '../partials';

export default gql`
  query projectReports($limit: Int, $page: Int) {
    projectReports(limit: $limit, page: $page) {
      collection {
        ${projectReport}
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {pathOr} from 'ramda';

import {
  Box,
  Flex,
  SelectField,
  Textarea,
  Checkbox,
  AlertWithIcon,
} from '@renofi/components-internal';
import {getFormattedAddress} from '@renofi/utilities/src/data';

const REASONS = {
  no_longer_contractor: 'No longer the contractor for the renovation',
  previously_passed: 'Previously passed a due diligence review',
  not_required: "Not required, doesn't need RU",
  duplicate_entry: 'Duplicate entry by homeowner',
};

const DUPLICATE_ENTRY = 'duplicate_entry';

const getMessage = (gcddReview) => {
  const clientName = gcddReview?.project?.primaryApplicant?.fullName;
  const clientAddress = getFormattedAddress(gcddReview?.project);
  const gcName = pathOr('there', ['contractor', 'businessName'], gcddReview);
  return `Hi ${gcName},

After an initial review of the renovation project for ${clientName} at ${clientAddress}, you are no longer required to complete the Contractor questionnaire previously requested. Apologies for any inconvenience caused and please do not hesitate to contact us should you have questions on this matter.
  
Respectfully,
  
The RenoFi Operations Team`;
};

function CancelGcddReview({gcddReview, onChange}) {
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState(getMessage(gcddReview));
  const [isSendEmailNotification, setIsSendEmailNotification] = useState(true);
  const isDuplicateEntry = reason === DUPLICATE_ENTRY;
  const isReasonEmpty = reason === '';
  const isMessageVisible = !isDuplicateEntry && !isReasonEmpty;

  const handleSetReason = (reason) => {
    const isReasonDuplicate = reason === DUPLICATE_ENTRY;

    setReason(reason);
    setIsSendEmailNotification(!isReasonDuplicate);
  };

  const handleSendEmailNotification = () => {
    setIsSendEmailNotification(!isSendEmailNotification);
  };

  useEffect(() => {
    onChange({reason, message, isSendEmailNotification});
  }, [reason, message, isSendEmailNotification]);

  return (
    <Flex flexDirection="column">
      <Box>
        <SelectField
          label="Reason for cancelling the request"
          options={REASONS}
          onChange={handleSetReason}
          placeholder="Select a reason for cancellation"
          value={reason}
        />
      </Box>
      {isDuplicateEntry && (
        <AlertWithIcon variant="info" mb={24}>
          Please note: The cancelation email will NOT be sent to the Contractor.
        </AlertWithIcon>
      )}
      {isMessageVisible && (
        <>
          <Box mb={33}>
            <Checkbox
              label="Send email notification to the Contractor"
              onChange={handleSendEmailNotification}
              checked={isSendEmailNotification}
              fontSize={14}
            />
          </Box>
          {isSendEmailNotification && (
            <Box>
              <Textarea
                rows={10}
                label="Message"
                onChange={setMessage}
                placeholder="Please include a message"
                value={message}
              />
            </Box>
          )}
        </>
      )}
    </Flex>
  );
}

CancelGcddReview.propTypes = {
  gcddReview: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default CancelGcddReview;

import {withViewerLayout} from 'api/hoc';

import {compose, mapProps} from '@renofi/recompose/src';

import Component from './Component';

export default compose(
  withViewerLayout(),
  mapProps(({studyItems, ...props}) => ({
    totalCost: studyItems.reduce((total, {cost}) => total + cost, 0),
    // totalCalculatedCost: studyItems.reduce(
    //   (total, {adjustedCost, cost}) => total + (adjustedCost || cost),
    //   0,
    // ),
    ...props,
  })),
)(Component);

import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {Button} from './styled';

const LinkButton = ({active, children, onClick}) => {
  const [hover, setHover] = useState(false);

  return (
    <Button
      onClick={onClick}
      css={{width: 56}}
      active={active}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      {children({active, hover})}
    </Button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.func.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default LinkButton;

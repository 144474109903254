import {isNil, prop, propOr} from 'ramda';

const INPUT_FIELDS = [
  'businessName',
  'city',
  'state',
  'streetAddress',
  'zipCode',
];
const DOC_FIELDS = ['insurances', 'licenses'];

export default function getGcddReviewContractorStatus(gcddReview) {
  const contractor = propOr(null, 'contractor', gcddReview);
  const allFieldsValid = INPUT_FIELDS.every(
    (key) => !isNil(prop(key, contractor)),
  );
  const areDocsGiven = DOC_FIELDS.every((key) =>
    Boolean(propOr([], key, contractor).length),
  );

  return allFieldsValid && areDocsGiven ? 'completed' : 'processing';
}

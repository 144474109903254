import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic88, white} from '@renofi/theme';

export const ArrowWrapper = styled(Flex)(({disabled}) => ({
  height: 30,
  cursor: disabled ? 'auto' : 'pointer',
  userSelect: disabled ? 'none' : 'auto',
  background: 'rgba(32,32,32,0.4)',

  color: white,
  fontSize: 12,
  lineHeight: '14px',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  flexShrink: 0,
  opacity: disabled ? 0.6 : 1,

  ':hover': {
    color: white,
    textDecoration: 'none',
    background: !disabled ? basic88 : 'rgba(32,32,32,0.4)',
  },
}));

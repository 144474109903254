export const YEARS_AS_CONTRACTOR_MAPPER = {
  0: 'Less than 1 year',
  1: '1-4 years',
  5: '5-9 years',
  10: '10-19 years',
  20: '20-29 years',
  31: 'More than 30 years',
};

export const NUMBER_OF_COMPLETED_RENOVATIONS_MAPPER = {
  49: 'Less than 50',
  99: 'Less than 100',
  249: 'Less than 250',
  251: 'More than 250',
};

export const LAST_TWO_YEARS_PERCENTAGE_MAPPER = {
  9: 'Less than 10',
};

export const MARKETING_SPEND_OPTIONS = {
  0: 'I’m not sure',
  4: 'Less than 5%',
  9: 'Less than 10%',
  10: '10% or more',
};

export const EXPERT_DESIGN_OPTIONS = {
  9: 'Less than 10%',
  10: '10-25%',
  25: '25-50%',
  51: 'More than 50%',
};

export const RENOVATION_AMOUNT_RANGES = [
  'Less than $50k',
  'Between $50k - $250k',
  'Between $250k - $500k',
  'Between $500k - $1m',
  'Greater than $1m',
];

import {pathOr} from 'ramda';

import {taskTypes} from '@renofi/utilities/src/enums';

import {ONBOARDING_VALUE, PERSONAL_INFO_VALUE} from './constants';

const hasOnboardingTask = (tasks) => {
  return tasks.some((task) => task.taskType === taskTypes.borrowers_onboarding);
};

const hasBorrowers = (tasks) => {
  const onboardingTask = tasks.find(
    ({taskType}) => taskType === taskTypes.borrowers_onboarding,
  );
  const borrowersNumber = pathOr(
    null,
    ['borrowersAndPropertyInformation', 'borrowersNumber'],
    onboardingTask,
  );
  const hasBorrowers = borrowersNumber > 0;

  return hasBorrowers;
};

const hasPersonalInformationTask = (tasks) => {
  return tasks.some(
    ({taskType}) => taskType === taskTypes.personal_info_and_residency,
  );
};

const isTaskPresentInList = (list = [], value) => {
  return list.includes(value);
};

const mapTemplateOptions = ({id, title, taskType}) => ({
  label: `${taskType} - ${title}`,
  value: id,
});

export const generateTemplateOptions = (tasks, taskTemplates) => {
  let tasksToRemove = [];
  const taskTemplateOptions = taskTemplates.map(mapTemplateOptions);

  if (!hasOnboardingTask(tasks)) {
    tasksToRemove = [PERSONAL_INFO_VALUE];

    return taskTemplateOptions.filter(({value}) => {
      const shouldRemoveTask = isTaskPresentInList(tasksToRemove, value);

      return !shouldRemoveTask;
    });
  }

  if (!hasBorrowers(tasks)) {
    tasksToRemove = [ONBOARDING_VALUE, PERSONAL_INFO_VALUE];

    return taskTemplateOptions.filter(({value}) => {
      const shouldRemoveTask = isTaskPresentInList(tasksToRemove, value);

      return !shouldRemoveTask;
    });
  }

  tasksToRemove = [
    ONBOARDING_VALUE,
    ...(hasPersonalInformationTask(tasks) ? [PERSONAL_INFO_VALUE] : []),
  ];

  return taskTemplateOptions.filter(({value}) => {
    const shouldRemoveTask = isTaskPresentInList(tasksToRemove, value);

    return !shouldRemoveTask;
  });
};

export const isTaskEmpty = (taskType) => !taskType;

import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';
import {isFeasibilityReviewerSource} from 'lib/sourceHelper';

import {Text, Textarea} from '@renofi/components-internal';
import {dangerLight} from '@renofi/theme';

import Container from '../../../Container';
import RowItem from '../../../RowItem';

const ReviewItem = ({
  disabled,
  currentSource,
  label,
  notes,
  isApproved,
  onChangeNotes,
  onToggleIsApproved,
}) => {
  const isReviewer = isFeasibilityReviewerSource(currentSource);

  const [text, setText] = useState(notes ?? '');
  const onChangeText = useCallback((value) => {
    setText(value);
    onChangeNotes(value);
  });

  return (
    <>
      <RowItem
        value={isApproved}
        disabled={disabled}
        onAccept={() => onToggleIsApproved(true)}
        onReject={() => onToggleIsApproved(false)}>
        {label}
      </RowItem>
      {isApproved === false && (isReviewer || Boolean(notes)) && (
        <Container px={16} pt={16} pb={0} inverse>
          <Textarea
            rows={3}
            value={text}
            label="Reason for fail"
            help={!text ? <Text color={dangerLight}>Required</Text> : null}
            onChange={onChangeText}
          />
        </Container>
      )}
    </>
  );
};

ReviewItem.propTypes = {
  disabled: PropTypes.bool,
  currentSource: PropTypes.string.isRequired,
  label: PropTypes.node,
  layout: PropTypes.string.isRequired,
  notes: PropTypes.string,
  isApproved: PropTypes.bool,
  onChangeNotes: PropTypes.func.isRequired,
  onToggleIsApproved: PropTypes.func.isRequired,
};

export default ReviewItem;

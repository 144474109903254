const ENVIRONMENT = process.env.REACT_APP_NODE_ENV || 'development';

export const BLUEPRINT_SERVICE_ACCOUNT_ID =
  process.env.REACT_APP_BLUEPRINT_SERVICE_ACCOUNT_ID;
export const REVIEWERS_SERVICE_ACCOUNT_ID =
  process.env.REACT_APP_REVIEWERS_SERVICE_ACCOUNT_ID;
export const PRODUCTION = ENVIRONMENT === 'production';

window._config = {
  NODE_ENV: process.env.NODE_ENV,
  REVIEWERS_SERVICE_ACCOUNT_ID,
  BLUEPRINT_SERVICE_ACCOUNT_ID,
  PRODUCTION,
};

import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {SUBMIT_EXCEPTION_REQUEST} from '../mutations';
import {GET_PROJECT} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(SUBMIT_EXCEPTION_REQUEST, {
      name: 'submitExceptionRequest',
    }),
    withHandlers({
      submitExceptionRequest:
        ({client, submitExceptionRequest}) =>
        async ({metrics, ...variables}) => {
          const response = await submitExceptionRequest({
            variables: {
              ...variables,
              metrics: metrics.map(({value, ...metric}) => ({
                ...metric,
                value: `${value}`,
              })),
            },
          });
          const exceptionRequest = pathOr(
            null,
            ['data', 'submitExceptionRequest', 'exceptionRequest'],
            response,
          );
          if (exceptionRequest) {
            const {projectId} = variables;
            const {projectById: project} = client.readQuery({
              query: GET_PROJECT,
              variables: {
                id: projectId,
              },
            });
            client.writeQuery({
              query: GET_PROJECT,
              variables: {
                id: projectId,
              },
              data: {
                projectById: {
                  ...project,
                  exceptionRequests:
                    project.exceptionRequests.concat(exceptionRequest),
                },
              },
            });
          }
          return response;
        },
    }),
  );

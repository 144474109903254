import {isEmpty, isNil, keys} from 'ramda';

import {isEmail} from '@renofi/utilities/src/validators';

import {
  PROPERTY_ADDRESS_FIELDS,
  REQUIRED_APPRAISAL_ORDER_FIELDS,
} from './constants';

// These are not required, and the UI doesn't allow editing...
const isNullish = (obj) => isNil(obj) || isEmpty(obj);

export default ({amcCode, appraisalProduct, orderParams = {}}) => {
  // const isFinalInspection = propOr(false, 'finalInspection', appraisalProduct);
  const totalKeys = [];

  if (!amcCode) {
    totalKeys.push('amcCode');
  }

  if (!appraisalProduct?.renofiFormType) {
    totalKeys.push('formType');
  }

  return keys(orderParams).reduce(
    (arr, key) => {
      const value = orderParams[key];
      const isRequired = REQUIRED_APPRAISAL_ORDER_FIELDS.includes(key);
      const isPropertyDetails = key === 'propertyDetails';
      const isEmailField = key === 'contactEmail';

      switch (true) {
        case isEmailField:
          return !isEmail(value) ? arr.concat(key) : arr;
        case isRequired:
          return isNullish(value) ? arr.concat(key) : arr;
        case isPropertyDetails:
          return arr.concat(
            PROPERTY_ADDRESS_FIELDS.filter((k) => isNullish(value[k])),
          );
        default:
          return arr;
      }
    },
    [...totalKeys],
  );
};

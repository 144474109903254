import {propOr, pipe, isNil, not} from 'ramda';

export const getStyles = (wide) => {
  const textAlign = wide ? 'right' : 'left';
  const alignItems = wide ? 'center' : 'flex-start';
  const flexDirection = wide ? 'row' : 'column';
  const justifyContent = wide ? 'space-between' : 'flex-start';
  const columnWidth = wide ? 1 / 2 : 1;
  const marginSide = wide ? '1px' : 0;
  const radiusLeft = wide ? '4px 0 0 0' : '4px 4px 0 0';
  const radiusRight = wide ? '0 4px 0 0' : '0 0 0 0';

  return {
    textAlign,
    alignItems,
    flexDirection,
    justifyContent,
    columnWidth,
    marginSide,
    radiusLeft,
    radiusRight,
  };
};

export const calculateTotalFieldValue = (
  fieldName = '',
  termsOfPayments = [],
) => {
  return termsOfPayments
    .map(propOr(0, fieldName))
    .filter(pipe(isNil, not))
    .reduce((sum, val) => sum + val, 0);
};

export const getRenovationBudgetRemainingPercentage = (
  renovationBudgetRemaining,
  totalRenovationBudget,
) => {
  if (
    isNil(renovationBudgetRemaining) ||
    isNil(totalRenovationBudget) ||
    totalRenovationBudget === 0
  ) {
    return null;
  }

  return Math.round((renovationBudgetRemaining / totalRenovationBudget) * 100);
};

export const parseNumber = (value) => (value ? parseInt(value) : null);

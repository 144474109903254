import styled from '@emotion/styled';

import {Button} from '@renofi/components-internal';
import {
  basic75,
  basic20,
  actionDark,
  white,
  actionRegular,
} from '@renofi/theme/src/colors';

export const AssignButton = styled(Button)({
  background: basic75,
  border: '2px solid rgba(115, 115, 115, 0.4)',
  color: basic20,
  '&:hover': {
    background: actionDark,
    border: `2px solid ${actionRegular}`,
    color: white,
  },
});

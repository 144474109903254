import React from 'react';

import {
  equals,
  sortBy,
  prop,
  identity,
  pipe,
  map,
  propOr,
  flatten,
  propEq,
  find,
  isEmpty,
} from 'ramda';
import {StatusBadge} from 'modules/viewer-v2';

const getStatusOfReturnedReview = (status, isReviewValid) => {
  if (status === 'fail') return 'Fail';
  if (status === 'pass') return 'Pass';
  if (status === 'pending') return 'Pending review';
  if (isReviewValid) return 'Pass';
  return '';
};

const getContractReviewStatus = ({
  isReturned,
  isContractReviewValid,
  contractReviewStatus,
}) => {
  if (isReturned) {
    const status = getStatusOfReturnedReview(
      contractReviewStatus,
      isContractReviewValid,
    );
    return status;
  }

  if (isContractReviewValid) {
    return 'Reviewed';
  }

  return 'Pending review';
};

const getFeasibilityStudyStatus = ({
  isFeasibilityStudyValid,
  isReturned,
  feasibilityStudyStatus,
}) => {
  if (isReturned) {
    const status = getStatusOfReturnedReview(
      feasibilityStudyStatus,
      isFeasibilityStudyValid,
    );
    return status;
  }

  if (isFeasibilityStudyValid) {
    return 'Reviewed';
  }

  return 'Pending review';
};

const getVariant = (status) => {
  if (status === 'Fail') return 'danger';
  if (status === 'Pass' || status === 'Reviewed') return 'success';
  if (status === 'Pending review') return 'warning';
};

const getInverse = (status) => {
  return status === 'Fail' || status === 'Pass';
};

export const calculateCanUpdate = ({feasibilityReview, tasks = []}) => {
  const {returnedAt, completedAt, requestedAt, reviewer} =
    feasibilityReview || {};

  const isReturned = Boolean(returnedAt);
  const isCompleted = Boolean(completedAt);
  const isRequested = Boolean(requestedAt && reviewer);

  const documents = pipe(map(propOr([], 'documents')), flatten)(tasks);

  const propertyPhotos = pipe(
    find(propEq('taskType', 'appraisal_property_photos')),
    propOr([], 'appraisalPropertyPhotos'),
  )(tasks);

  const isDocumentsEqual = equals(
    sortBy(identity, documents.map(prop('id'))),
    sortBy(
      identity,
      (feasibilityReview.documents.length
        ? feasibilityReview.documents
        : documents
      ).map(prop('id')),
    ),
  );

  const isPropertyPhotosEqual = equals(
    sortBy(identity, propertyPhotos.map(prop('id'))),
    sortBy(
      identity,
      (feasibilityReview.propertyPhotos.length
        ? feasibilityReview.propertyPhotos
        : propertyPhotos
      ).map(prop('id')),
    ),
  );

  return (
    isRequested &&
    !isReturned &&
    !isCompleted &&
    (!isDocumentsEqual || !isPropertyPhotosEqual)
  );
};

export const calculateCanRequestSecond = ({
  feasibilityReview,
  feasibilityReviews,
}) => {
  const {returnedAt, completedAt, requestedAt, reviewer} =
    feasibilityReview || {};

  const isReturned = Boolean(returnedAt);
  const isCompleted = Boolean(completedAt);
  const isRequested = Boolean(requestedAt && reviewer);

  const hasOneFeasibilityReview = feasibilityReviews?.length === 1;

  return isRequested && isReturned && !isCompleted && hasOneFeasibilityReview;
};

export const isActionsDropdownEnabled = ({
  feasibilityReview,
  hasFeasibilityEditPermission,
}) => {
  const {completedAt, returnedAt, canceledAt} = feasibilityReview || {};
  const isCompleted = Boolean(completedAt);
  const isReturned = Boolean(returnedAt);

  return (
    !canceledAt &&
    !isCompleted &&
    (isReturned || (!isReturned && hasFeasibilityEditPermission))
  );
};

export const getTabs = ({feasibilityReview, validation}) => {
  const isContractReviewValid = isEmpty(validation.contractReview);
  const isFeasibilityStudyValid = isEmpty(validation.feasibilityStudy);

  const {feasibilityStudyStatus, contractReviewStatus, returnedAt} =
    feasibilityReview || {};

  const isReturned = Boolean(returnedAt);

  const contractReviewTabStatus = getContractReviewStatus({
    isReturned,
    isContractReviewValid,
    contractReviewStatus,
  });
  const feasibilityReviewTabStatus = getFeasibilityStudyStatus({
    isFeasibilityStudyValid,
    isReturned,
    feasibilityStudyStatus,
  });

  return {
    contractReview: (
      <>
        Contract review
        <StatusBadge
          ml="8px"
          inverse={getInverse(contractReviewTabStatus)}
          variant={getVariant(contractReviewTabStatus)}>
          {contractReviewTabStatus}
        </StatusBadge>
      </>
    ),
    feasibilityStudy: (
      <>
        Feasibility study
        <StatusBadge
          ml="8px"
          inverse={getInverse(feasibilityReviewTabStatus)}
          variant={getVariant(feasibilityReviewTabStatus)}>
          {feasibilityReviewTabStatus}
        </StatusBadge>
      </>
    ),
  };
};

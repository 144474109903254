import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Box, Card, Flex, Table} from '@renofi/components-internal';
import ExternalIcon from '@renofi/icons/src/External';
import {actionRegular} from '@renofi/theme/src/colors';

import {Td, Th, Link, Url} from './styled';
import ShareableCheckbox from './components/ShareableCheckbox';

function ComparableSales({onChangeShareable, task}) {
  const sales = propOr([], 'appraisalComparableSaleUrls', task);

  return (
    <Flex mt={32} pb={32} width={1} justifyContent="center">
      <Box css={{maxWidth: 860}}>
        <Card title="Recent sales of similar properties" p={0}>
          <Table css={{width: '100%', verticalAlign: 'middle'}}>
            <thead>
              <tr>
                <Th>Comps</Th>
                <Th>Share with Appraiser</Th>
              </tr>
            </thead>
            <tbody>
              {sales.map(({id, shareableWithAmc, url}, index) => (
                <tr key={index}>
                  <Td>
                    <Flex alignItems="center">
                      <Box>Property #{index + 1}</Box>

                      <Link blank href={url}>
                        <Url mx={3}>{url}</Url>
                        <ExternalIcon
                          css={{}}
                          color={actionRegular}
                          width={16}
                        />
                      </Link>
                    </Flex>
                  </Td>
                  <Td>
                    <ShareableCheckbox
                      onChange={(shareable) =>
                        onChangeShareable({id, shareable})
                      }
                      shareableWithAmc={shareableWithAmc}
                    />
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Box>
    </Flex>
  );
}

ComparableSales.propTypes = {
  onChangeShareable: PropTypes.func.isRequired,
  task: PropTypes.shape({
    appraisalComparableSales: PropTypes.shape({
      urls: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
};

export default ComparableSales;

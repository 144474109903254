import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose/src';

import {REJECT_CONTRACTOR} from '../mutations';
import {GET_PROJECT} from '../queries';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(REJECT_CONTRACTOR, {
      name: 'rejectContractor',
    }),
    withHandlers({
      rejectContractor:
        ({client, rejectContractor}) =>
        async ({rejectionReason, contractorId, projectId, message}) => {
          const response = await rejectContractor({
            variables: {
              rejectionReason,
              contractorId,
              message,
            },
          });
          const success = pathOr(
            null,
            ['data', 'rejectContractorInformation', 'success'],
            response,
          );
          if (success) {
            const result = await client.query({
              query: GET_PROJECT,
              variables: {id: projectId},
              fetchPolicy: 'no-cache',
            });
            const project = pathOr(null, ['data', 'projectById'])(result);
            client.writeQuery({
              query: GET_PROJECT,
              variables: {
                id: projectId,
              },
              data: {
                projectById: project,
              },
            });
          }
        },
    }),
  );

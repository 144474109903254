import React from 'react';

import {find, propEq} from 'ramda';
import {
  useTaskByCurrentItemId,
  useViewerNavigation,
} from 'modules/viewer-v2/hooks';
import PdfDocumentView from 'modules/viewer-v2/components/PdfDocumentView';

import PropertyPhotos from './PropertyPhotos';

const FeasibilityReviewItemView = () => {
  const {search} = useViewerNavigation();
  const {documentId, taskId} = search || {};
  const {loading, projectId, project, task} = useTaskByCurrentItemId({taskId});

  const props = {loading, project, task, projectId};
  const {documents = [], taskType} = task || {};

  switch (taskType) {
    case 'renovation_contract':
    case 'renovation_plan':
      const doc = find(propEq('id', documentId), documents);
      return <PdfDocumentView doc={doc} />;
    case 'appraisal_property_photos':
      const photos = task?.appraisalPropertyPhotos || [];
      return <PropertyPhotos {...props} photos={photos} />;
    default:
      return null;
  }
};

export default FeasibilityReviewItemView;

import {compose, withHandlers} from '@renofi/recompose/src';
import {withApollo} from '@renofi/graphql/src/hoc';

import {GET_APPLICATION_STATE} from '../queries';

export default () =>
  compose(
    withApollo,
    withHandlers({
      resetMessage:
        ({client}) =>
        () => {
          const {application} = client.readQuery({
            query: GET_APPLICATION_STATE,
          });
          client.writeQuery({
            query: GET_APPLICATION_STATE,
            data: {
              application: {
                ...application,
                message: {
                  type: 'documents',
                  active: false,
                  reason: null,
                  rejectionReason: null,
                  showReasonDropdown: true,
                  messageContent: '',
                  documents: [],
                  tasks: [],
                  __typename: 'Message',
                },
                __typename: 'Application',
              },
            },
          });
        },
    }),
  );

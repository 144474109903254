import styled from '@emotion/styled';

export const Wrapper = styled.div(
  {
    position: 'fixed',
    bottom: 0,
    right: 0,
    transition: 'all 200ms ease-out',
    zIndex: 9,
  },
  ({wide}) => ({
    width: wide ? 600 : 300,
  }),
);

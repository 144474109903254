import {pathOr} from 'ramda';

export const CONTACT_DETAILS_PATHS = [
  ['questionnaire', 'contactFirstName'],
  ['questionnaire', 'contactLastName'],
  ['questionnaire', 'contactTitle'],
  ['questionnaire', 'phoneNumber'],
  ['questionnaire', 'email'],
  // ['questionnaire', 'website'],
];

export const COMPANY_DETAILS_PATHS = [
  ['questionnaire', 'businessName'],
  ['questionnaire', 'businessType'],
  // ['questionnaire', 'businessTypeOtherDetails'],
  ['questionnaire', 'city'],
  ['questionnaire', 'officePhoneNumber'],
  ['questionnaire', 'streetAddress'],
  ['questionnaire', 'state'],
  ['questionnaire', 'teamSize'],
  ['questionnaire', 'zipCode'],
];

export const hasAllValues = (paths = [], review) =>
  paths.every((path) => Boolean(pathOr(false, path, review)));

import React from 'react';

import PropTypes from 'prop-types';

import {
  Flex,
  ModalWithButtons,
  SelectField,
  Textarea,
} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {usePreview} from '@renofi/utilities/src/hooks';

const reasonOptions = {
  missing_documents: 'Missing documents',
  incorrect_documents: 'Incorrect documents',
  illegible_documents: 'Illegible documents',
};

const Uncompleted = ({
  complete,
  details,
  disabled,
  loading,
  preview,
  reason,
  onChangeDetails,
  onChangeReason,
  onClose,
  onSubmit,
}) => {
  if (complete) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePreview(preview, 'feasibilityReview');
  }
  return (
    <ModalWithButtons
      show
      lock
      fixed
      mb={24}
      width={600}
      disabled={disabled && !complete}
      loading={loading}
      rejectLabel="Close"
      acceptLabel="Return to renofi"
      onAccept={onSubmit}
      onCancel={onClose}
      onClose={onClose}
      header="Returning feasibility & contract review to Renofi"
      css={{zIndex: 9999}}>
      <ThemeProvider theme="light">
        {!complete && (
          <Flex flexDirection="column">
            <SelectField
              value={reason}
              options={reasonOptions}
              label="Reason for returning uncompleted?"
              placeholder="Select reason"
              onChange={onChangeReason}
            />
            <Textarea
              rows={6}
              value={details}
              label="Please provide details to help us resolve the issue."
              onChange={onChangeDetails}
            />
          </Flex>
        )}
        {complete && <div id="feasibilityReview" />}
      </ThemeProvider>
    </ModalWithButtons>
  );
};

Uncompleted.propTypes = {
  complete: PropTypes.bool,
  details: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  preview: PropTypes.object,
  reason: PropTypes.string,
  onChangeDetails: PropTypes.func.isRequired,
  onChangeReason: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Uncompleted;

import noop from '@renofi/utilities/src/noop';

import {GET_ALL_PERMISSIONS} from '../../queries';
import useLazyQuery from '../useLazyQuery';

export default function useGetAllPermissions({onCompleted = noop} = {}) {
  const [getAllPermissions, {data, loading, error}] = useLazyQuery(
    GET_ALL_PERMISSIONS,
    {
      onCompleted,
    },
  );

  return {
    getAllPermissions,
    allPermissions: data?.permissions,
    loading,
    error,
  };
}

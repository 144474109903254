import React from 'react';

import PropTypes from 'prop-types';
import {find, propEq} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import PdfDocumentView from 'modules/viewer-v2/components/PdfDocumentView';

import {validateUuidProp} from '@renofi/utilities/src/react';
import {
  useProject,
  useSetAppraisalComparableSaleUrlShareable,
} from '@renofi/graphql';

import PropertyPhotos from '../../../FeasibilityReviewType/components/PropertyPhotos';

import PersonalAndResidency from './components/PersonalAndResidency';
import ComparableSales from './components/ComparableSales';
import PropertyDetails from './components/PropertyDetails';
import RenovationDetails from './components/RenovationDetails';

const TaskItemView = ({task}) => {
  const {params, search} = useViewerNavigation();
  const {projectId} = params;
  const {documentId} = search || {};
  const {loading: isLoadingProject, project} = useProject({
    variables: {id: projectId},
  });
  const {loading: isSharing, setAppraisalComparableSaleUrlShareable} =
    useSetAppraisalComparableSaleUrlShareable();

  const {documents = [], taskType} = task || {};
  const loading = isLoadingProject || isSharing;
  const props = {loading, project, task, projectId};

  const onChangeShareable = (variables) => {
    setAppraisalComparableSaleUrlShareable({
      variables,
    });
  };

  switch (true) {
    case taskType === 'personal_info_and_residency':
      return <PersonalAndResidency task={task} />;
    case taskType === 'appraisal_comparable_sales':
      return (
        <ComparableSales {...props} onChangeShareable={onChangeShareable} />
      );
    case taskType === 'appraisal_property_details':
      return <PropertyDetails {...props} />;
    case taskType === 'appraisal_renovation_details':
      return <RenovationDetails {...props} />;
    case taskType === 'appraisal_property_photos':
      const photos = task?.appraisalPropertyPhotos || [];
      return <PropertyPhotos photos={photos} {...props} />;
    case Boolean(documentId):
      const doc = find(propEq('id', documentId), documents);
      return <PdfDocumentView doc={doc} />;
    default:
      return null;
  }
};

TaskItemView.propTypes = {
  task: PropTypes.shape({
    appraisalPropertyPhotos: PropTypes.array,
    id: validateUuidProp,
    personalInfoAndResidency: PropTypes.shape({
      id: validateUuidProp,
    }),
    taskType: PropTypes.string.isRequired,
  }),
};

export default TaskItemView;

import {useEffect, useState} from 'react';

import {mobile} from '@renofi/theme';

const getSize = (width = mobile) =>
  global?.window?.matchMedia(`(max-width: ${width - 1}px)`)?.matches;

export default function useScreenSize(width) {
  const [isMobile, setIsMobile] = useState(getSize(width));
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isAndroid = /(android)/i.test(global?.navigator?.userAgent);

  useEffect(() => {
    function handleResize() {
      setIsMobile(getSize(width));
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    isDesktop: !isMobile,
    isFirefox,
    isAndroid,
  };
}

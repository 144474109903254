import {MILLISECONDS_PER_DAY} from './constants';

export const getDaysDuration = (from, to) => {
  if (!from) {
    return null;
  }
  const toDate = to ? new Date(to) : new Date();
  const diff = toDate - new Date(from);
  const diffInDays = Math.round(diff / MILLISECONDS_PER_DAY) + 1;
  return `${diffInDays} day${diffInDays === 1 ? '' : 's'}`;
};

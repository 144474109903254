import {makeVar, useReactiveVar} from '@apollo/client';

const borrowerVar = makeVar('borrower');

const useBorrower = () => {
  const activeBorrower = useReactiveVar(borrowerVar);

  const setBorrower = (value) => {
    borrowerVar(value);
  };

  return {activeBorrower, setBorrower};
};

export default useBorrower;
